import { TalentDiscoveryMapV2, PopupDataKey } from '@revelio/d3';
import { TalentDiscoveryDataQuery } from '@revelio/data-access';
import { Card } from '@revelio/layout';

const popupDataKeys = [
  'headcount',
  'postings',
  'timeToFill',
  'salaryDist',
] as PopupDataKey[];

export const CompanyMap = ({
  mapData,
}: {
  mapData: TalentDiscoveryDataQuery['talent_discovery_search_v1_5']['map_data'];
}) => {
  return (
    <Card p="12px">
      <TalentDiscoveryMapV2
        data={{
          data: mapData || [],
          popupDataKeys,
        }}
        minZoomLevel={1}
        tooltipHeight={200}
        initialCoords={{
          lng: 20,
          lat: 15,
        }}
      />
    </Card>
  );
};
