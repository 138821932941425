export const mtMetro = [
  {
    percentile: 0,
    z_score: -1.490233826,
  },
  {
    percentile: 0.1184834123,
    z_score: -1.294787997,
  },
  {
    percentile: 0.2369668246,
    z_score: -1.2623966,
  },
  {
    percentile: 0.355450237,
    z_score: -1.172098092,
  },
  {
    percentile: 0.4739336493,
    z_score: -1.15669446,
  },
  {
    percentile: 0.5924170616,
    z_score: -0.8919400688,
  },
  {
    percentile: 0.7109004739,
    z_score: -0.8257256118,
  },
  {
    percentile: 0.8293838863,
    z_score: -0.7044287633,
  },
  {
    percentile: 0.9478672986,
    z_score: -0.6755298248,
  },
  {
    percentile: 1.066350711,
    z_score: -0.6736768724,
  },
  {
    percentile: 1.184834123,
    z_score: -0.6665439699,
  },
  {
    percentile: 1.303317536,
    z_score: -0.657961402,
  },
  {
    percentile: 1.421800948,
    z_score: -0.6379724379,
  },
  {
    percentile: 1.54028436,
    z_score: -0.6191266742,
  },
  {
    percentile: 1.658767773,
    z_score: -0.6119131898,
  },
  {
    percentile: 1.777251185,
    z_score: -0.5875785547,
  },
  {
    percentile: 1.895734597,
    z_score: -0.5778788907,
  },
  {
    percentile: 2.014218009,
    z_score: -0.5739604017,
  },
  {
    percentile: 2.132701422,
    z_score: -0.5438264998,
  },
  {
    percentile: 2.251184834,
    z_score: -0.5344191009,
  },
  {
    percentile: 2.369668246,
    z_score: -0.5318521479,
  },
  {
    percentile: 2.488151659,
    z_score: -0.5014198489,
  },
  {
    percentile: 2.606635071,
    z_score: -0.4963063958,
  },
  {
    percentile: 2.725118483,
    z_score: -0.4794431971,
  },
  {
    percentile: 2.843601896,
    z_score: -0.4741094464,
  },
  {
    percentile: 2.962085308,
    z_score: -0.4711112688,
  },
  {
    percentile: 3.08056872,
    z_score: -0.4526900439,
  },
  {
    percentile: 3.199052133,
    z_score: -0.4386954831,
  },
  {
    percentile: 3.317535545,
    z_score: -0.4323803185,
  },
  {
    percentile: 3.436018957,
    z_score: -0.4306733308,
  },
  {
    percentile: 3.55450237,
    z_score: -0.4281945574,
  },
  {
    percentile: 3.672985782,
    z_score: -0.4271102175,
  },
  {
    percentile: 3.791469194,
    z_score: -0.4254393363,
  },
  {
    percentile: 3.909952607,
    z_score: -0.425136462,
  },
  {
    percentile: 4.028436019,
    z_score: -0.4220183614,
  },
  {
    percentile: 4.146919431,
    z_score: -0.4212377717,
  },
  {
    percentile: 4.265402844,
    z_score: -0.419497036,
  },
  {
    percentile: 4.383886256,
    z_score: -0.417723531,
  },
  {
    percentile: 4.502369668,
    z_score: -0.4168634364,
  },
  {
    percentile: 4.620853081,
    z_score: -0.4167949986,
  },
  {
    percentile: 4.739336493,
    z_score: -0.4144938911,
  },
  {
    percentile: 4.857819905,
    z_score: -0.4137012264,
  },
  {
    percentile: 4.976303318,
    z_score: -0.413270249,
  },
  {
    percentile: 5.09478673,
    z_score: -0.4119630409,
  },
  {
    percentile: 5.213270142,
    z_score: -0.4118268399,
  },
  {
    percentile: 5.331753555,
    z_score: -0.4091100399,
  },
  {
    percentile: 5.450236967,
    z_score: -0.405915955,
  },
  {
    percentile: 5.568720379,
    z_score: -0.4056431001,
  },
  {
    percentile: 5.687203791,
    z_score: -0.4052269709,
  },
  {
    percentile: 5.805687204,
    z_score: -0.4030791423,
  },
  {
    percentile: 5.924170616,
    z_score: -0.4028189495,
  },
  {
    percentile: 6.042654028,
    z_score: -0.4019255378,
  },
  {
    percentile: 6.161137441,
    z_score: -0.4001241774,
  },
  {
    percentile: 6.279620853,
    z_score: -0.3998608643,
  },
  {
    percentile: 6.398104265,
    z_score: -0.399401467,
  },
  {
    percentile: 6.516587678,
    z_score: -0.3963676376,
  },
  {
    percentile: 6.63507109,
    z_score: -0.394313007,
  },
  {
    percentile: 6.753554502,
    z_score: -0.3929925935,
  },
  {
    percentile: 6.872037915,
    z_score: -0.3921234772,
  },
  {
    percentile: 6.990521327,
    z_score: -0.3917897904,
  },
  {
    percentile: 7.109004739,
    z_score: -0.3881787458,
  },
  {
    percentile: 7.227488152,
    z_score: -0.3881640007,
  },
  {
    percentile: 7.345971564,
    z_score: -0.3869505466,
  },
  {
    percentile: 7.464454976,
    z_score: -0.3863663534,
  },
  {
    percentile: 7.582938389,
    z_score: -0.3854562784,
  },
  {
    percentile: 7.701421801,
    z_score: -0.3850318197,
  },
  {
    percentile: 7.819905213,
    z_score: -0.3849958377,
  },
  {
    percentile: 7.938388626,
    z_score: -0.384940735,
  },
  {
    percentile: 8.056872038,
    z_score: -0.3845104202,
  },
  {
    percentile: 8.17535545,
    z_score: -0.3839737893,
  },
  {
    percentile: 8.293838863,
    z_score: -0.3825938127,
  },
  {
    percentile: 8.412322275,
    z_score: -0.3823252559,
  },
  {
    percentile: 8.530805687,
    z_score: -0.3808872355,
  },
  {
    percentile: 8.6492891,
    z_score: -0.3804928237,
  },
  {
    percentile: 8.767772512,
    z_score: -0.379356818,
  },
  {
    percentile: 8.886255924,
    z_score: -0.3788825686,
  },
  {
    percentile: 9.004739336,
    z_score: -0.3783354166,
  },
  {
    percentile: 9.123222749,
    z_score: -0.3775528988,
  },
  {
    percentile: 9.241706161,
    z_score: -0.3774577874,
  },
  {
    percentile: 9.360189573,
    z_score: -0.3765799176,
  },
  {
    percentile: 9.478672986,
    z_score: -0.3760126311,
  },
  {
    percentile: 9.597156398,
    z_score: -0.375977086,
  },
  {
    percentile: 9.71563981,
    z_score: -0.3737168812,
  },
  {
    percentile: 9.834123223,
    z_score: -0.372606515,
  },
  {
    percentile: 9.952606635,
    z_score: -0.3724558231,
  },
  {
    percentile: 10.071090047,
    z_score: -0.372329989,
  },
  {
    percentile: 10.18957346,
    z_score: -0.371171722,
  },
  {
    percentile: 10.308056872,
    z_score: -0.3705798825,
  },
  {
    percentile: 10.426540284,
    z_score: -0.3703102962,
  },
  {
    percentile: 10.545023697,
    z_score: -0.3699598156,
  },
  {
    percentile: 10.663507109,
    z_score: -0.3699188718,
  },
  {
    percentile: 10.781990521,
    z_score: -0.3697512578,
  },
  {
    percentile: 10.900473934,
    z_score: -0.3695220153,
  },
  {
    percentile: 11.018957346,
    z_score: -0.3692770662,
  },
  {
    percentile: 11.137440758,
    z_score: -0.3690013173,
  },
  {
    percentile: 11.255924171,
    z_score: -0.3688823101,
  },
  {
    percentile: 11.374407583,
    z_score: -0.3687339037,
  },
  {
    percentile: 11.492890995,
    z_score: -0.3680284064,
  },
  {
    percentile: 11.611374408,
    z_score: -0.3670631857,
  },
  {
    percentile: 11.72985782,
    z_score: -0.3669670903,
  },
  {
    percentile: 11.848341232,
    z_score: -0.3669321452,
  },
  {
    percentile: 11.966824645,
    z_score: -0.3664674213,
  },
  {
    percentile: 12.085308057,
    z_score: -0.3663380707,
  },
  {
    percentile: 12.203791469,
    z_score: -0.3660333242,
  },
  {
    percentile: 12.322274882,
    z_score: -0.3658640883,
  },
  {
    percentile: 12.440758294,
    z_score: -0.3655064379,
  },
  {
    percentile: 12.559241706,
    z_score: -0.3651030136,
  },
  {
    percentile: 12.677725118,
    z_score: -0.3649517065,
  },
  {
    percentile: 12.796208531,
    z_score: -0.3645536436,
  },
  {
    percentile: 12.914691943,
    z_score: -0.3640998831,
  },
  {
    percentile: 13.033175355,
    z_score: -0.3637645489,
  },
  {
    percentile: 13.151658768,
    z_score: -0.3635710334,
  },
  {
    percentile: 13.27014218,
    z_score: -0.3635095511,
  },
  {
    percentile: 13.388625592,
    z_score: -0.3633940378,
  },
  {
    percentile: 13.507109005,
    z_score: -0.3631157101,
  },
  {
    percentile: 13.625592417,
    z_score: -0.3629911836,
  },
  {
    percentile: 13.744075829,
    z_score: -0.3624205335,
  },
  {
    percentile: 13.862559242,
    z_score: -0.3623287,
  },
  {
    percentile: 13.981042654,
    z_score: -0.3620143663,
  },
  {
    percentile: 14.099526066,
    z_score: -0.3619119287,
  },
  {
    percentile: 14.218009479,
    z_score: -0.3614968378,
  },
  {
    percentile: 14.336492891,
    z_score: -0.3612716017,
  },
  {
    percentile: 14.454976303,
    z_score: -0.3608190601,
  },
  {
    percentile: 14.573459716,
    z_score: -0.360777003,
  },
  {
    percentile: 14.691943128,
    z_score: -0.3605934588,
  },
  {
    percentile: 14.81042654,
    z_score: -0.3603869128,
  },
  {
    percentile: 14.928909953,
    z_score: -0.3599578811,
  },
  {
    percentile: 15.047393365,
    z_score: -0.3591573026,
  },
  {
    percentile: 15.165876777,
    z_score: -0.359017389,
  },
  {
    percentile: 15.28436019,
    z_score: -0.3568909304,
  },
  {
    percentile: 15.402843602,
    z_score: -0.3559084017,
  },
  {
    percentile: 15.521327014,
    z_score: -0.3557600788,
  },
  {
    percentile: 15.639810427,
    z_score: -0.3551855125,
  },
  {
    percentile: 15.758293839,
    z_score: -0.3551637122,
  },
  {
    percentile: 15.876777251,
    z_score: -0.3551114925,
  },
  {
    percentile: 15.995260664,
    z_score: -0.3532677602,
  },
  {
    percentile: 16.113744076,
    z_score: -0.352504836,
  },
  {
    percentile: 16.232227488,
    z_score: -0.3522279048,
  },
  {
    percentile: 16.3507109,
    z_score: -0.3520966685,
  },
  {
    percentile: 16.469194313,
    z_score: -0.351334681,
  },
  {
    percentile: 16.587677725,
    z_score: -0.350602874,
  },
  {
    percentile: 16.706161137,
    z_score: -0.3505582591,
  },
  {
    percentile: 16.82464455,
    z_score: -0.3505056686,
  },
  {
    percentile: 16.943127962,
    z_score: -0.350087113,
  },
  {
    percentile: 17.061611374,
    z_score: -0.3500668983,
  },
  {
    percentile: 17.180094787,
    z_score: -0.3500005452,
  },
  {
    percentile: 17.298578199,
    z_score: -0.349197024,
  },
  {
    percentile: 17.417061611,
    z_score: -0.3491842716,
  },
  {
    percentile: 17.535545024,
    z_score: -0.3491316727,
  },
  {
    percentile: 17.654028436,
    z_score: -0.3488861135,
  },
  {
    percentile: 17.772511848,
    z_score: -0.3483042855,
  },
  {
    percentile: 17.890995261,
    z_score: -0.3470605211,
  },
  {
    percentile: 18.009478673,
    z_score: -0.3466976597,
  },
  {
    percentile: 18.127962085,
    z_score: -0.3466604597,
  },
  {
    percentile: 18.246445498,
    z_score: -0.3461589945,
  },
  {
    percentile: 18.36492891,
    z_score: -0.344490302,
  },
  {
    percentile: 18.483412322,
    z_score: -0.3438753967,
  },
  {
    percentile: 18.601895735,
    z_score: -0.3438219892,
  },
  {
    percentile: 18.720379147,
    z_score: -0.3422183211,
  },
  {
    percentile: 18.838862559,
    z_score: -0.3420410782,
  },
  {
    percentile: 18.957345972,
    z_score: -0.3418474322,
  },
  {
    percentile: 19.075829384,
    z_score: -0.3418012797,
  },
  {
    percentile: 19.194312796,
    z_score: -0.3413066788,
  },
  {
    percentile: 19.312796209,
    z_score: -0.3402108784,
  },
  {
    percentile: 19.431279621,
    z_score: -0.3398747266,
  },
  {
    percentile: 19.549763033,
    z_score: -0.3395253656,
  },
  {
    percentile: 19.668246445,
    z_score: -0.3388521887,
  },
  {
    percentile: 19.786729858,
    z_score: -0.3383392398,
  },
  {
    percentile: 19.90521327,
    z_score: -0.3376737679,
  },
  {
    percentile: 20.023696682,
    z_score: -0.3375054441,
  },
  {
    percentile: 20.142180095,
    z_score: -0.3369477174,
  },
  {
    percentile: 20.260663507,
    z_score: -0.3367652323,
  },
  {
    percentile: 20.379146919,
    z_score: -0.3362616827,
  },
  {
    percentile: 20.497630332,
    z_score: -0.3355876203,
  },
  {
    percentile: 20.616113744,
    z_score: -0.3350119598,
  },
  {
    percentile: 20.734597156,
    z_score: -0.3346542909,
  },
  {
    percentile: 20.853080569,
    z_score: -0.3337979659,
  },
  {
    percentile: 20.971563981,
    z_score: -0.3337266537,
  },
  {
    percentile: 21.090047393,
    z_score: -0.3317371914,
  },
  {
    percentile: 21.208530806,
    z_score: -0.3316514094,
  },
  {
    percentile: 21.327014218,
    z_score: -0.331258791,
  },
  {
    percentile: 21.44549763,
    z_score: -0.3302760259,
  },
  {
    percentile: 21.563981043,
    z_score: -0.3292296268,
  },
  {
    percentile: 21.682464455,
    z_score: -0.3285945097,
  },
  {
    percentile: 21.800947867,
    z_score: -0.3279175511,
  },
  {
    percentile: 21.91943128,
    z_score: -0.327487337,
  },
  {
    percentile: 22.037914692,
    z_score: -0.3267125559,
  },
  {
    percentile: 22.156398104,
    z_score: -0.3266986827,
  },
  {
    percentile: 22.274881517,
    z_score: -0.3256639447,
  },
  {
    percentile: 22.393364929,
    z_score: -0.3247539731,
  },
  {
    percentile: 22.511848341,
    z_score: -0.3245346646,
  },
  {
    percentile: 22.630331754,
    z_score: -0.3243424094,
  },
  {
    percentile: 22.748815166,
    z_score: -0.3240819057,
  },
  {
    percentile: 22.867298578,
    z_score: -0.3230176734,
  },
  {
    percentile: 22.985781991,
    z_score: -0.3229172966,
  },
  {
    percentile: 23.104265403,
    z_score: -0.3228475678,
  },
  {
    percentile: 23.222748815,
    z_score: -0.3219836367,
  },
  {
    percentile: 23.341232227,
    z_score: -0.3193352395,
  },
  {
    percentile: 23.45971564,
    z_score: -0.3173566385,
  },
  {
    percentile: 23.578199052,
    z_score: -0.3173564928,
  },
  {
    percentile: 23.696682464,
    z_score: -0.3166955374,
  },
  {
    percentile: 23.815165877,
    z_score: -0.3157672281,
  },
  {
    percentile: 23.933649289,
    z_score: -0.3155616645,
  },
  {
    percentile: 24.052132701,
    z_score: -0.3150548782,
  },
  {
    percentile: 24.170616114,
    z_score: -0.3146664085,
  },
  {
    percentile: 24.289099526,
    z_score: -0.3145610521,
  },
  {
    percentile: 24.407582938,
    z_score: -0.3141004715,
  },
  {
    percentile: 24.526066351,
    z_score: -0.3128528671,
  },
  {
    percentile: 24.644549763,
    z_score: -0.3127390738,
  },
  {
    percentile: 24.763033175,
    z_score: -0.3126863739,
  },
  {
    percentile: 24.881516588,
    z_score: -0.3126777585,
  },
  {
    percentile: 25,
    z_score: -0.3121929191,
  },
  {
    percentile: 25.118483412,
    z_score: -0.3101320762,
  },
  {
    percentile: 25.236966825,
    z_score: -0.3100794915,
  },
  {
    percentile: 25.355450237,
    z_score: -0.3068320081,
  },
  {
    percentile: 25.473933649,
    z_score: -0.3052054234,
  },
  {
    percentile: 25.592417062,
    z_score: -0.3050835356,
  },
  {
    percentile: 25.710900474,
    z_score: -0.3046625494,
  },
  {
    percentile: 25.829383886,
    z_score: -0.3044883159,
  },
  {
    percentile: 25.947867299,
    z_score: -0.3042013752,
  },
  {
    percentile: 26.066350711,
    z_score: -0.3039011297,
  },
  {
    percentile: 26.184834123,
    z_score: -0.298561668,
  },
  {
    percentile: 26.303317536,
    z_score: -0.2979048219,
  },
  {
    percentile: 26.421800948,
    z_score: -0.2967574207,
  },
  {
    percentile: 26.54028436,
    z_score: -0.2965932266,
  },
  {
    percentile: 26.658767773,
    z_score: -0.295040961,
  },
  {
    percentile: 26.777251185,
    z_score: -0.2941873272,
  },
  {
    percentile: 26.895734597,
    z_score: -0.2935605913,
  },
  {
    percentile: 27.014218009,
    z_score: -0.2933566282,
  },
  {
    percentile: 27.132701422,
    z_score: -0.2927036927,
  },
  {
    percentile: 27.251184834,
    z_score: -0.2925390518,
  },
  {
    percentile: 27.369668246,
    z_score: -0.2921759013,
  },
  {
    percentile: 27.488151659,
    z_score: -0.2918917777,
  },
  {
    percentile: 27.606635071,
    z_score: -0.2914668177,
  },
  {
    percentile: 27.725118483,
    z_score: -0.2905191926,
  },
  {
    percentile: 27.843601896,
    z_score: -0.2900288443,
  },
  {
    percentile: 27.962085308,
    z_score: -0.2893611146,
  },
  {
    percentile: 28.08056872,
    z_score: -0.2890441921,
  },
  {
    percentile: 28.199052133,
    z_score: -0.28854459,
  },
  {
    percentile: 28.317535545,
    z_score: -0.2871887828,
  },
  {
    percentile: 28.436018957,
    z_score: -0.2871333189,
  },
  {
    percentile: 28.55450237,
    z_score: -0.286127594,
  },
  {
    percentile: 28.672985782,
    z_score: -0.2846203359,
  },
  {
    percentile: 28.791469194,
    z_score: -0.2843056715,
  },
  {
    percentile: 28.909952607,
    z_score: -0.2818370463,
  },
  {
    percentile: 29.028436019,
    z_score: -0.2812945302,
  },
  {
    percentile: 29.146919431,
    z_score: -0.2812888601,
  },
  {
    percentile: 29.265402844,
    z_score: -0.278673818,
  },
  {
    percentile: 29.383886256,
    z_score: -0.2768477789,
  },
  {
    percentile: 29.502369668,
    z_score: -0.2762887631,
  },
  {
    percentile: 29.620853081,
    z_score: -0.2759787382,
  },
  {
    percentile: 29.739336493,
    z_score: -0.2753975094,
  },
  {
    percentile: 29.857819905,
    z_score: -0.2753900398,
  },
  {
    percentile: 29.976303318,
    z_score: -0.275355578,
  },
  {
    percentile: 30.09478673,
    z_score: -0.2742858778,
  },
  {
    percentile: 30.213270142,
    z_score: -0.2739187448,
  },
  {
    percentile: 30.331753555,
    z_score: -0.2735640153,
  },
  {
    percentile: 30.450236967,
    z_score: -0.2715937919,
  },
  {
    percentile: 30.568720379,
    z_score: -0.2715755007,
  },
  {
    percentile: 30.687203791,
    z_score: -0.2709161975,
  },
  {
    percentile: 30.805687204,
    z_score: -0.2700529406,
  },
  {
    percentile: 30.924170616,
    z_score: -0.2676089098,
  },
  {
    percentile: 31.042654028,
    z_score: -0.2675248131,
  },
  {
    percentile: 31.161137441,
    z_score: -0.2671036431,
  },
  {
    percentile: 31.279620853,
    z_score: -0.2664142837,
  },
  {
    percentile: 31.398104265,
    z_score: -0.2658634426,
  },
  {
    percentile: 31.516587678,
    z_score: -0.2658520158,
  },
  {
    percentile: 31.63507109,
    z_score: -0.265461942,
  },
  {
    percentile: 31.753554502,
    z_score: -0.263592357,
  },
  {
    percentile: 31.872037915,
    z_score: -0.2627493074,
  },
  {
    percentile: 31.990521327,
    z_score: -0.2623030595,
  },
  {
    percentile: 32.109004739,
    z_score: -0.2622328821,
  },
  {
    percentile: 32.227488152,
    z_score: -0.2616014563,
  },
  {
    percentile: 32.345971564,
    z_score: -0.2602817787,
  },
  {
    percentile: 32.464454976,
    z_score: -0.2584239117,
  },
  {
    percentile: 32.582938389,
    z_score: -0.2581178683,
  },
  {
    percentile: 32.701421801,
    z_score: -0.2580506048,
  },
  {
    percentile: 32.819905213,
    z_score: -0.2580451325,
  },
  {
    percentile: 32.938388626,
    z_score: -0.2579352388,
  },
  {
    percentile: 33.056872038,
    z_score: -0.2578802193,
  },
  {
    percentile: 33.17535545,
    z_score: -0.2555648476,
  },
  {
    percentile: 33.293838863,
    z_score: -0.2541310844,
  },
  {
    percentile: 33.412322275,
    z_score: -0.2530479437,
  },
  {
    percentile: 33.530805687,
    z_score: -0.2503549211,
  },
  {
    percentile: 33.6492891,
    z_score: -0.2480500029,
  },
  {
    percentile: 33.767772512,
    z_score: -0.246853558,
  },
  {
    percentile: 33.886255924,
    z_score: -0.2459550438,
  },
  {
    percentile: 34.004739336,
    z_score: -0.2448766688,
  },
  {
    percentile: 34.123222749,
    z_score: -0.2432467533,
  },
  {
    percentile: 34.241706161,
    z_score: -0.2427416131,
  },
  {
    percentile: 34.360189573,
    z_score: -0.2423473901,
  },
  {
    percentile: 34.478672986,
    z_score: -0.2398337204,
  },
  {
    percentile: 34.597156398,
    z_score: -0.2393255972,
  },
  {
    percentile: 34.71563981,
    z_score: -0.2388444217,
  },
  {
    percentile: 34.834123223,
    z_score: -0.2364285604,
  },
  {
    percentile: 34.952606635,
    z_score: -0.2320145148,
  },
  {
    percentile: 35.071090047,
    z_score: -0.2309513002,
  },
  {
    percentile: 35.18957346,
    z_score: -0.2300046383,
  },
  {
    percentile: 35.308056872,
    z_score: -0.2297266411,
  },
  {
    percentile: 35.426540284,
    z_score: -0.2278387311,
  },
  {
    percentile: 35.545023697,
    z_score: -0.2277762354,
  },
  {
    percentile: 35.663507109,
    z_score: -0.2269661883,
  },
  {
    percentile: 35.781990521,
    z_score: -0.2267606005,
  },
  {
    percentile: 35.900473934,
    z_score: -0.22597603,
  },
  {
    percentile: 36.018957346,
    z_score: -0.2222395034,
  },
  {
    percentile: 36.137440758,
    z_score: -0.2221875406,
  },
  {
    percentile: 36.255924171,
    z_score: -0.2220412733,
  },
  {
    percentile: 36.374407583,
    z_score: -0.2213584172,
  },
  {
    percentile: 36.492890995,
    z_score: -0.2195292652,
  },
  {
    percentile: 36.611374408,
    z_score: -0.2194681781,
  },
  {
    percentile: 36.72985782,
    z_score: -0.2192740269,
  },
  {
    percentile: 36.848341232,
    z_score: -0.2190548199,
  },
  {
    percentile: 36.966824645,
    z_score: -0.217862962,
  },
  {
    percentile: 37.085308057,
    z_score: -0.2173861113,
  },
  {
    percentile: 37.203791469,
    z_score: -0.2163407296,
  },
  {
    percentile: 37.322274882,
    z_score: -0.2158001825,
  },
  {
    percentile: 37.440758294,
    z_score: -0.214892613,
  },
  {
    percentile: 37.559241706,
    z_score: -0.2135111739,
  },
  {
    percentile: 37.677725118,
    z_score: -0.2129662644,
  },
  {
    percentile: 37.796208531,
    z_score: -0.2126344422,
  },
  {
    percentile: 37.914691943,
    z_score: -0.2122863874,
  },
  {
    percentile: 38.033175355,
    z_score: -0.2108774348,
  },
  {
    percentile: 38.151658768,
    z_score: -0.2104925068,
  },
  {
    percentile: 38.27014218,
    z_score: -0.2101760113,
  },
  {
    percentile: 38.388625592,
    z_score: -0.2067851968,
  },
  {
    percentile: 38.507109005,
    z_score: -0.2061630211,
  },
  {
    percentile: 38.625592417,
    z_score: -0.2060970721,
  },
  {
    percentile: 38.744075829,
    z_score: -0.2033757877,
  },
  {
    percentile: 38.862559242,
    z_score: -0.2032648641,
  },
  {
    percentile: 38.981042654,
    z_score: -0.2016206265,
  },
  {
    percentile: 39.099526066,
    z_score: -0.2014832792,
  },
  {
    percentile: 39.218009479,
    z_score: -0.2012855252,
  },
  {
    percentile: 39.336492891,
    z_score: -0.2009648076,
  },
  {
    percentile: 39.454976303,
    z_score: -0.1996699876,
  },
  {
    percentile: 39.573459716,
    z_score: -0.1959472641,
  },
  {
    percentile: 39.691943128,
    z_score: -0.1957098909,
  },
  {
    percentile: 39.81042654,
    z_score: -0.1955388666,
  },
  {
    percentile: 39.928909953,
    z_score: -0.1949339467,
  },
  {
    percentile: 40.047393365,
    z_score: -0.1934932544,
  },
  {
    percentile: 40.165876777,
    z_score: -0.1924862157,
  },
  {
    percentile: 40.28436019,
    z_score: -0.1918060406,
  },
  {
    percentile: 40.402843602,
    z_score: -0.191487778,
  },
  {
    percentile: 40.521327014,
    z_score: -0.1901008716,
  },
  {
    percentile: 40.639810427,
    z_score: -0.1891260244,
  },
  {
    percentile: 40.758293839,
    z_score: -0.1885176531,
  },
  {
    percentile: 40.876777251,
    z_score: -0.1873213031,
  },
  {
    percentile: 40.995260664,
    z_score: -0.1855631231,
  },
  {
    percentile: 41.113744076,
    z_score: -0.1854366377,
  },
  {
    percentile: 41.232227488,
    z_score: -0.1853843855,
  },
  {
    percentile: 41.3507109,
    z_score: -0.1801076827,
  },
  {
    percentile: 41.469194313,
    z_score: -0.1787645418,
  },
  {
    percentile: 41.587677725,
    z_score: -0.1779601295,
  },
  {
    percentile: 41.706161137,
    z_score: -0.1777526147,
  },
  {
    percentile: 41.82464455,
    z_score: -0.1768652427,
  },
  {
    percentile: 41.943127962,
    z_score: -0.176348588,
  },
  {
    percentile: 42.061611374,
    z_score: -0.1758110593,
  },
  {
    percentile: 42.180094787,
    z_score: -0.1755315928,
  },
  {
    percentile: 42.298578199,
    z_score: -0.1740749474,
  },
  {
    percentile: 42.417061611,
    z_score: -0.172851428,
  },
  {
    percentile: 42.535545024,
    z_score: -0.171335379,
  },
  {
    percentile: 42.654028436,
    z_score: -0.1709833975,
  },
  {
    percentile: 42.772511848,
    z_score: -0.166066422,
  },
  {
    percentile: 42.890995261,
    z_score: -0.165823263,
  },
  {
    percentile: 43.009478673,
    z_score: -0.1647649182,
  },
  {
    percentile: 43.127962085,
    z_score: -0.1642731438,
  },
  {
    percentile: 43.246445498,
    z_score: -0.1629850472,
  },
  {
    percentile: 43.36492891,
    z_score: -0.1628876535,
  },
  {
    percentile: 43.483412322,
    z_score: -0.1616457349,
  },
  {
    percentile: 43.601895735,
    z_score: -0.1610644759,
  },
  {
    percentile: 43.720379147,
    z_score: -0.1576094575,
  },
  {
    percentile: 43.838862559,
    z_score: -0.1574432,
  },
  {
    percentile: 43.957345972,
    z_score: -0.153939864,
  },
  {
    percentile: 44.075829384,
    z_score: -0.1512018044,
  },
  {
    percentile: 44.194312796,
    z_score: -0.1495315331,
  },
  {
    percentile: 44.312796209,
    z_score: -0.1482870186,
  },
  {
    percentile: 44.431279621,
    z_score: -0.1474362118,
  },
  {
    percentile: 44.549763033,
    z_score: -0.1469927054,
  },
  {
    percentile: 44.668246445,
    z_score: -0.1469318711,
  },
  {
    percentile: 44.786729858,
    z_score: -0.1467476109,
  },
  {
    percentile: 44.90521327,
    z_score: -0.1449376566,
  },
  {
    percentile: 45.023696682,
    z_score: -0.1435440787,
  },
  {
    percentile: 45.142180095,
    z_score: -0.1430834664,
  },
  {
    percentile: 45.260663507,
    z_score: -0.1419116904,
  },
  {
    percentile: 45.379146919,
    z_score: -0.1412332234,
  },
  {
    percentile: 45.497630332,
    z_score: -0.1399176775,
  },
  {
    percentile: 45.616113744,
    z_score: -0.1379021171,
  },
  {
    percentile: 45.734597156,
    z_score: -0.1373482011,
  },
  {
    percentile: 45.853080569,
    z_score: -0.1362938247,
  },
  {
    percentile: 45.971563981,
    z_score: -0.1360810773,
  },
  {
    percentile: 46.090047393,
    z_score: -0.1352456705,
  },
  {
    percentile: 46.208530806,
    z_score: -0.1347770324,
  },
  {
    percentile: 46.327014218,
    z_score: -0.1331962638,
  },
  {
    percentile: 46.44549763,
    z_score: -0.1304248643,
  },
  {
    percentile: 46.563981043,
    z_score: -0.1291937632,
  },
  {
    percentile: 46.682464455,
    z_score: -0.1290575612,
  },
  {
    percentile: 46.800947867,
    z_score: -0.1277199601,
  },
  {
    percentile: 46.91943128,
    z_score: -0.1266416205,
  },
  {
    percentile: 47.037914692,
    z_score: -0.123992754,
  },
  {
    percentile: 47.156398104,
    z_score: -0.1230471982,
  },
  {
    percentile: 47.274881517,
    z_score: -0.1226096554,
  },
  {
    percentile: 47.393364929,
    z_score: -0.1224584803,
  },
  {
    percentile: 47.511848341,
    z_score: -0.1209221572,
  },
  {
    percentile: 47.630331754,
    z_score: -0.1196740543,
  },
  {
    percentile: 47.748815166,
    z_score: -0.1196156261,
  },
  {
    percentile: 47.867298578,
    z_score: -0.119051456,
  },
  {
    percentile: 47.985781991,
    z_score: -0.1189397909,
  },
  {
    percentile: 48.104265403,
    z_score: -0.1179105959,
  },
  {
    percentile: 48.222748815,
    z_score: -0.1178709502,
  },
  {
    percentile: 48.341232227,
    z_score: -0.1170201858,
  },
  {
    percentile: 48.45971564,
    z_score: -0.1154733748,
  },
  {
    percentile: 48.578199052,
    z_score: -0.1152664563,
  },
  {
    percentile: 48.696682464,
    z_score: -0.1151245379,
  },
  {
    percentile: 48.815165877,
    z_score: -0.1144815598,
  },
  {
    percentile: 48.933649289,
    z_score: -0.1139868351,
  },
  {
    percentile: 49.052132701,
    z_score: -0.1134036686,
  },
  {
    percentile: 49.170616114,
    z_score: -0.111693441,
  },
  {
    percentile: 49.289099526,
    z_score: -0.111627185,
  },
  {
    percentile: 49.407582938,
    z_score: -0.1114565023,
  },
  {
    percentile: 49.526066351,
    z_score: -0.1091879704,
  },
  {
    percentile: 49.644549763,
    z_score: -0.1083724296,
  },
  {
    percentile: 49.763033175,
    z_score: -0.1072785931,
  },
  {
    percentile: 49.881516588,
    z_score: -0.1064681897,
  },
  {
    percentile: 50,
    z_score: -0.1059111606,
  },
  {
    percentile: 50.118483412,
    z_score: -0.1057488638,
  },
  {
    percentile: 50.236966825,
    z_score: -0.1057488548,
  },
  {
    percentile: 50.355450237,
    z_score: -0.1039210574,
  },
  {
    percentile: 50.473933649,
    z_score: -0.1034384501,
  },
  {
    percentile: 50.592417062,
    z_score: -0.1027754671,
  },
  {
    percentile: 50.710900474,
    z_score: -0.1017927172,
  },
  {
    percentile: 50.829383886,
    z_score: -0.1017797862,
  },
  {
    percentile: 50.947867299,
    z_score: -0.1013973434,
  },
  {
    percentile: 51.066350711,
    z_score: -0.1008334595,
  },
  {
    percentile: 51.184834123,
    z_score: -0.09982845468,
  },
  {
    percentile: 51.303317536,
    z_score: -0.09950283543,
  },
  {
    percentile: 51.421800948,
    z_score: -0.09891910224,
  },
  {
    percentile: 51.54028436,
    z_score: -0.09777135771,
  },
  {
    percentile: 51.658767773,
    z_score: -0.09743257797,
  },
  {
    percentile: 51.777251185,
    z_score: -0.09726727596,
  },
  {
    percentile: 51.895734597,
    z_score: -0.09659902195,
  },
  {
    percentile: 52.014218009,
    z_score: -0.09637702466,
  },
  {
    percentile: 52.132701422,
    z_score: -0.09554278386,
  },
  {
    percentile: 52.251184834,
    z_score: -0.09529947519,
  },
  {
    percentile: 52.369668246,
    z_score: -0.0935784108,
  },
  {
    percentile: 52.488151659,
    z_score: -0.09355716441,
  },
  {
    percentile: 52.606635071,
    z_score: -0.09352186992,
  },
  {
    percentile: 52.725118483,
    z_score: -0.092058951,
  },
  {
    percentile: 52.843601896,
    z_score: -0.09109442992,
  },
  {
    percentile: 52.962085308,
    z_score: -0.09046606481,
  },
  {
    percentile: 53.08056872,
    z_score: -0.08941215123,
  },
  {
    percentile: 53.199052133,
    z_score: -0.08886850332,
  },
  {
    percentile: 53.317535545,
    z_score: -0.08647649016,
  },
  {
    percentile: 53.436018957,
    z_score: -0.08587452759,
  },
  {
    percentile: 53.55450237,
    z_score: -0.08536281539,
  },
  {
    percentile: 53.672985782,
    z_score: -0.08264377373,
  },
  {
    percentile: 53.791469194,
    z_score: -0.08243578469,
  },
  {
    percentile: 53.909952607,
    z_score: -0.08139928737,
  },
  {
    percentile: 54.028436019,
    z_score: -0.08037205194,
  },
  {
    percentile: 54.146919431,
    z_score: -0.07912486168,
  },
  {
    percentile: 54.265402844,
    z_score: -0.07839023406,
  },
  {
    percentile: 54.383886256,
    z_score: -0.07790129619,
  },
  {
    percentile: 54.502369668,
    z_score: -0.0767754334,
  },
  {
    percentile: 54.620853081,
    z_score: -0.07552475892,
  },
  {
    percentile: 54.739336493,
    z_score: -0.07309196933,
  },
  {
    percentile: 54.857819905,
    z_score: -0.0729424912,
  },
  {
    percentile: 54.976303318,
    z_score: -0.07262462983,
  },
  {
    percentile: 55.09478673,
    z_score: -0.07246786804,
  },
  {
    percentile: 55.213270142,
    z_score: -0.07203413341,
  },
  {
    percentile: 55.331753555,
    z_score: -0.07114650477,
  },
  {
    percentile: 55.450236967,
    z_score: -0.0707120173,
  },
  {
    percentile: 55.568720379,
    z_score: -0.0695138964,
  },
  {
    percentile: 55.687203791,
    z_score: -0.06870544138,
  },
  {
    percentile: 55.805687204,
    z_score: -0.06844561495,
  },
  {
    percentile: 55.924170616,
    z_score: -0.06771769328,
  },
  {
    percentile: 56.042654028,
    z_score: -0.06700363831,
  },
  {
    percentile: 56.161137441,
    z_score: -0.0653633112,
  },
  {
    percentile: 56.279620853,
    z_score: -0.06530347032,
  },
  {
    percentile: 56.398104265,
    z_score: -0.06440854789,
  },
  {
    percentile: 56.516587678,
    z_score: -0.06421211247,
  },
  {
    percentile: 56.63507109,
    z_score: -0.06404341994,
  },
  {
    percentile: 56.753554502,
    z_score: -0.06343363539,
  },
  {
    percentile: 56.872037915,
    z_score: -0.0632784423,
  },
  {
    percentile: 56.990521327,
    z_score: -0.06209480723,
  },
  {
    percentile: 57.109004739,
    z_score: -0.0614547069,
  },
  {
    percentile: 57.227488152,
    z_score: -0.060928236,
  },
  {
    percentile: 57.345971564,
    z_score: -0.06044435225,
  },
  {
    percentile: 57.464454976,
    z_score: -0.05997360273,
  },
  {
    percentile: 57.582938389,
    z_score: -0.05952363997,
  },
  {
    percentile: 57.701421801,
    z_score: -0.05906554721,
  },
  {
    percentile: 57.819905213,
    z_score: -0.05785720437,
  },
  {
    percentile: 57.938388626,
    z_score: -0.05707604632,
  },
  {
    percentile: 58.056872038,
    z_score: -0.056913516,
  },
  {
    percentile: 58.17535545,
    z_score: -0.05679983125,
  },
  {
    percentile: 58.293838863,
    z_score: -0.05529261542,
  },
  {
    percentile: 58.412322275,
    z_score: -0.05497905749,
  },
  {
    percentile: 58.530805687,
    z_score: -0.05402888571,
  },
  {
    percentile: 58.6492891,
    z_score: -0.05326484013,
  },
  {
    percentile: 58.767772512,
    z_score: -0.05273248776,
  },
  {
    percentile: 58.886255924,
    z_score: -0.05236880087,
  },
  {
    percentile: 59.004739336,
    z_score: -0.0517910554,
  },
  {
    percentile: 59.123222749,
    z_score: -0.05109728326,
  },
  {
    percentile: 59.241706161,
    z_score: -0.04958538282,
  },
  {
    percentile: 59.360189573,
    z_score: -0.04885721007,
  },
  {
    percentile: 59.478672986,
    z_score: -0.04745756758,
  },
  {
    percentile: 59.597156398,
    z_score: -0.04716986009,
  },
  {
    percentile: 59.71563981,
    z_score: -0.04709639435,
  },
  {
    percentile: 59.834123223,
    z_score: -0.04364526197,
  },
  {
    percentile: 59.952606635,
    z_score: -0.04355926053,
  },
  {
    percentile: 60.071090047,
    z_score: -0.04296161182,
  },
  {
    percentile: 60.18957346,
    z_score: -0.04073181758,
  },
  {
    percentile: 60.308056872,
    z_score: -0.04058698023,
  },
  {
    percentile: 60.426540284,
    z_score: -0.04031396307,
  },
  {
    percentile: 60.545023697,
    z_score: -0.04014715415,
  },
  {
    percentile: 60.663507109,
    z_score: -0.03960776186,
  },
  {
    percentile: 60.781990521,
    z_score: -0.03794786423,
  },
  {
    percentile: 60.900473934,
    z_score: -0.03699967037,
  },
  {
    percentile: 61.018957346,
    z_score: -0.03519200916,
  },
  {
    percentile: 61.137440758,
    z_score: -0.03310534004,
  },
  {
    percentile: 61.255924171,
    z_score: -0.03243499222,
  },
  {
    percentile: 61.374407583,
    z_score: -0.03142959237,
  },
  {
    percentile: 61.492890995,
    z_score: -0.03084702391,
  },
  {
    percentile: 61.611374408,
    z_score: -0.02961230465,
  },
  {
    percentile: 61.72985782,
    z_score: -0.02945110031,
  },
  {
    percentile: 61.848341232,
    z_score: -0.02864172356,
  },
  {
    percentile: 61.966824645,
    z_score: -0.0281714136,
  },
  {
    percentile: 62.085308057,
    z_score: -0.02705944914,
  },
  {
    percentile: 62.203791469,
    z_score: -0.02701850083,
  },
  {
    percentile: 62.322274882,
    z_score: -0.02590406642,
  },
  {
    percentile: 62.440758294,
    z_score: -0.02390787438,
  },
  {
    percentile: 62.559241706,
    z_score: -0.02138303302,
  },
  {
    percentile: 62.677725118,
    z_score: -0.01949975461,
  },
  {
    percentile: 62.796208531,
    z_score: -0.0187918454,
  },
  {
    percentile: 62.914691943,
    z_score: -0.01836337653,
  },
  {
    percentile: 63.033175355,
    z_score: -0.0169880692,
  },
  {
    percentile: 63.151658768,
    z_score: -0.01524726348,
  },
  {
    percentile: 63.27014218,
    z_score: -0.01433351743,
  },
  {
    percentile: 63.388625592,
    z_score: -0.01373549333,
  },
  {
    percentile: 63.507109005,
    z_score: -0.01318083448,
  },
  {
    percentile: 63.625592417,
    z_score: -0.01152507481,
  },
  {
    percentile: 63.744075829,
    z_score: -0.01069469166,
  },
  {
    percentile: 63.862559242,
    z_score: -0.009931233122,
  },
  {
    percentile: 63.981042654,
    z_score: -0.009670215545,
  },
  {
    percentile: 64.099526066,
    z_score: -0.009554357483,
  },
  {
    percentile: 64.218009479,
    z_score: -0.008942486187,
  },
  {
    percentile: 64.336492891,
    z_score: -0.007951731048,
  },
  {
    percentile: 64.454976303,
    z_score: -0.007908546583,
  },
  {
    percentile: 64.573459716,
    z_score: -0.0075881118,
  },
  {
    percentile: 64.691943128,
    z_score: -0.007316183906,
  },
  {
    percentile: 64.81042654,
    z_score: -0.007109068236,
  },
  {
    percentile: 64.928909953,
    z_score: -0.006684580676,
  },
  {
    percentile: 65.047393365,
    z_score: -0.006470814461,
  },
  {
    percentile: 65.165876777,
    z_score: -0.005883928139,
  },
  {
    percentile: 65.28436019,
    z_score: -0.005751473619,
  },
  {
    percentile: 65.402843602,
    z_score: -0.005177379676,
  },
  {
    percentile: 65.521327014,
    z_score: -0.004976263334,
  },
  {
    percentile: 65.639810427,
    z_score: -0.004037759142,
  },
  {
    percentile: 65.758293839,
    z_score: -0.002198785573,
  },
  {
    percentile: 65.876777251,
    z_score: -0.0021064757,
  },
  {
    percentile: 65.995260664,
    z_score: -0.002061046885,
  },
  {
    percentile: 66.113744076,
    z_score: -0.0005965413443,
  },
  {
    percentile: 66.232227488,
    z_score: 0.0007799837765,
  },
  {
    percentile: 66.3507109,
    z_score: 0.001393921192,
  },
  {
    percentile: 66.469194313,
    z_score: 0.001918556351,
  },
  {
    percentile: 66.587677725,
    z_score: 0.002599182442,
  },
  {
    percentile: 66.706161137,
    z_score: 0.003395938778,
  },
  {
    percentile: 66.82464455,
    z_score: 0.00493925821,
  },
  {
    percentile: 66.943127962,
    z_score: 0.006387107215,
  },
  {
    percentile: 67.061611374,
    z_score: 0.008064549327,
  },
  {
    percentile: 67.180094787,
    z_score: 0.009035130178,
  },
  {
    percentile: 67.298578199,
    z_score: 0.009881279566,
  },
  {
    percentile: 67.417061611,
    z_score: 0.009917056721,
  },
  {
    percentile: 67.535545024,
    z_score: 0.01178019525,
  },
  {
    percentile: 67.654028436,
    z_score: 0.01211668148,
  },
  {
    percentile: 67.772511848,
    z_score: 0.01340640408,
  },
  {
    percentile: 67.890995261,
    z_score: 0.01356014192,
  },
  {
    percentile: 68.009478673,
    z_score: 0.01380229835,
  },
  {
    percentile: 68.127962085,
    z_score: 0.01408311114,
  },
  {
    percentile: 68.246445498,
    z_score: 0.01535684522,
  },
  {
    percentile: 68.36492891,
    z_score: 0.01578474148,
  },
  {
    percentile: 68.483412322,
    z_score: 0.01594082741,
  },
  {
    percentile: 68.601895735,
    z_score: 0.01789922425,
  },
  {
    percentile: 68.720379147,
    z_score: 0.018386377,
  },
  {
    percentile: 68.838862559,
    z_score: 0.01862436827,
  },
  {
    percentile: 68.957345972,
    z_score: 0.01936441402,
  },
  {
    percentile: 69.075829384,
    z_score: 0.02046711104,
  },
  {
    percentile: 69.194312796,
    z_score: 0.02250789117,
  },
  {
    percentile: 69.312796209,
    z_score: 0.02583305578,
  },
  {
    percentile: 69.431279621,
    z_score: 0.02613168115,
  },
  {
    percentile: 69.549763033,
    z_score: 0.02648565768,
  },
  {
    percentile: 69.668246445,
    z_score: 0.02854539284,
  },
  {
    percentile: 69.786729858,
    z_score: 0.02913650388,
  },
  {
    percentile: 69.90521327,
    z_score: 0.02928288542,
  },
  {
    percentile: 70.023696682,
    z_score: 0.02936134302,
  },
  {
    percentile: 70.142180095,
    z_score: 0.02951674623,
  },
  {
    percentile: 70.260663507,
    z_score: 0.03006725697,
  },
  {
    percentile: 70.379146919,
    z_score: 0.03084035988,
  },
  {
    percentile: 70.497630332,
    z_score: 0.03149744408,
  },
  {
    percentile: 70.616113744,
    z_score: 0.03231777068,
  },
  {
    percentile: 70.734597156,
    z_score: 0.03376580486,
  },
  {
    percentile: 70.853080569,
    z_score: 0.03493343455,
  },
  {
    percentile: 70.971563981,
    z_score: 0.03517011453,
  },
  {
    percentile: 71.090047393,
    z_score: 0.03974650155,
  },
  {
    percentile: 71.208530806,
    z_score: 0.04126540266,
  },
  {
    percentile: 71.327014218,
    z_score: 0.04130071912,
  },
  {
    percentile: 71.44549763,
    z_score: 0.04158014795,
  },
  {
    percentile: 71.563981043,
    z_score: 0.04347877836,
  },
  {
    percentile: 71.682464455,
    z_score: 0.04684580139,
  },
  {
    percentile: 71.800947867,
    z_score: 0.04707461983,
  },
  {
    percentile: 71.91943128,
    z_score: 0.04864000733,
  },
  {
    percentile: 72.037914692,
    z_score: 0.05091990896,
  },
  {
    percentile: 72.156398104,
    z_score: 0.05135886169,
  },
  {
    percentile: 72.274881517,
    z_score: 0.05146521764,
  },
  {
    percentile: 72.393364929,
    z_score: 0.05197982473,
  },
  {
    percentile: 72.511848341,
    z_score: 0.05405214946,
  },
  {
    percentile: 72.630331754,
    z_score: 0.05426021317,
  },
  {
    percentile: 72.748815166,
    z_score: 0.05695301328,
  },
  {
    percentile: 72.867298578,
    z_score: 0.05717964394,
  },
  {
    percentile: 72.985781991,
    z_score: 0.05808850112,
  },
  {
    percentile: 73.104265403,
    z_score: 0.0601323487,
  },
  {
    percentile: 73.222748815,
    z_score: 0.06394951748,
  },
  {
    percentile: 73.341232227,
    z_score: 0.06745769891,
  },
  {
    percentile: 73.45971564,
    z_score: 0.06855949014,
  },
  {
    percentile: 73.578199052,
    z_score: 0.06993603739,
  },
  {
    percentile: 73.696682464,
    z_score: 0.07023371831,
  },
  {
    percentile: 73.815165877,
    z_score: 0.07040963141,
  },
  {
    percentile: 73.933649289,
    z_score: 0.07082508028,
  },
  {
    percentile: 74.052132701,
    z_score: 0.07157967713,
  },
  {
    percentile: 74.170616114,
    z_score: 0.07167644056,
  },
  {
    percentile: 74.289099526,
    z_score: 0.07234951707,
  },
  {
    percentile: 74.407582938,
    z_score: 0.07258181788,
  },
  {
    percentile: 74.526066351,
    z_score: 0.07333438577,
  },
  {
    percentile: 74.644549763,
    z_score: 0.0735743889,
  },
  {
    percentile: 74.763033175,
    z_score: 0.07511800642,
  },
  {
    percentile: 74.881516588,
    z_score: 0.07901787027,
  },
  {
    percentile: 75,
    z_score: 0.07978051589,
  },
  {
    percentile: 75.118483412,
    z_score: 0.07984683548,
  },
  {
    percentile: 75.236966825,
    z_score: 0.08015938419,
  },
  {
    percentile: 75.355450237,
    z_score: 0.08075660961,
  },
  {
    percentile: 75.473933649,
    z_score: 0.08115253476,
  },
  {
    percentile: 75.592417062,
    z_score: 0.08336071443,
  },
  {
    percentile: 75.710900474,
    z_score: 0.08352534989,
  },
  {
    percentile: 75.829383886,
    z_score: 0.08358360151,
  },
  {
    percentile: 75.947867299,
    z_score: 0.08732332168,
  },
  {
    percentile: 76.066350711,
    z_score: 0.08809392288,
  },
  {
    percentile: 76.184834123,
    z_score: 0.08896131691,
  },
  {
    percentile: 76.303317536,
    z_score: 0.08902655615,
  },
  {
    percentile: 76.421800948,
    z_score: 0.09091823398,
  },
  {
    percentile: 76.54028436,
    z_score: 0.09197936909,
  },
  {
    percentile: 76.658767773,
    z_score: 0.09290342427,
  },
  {
    percentile: 76.777251185,
    z_score: 0.09369202517,
  },
  {
    percentile: 76.895734597,
    z_score: 0.09534905504,
  },
  {
    percentile: 77.014218009,
    z_score: 0.09772180676,
  },
  {
    percentile: 77.132701422,
    z_score: 0.09867400689,
  },
  {
    percentile: 77.251184834,
    z_score: 0.09983575991,
  },
  {
    percentile: 77.369668246,
    z_score: 0.1005020304,
  },
  {
    percentile: 77.488151659,
    z_score: 0.1031995418,
  },
  {
    percentile: 77.606635071,
    z_score: 0.1037329244,
  },
  {
    percentile: 77.725118483,
    z_score: 0.1060380954,
  },
  {
    percentile: 77.843601896,
    z_score: 0.1080291833,
  },
  {
    percentile: 77.962085308,
    z_score: 0.1095611599,
  },
  {
    percentile: 78.08056872,
    z_score: 0.1106348778,
  },
  {
    percentile: 78.199052133,
    z_score: 0.1108435836,
  },
  {
    percentile: 78.317535545,
    z_score: 0.1114896221,
  },
  {
    percentile: 78.436018957,
    z_score: 0.1119787847,
  },
  {
    percentile: 78.55450237,
    z_score: 0.1125513868,
  },
  {
    percentile: 78.672985782,
    z_score: 0.1125858157,
  },
  {
    percentile: 78.791469194,
    z_score: 0.1127080953,
  },
  {
    percentile: 78.909952607,
    z_score: 0.1129471626,
  },
  {
    percentile: 79.028436019,
    z_score: 0.115477132,
  },
  {
    percentile: 79.146919431,
    z_score: 0.117497846,
  },
  {
    percentile: 79.265402844,
    z_score: 0.1183638605,
  },
  {
    percentile: 79.383886256,
    z_score: 0.1205872019,
  },
  {
    percentile: 79.502369668,
    z_score: 0.1207380943,
  },
  {
    percentile: 79.620853081,
    z_score: 0.1212600217,
  },
  {
    percentile: 79.739336493,
    z_score: 0.1213861338,
  },
  {
    percentile: 79.857819905,
    z_score: 0.1220815069,
  },
  {
    percentile: 79.976303318,
    z_score: 0.1244946502,
  },
  {
    percentile: 80.09478673,
    z_score: 0.1247665967,
  },
  {
    percentile: 80.213270142,
    z_score: 0.1284978429,
  },
  {
    percentile: 80.331753555,
    z_score: 0.1289202907,
  },
  {
    percentile: 80.450236967,
    z_score: 0.129354423,
  },
  {
    percentile: 80.568720379,
    z_score: 0.1327791019,
  },
  {
    percentile: 80.687203791,
    z_score: 0.1329415739,
  },
  {
    percentile: 80.805687204,
    z_score: 0.1343029311,
  },
  {
    percentile: 80.924170616,
    z_score: 0.1348375738,
  },
  {
    percentile: 81.042654028,
    z_score: 0.1360861533,
  },
  {
    percentile: 81.161137441,
    z_score: 0.1367008051,
  },
  {
    percentile: 81.279620853,
    z_score: 0.1367125731,
  },
  {
    percentile: 81.398104265,
    z_score: 0.1389119769,
  },
  {
    percentile: 81.516587678,
    z_score: 0.141287524,
  },
  {
    percentile: 81.63507109,
    z_score: 0.1421108822,
  },
  {
    percentile: 81.753554502,
    z_score: 0.1422333411,
  },
  {
    percentile: 81.872037915,
    z_score: 0.1452311394,
  },
  {
    percentile: 81.990521327,
    z_score: 0.1525620655,
  },
  {
    percentile: 82.109004739,
    z_score: 0.1584936147,
  },
  {
    percentile: 82.227488152,
    z_score: 0.1605806333,
  },
  {
    percentile: 82.345971564,
    z_score: 0.1616018162,
  },
  {
    percentile: 82.464454976,
    z_score: 0.1633998636,
  },
  {
    percentile: 82.582938389,
    z_score: 0.1636935244,
  },
  {
    percentile: 82.701421801,
    z_score: 0.1689517036,
  },
  {
    percentile: 82.819905213,
    z_score: 0.1698684175,
  },
  {
    percentile: 82.938388626,
    z_score: 0.1725961031,
  },
  {
    percentile: 83.056872038,
    z_score: 0.1727041826,
  },
  {
    percentile: 83.17535545,
    z_score: 0.1731558007,
  },
  {
    percentile: 83.293838863,
    z_score: 0.1778765813,
  },
  {
    percentile: 83.412322275,
    z_score: 0.1819505034,
  },
  {
    percentile: 83.530805687,
    z_score: 0.1832950515,
  },
  {
    percentile: 83.6492891,
    z_score: 0.1836899849,
  },
  {
    percentile: 83.767772512,
    z_score: 0.1921531761,
  },
  {
    percentile: 83.886255924,
    z_score: 0.193727562,
  },
  {
    percentile: 84.004739336,
    z_score: 0.1942257358,
  },
  {
    percentile: 84.123222749,
    z_score: 0.1957517237,
  },
  {
    percentile: 84.241706161,
    z_score: 0.1974057534,
  },
  {
    percentile: 84.360189573,
    z_score: 0.2025247877,
  },
  {
    percentile: 84.478672986,
    z_score: 0.2038683304,
  },
  {
    percentile: 84.597156398,
    z_score: 0.2060040805,
  },
  {
    percentile: 84.71563981,
    z_score: 0.2084197947,
  },
  {
    percentile: 84.834123223,
    z_score: 0.2099734234,
  },
  {
    percentile: 84.952606635,
    z_score: 0.2145583393,
  },
  {
    percentile: 85.071090047,
    z_score: 0.2271078281,
  },
  {
    percentile: 85.18957346,
    z_score: 0.2274117156,
  },
  {
    percentile: 85.308056872,
    z_score: 0.2309788273,
  },
  {
    percentile: 85.426540284,
    z_score: 0.2330012964,
  },
  {
    percentile: 85.545023697,
    z_score: 0.2347238484,
  },
  {
    percentile: 85.663507109,
    z_score: 0.2359094348,
  },
  {
    percentile: 85.781990521,
    z_score: 0.2435676717,
  },
  {
    percentile: 85.900473934,
    z_score: 0.2443188799,
  },
  {
    percentile: 86.018957346,
    z_score: 0.2462422613,
  },
  {
    percentile: 86.137440758,
    z_score: 0.2466523181,
  },
  {
    percentile: 86.255924171,
    z_score: 0.2482182451,
  },
  {
    percentile: 86.374407583,
    z_score: 0.2508275124,
  },
  {
    percentile: 86.492890995,
    z_score: 0.2526270208,
  },
  {
    percentile: 86.611374408,
    z_score: 0.2528873649,
  },
  {
    percentile: 86.72985782,
    z_score: 0.2541932339,
  },
  {
    percentile: 86.848341232,
    z_score: 0.258467878,
  },
  {
    percentile: 86.966824645,
    z_score: 0.2643209699,
  },
  {
    percentile: 87.085308057,
    z_score: 0.2654061856,
  },
  {
    percentile: 87.203791469,
    z_score: 0.2684976153,
  },
  {
    percentile: 87.322274882,
    z_score: 0.2687346616,
  },
  {
    percentile: 87.440758294,
    z_score: 0.269276204,
  },
  {
    percentile: 87.559241706,
    z_score: 0.2714183101,
  },
  {
    percentile: 87.677725118,
    z_score: 0.2792935798,
  },
  {
    percentile: 87.796208531,
    z_score: 0.2816802868,
  },
  {
    percentile: 87.914691943,
    z_score: 0.2846287493,
  },
  {
    percentile: 88.033175355,
    z_score: 0.2896371989,
  },
  {
    percentile: 88.151658768,
    z_score: 0.2899485662,
  },
  {
    percentile: 88.27014218,
    z_score: 0.2926421121,
  },
  {
    percentile: 88.388625592,
    z_score: 0.2969443862,
  },
  {
    percentile: 88.507109005,
    z_score: 0.3000070737,
  },
  {
    percentile: 88.625592417,
    z_score: 0.3049248915,
  },
  {
    percentile: 88.744075829,
    z_score: 0.3228205603,
  },
  {
    percentile: 88.862559242,
    z_score: 0.3271048163,
  },
  {
    percentile: 88.981042654,
    z_score: 0.3310126092,
  },
  {
    percentile: 89.099526066,
    z_score: 0.3311399032,
  },
  {
    percentile: 89.218009479,
    z_score: 0.3409331725,
  },
  {
    percentile: 89.336492891,
    z_score: 0.3424815645,
  },
  {
    percentile: 89.454976303,
    z_score: 0.3429714101,
  },
  {
    percentile: 89.573459716,
    z_score: 0.3554235251,
  },
  {
    percentile: 89.691943128,
    z_score: 0.3573561673,
  },
  {
    percentile: 89.81042654,
    z_score: 0.3713669177,
  },
  {
    percentile: 89.928909953,
    z_score: 0.3724277885,
  },
  {
    percentile: 90.047393365,
    z_score: 0.3836509644,
  },
  {
    percentile: 90.165876777,
    z_score: 0.3962086425,
  },
  {
    percentile: 90.28436019,
    z_score: 0.3966438281,
  },
  {
    percentile: 90.402843602,
    z_score: 0.4086160652,
  },
  {
    percentile: 90.521327014,
    z_score: 0.4171846531,
  },
  {
    percentile: 90.639810427,
    z_score: 0.4293360438,
  },
  {
    percentile: 90.758293839,
    z_score: 0.4720738288,
  },
  {
    percentile: 90.876777251,
    z_score: 0.4785824692,
  },
  {
    percentile: 90.995260664,
    z_score: 0.5099938364,
  },
  {
    percentile: 91.113744076,
    z_score: 0.5110525806,
  },
  {
    percentile: 91.232227488,
    z_score: 0.5373746947,
  },
  {
    percentile: 91.3507109,
    z_score: 0.5403879142,
  },
  {
    percentile: 91.469194313,
    z_score: 0.5750619801,
  },
  {
    percentile: 91.587677725,
    z_score: 0.5841472953,
  },
  {
    percentile: 91.706161137,
    z_score: 0.6160899428,
  },
  {
    percentile: 91.82464455,
    z_score: 0.6318105116,
  },
  {
    percentile: 91.943127962,
    z_score: 0.6455479396,
  },
  {
    percentile: 92.061611374,
    z_score: 0.6614055755,
  },
  {
    percentile: 92.180094787,
    z_score: 0.6677569866,
  },
  {
    percentile: 92.298578199,
    z_score: 0.6750069988,
  },
  {
    percentile: 92.417061611,
    z_score: 0.6884489878,
  },
  {
    percentile: 92.535545024,
    z_score: 0.7185250653,
  },
  {
    percentile: 92.654028436,
    z_score: 0.7282150498,
  },
  {
    percentile: 92.772511848,
    z_score: 0.731167335,
  },
  {
    percentile: 92.890995261,
    z_score: 0.75527785,
  },
  {
    percentile: 93.009478673,
    z_score: 0.7586386054,
  },
  {
    percentile: 93.127962085,
    z_score: 0.774596778,
  },
  {
    percentile: 93.246445498,
    z_score: 0.780673201,
  },
  {
    percentile: 93.36492891,
    z_score: 0.7986925461,
  },
  {
    percentile: 93.483412322,
    z_score: 0.8004002179,
  },
  {
    percentile: 93.601895735,
    z_score: 0.8319623068,
  },
  {
    percentile: 93.720379147,
    z_score: 0.8334609043,
  },
  {
    percentile: 93.838862559,
    z_score: 0.8405765797,
  },
  {
    percentile: 93.957345972,
    z_score: 0.8440506934,
  },
  {
    percentile: 94.075829384,
    z_score: 0.8451731219,
  },
  {
    percentile: 94.194312796,
    z_score: 0.8891032276,
  },
  {
    percentile: 94.312796209,
    z_score: 0.9189948594,
  },
  {
    percentile: 94.431279621,
    z_score: 0.9247753359,
  },
  {
    percentile: 94.549763033,
    z_score: 0.9293202696,
  },
  {
    percentile: 94.668246445,
    z_score: 0.9357485783,
  },
  {
    percentile: 94.786729858,
    z_score: 0.9447209436,
  },
  {
    percentile: 94.90521327,
    z_score: 0.9500399548,
  },
  {
    percentile: 95.023696682,
    z_score: 0.9542645313,
  },
  {
    percentile: 95.142180095,
    z_score: 0.9664127225,
  },
  {
    percentile: 95.260663507,
    z_score: 0.9996084827,
  },
  {
    percentile: 95.379146919,
    z_score: 1.022528854,
  },
  {
    percentile: 95.497630332,
    z_score: 1.027485946,
  },
  {
    percentile: 95.616113744,
    z_score: 1.031903799,
  },
  {
    percentile: 95.734597156,
    z_score: 1.059818669,
  },
  {
    percentile: 95.853080569,
    z_score: 1.061455635,
  },
  {
    percentile: 95.971563981,
    z_score: 1.07378719,
  },
  {
    percentile: 96.090047393,
    z_score: 1.073833857,
  },
  {
    percentile: 96.208530806,
    z_score: 1.093935334,
  },
  {
    percentile: 96.327014218,
    z_score: 1.099677849,
  },
  {
    percentile: 96.44549763,
    z_score: 1.111684799,
  },
  {
    percentile: 96.563981043,
    z_score: 1.12312322,
  },
  {
    percentile: 96.682464455,
    z_score: 1.123496338,
  },
  {
    percentile: 96.800947867,
    z_score: 1.124243003,
  },
  {
    percentile: 96.91943128,
    z_score: 1.126074965,
  },
  {
    percentile: 97.037914692,
    z_score: 1.131511722,
  },
  {
    percentile: 97.156398104,
    z_score: 1.138502353,
  },
  {
    percentile: 97.274881517,
    z_score: 1.150603294,
  },
  {
    percentile: 97.393364929,
    z_score: 1.183362701,
  },
  {
    percentile: 97.511848341,
    z_score: 1.198641614,
  },
  {
    percentile: 97.630331754,
    z_score: 1.233201445,
  },
  {
    percentile: 97.748815166,
    z_score: 1.233963398,
  },
  {
    percentile: 97.867298578,
    z_score: 1.253911233,
  },
  {
    percentile: 97.985781991,
    z_score: 1.275115813,
  },
  {
    percentile: 98.104265403,
    z_score: 1.287010355,
  },
  {
    percentile: 98.222748815,
    z_score: 1.290433257,
  },
  {
    percentile: 98.341232227,
    z_score: 1.352349795,
  },
  {
    percentile: 98.45971564,
    z_score: 1.382274384,
  },
  {
    percentile: 98.578199052,
    z_score: 1.403770047,
  },
  {
    percentile: 98.696682464,
    z_score: 1.452744513,
  },
  {
    percentile: 98.815165877,
    z_score: 1.511498336,
  },
  {
    percentile: 98.933649289,
    z_score: 1.54183604,
  },
  {
    percentile: 99.052132701,
    z_score: 1.555221983,
  },
  {
    percentile: 99.170616114,
    z_score: 1.637530135,
  },
  {
    percentile: 99.289099526,
    z_score: 1.644542422,
  },
  {
    percentile: 99.407582938,
    z_score: 1.837171256,
  },
  {
    percentile: 99.526066351,
    z_score: 1.964490395,
  },
  {
    percentile: 99.644549763,
    z_score: 5.093199988,
  },
  {
    percentile: 99.763033175,
    z_score: 10.300276355,
  },
  {
    percentile: 99.881516588,
    z_score: 11,
  },
  {
    percentile: 99.881516588,
    z_score: 12,
  },
];
