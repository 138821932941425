/* eslint-disable @typescript-eslint/no-explicit-any */
import { select, stack, scaleOrdinal, scaleBand, scaleLinear } from 'd3';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-ignore */
import { axisLeft } from 'd3-axis';
import { colors } from '../../utilities/colors';
import { generateHTML } from '../../utilities/generate-html';
import '../../d3-styles.scss';
import { generateLegendHTML } from '../../utilities/generate-legend-html';
import { generateInlineStyles } from '../../utilities/generate-inline-styles';
import { appendWatermark } from '../../utilities/append-watermark';
import { notifyChartRenderComplete } from '../../utilities/notify-chart-render-complete';
import {
  calcMaxLeftLabelWidth,
  getYAxisRange,
  transformAndMapData,
  calcTTHeight,
  getTooltipData,
  calcTTTransformOnMouseOver,
  createTooltip,
  generateSVGLegend,
  styleTextboxes,
  drawStackedBars,
  calcBarsHeight,
  positionSVGContainer,
  getComputedHeight,
  appendYAxis,
} from './helpers';
import { adjustDownloadMargins } from '../../utilities/adjust-download-margins';
import { appendTitle } from '../../utilities/append-title';
import { diagonalStripePattern4 } from '../patterns/diagonalStripe4';
import { DownloadOptions, PlotConfig } from '../../types/types';
import { StackedBarChartHorizontalData } from './types';
import { globalLoader } from '@revelio/core';

const labelFormatter = (str: string) => {
  return str?.split('/')?.[0].trim();
};
interface StackedBarChartHorizontalPlotConfig
  extends PlotConfig<StackedBarChartHorizontalData[]> {
  ttMainFormat: any;
  ttSecondaryFormat: any;
  hideLegend: any;
  boxRef: any;
}

/**
 * Generates a Horizontal Stacked Bar Chart
 *
 * @param plotConfigs - configs for generating the plot
 * @param downloadOptions - oprions for downloading plot as image
 *
 * @returns if getSVGNode is true, then return the svg node of the rendered plot.
 * Otherwise, void.
 */
export const StackedBarChartHorizontalGenerator = (
  plotConfigs: StackedBarChartHorizontalPlotConfig,
  downloadOptions: DownloadOptions
): SVGSVGElement | null | void => {
  const {
    data,
    chartPosition,
    ttMainFormat,
    ttSecondaryFormat,
    hideLegend,
    targetRef,
    chartSize,
    boxRef,
    requestHash,
    isRenderingOrLoading,
    customMargins,
  } = plotConfigs;

  let { name, height, width } = plotConfigs;

  const {
    title,
    download,
    getSVGNode,
    svgHeight,
    svgWidth,
    containerId,
    padding,
    watermark,
  } = downloadOptions;

  const fontColor = '#636d7e';

  const dims: any = {};

  const watermarkHeight = watermark?.height || 0;

  name = getSVGNode ? name + '-download' : name;
  height = svgHeight || height;
  width = svgWidth || width;
  if (
    data &&
    (targetRef?.current || containerId) &&
    height &&
    width &&
    (download || boxRef?.current)
  ) {
    // remove old svg
    select(`.svg-${name}`).remove();
    select(`.tooltip-${name}`).remove();

    // setup margins and inner dims; reduce bottom margin when hiding legend
    if (chartSize === 'large') {
      dims.margin = {
        top: download ? 30 : 0,
        left: 58,
        bottom: download && data.length > 5 ? 80 : 50,
        right: 0,
      };
    } else {
      if (hideLegend) {
        dims.margin = { top: 24, left: 48, bottom: 4, right: 0 };
      } else {
        dims.margin = { top: 30, left: 58, bottom: 50, right: 0 };
      }
    }

    if (download) {
      adjustDownloadMargins(dims, {
        title,
        watermark,
        watermarkHeight,
        padding,
      });
    }

    // setup svg node
    const node = targetRef?.current;

    const svg: any = node
      ? select(node).append('svg')
      : select(containerId).append('svg');

    svg
      .attr('width', svgWidth || '100%')
      .attr('height', svgHeight || '100%')
      .attr('class', `svg-${name}`);

    const chart = svg.append('g');

    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    //=============================================================================
    const fontFamily = 'Source Sans Pro';
    let fontSize;
    let lineHeight;

    //smaller font size to fit wrapped y labels next to smaller bars
    if (data.length >= 5) {
      if (chartSize === 'large') {
        fontSize = width > 900 ? 12 : 10;
        lineHeight = 13;
      } else {
        fontSize = 9;
        lineHeight = 10;
      }
    } else if (data.length >= 4) {
      if (chartSize === 'large') {
        fontSize = 11;
        lineHeight = 14;
      } else {
        fontSize = 10;
        lineHeight = 10;
      }
    } else {
      if (chartSize === 'medium' || chartSize === 'large') {
        fontSize = 12;
      } else {
        fontSize = 11;
      }
      lineHeight = 11;
    }

    // extract subgroup values from the data
    const subgroups = new Set();
    const nullGroups = new Set();

    // TODO: Regions with zero value seem to be getting omitted form the response. This is causing
    // the ordering of the stacked bar to change. Here is a temp fix to ensure Other is always at
    // the end of the stack ordering
    let appendOther = false;

    data.forEach((d) => {
      let isAllZeroValues = true;

      const subsets = d.value.map((d) => {
        if (d.value !== 0) {
          isAllZeroValues = false;
        }

        return d.metadata.shortName;
      });

      if (isAllZeroValues) nullGroups.add(d.metadata.shortName);

      subsets.forEach((d: any) => {
        if (d.toLowerCase() === 'other') {
          // TODO: maybe a nicer way to do this. Basically for the skill plot only,
          // we want to ignore the OTHER entity.
          if (!appendOther && name !== 'skill') {
            appendOther = true;
          }
          return;
        }

        subgroups.add(d);
      });
    });

    if (nullGroups.size) {
      const defs = chart.append('defs');
      defs.html(diagonalStripePattern4);
    }

    if (appendOther) {
      subgroups.add('Other');
    }

    const [valueMapping, dataTransformed] = transformAndMapData(data);

    const axisTickLinesOffset = 5; // + 5 to account for axis and tick lines that we remove

    const maxTextWidth = calcMaxLeftLabelWidth(
      dataTransformed,
      fontSize,
      fontFamily
    );

    if (
      maxTextWidth + axisTickLinesOffset <= dims.margin.left &&
      chartSize !== 'large'
    ) {
      // + 5 to account for axis and tick lines that we remove
      dims.margin.left = maxTextWidth + 5.5;
    }

    //Override margins
    if (customMargins) {
      dims.margin = { ...dims.margin, ...customMargins };
    }

    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);

    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    const stackedData = stack().keys(subgroups as any)(dataTransformed as any);

    stackedData.map((a) =>
      a.map((d: any) => {
        d.key = a.key;
        return d;
      })
    );

    const otherColor = '#A0A9B8';

    const colorsCopy = colors.filter((color) => color !== otherColor);

    const color = scaleOrdinal()
      .domain(subgroups as any)
      .range(colorsCopy);

    const compStyles = targetRef?.current
      ? getComputedStyle(targetRef.current)
      : undefined;

    const chartHeightStyle =
      compStyles === undefined
        ? dims.innerHeight
        : getComputedHeight({
            compStyles,
            hideLegend,
            marginTop: dims.margin.top,
            marginBottom: dims.margin.bottom,
            legendHeight: boxRef.current.clientHeight,
          });

    const groups = (dataTransformed as any).map((d: any) => d.group_shortName);
    const xScale = scaleLinear().domain([0, 100]).range([0, dims.innerWidth]);

    // keep inner padding between bars constant and clamp space occupied by bar & inner padding to a max
    const innerPad = 0.15; //this is a percentage of step
    const maxStep = chartSize === 'large' ? 55 : 35; //space from start of 1 bar to start of the next

    const yAxisRange = getYAxisRange(data.length, maxStep, chartHeightStyle);

    const yScale = scaleBand()
      .domain(groups)
      .range(yAxisRange)
      .paddingInner(innerPad);

    if (!hideLegend && targetRef?.current && !download) {
      generateLegendHTML(
        stackedData,
        targetRef.current,
        boxRef.current,
        color,
        dims.margin,
        otherColor,
        data.length,
        yScale.bandwidth(),
        yAxisRange,
        yScale.step(),
        innerPad,
        height,
        chartSize,
        labelFormatter
      );
    }

    const tooltipWidth = 240;
    const ttUpperPadding = 12;
    const ttTitleLineHeight = 14;
    const charsPerTitleLine = 36;
    const titleBottomPadding = 10;
    const ttRowHeight = 19;
    const ttArrowLength = 7;

    const tooltip = createTooltip({
      container: targetRef?.current || containerId,
      tooltipWidth,
      name,
    });

    const fade = (allNodes: any, opacity: any, d: any, eventKey?: any) => {
      allNodes
        .selectAll('.rect')
        .filter(function (e: any) {
          if (eventKey) {
            return eventKey !== d.key;
          } else {
            return e.key !== d.key;
          }
        })
        .style('opacity', opacity);
    };

    let tooltipDataLen = 0;
    let mouseoverEventKey: any, mouseoverData: any; //variables to hold last bar that was hovered over to keep the fade level of that bar group

    const mouseOver = (event: any, d: any) => {
      fade(chart, 0.5, d);
      mouseoverEventKey = event.key;
      mouseoverData = d;

      // change position of tooltip for last bar on charts positioned on the right of the page
      chartPosition === 'right' && d[1] > 75 && d[1] - d[0] < 46
        ? tooltip
            .classed('tooltip-stacked-bar-chart-right', true)
            .classed('tooltip-stacked-bar-chart', false)
        : tooltip
            .classed('tooltip-stacked-bar-chart', true)
            .classed('tooltip-stacked-bar-chart-right', false);

      // get stack values for top bar if want to position tooltip above top bar of that group
      // var topBarVals = stackedData.filter((group) => group.key === d.key)[0][0];

      const tooltipData = getTooltipData({
        data,
        d,
        ttMainFormat,
        ttSecondaryFormat,
      });

      tooltipDataLen = tooltipData.length;

      // tt height = tt upper padding (12px) + # lines title occupies (14px per line, each line fits ~36 title characters) + title bottom padding (10px) + data.length * height of each row (19px) + tt bottom padding (12px) + arrow length (~7px)
      const tooltipHeight = calcTTHeight({
        dataLength: data.length,
        d,
        valueMapping,
        ttUpperPadding,
        ttTitleLineHeight,
        charsPerTitleLine,
        titleBottomPadding,
        ttRowHeight,
        ttArrowLength,
      });

      tooltip
        .style('opacity', 1)
        .attr('hidden', null)

        .html(generateHTML(d, tooltipData, valueMapping))
        .style('transform', function (this) {
          const dynamicTTHeight = this?.getBoundingClientRect()?.height;

          return calcTTTransformOnMouseOver({
            d,
            chartPosition,
            dims,
            xScale,
            yScale,
            // TODO: can probably remove tooltipHeight and just rely on dynamicHeight, want to test more to be sure though
            tooltipHeight: dynamicTTHeight || tooltipHeight,
            tooltipWidth,
            hideLegend,
            BBox: event?.target?.getBBox(),
            nullGroups,
          });
        });
    };

    const mouseOut = (d: any) => {
      fade(chart, 1, d);
      tooltip.style('opacity', 0).attr('hidden', true);
    };

    const barsBackground = chart.append('g');

    barsBackground
      .append('rect')
      .classed('bars-container', true)
      .attr('x', 0)
      .attr('y', yAxisRange[0] + 2) //2px stroke on bars
      .attr('width', dims.innerWidth)
      .attr(
        'height',
        yScale.bandwidth() * data.length +
          yScale.step() * innerPad * (data.length - 1) -
          2
      )
      .attr('fill', 'rgba(0,0,0,0)')
      .attr('cursor', 'pointer')
      .on('mouseover', () => {
        // prevent 'flashing' opacity of bars when mouse moves into the space between bars
        if (tooltipDataLen > 0) {
          // prevent tooltip appearing if there is no data
          tooltip.style('opacity', 1).attr('hidden', null);
          fade(chart, 0.5, mouseoverData, mouseoverEventKey);
        }
      })
      .on('mouseout', () => {
        tooltip.style('opacity', 0).attr('hidden', true);
        fade(chart, 1, mouseoverData, mouseoverEventKey);
      });

    drawStackedBars({
      stackedData,
      nullGroups,
      innerWidth: dims.innerWidth,
      barsBackground,
      xScale,
      yScale,
      mouseOver,
      mouseOut,
      otherColor,
      color,
    });

    // axes
    const yAxisLeft = axisLeft().scale(yScale).tickSizeOuter(0).tickFormat('');

    if (download) {
      const chartHeight = calcBarsHeight({ data, yScale, innerPad });

      // generate SVG legend for download
      const legendContainer = generateSVGLegend({
        chart,
        width,
        height,
        dims,
        stackedData,
        color,
        otherColor,
        fontColor: '#636d7e',
        formatter: labelFormatter,
      });

      positionSVGContainer({
        data,
        maxStep,
        legendContainer,
        barsBackground,
        height,
        chartHeight,
        dims,
        yAxisRange,
        yScale,
        groups,
        innerPad,
        bottomGutter: watermarkHeight + padding,
      } as any);
    }

    // add groups for text boxes
    const textBoxes = chart
      .append('g')
      .selectAll('.textBox')
      .data(data)
      .enter()
      .append('g');

    styleTextboxes({
      textBoxes,
      dims,
      yScale,
      name,
      fontSize,
      lineHeight,
      download,
      fontColor,
    });

    appendYAxis({ chart, fontColor, yAxisLeft });

    if (!download) {
      notifyChartRenderComplete(chart, requestHash, () => {
        globalLoader.next(false);
        isRenderingOrLoading?.next(false);
      });
    }

    if (download) {
      generateInlineStyles(`.inline-style-target-${name}`);
    }

    if (title) {
      appendTitle(chart, title, dims, padding);
    }

    if (watermark) {
      appendWatermark(
        chart,
        watermark,
        dims.innerWidth + dims.margin.right,
        dims.innerHeight + dims.margin.bottom,
        padding
      );
    }

    if (getSVGNode) {
      return svg.node();
    }
  }
};
