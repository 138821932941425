import { PageTitles } from '@revelio/core';
import { SelectionCategories } from '@revelio/filtering';
import { Screener } from '../../../screener/screener';
import { generateFilterOptions } from '../../utils/generateFilterOptions';
import { ScreenerDimension } from '@revelio/data-access';

const title = [PageTitles.GEOGRAPHY, PageTitles.SCREENER];

export const GeographyScreener = () => {
  const filterOptions = generateFilterOptions(
    SelectionCategories.REGION,
    'Geography'
  );

  return (
    <Screener
      title={title}
      filterOptions={filterOptions}
      view={ScreenerDimension.Geography}
    />
  );
};

export default GeographyScreener;
