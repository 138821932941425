import { Meter } from '@revelio/d3';
import { GeoSummaryPostingsDataQuery } from '@revelio/data-access';
import { useEffect, useState } from 'react';
import { calculateMeterValue } from './meter-scaling/calculateMeterValue';

interface TimeToFillMeterProps {
  postingsData: GeoSummaryPostingsDataQuery | undefined;
}
export const TimeToFillMeter = ({ postingsData }: TimeToFillMeterProps) => {
  const postingsCategory = postingsData?.posting?.[0]?.category;

  const timeToFillStart = postingsCategory?.[0]?.metrics?.time_to_fill;
  const timeToFillEnd =
    postingsCategory?.[postingsCategory?.length - 1]?.metrics?.time_to_fill ||
    0;

  const [meterValue, setMeterValue] = useState<number | null>(null);

  useEffect(() => {
    const meterValue = calculateMeterValue({
      value: timeToFillEnd,
      metric: 'timeToFill',
    });

    setMeterValue(meterValue);
  }, [timeToFillEnd]);

  return (
    <Meter
      name="time-to-fill-meter"
      data={{
        title: `${timeToFillStart?.toFixed(0) || 0} days → 
      ${timeToFillEnd?.toFixed(0) || 0} days`,
        value: meterValue,
        unit: 'days',
      }}
      format="d"
      customFormatter={(_value: string) => `${timeToFillEnd?.toFixed(0) || 0}`}
      customMargins={{ top: 15, right: 0, bottom: 15, left: 0 }}
    />
  );
};
