import { CompanyStat } from '../../company-stat';
import { MonthlyLineChartProps, MonthlyLineChart } from '../monthly-line-chart';
import {
  CompanySentimentMetric,
  getAggregatedCompetitorSentiment,
} from '../utils/get-aggregated-competitor-metrics';
import { CompanyStatsProps } from '../types';
import { calculatePercentageChange } from '../utils/calculate-percentage-change';
import { Text } from '@chakra-ui/react';

interface BenefitsChartProps {
  sentimentData: CompanyStatsProps['sentimentData'];
  selectedCompanyRCID: CompanyStatsProps['selectedCompanyRCID'];
  competitors: CompanyStatsProps['competitors'];
  companyName: string | null | undefined;
}
export const BenefitsChart = ({
  selectedCompanyRCID,
  companyName,
  competitors,
  sentimentData,
}: BenefitsChartProps) => {
  const primarySentimentData = sentimentData?.sentiment2d?.find(
    (datum) => datum?.metadata?.id === selectedCompanyRCID
  );
  const primaryCompanyCategories = primarySentimentData?.category || [];

  const benefitsTimeseries: MonthlyLineChartProps['timeSeries'] =
    primaryCompanyCategories?.map((datum) => ({
      id: datum?.metadata?.id,
      value: datum?.metrics?.compensationsBenefitsRating,
      date: datum?.metadata?.shortName,
      count: datum?.metrics?.compensationsBenefitsRatingCount,
    })) || [];

  const { lastValue, percentageChange } =
    calculatePercentageChange(benefitsTimeseries);

  const aggregatedCompetitorBenefits = sentimentData?.sentiment2d
    ? getAggregatedCompetitorSentiment(
        competitors,
        sentimentData.sentiment2d,
        CompanySentimentMetric.CompensationsBenefitsRating
      )
    : 0;

  const vsCompetitors = lastValue - aggregatedCompetitorBenefits;

  return (
    <CompanyStat
      name={CompanySentimentMetric.CompensationsBenefitsRating}
      plotInfo={
        <Text color="white">
          The average score given by workers on the topic of Compensation &
          Benefits
        </Text>
      }
      header="Benefits"
      statValues={{
        primaryValue: lastValue.toFixed(2),
        percentChange: percentageChange,
        vsCompetitors,
      }}
      competitorFormatting={(value: number) => value.toFixed(2)}
    >
      <MonthlyLineChart
        name={CompanySentimentMetric.CompensationsBenefitsRating}
        companyName={companyName}
        timeSeries={benefitsTimeseries}
        chartProps={{
          ttType: 'percent',
          ttMainFormat: '.2f',
          ttSecondaryFormat: ',',
          ttPosition: 'left',
        }}
      />
    </CompanyStat>
  );
};
