import { SubFilterValues } from './types';

export const FORMAT_MAP: Record<
  SubFilterValues,
  {
    text: string;
    type: 'prefix' | 'suffix' | 'none';
    defaults: { startValue: number; endValue: number };
  }
> = {
  headcount: {
    text: '',
    type: 'none',
    defaults: { startValue: 0, endValue: 10000 },
  },
  inflow: {
    text: '',
    type: 'none',
    defaults: { startValue: 0, endValue: 100 },
  },
  outflow: {
    text: '',
    type: 'none',
    defaults: { startValue: 0, endValue: 100 },
  },
  hiring: {
    text: '%',
    type: 'suffix',
    defaults: { startValue: 0, endValue: 25 },
  },
  attrition: {
    text: '%',
    type: 'suffix',
    defaults: { startValue: 0, endValue: 25 },
  },
  growth: {
    text: '%',
    type: 'suffix',
    defaults: { startValue: 0, endValue: 25 },
  },
  growth_yoy: {
    text: '%',
    type: 'suffix',
    defaults: { startValue: 0, endValue: 25 },
  },
  salary: {
    text: '$',
    type: 'prefix',
    defaults: { startValue: 0, endValue: 100000 },
  },
  tenure: {
    text: 'yr',
    type: 'suffix',
    defaults: { startValue: 0, endValue: 2 },
  },
};
