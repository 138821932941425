import { graphql } from '@revelio/data-access';

export const GAME_GET_RESULTS_STATS = graphql(`
  query GetGameResultsStats(
    $msa: [ID!]
    $role_k150: [ID!]
    $seniority: [ID!]
    $endDate: String
  ) {
    rawComposition: composition(
      filters: { msa: $msa, role_k150: $role_k150, seniority: $seniority }
      dim1: geography
    ) {
      metadata {
        id
        shortName
        longName
      }
      metrics {
        headcount {
          timeseries {
            id
            date
            value
          }
        }
        growth_rate {
          timeseries {
            id
            date
            value
          }
        }
        hiring_rate {
          timeseries {
            id
            date
            value
          }
        }
        attrition_rate {
          timeseries {
            id
            date
            value
          }
        }
        tenure {
          timeseries {
            id
            date
            value
          }
        }
        salary {
          timeseries {
            id
            date
            value
          }
        }
      }
    }
    posting(
      filters: {
        msa: $msa
        role_k150: $role_k150
        seniority: $seniority
        provider: [1, 2, 3]
      }
      dim1: geography
      dim2: date
    ) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          id
          shortName
          longName
        }
        metrics {
          time_to_fill
          active
        }
      }
    }
    currentYearSentiment: sentiment2d(
      filters: { msa: $msa, role_k150: $role_k150 }
      dim1: geography
      dim2: month
    ) {
      metrics {
        overallRating
      }
    }
    previousYearSentiment: sentiment2d(
      filters: { msa: $msa, role_k150: $role_k150, end_date: $endDate }
      dim1: geography
      dim2: month
    ) {
      metrics {
        overallRating
      }
    }
  }
`);
