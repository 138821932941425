import { CompanyStatsProps } from '../types';
import { calculatePercentageChange } from './calculate-percentage-change';

export const getMetricStats = (
  metrics:
    | NonNullable<
        NonNullable<
          NonNullable<CompanyStatsProps['compositionData']>['composition']
        >[number]
      >['metrics']
    | undefined
    | null,
  metric: 'headcount' | 'growth_rate'
) => {
  const { lastValue, percentageChange } = calculatePercentageChange(
    metrics?.[metric]?.timeseries || []
  );

  return {
    percentageChange,
    lastValue,
  };
};
