import { Box, Grid, GridItem, Text, Flex } from '@chakra-ui/react';
import { PageTitles, AddEntityButtonText, PrimaryFilters } from '@revelio/core';
import {
  AddEntityButton,
  FilterChipsContainer,
  ROLE_GRANULARITY_FILTERS,
  PrimaryFilterLimits,
  SHARED_SET_ENTITY_LIMIT,
  createSelectableFiltersMap,
  useViewFilters,
} from '@revelio/filtering';
import { flatten } from 'lodash';
import { useMemo } from 'react';
import DashboardPage from '../../DashboardPage';

export const RoleSummary = () => {
  const primaryViewFilters = useMemo(
    () => [
      {
        filters: ROLE_GRANULARITY_FILTERS,
        isNested: true,
        limit: 1,
      },
    ],
    []
  );

  const primaryFilters = useMemo(
    () => flatten(createSelectableFiltersMap(primaryViewFilters)),
    [primaryViewFilters]
  );

  useViewFilters([...primaryFilters]);

  const anyRequestsLoading = false;

  return (
    <DashboardPage
      title={[PageTitles.ROLE, PageTitles.SUMMARY]}
      hideSelectionsMargins
      loading={anyRequestsLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="filterChip"
            showColors
            isPrimaryChip
            useChipsSkeleton={false}
            min={1}
            addButton={
              <AddEntityButton
                entities={primaryViewFilters}
                entityName={AddEntityButtonText[PrimaryFilters.ROLE]}
                buttonText={AddEntityButtonText[PrimaryFilters.ROLE]}
                activeLimit={PrimaryFilterLimits.ROLE_SUMMARY}
                limit={SHARED_SET_ENTITY_LIMIT}
                required={1}
                // trialNoResultsMessage={trialNoResultsMessage}
              />
            }
          />
        </Flex>
      }
    >
      <Grid templateColumns="2fr 1fr" gap={4} mb={4}>
        {/* Left Column: Detailed Information */}
        <GridItem>
          <Box bg="gray.100" p={4} borderRadius="md">
            <Text size="md">Accountant</Text>
            <Text mt={4}>
              Lorem ipsum dolor sit amet. Quo voluptate minima qui nostrum
              accusamus sit facere voluptas aut debitis harum sit dignissimos
              nesciunt...
            </Text>
            <Text mt={4}>
              Aut autem rerum et omnis aspernatur aut nemo voluptas cum dolor.
              Non officia rerum et vero...
            </Text>
            <Text mt={4} fontWeight="bold">
              Common titles:
            </Text>
            <Text>Lorem ipsum dolor sit amet. Quo voluptate minima...</Text>
          </Box>
        </GridItem>

        {/* Right Column: Single Component */}
        <GridItem>
          <Box bg="gray.100" p={4} borderRadius="md" height="100%">
            <Text fontWeight="bold">Metrics Overview</Text>
            {/*  headcount, growth, postings, and sentiment here */}
          </Box>
        </GridItem>
      </Grid>

      {/* Lower Section: Top Skills, Geographies, Companies */}
      <Grid templateColumns="repeat(3, 1fr)" gap={4}>
        <GridItem>
          <Box bg="gray.100" p={4} borderRadius="md">
            <Text fontWeight="bold">Top Skills</Text>
            {/* Insert bar chart or data here */}
          </Box>
        </GridItem>
        <GridItem>
          <Box bg="gray.100" p={4} borderRadius="md">
            <Text fontWeight="bold">Top Geographies</Text>
            {/* Insert bar chart or data here */}
          </Box>
        </GridItem>
        <GridItem>
          <Box bg="gray.100" p={4} borderRadius="md">
            <Text fontWeight="bold">Top Companies</Text>
            {/* Insert bar chart or data here */}
          </Box>
        </GridItem>
      </Grid>
    </DashboardPage>
  );
};
