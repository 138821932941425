import {
  CompositionDataQueryVariables,
  Dimension1,
  Filters,
} from '@revelio/data-access';
import { PrimaryView } from '@revelio/core';
import { mapKeys } from 'lodash';
import {
  Filter,
  FilterName,
  FilterParameterKeys,
  GqlFilterKeyMapper,
  SelectionCategories,
  SelectionList,
  ValidValueTypes,
  formatAndBreakoutFilters,
  getCompositionOvertimeRequiredSubFilterIds,
  mergeFiltersTogether,
} from '@revelio/filtering';

type TransformFiltersToVariables = {
  view: PrimaryView;
  filters: Filter[];
};

const getMappedFilters = (filters: Filter[]) => {
  const { formattedFilter } = formatAndBreakoutFilters(filters, [
    SelectionCategories.PRIMARY_FILTER,
  ]);

  const mergedFilters = mergeFiltersTogether(formattedFilter, [
    [SelectionCategories.KEYWORD, SelectionCategories.KEYWORDS_CATEGORY],
  ]);

  return mapKeys(mergedFilters, (_v, key): FilterName | FilterParameterKeys => {
    const typedKey = key as keyof typeof mergedFilters;
    return GqlFilterKeyMapper[typedKey] || typedKey;
  });
};

export const getDim1FromView = (view: PrimaryView): Dimension1 => {
  switch (view) {
    case PrimaryView.COMPANY:
      return Dimension1.Company;
    case PrimaryView.GEOGRAPHY:
      return Dimension1.Geography;
    case PrimaryView.ROLE:
      return Dimension1.Role;
  }
};

/** ================================
 * Snapshot
 ================================ */
export const transformFiltersToSnapshotVariables = ({
  view,
  filters,
  selectionListsData,
}: TransformFiltersToVariables & {
  selectionListsData: SelectionList<ValidValueTypes>[];
}): CompositionDataQueryVariables => {
  const mappedFilters: Filters = getMappedFilters(filters);

  const categorySubFilters = getCompositionOvertimeRequiredSubFilterIds({
    primaryView: view,
    /** Don't send undefined values to sub filter conversion */
    selectedPageFilters: Object.entries(mappedFilters).reduce(
      (acc, [key, val]) => {
        if (val) return { ...acc, [key]: val };
        return acc;
      },
      {}
    ),
    selectionLists: selectionListsData,
  });

  return {
    dim1: getDim1FromView(view),
    filters: {
      ...mappedFilters,
      category_sub_filter: {
        skill: categorySubFilters?.skill,
      },
      start_date: mappedFilters.end_date,
    },
  };
};

/** ================================
 * Overtime
 ================================ */
export const transformFiltersToOvertimeVariables = ({
  view,
  filters,
  selectionListsData,
}: TransformFiltersToVariables & {
  selectionListsData: SelectionList<ValidValueTypes>[];
}): CompositionDataQueryVariables => {
  const mappedFilters = getMappedFilters(filters);

  return {
    dim1: getDim1FromView(view),
    filters: {
      ...mappedFilters,
      category_sub_filter: getCompositionOvertimeRequiredSubFilterIds({
        primaryView: view,
        /** Don't send undefined values to sub filter conversion */
        selectedPageFilters: Object.entries(mappedFilters).reduce(
          (acc, [key, val]) => {
            if (val) return { ...acc, [key]: val };
            return acc;
          },
          {}
        ),
        selectionLists: selectionListsData,
      }),
    },
  };
};
