import { Option } from '../../selection-list';
import { User } from '@revelio/data-access';

const WEBSITE_POSTINGS_SOURCE_OPTION = {
  value: '2',
  label: 'Website Postings (other)',
};
export const UNIFIED_POSTING_SOURCE_OPTION = {
  value: '9',
  label: 'Unified',
};
const OPTIONS: Option[] = [
  UNIFIED_POSTING_SOURCE_OPTION,
  {
    value: '8',
    label: 'Revelio Labs Website Postings',
  },
  WEBSITE_POSTINGS_SOURCE_OPTION,
  {
    value: '3',
    label: 'LinkedIn',
  },
  {
    value: '1',
    label: 'Other (Indeed, ZipRecruiter, etc.)',
  },
];

export const getPostingsSourceOptions = (loggedInUser: User) => {
  let postingSourceOptions = OPTIONS;
  const userHasWebsitePostings = loggedInUser.linkup_postings;
  if (!userHasWebsitePostings) {
    postingSourceOptions = postingSourceOptions.filter(
      (option) => option.value !== WEBSITE_POSTINGS_SOURCE_OPTION.value
    );
  }
  const userHasUnifiedPostings = loggedInUser.unified_postings;
  if (!userHasUnifiedPostings) {
    postingSourceOptions = postingSourceOptions.filter(
      (option) => option.value !== UNIFIED_POSTING_SOURCE_OPTION.value
    );
  }

  return postingSourceOptions;
};
