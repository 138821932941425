import { graphql } from '@revelio/data-access';

export const AllUsersQuery = graphql(`
  query GetAllUsers {
    users {
      id
      name
      username
      email
      live
      legacy_id
    }
  }
`);

export const AllClientUsersQuery = graphql(`
  query GetAllClientUsers($clientName: String!) {
    clients(ids: [$clientName]) {
      users {
        id
        name
        username
        email
        live
        active
        legacy_id
      }
    }
  }
`);

export const AllClientsQuery = graphql(`
  query GetAllClients {
    clients {
      client_name
    }
  }
`);

export const ClientDetailsQuery = graphql(`
  query GetClientDetails($ids: [String]!) {
    clients(ids: $ids) {
      client_name
      company_lists
      active
      tabs
      live
      linkup_postings
      unified_postings
      num_seats
      data_builder_configuration {
        client_group
        pipeline_type
        posting_source
        s3_location {
          s3_bucket
          s3_file_prefix
          s3_format
        }
        snowflake_location {
          snowflake_db
          snowflake_schema
          snowflake_file_prefix
        }
      }
      reports_configuration {
        report_types
      }
    }
  }
`);

export const UpdateUserMutation = graphql(`
  mutation UpdateUser(
    $id: String!
    $name: String
    $email: String
    $password: String
    $role: Role
    $client_name: String
    $active: Boolean
    $metadata: [KeyValue]
  ) {
    updateUser(
      input: {
        id: $id
        name: $name
        email: $email
        username: $email
        role: $role
        client_name: $client_name
        password: $password
        active: $active
        metadata: $metadata
      }
    ) {
      id
      name
      username
      email
      role
      client_name
      live
      active
      tabs
      company_lists
      linkup_postings
      unified_postings
      num_seats
      metadata {
        key
        value
      }
    }
  }
`);

export const UpdateUserMetaDataMutation = graphql(`
  mutation UpdateUserMetaData($id: String!, $metadata: [KeyValue]) {
    updateUser(input: { id: $id, metadata: $metadata }) {
      id
      name
      username
      email
      role
      client_name
      live
      active
      tabs
      company_lists
      linkup_postings
      unified_postings
      num_seats
      metadata {
        key
        value
      }
    }
  }
`);

export const AddUserMutation = graphql(`
  mutation AddUser(
    $name: String!
    $email: String!
    $password: String
    $role: Role!
    $client_name: String!
    $active: Boolean!
    $metadata: [KeyValue]
  ) {
    createUser(
      input: {
        name: $name
        username: $email
        email: $email
        password: $password
        role: $role
        client_name: $client_name
        active: $active
        metadata: $metadata
      }
    ) {
      id
      name
      username
      email
      role
      client_name
      live
      active
      tabs
      company_lists
      linkup_postings
      unified_postings
      num_seats
      metadata {
        key
        value
      }
    }
  }
`);

export const UpdateClientGroupMutation = graphql(`
  mutation UpdateClientGroupRewrite(
    $client_name: String!
    $tabs: [Tab!]!
    $live: Boolean!
    $active: Boolean!
    $linkup_postings: Boolean!
    $unified_postings: Boolean!
    $num_seats: String!
    $data_builder_configuration: PipelineClientConfigInput!
    $reports_configuration: ClientReportsConfigInput
  ) {
    updateClientGroup(
      input: {
        client_name: $client_name
        tabs: $tabs
        live: $live
        active: $active
        linkup_postings: $linkup_postings
        unified_postings: $unified_postings
        num_seats: $num_seats
        data_builder_configuration: $data_builder_configuration
        reports_configuration: $reports_configuration
      }
    ) {
      client_name
      company_lists
      active
      tabs
      live
      linkup_postings
      unified_postings
      num_seats
    }
  }
`);

export const DeleteUserMutation = graphql(`
  mutation DeleteUser($id: String!) {
    deleteUser(input: $id)
  }
`);
