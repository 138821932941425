import { CompensationResponse } from '@revelio/core';
import { useEffect, useState } from 'react';
import { CompensationBody } from './use-get-compensation-body';
import objectHash from 'object-hash';
import { dataCache } from './data-cache';
import { GO_API_ROOT } from '@revelio/auth';

export const useFetchCompensation = ({
  compensationBody,
  setLoading,
}: {
  compensationBody: CompensationBody | undefined;
  setLoading: (loading: boolean) => void;
}) => {
  const [data, setData] = useState<CompensationResponse[]>();

  useEffect(() => {
    (async () => {
      if (compensationBody) {
        const requestHash = objectHash(
          { url: '/models/compensation', ...compensationBody },
          { unorderedArrays: true }
        );
        const hasCacheEntry = dataCache.has(requestHash);

        if (hasCacheEntry) {
          setData(dataCache.get(requestHash));
          setLoading(false);
          return;
        }

        setLoading(true);
        const resp = await fetch(`${GO_API_ROOT}/models/compensation`, {
          method: 'POST',
          body: JSON.stringify(compensationBody),
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'x-request-id': crypto.randomUUID(),
          },
        });

        const data = await resp.json();
        dataCache.set(requestHash, data);
        setData(data);
        setLoading(false);
      }
    })();
  }, [compensationBody, setLoading]);

  return { data };
};
