export const wgMetro = [
  {
    percentile: 0,
    z_score: -6.980125262,
  },
  {
    percentile: 0.1164144354,
    z_score: -4.814002574,
  },
  {
    percentile: 0.2328288708,
    z_score: -4.303763692,
  },
  {
    percentile: 0.3492433062,
    z_score: -3.725071357,
  },
  {
    percentile: 0.4656577416,
    z_score: -3.52504493,
  },
  {
    percentile: 0.5820721769,
    z_score: -3.471046575,
  },
  {
    percentile: 0.6984866123,
    z_score: -3.257909283,
  },
  {
    percentile: 0.8149010477,
    z_score: -3.01495313,
  },
  {
    percentile: 0.9313154831,
    z_score: -2.623726656,
  },
  {
    percentile: 1.047729919,
    z_score: -2.621652439,
  },
  {
    percentile: 1.164144354,
    z_score: -2.615392377,
  },
  {
    percentile: 1.280558789,
    z_score: -2.113117568,
  },
  {
    percentile: 1.396973225,
    z_score: -2.022212764,
  },
  {
    percentile: 1.51338766,
    z_score: -1.952442928,
  },
  {
    percentile: 1.629802095,
    z_score: -1.717694769,
  },
  {
    percentile: 1.746216531,
    z_score: -1.559090648,
  },
  {
    percentile: 1.862630966,
    z_score: -1.31078529,
  },
  {
    percentile: 1.979045402,
    z_score: -1.245053616,
  },
  {
    percentile: 2.095459837,
    z_score: -1.18215669,
  },
  {
    percentile: 2.211874272,
    z_score: -0.9854778355,
  },
  {
    percentile: 2.328288708,
    z_score: -0.8947937605,
  },
  {
    percentile: 2.444703143,
    z_score: -0.8514935369,
  },
  {
    percentile: 2.561117579,
    z_score: -0.8225733344,
  },
  {
    percentile: 2.677532014,
    z_score: -0.7969387189,
  },
  {
    percentile: 2.793946449,
    z_score: -0.7241025153,
  },
  {
    percentile: 2.910360885,
    z_score: -0.6864966221,
  },
  {
    percentile: 3.02677532,
    z_score: -0.6634354754,
  },
  {
    percentile: 3.143189756,
    z_score: -0.6606860043,
  },
  {
    percentile: 3.259604191,
    z_score: -0.6266581784,
  },
  {
    percentile: 3.376018626,
    z_score: -0.6212788858,
  },
  {
    percentile: 3.492433062,
    z_score: -0.6063384315,
  },
  {
    percentile: 3.608847497,
    z_score: -0.5907095107,
  },
  {
    percentile: 3.725261932,
    z_score: -0.5801155889,
  },
  {
    percentile: 3.841676368,
    z_score: -0.5668520239,
  },
  {
    percentile: 3.958090803,
    z_score: -0.5629691708,
  },
  {
    percentile: 4.074505239,
    z_score: -0.560012143,
  },
  {
    percentile: 4.190919674,
    z_score: -0.5586428281,
  },
  {
    percentile: 4.307334109,
    z_score: -0.5473440861,
  },
  {
    percentile: 4.423748545,
    z_score: -0.53849889,
  },
  {
    percentile: 4.54016298,
    z_score: -0.5158021948,
  },
  {
    percentile: 4.656577416,
    z_score: -0.5134100205,
  },
  {
    percentile: 4.772991851,
    z_score: -0.5133516069,
  },
  {
    percentile: 4.889406286,
    z_score: -0.5122232038,
  },
  {
    percentile: 5.005820722,
    z_score: -0.5109376028,
  },
  {
    percentile: 5.122235157,
    z_score: -0.5064211698,
  },
  {
    percentile: 5.238649593,
    z_score: -0.4967754825,
  },
  {
    percentile: 5.355064028,
    z_score: -0.4848818148,
  },
  {
    percentile: 5.471478463,
    z_score: -0.4845346338,
  },
  {
    percentile: 5.587892899,
    z_score: -0.4733798635,
  },
  {
    percentile: 5.704307334,
    z_score: -0.4651538998,
  },
  {
    percentile: 5.820721769,
    z_score: -0.4628509998,
  },
  {
    percentile: 5.937136205,
    z_score: -0.4433236081,
  },
  {
    percentile: 6.05355064,
    z_score: -0.4429275093,
  },
  {
    percentile: 6.169965076,
    z_score: -0.4427977555,
  },
  {
    percentile: 6.286379511,
    z_score: -0.4405434304,
  },
  {
    percentile: 6.402793946,
    z_score: -0.4401225025,
  },
  {
    percentile: 6.519208382,
    z_score: -0.4390396763,
  },
  {
    percentile: 6.635622817,
    z_score: -0.4299607087,
  },
  {
    percentile: 6.752037253,
    z_score: -0.4264596561,
  },
  {
    percentile: 6.868451688,
    z_score: -0.4259037118,
  },
  {
    percentile: 6.984866123,
    z_score: -0.425161609,
  },
  {
    percentile: 7.101280559,
    z_score: -0.4251328753,
  },
  {
    percentile: 7.217694994,
    z_score: -0.4231906494,
  },
  {
    percentile: 7.33410943,
    z_score: -0.4226625325,
  },
  {
    percentile: 7.450523865,
    z_score: -0.4215468999,
  },
  {
    percentile: 7.5669383,
    z_score: -0.4214620617,
  },
  {
    percentile: 7.683352736,
    z_score: -0.4207425535,
  },
  {
    percentile: 7.799767171,
    z_score: -0.4185900278,
  },
  {
    percentile: 7.916181607,
    z_score: -0.4177750844,
  },
  {
    percentile: 8.032596042,
    z_score: -0.4174820274,
  },
  {
    percentile: 8.149010477,
    z_score: -0.4164272724,
  },
  {
    percentile: 8.265424913,
    z_score: -0.4163440005,
  },
  {
    percentile: 8.381839348,
    z_score: -0.4129698033,
  },
  {
    percentile: 8.498253783,
    z_score: -0.4056268002,
  },
  {
    percentile: 8.614668219,
    z_score: -0.4045473456,
  },
  {
    percentile: 8.731082654,
    z_score: -0.403051811,
  },
  {
    percentile: 8.84749709,
    z_score: -0.3987677674,
  },
  {
    percentile: 8.963911525,
    z_score: -0.3968410466,
  },
  {
    percentile: 9.08032596,
    z_score: -0.3959702535,
  },
  {
    percentile: 9.196740396,
    z_score: -0.3881991214,
  },
  {
    percentile: 9.313154831,
    z_score: -0.3848503756,
  },
  {
    percentile: 9.429569267,
    z_score: -0.3835494935,
  },
  {
    percentile: 9.545983702,
    z_score: -0.3827054478,
  },
  {
    percentile: 9.662398137,
    z_score: -0.3808592158,
  },
  {
    percentile: 9.778812573,
    z_score: -0.3792846115,
  },
  {
    percentile: 9.895227008,
    z_score: -0.3748223568,
  },
  {
    percentile: 10.011641444,
    z_score: -0.3747733202,
  },
  {
    percentile: 10.128055879,
    z_score: -0.3728222355,
  },
  {
    percentile: 10.244470314,
    z_score: -0.3693681932,
  },
  {
    percentile: 10.36088475,
    z_score: -0.3615302667,
  },
  {
    percentile: 10.477299185,
    z_score: -0.3610103547,
  },
  {
    percentile: 10.59371362,
    z_score: -0.3607171705,
  },
  {
    percentile: 10.710128056,
    z_score: -0.360317511,
  },
  {
    percentile: 10.826542491,
    z_score: -0.3568804364,
  },
  {
    percentile: 10.942956927,
    z_score: -0.3564470724,
  },
  {
    percentile: 11.059371362,
    z_score: -0.3549173046,
  },
  {
    percentile: 11.175785797,
    z_score: -0.3543853992,
  },
  {
    percentile: 11.292200233,
    z_score: -0.353560078,
  },
  {
    percentile: 11.408614668,
    z_score: -0.3519019885,
  },
  {
    percentile: 11.525029104,
    z_score: -0.3515672355,
  },
  {
    percentile: 11.641443539,
    z_score: -0.3495336368,
  },
  {
    percentile: 11.757857974,
    z_score: -0.3492176593,
  },
  {
    percentile: 11.87427241,
    z_score: -0.3419125339,
  },
  {
    percentile: 11.990686845,
    z_score: -0.3404562169,
  },
  {
    percentile: 12.107101281,
    z_score: -0.3389682602,
  },
  {
    percentile: 12.223515716,
    z_score: -0.3386512414,
  },
  {
    percentile: 12.339930151,
    z_score: -0.3368899814,
  },
  {
    percentile: 12.456344587,
    z_score: -0.3351846367,
  },
  {
    percentile: 12.572759022,
    z_score: -0.3326410689,
  },
  {
    percentile: 12.689173458,
    z_score: -0.3311862683,
  },
  {
    percentile: 12.805587893,
    z_score: -0.3299552855,
  },
  {
    percentile: 12.922002328,
    z_score: -0.3282116352,
  },
  {
    percentile: 13.038416764,
    z_score: -0.3281048803,
  },
  {
    percentile: 13.154831199,
    z_score: -0.3263730073,
  },
  {
    percentile: 13.271245634,
    z_score: -0.3259977752,
  },
  {
    percentile: 13.38766007,
    z_score: -0.3257756784,
  },
  {
    percentile: 13.504074505,
    z_score: -0.3237886142,
  },
  {
    percentile: 13.620488941,
    z_score: -0.3230350486,
  },
  {
    percentile: 13.736903376,
    z_score: -0.31518897,
  },
  {
    percentile: 13.853317811,
    z_score: -0.3109766723,
  },
  {
    percentile: 13.969732247,
    z_score: -0.305662353,
  },
  {
    percentile: 14.086146682,
    z_score: -0.3047204885,
  },
  {
    percentile: 14.202561118,
    z_score: -0.3037295939,
  },
  {
    percentile: 14.318975553,
    z_score: -0.302506852,
  },
  {
    percentile: 14.435389988,
    z_score: -0.3023978566,
  },
  {
    percentile: 14.551804424,
    z_score: -0.3015137321,
  },
  {
    percentile: 14.668218859,
    z_score: -0.3008641647,
  },
  {
    percentile: 14.784633295,
    z_score: -0.2979115336,
  },
  {
    percentile: 14.90104773,
    z_score: -0.2974873196,
  },
  {
    percentile: 15.017462165,
    z_score: -0.2968043427,
  },
  {
    percentile: 15.133876601,
    z_score: -0.2967919611,
  },
  {
    percentile: 15.250291036,
    z_score: -0.2965076169,
  },
  {
    percentile: 15.366705471,
    z_score: -0.295781869,
  },
  {
    percentile: 15.483119907,
    z_score: -0.2939734825,
  },
  {
    percentile: 15.599534342,
    z_score: -0.2927661743,
  },
  {
    percentile: 15.715948778,
    z_score: -0.2917961038,
  },
  {
    percentile: 15.832363213,
    z_score: -0.2903359383,
  },
  {
    percentile: 15.948777648,
    z_score: -0.2899550661,
  },
  {
    percentile: 16.065192084,
    z_score: -0.2871984587,
  },
  {
    percentile: 16.181606519,
    z_score: -0.2870634787,
  },
  {
    percentile: 16.298020955,
    z_score: -0.28512733,
  },
  {
    percentile: 16.41443539,
    z_score: -0.2851172172,
  },
  {
    percentile: 16.530849825,
    z_score: -0.2807904294,
  },
  {
    percentile: 16.647264261,
    z_score: -0.2786217134,
  },
  {
    percentile: 16.763678696,
    z_score: -0.2761047052,
  },
  {
    percentile: 16.880093132,
    z_score: -0.2747117958,
  },
  {
    percentile: 16.996507567,
    z_score: -0.2739128985,
  },
  {
    percentile: 17.112922002,
    z_score: -0.2725283625,
  },
  {
    percentile: 17.229336438,
    z_score: -0.2720729824,
  },
  {
    percentile: 17.345750873,
    z_score: -0.2712858821,
  },
  {
    percentile: 17.462165308,
    z_score: -0.270591626,
  },
  {
    percentile: 17.578579744,
    z_score: -0.2697110137,
  },
  {
    percentile: 17.694994179,
    z_score: -0.2696893327,
  },
  {
    percentile: 17.811408615,
    z_score: -0.2682389087,
  },
  {
    percentile: 17.92782305,
    z_score: -0.2665666139,
  },
  {
    percentile: 18.044237485,
    z_score: -0.2658147444,
  },
  {
    percentile: 18.160651921,
    z_score: -0.2657391304,
  },
  {
    percentile: 18.277066356,
    z_score: -0.2654069384,
  },
  {
    percentile: 18.393480792,
    z_score: -0.2618303962,
  },
  {
    percentile: 18.509895227,
    z_score: -0.2607807582,
  },
  {
    percentile: 18.626309662,
    z_score: -0.2602485058,
  },
  {
    percentile: 18.742724098,
    z_score: -0.2573321785,
  },
  {
    percentile: 18.859138533,
    z_score: -0.2572158991,
  },
  {
    percentile: 18.975552969,
    z_score: -0.2557272026,
  },
  {
    percentile: 19.091967404,
    z_score: -0.2554560961,
  },
  {
    percentile: 19.208381839,
    z_score: -0.2532716714,
  },
  {
    percentile: 19.324796275,
    z_score: -0.2530881966,
  },
  {
    percentile: 19.44121071,
    z_score: -0.2528763264,
  },
  {
    percentile: 19.557625146,
    z_score: -0.2473397499,
  },
  {
    percentile: 19.674039581,
    z_score: -0.2454841218,
  },
  {
    percentile: 19.790454016,
    z_score: -0.2454133744,
  },
  {
    percentile: 19.906868452,
    z_score: -0.2443937073,
  },
  {
    percentile: 20.023282887,
    z_score: -0.2441166628,
  },
  {
    percentile: 20.139697322,
    z_score: -0.2433714527,
  },
  {
    percentile: 20.256111758,
    z_score: -0.2428603327,
  },
  {
    percentile: 20.372526193,
    z_score: -0.2423632458,
  },
  {
    percentile: 20.488940629,
    z_score: -0.2421281108,
  },
  {
    percentile: 20.605355064,
    z_score: -0.2393658591,
  },
  {
    percentile: 20.721769499,
    z_score: -0.2383928312,
  },
  {
    percentile: 20.838183935,
    z_score: -0.2372361954,
  },
  {
    percentile: 20.95459837,
    z_score: -0.2367210579,
  },
  {
    percentile: 21.071012806,
    z_score: -0.2331461,
  },
  {
    percentile: 21.187427241,
    z_score: -0.2331102831,
  },
  {
    percentile: 21.303841676,
    z_score: -0.2321033824,
  },
  {
    percentile: 21.420256112,
    z_score: -0.2282731142,
  },
  {
    percentile: 21.536670547,
    z_score: -0.2274621687,
  },
  {
    percentile: 21.653084983,
    z_score: -0.2271244687,
  },
  {
    percentile: 21.769499418,
    z_score: -0.2227900549,
  },
  {
    percentile: 21.885913853,
    z_score: -0.2222808423,
  },
  {
    percentile: 22.002328289,
    z_score: -0.2207570306,
  },
  {
    percentile: 22.118742724,
    z_score: -0.2206341142,
  },
  {
    percentile: 22.235157159,
    z_score: -0.2201205987,
  },
  {
    percentile: 22.351571595,
    z_score: -0.2200952368,
  },
  {
    percentile: 22.46798603,
    z_score: -0.2193693499,
  },
  {
    percentile: 22.584400466,
    z_score: -0.2185861263,
  },
  {
    percentile: 22.700814901,
    z_score: -0.2182999435,
  },
  {
    percentile: 22.817229336,
    z_score: -0.2182333049,
  },
  {
    percentile: 22.933643772,
    z_score: -0.2170312502,
  },
  {
    percentile: 23.050058207,
    z_score: -0.2169959933,
  },
  {
    percentile: 23.166472643,
    z_score: -0.215509651,
  },
  {
    percentile: 23.282887078,
    z_score: -0.2139867403,
  },
  {
    percentile: 23.399301513,
    z_score: -0.2136423947,
  },
  {
    percentile: 23.515715949,
    z_score: -0.2134358944,
  },
  {
    percentile: 23.632130384,
    z_score: -0.2121908964,
  },
  {
    percentile: 23.74854482,
    z_score: -0.2107776913,
  },
  {
    percentile: 23.864959255,
    z_score: -0.2082006406,
  },
  {
    percentile: 23.98137369,
    z_score: -0.2081069175,
  },
  {
    percentile: 24.097788126,
    z_score: -0.207295559,
  },
  {
    percentile: 24.214202561,
    z_score: -0.2070149124,
  },
  {
    percentile: 24.330616997,
    z_score: -0.2069227075,
  },
  {
    percentile: 24.447031432,
    z_score: -0.2068423868,
  },
  {
    percentile: 24.563445867,
    z_score: -0.2065676977,
  },
  {
    percentile: 24.679860303,
    z_score: -0.2055930938,
  },
  {
    percentile: 24.796274738,
    z_score: -0.2054707838,
  },
  {
    percentile: 24.912689173,
    z_score: -0.2053718729,
  },
  {
    percentile: 25.029103609,
    z_score: -0.2052037673,
  },
  {
    percentile: 25.145518044,
    z_score: -0.2050676328,
  },
  {
    percentile: 25.26193248,
    z_score: -0.2048307007,
  },
  {
    percentile: 25.378346915,
    z_score: -0.2022179793,
  },
  {
    percentile: 25.49476135,
    z_score: -0.202120541,
  },
  {
    percentile: 25.611175786,
    z_score: -0.2016992788,
  },
  {
    percentile: 25.727590221,
    z_score: -0.2014723137,
  },
  {
    percentile: 25.844004657,
    z_score: -0.2012088798,
  },
  {
    percentile: 25.960419092,
    z_score: -0.2008849848,
  },
  {
    percentile: 26.076833527,
    z_score: -0.1991963837,
  },
  {
    percentile: 26.193247963,
    z_score: -0.1987684304,
  },
  {
    percentile: 26.309662398,
    z_score: -0.1982363849,
  },
  {
    percentile: 26.426076834,
    z_score: -0.1966782823,
  },
  {
    percentile: 26.542491269,
    z_score: -0.1961572661,
  },
  {
    percentile: 26.658905704,
    z_score: -0.1958240596,
  },
  {
    percentile: 26.77532014,
    z_score: -0.195097177,
  },
  {
    percentile: 26.891734575,
    z_score: -0.1946151869,
  },
  {
    percentile: 27.00814901,
    z_score: -0.1945286231,
  },
  {
    percentile: 27.124563446,
    z_score: -0.1942265982,
  },
  {
    percentile: 27.240977881,
    z_score: -0.1926936016,
  },
  {
    percentile: 27.357392317,
    z_score: -0.1918670871,
  },
  {
    percentile: 27.473806752,
    z_score: -0.1916536298,
  },
  {
    percentile: 27.590221187,
    z_score: -0.1913022283,
  },
  {
    percentile: 27.706635623,
    z_score: -0.1912395795,
  },
  {
    percentile: 27.823050058,
    z_score: -0.1906808482,
  },
  {
    percentile: 27.939464494,
    z_score: -0.1901158479,
  },
  {
    percentile: 28.055878929,
    z_score: -0.1899490053,
  },
  {
    percentile: 28.172293364,
    z_score: -0.1899422117,
  },
  {
    percentile: 28.2887078,
    z_score: -0.1888987434,
  },
  {
    percentile: 28.405122235,
    z_score: -0.1880572999,
  },
  {
    percentile: 28.521536671,
    z_score: -0.1872608922,
  },
  {
    percentile: 28.637951106,
    z_score: -0.1858322042,
  },
  {
    percentile: 28.754365541,
    z_score: -0.1857814056,
  },
  {
    percentile: 28.870779977,
    z_score: -0.1852861001,
  },
  {
    percentile: 28.987194412,
    z_score: -0.1850550301,
  },
  {
    percentile: 29.103608847,
    z_score: -0.1848110119,
  },
  {
    percentile: 29.220023283,
    z_score: -0.1846915819,
  },
  {
    percentile: 29.336437718,
    z_score: -0.1835128293,
  },
  {
    percentile: 29.452852154,
    z_score: -0.1832609311,
  },
  {
    percentile: 29.569266589,
    z_score: -0.1812859633,
  },
  {
    percentile: 29.685681024,
    z_score: -0.1803421053,
  },
  {
    percentile: 29.80209546,
    z_score: -0.1798906298,
  },
  {
    percentile: 29.918509895,
    z_score: -0.1791401511,
  },
  {
    percentile: 30.034924331,
    z_score: -0.1791128492,
  },
  {
    percentile: 30.151338766,
    z_score: -0.1773999464,
  },
  {
    percentile: 30.267753201,
    z_score: -0.1767994118,
  },
  {
    percentile: 30.384167637,
    z_score: -0.1762458152,
  },
  {
    percentile: 30.500582072,
    z_score: -0.1759321801,
  },
  {
    percentile: 30.616996508,
    z_score: -0.1737922648,
  },
  {
    percentile: 30.733410943,
    z_score: -0.172258952,
  },
  {
    percentile: 30.849825378,
    z_score: -0.1722277096,
  },
  {
    percentile: 30.966239814,
    z_score: -0.1710725627,
  },
  {
    percentile: 31.082654249,
    z_score: -0.1696225258,
  },
  {
    percentile: 31.199068685,
    z_score: -0.168534296,
  },
  {
    percentile: 31.31548312,
    z_score: -0.1680569099,
  },
  {
    percentile: 31.431897555,
    z_score: -0.1677342803,
  },
  {
    percentile: 31.548311991,
    z_score: -0.1672268219,
  },
  {
    percentile: 31.664726426,
    z_score: -0.1672037922,
  },
  {
    percentile: 31.781140861,
    z_score: -0.166788878,
  },
  {
    percentile: 31.897555297,
    z_score: -0.16585104,
  },
  {
    percentile: 32.013969732,
    z_score: -0.165113034,
  },
  {
    percentile: 32.130384168,
    z_score: -0.1638210888,
  },
  {
    percentile: 32.246798603,
    z_score: -0.1632075965,
  },
  {
    percentile: 32.363213038,
    z_score: -0.1628567271,
  },
  {
    percentile: 32.479627474,
    z_score: -0.1624889308,
  },
  {
    percentile: 32.596041909,
    z_score: -0.1616303935,
  },
  {
    percentile: 32.712456345,
    z_score: -0.1611906433,
  },
  {
    percentile: 32.82887078,
    z_score: -0.159414945,
  },
  {
    percentile: 32.945285215,
    z_score: -0.1591481244,
  },
  {
    percentile: 33.061699651,
    z_score: -0.1588922177,
  },
  {
    percentile: 33.178114086,
    z_score: -0.1583967318,
  },
  {
    percentile: 33.294528522,
    z_score: -0.158019442,
  },
  {
    percentile: 33.410942957,
    z_score: -0.157140278,
  },
  {
    percentile: 33.527357392,
    z_score: -0.157073421,
  },
  {
    percentile: 33.643771828,
    z_score: -0.1556936541,
  },
  {
    percentile: 33.760186263,
    z_score: -0.1555130439,
  },
  {
    percentile: 33.876600698,
    z_score: -0.1554491978,
  },
  {
    percentile: 33.993015134,
    z_score: -0.1553618168,
  },
  {
    percentile: 34.109429569,
    z_score: -0.1544423651,
  },
  {
    percentile: 34.225844005,
    z_score: -0.154344584,
  },
  {
    percentile: 34.34225844,
    z_score: -0.1524830653,
  },
  {
    percentile: 34.458672875,
    z_score: -0.1508590164,
  },
  {
    percentile: 34.575087311,
    z_score: -0.1506765151,
  },
  {
    percentile: 34.691501746,
    z_score: -0.1506122993,
  },
  {
    percentile: 34.807916182,
    z_score: -0.1504979615,
  },
  {
    percentile: 34.924330617,
    z_score: -0.150414902,
  },
  {
    percentile: 35.040745052,
    z_score: -0.1493741171,
  },
  {
    percentile: 35.157159488,
    z_score: -0.1488281101,
  },
  {
    percentile: 35.273573923,
    z_score: -0.1481431828,
  },
  {
    percentile: 35.389988359,
    z_score: -0.1479388021,
  },
  {
    percentile: 35.506402794,
    z_score: -0.1474310405,
  },
  {
    percentile: 35.622817229,
    z_score: -0.1473950438,
  },
  {
    percentile: 35.739231665,
    z_score: -0.1467982259,
  },
  {
    percentile: 35.8556461,
    z_score: -0.1461332563,
  },
  {
    percentile: 35.972060536,
    z_score: -0.1458989963,
  },
  {
    percentile: 36.088474971,
    z_score: -0.1455359161,
  },
  {
    percentile: 36.204889406,
    z_score: -0.1441820376,
  },
  {
    percentile: 36.321303842,
    z_score: -0.1439968711,
  },
  {
    percentile: 36.437718277,
    z_score: -0.1439206798,
  },
  {
    percentile: 36.554132712,
    z_score: -0.1428895212,
  },
  {
    percentile: 36.670547148,
    z_score: -0.1426632676,
  },
  {
    percentile: 36.786961583,
    z_score: -0.1405516424,
  },
  {
    percentile: 36.903376019,
    z_score: -0.1404012173,
  },
  {
    percentile: 37.019790454,
    z_score: -0.1399814985,
  },
  {
    percentile: 37.136204889,
    z_score: -0.1397541028,
  },
  {
    percentile: 37.252619325,
    z_score: -0.1396822188,
  },
  {
    percentile: 37.36903376,
    z_score: -0.138722233,
  },
  {
    percentile: 37.485448196,
    z_score: -0.1383953402,
  },
  {
    percentile: 37.601862631,
    z_score: -0.1376865484,
  },
  {
    percentile: 37.718277066,
    z_score: -0.1374469939,
  },
  {
    percentile: 37.834691502,
    z_score: -0.1373314791,
  },
  {
    percentile: 37.951105937,
    z_score: -0.1370261407,
  },
  {
    percentile: 38.067520373,
    z_score: -0.1368148097,
  },
  {
    percentile: 38.183934808,
    z_score: -0.1366504513,
  },
  {
    percentile: 38.300349243,
    z_score: -0.1366027714,
  },
  {
    percentile: 38.416763679,
    z_score: -0.1357274551,
  },
  {
    percentile: 38.533178114,
    z_score: -0.1356299263,
  },
  {
    percentile: 38.649592549,
    z_score: -0.135364258,
  },
  {
    percentile: 38.766006985,
    z_score: -0.1351325749,
  },
  {
    percentile: 38.88242142,
    z_score: -0.134880691,
  },
  {
    percentile: 38.998835856,
    z_score: -0.1348727736,
  },
  {
    percentile: 39.115250291,
    z_score: -0.1344670839,
  },
  {
    percentile: 39.231664726,
    z_score: -0.1327761143,
  },
  {
    percentile: 39.348079162,
    z_score: -0.132337181,
  },
  {
    percentile: 39.464493597,
    z_score: -0.1321406918,
  },
  {
    percentile: 39.580908033,
    z_score: -0.1316330982,
  },
  {
    percentile: 39.697322468,
    z_score: -0.1309862557,
  },
  {
    percentile: 39.813736903,
    z_score: -0.1289676049,
  },
  {
    percentile: 39.930151339,
    z_score: -0.1282372553,
  },
  {
    percentile: 40.046565774,
    z_score: -0.1272148602,
  },
  {
    percentile: 40.16298021,
    z_score: -0.1265265139,
  },
  {
    percentile: 40.279394645,
    z_score: -0.1262281032,
  },
  {
    percentile: 40.39580908,
    z_score: -0.1259622909,
  },
  {
    percentile: 40.512223516,
    z_score: -0.1255462171,
  },
  {
    percentile: 40.628637951,
    z_score: -0.1240789384,
  },
  {
    percentile: 40.745052386,
    z_score: -0.1232051566,
  },
  {
    percentile: 40.861466822,
    z_score: -0.1229523194,
  },
  {
    percentile: 40.977881257,
    z_score: -0.1226925412,
  },
  {
    percentile: 41.094295693,
    z_score: -0.1222655399,
  },
  {
    percentile: 41.210710128,
    z_score: -0.1218248034,
  },
  {
    percentile: 41.327124563,
    z_score: -0.1217241967,
  },
  {
    percentile: 41.443538999,
    z_score: -0.1206702987,
  },
  {
    percentile: 41.559953434,
    z_score: -0.1203513673,
  },
  {
    percentile: 41.67636787,
    z_score: -0.1193997165,
  },
  {
    percentile: 41.792782305,
    z_score: -0.1186099184,
  },
  {
    percentile: 41.90919674,
    z_score: -0.1181887701,
  },
  {
    percentile: 42.025611176,
    z_score: -0.1180682899,
  },
  {
    percentile: 42.142025611,
    z_score: -0.1178305544,
  },
  {
    percentile: 42.258440047,
    z_score: -0.1174076689,
  },
  {
    percentile: 42.374854482,
    z_score: -0.1170467807,
  },
  {
    percentile: 42.491268917,
    z_score: -0.1166877348,
  },
  {
    percentile: 42.607683353,
    z_score: -0.1165225831,
  },
  {
    percentile: 42.724097788,
    z_score: -0.1156805391,
  },
  {
    percentile: 42.840512224,
    z_score: -0.1148659755,
  },
  {
    percentile: 42.956926659,
    z_score: -0.1145871812,
  },
  {
    percentile: 43.073341094,
    z_score: -0.1144777593,
  },
  {
    percentile: 43.18975553,
    z_score: -0.1144317524,
  },
  {
    percentile: 43.306169965,
    z_score: -0.1134767965,
  },
  {
    percentile: 43.4225844,
    z_score: -0.1124375495,
  },
  {
    percentile: 43.538998836,
    z_score: -0.1124060551,
  },
  {
    percentile: 43.655413271,
    z_score: -0.1107683145,
  },
  {
    percentile: 43.771827707,
    z_score: -0.1104130662,
  },
  {
    percentile: 43.888242142,
    z_score: -0.1096623652,
  },
  {
    percentile: 44.004656577,
    z_score: -0.1083779925,
  },
  {
    percentile: 44.121071013,
    z_score: -0.1077938428,
  },
  {
    percentile: 44.237485448,
    z_score: -0.1075557527,
  },
  {
    percentile: 44.353899884,
    z_score: -0.1069894244,
  },
  {
    percentile: 44.470314319,
    z_score: -0.1061312944,
  },
  {
    percentile: 44.586728754,
    z_score: -0.1041795946,
  },
  {
    percentile: 44.70314319,
    z_score: -0.103553024,
  },
  {
    percentile: 44.819557625,
    z_score: -0.1029440749,
  },
  {
    percentile: 44.935972061,
    z_score: -0.1018480215,
  },
  {
    percentile: 45.052386496,
    z_score: -0.1013079932,
  },
  {
    percentile: 45.168800931,
    z_score: -0.1003762551,
  },
  {
    percentile: 45.285215367,
    z_score: -0.1003360381,
  },
  {
    percentile: 45.401629802,
    z_score: -0.09949908594,
  },
  {
    percentile: 45.518044237,
    z_score: -0.09899301692,
  },
  {
    percentile: 45.634458673,
    z_score: -0.09729835881,
  },
  {
    percentile: 45.750873108,
    z_score: -0.09656607015,
  },
  {
    percentile: 45.867287544,
    z_score: -0.09558479306,
  },
  {
    percentile: 45.983701979,
    z_score: -0.09371918763,
  },
  {
    percentile: 46.100116414,
    z_score: -0.09247791886,
  },
  {
    percentile: 46.21653085,
    z_score: -0.09187151882,
  },
  {
    percentile: 46.332945285,
    z_score: -0.09169967255,
  },
  {
    percentile: 46.449359721,
    z_score: -0.09117853415,
  },
  {
    percentile: 46.565774156,
    z_score: -0.08864928771,
  },
  {
    percentile: 46.682188591,
    z_score: -0.0870835965,
  },
  {
    percentile: 46.798603027,
    z_score: -0.0869411361,
  },
  {
    percentile: 46.915017462,
    z_score: -0.08568525448,
  },
  {
    percentile: 47.031431898,
    z_score: -0.08565368918,
  },
  {
    percentile: 47.147846333,
    z_score: -0.08474419141,
  },
  {
    percentile: 47.264260768,
    z_score: -0.08337559062,
  },
  {
    percentile: 47.380675204,
    z_score: -0.08316869019,
  },
  {
    percentile: 47.497089639,
    z_score: -0.0829016901,
  },
  {
    percentile: 47.613504075,
    z_score: -0.08262858078,
  },
  {
    percentile: 47.72991851,
    z_score: -0.08052937021,
  },
  {
    percentile: 47.846332945,
    z_score: -0.07979900912,
  },
  {
    percentile: 47.962747381,
    z_score: -0.07954691585,
  },
  {
    percentile: 48.079161816,
    z_score: -0.0785530481,
  },
  {
    percentile: 48.195576251,
    z_score: -0.07788502077,
  },
  {
    percentile: 48.311990687,
    z_score: -0.07751865647,
  },
  {
    percentile: 48.428405122,
    z_score: -0.07706647766,
  },
  {
    percentile: 48.544819558,
    z_score: -0.07679794715,
  },
  {
    percentile: 48.661233993,
    z_score: -0.07484136529,
  },
  {
    percentile: 48.777648428,
    z_score: -0.07355420079,
  },
  {
    percentile: 48.894062864,
    z_score: -0.07349272548,
  },
  {
    percentile: 49.010477299,
    z_score: -0.07332736132,
  },
  {
    percentile: 49.126891735,
    z_score: -0.07302337914,
  },
  {
    percentile: 49.24330617,
    z_score: -0.07296354697,
  },
  {
    percentile: 49.359720605,
    z_score: -0.07207735061,
  },
  {
    percentile: 49.476135041,
    z_score: -0.0714308678,
  },
  {
    percentile: 49.592549476,
    z_score: -0.06937802427,
  },
  {
    percentile: 49.708963912,
    z_score: -0.06933664875,
  },
  {
    percentile: 49.825378347,
    z_score: -0.0686031198,
  },
  {
    percentile: 49.941792782,
    z_score: -0.06756621401,
  },
  {
    percentile: 50.058207218,
    z_score: -0.06755849761,
  },
  {
    percentile: 50.174621653,
    z_score: -0.0675429316,
  },
  {
    percentile: 50.291036088,
    z_score: -0.06679106198,
  },
  {
    percentile: 50.407450524,
    z_score: -0.06677598588,
  },
  {
    percentile: 50.523864959,
    z_score: -0.06629342675,
  },
  {
    percentile: 50.640279395,
    z_score: -0.0656376277,
  },
  {
    percentile: 50.75669383,
    z_score: -0.06355978619,
  },
  {
    percentile: 50.873108265,
    z_score: -0.06176337059,
  },
  {
    percentile: 50.989522701,
    z_score: -0.06068582134,
  },
  {
    percentile: 51.105937136,
    z_score: -0.05954072731,
  },
  {
    percentile: 51.222351572,
    z_score: -0.05691975669,
  },
  {
    percentile: 51.338766007,
    z_score: -0.0566314943,
  },
  {
    percentile: 51.455180442,
    z_score: -0.0565461442,
  },
  {
    percentile: 51.571594878,
    z_score: -0.05641902139,
  },
  {
    percentile: 51.688009313,
    z_score: -0.0544757131,
  },
  {
    percentile: 51.804423749,
    z_score: -0.053810374,
  },
  {
    percentile: 51.920838184,
    z_score: -0.05341803678,
  },
  {
    percentile: 52.037252619,
    z_score: -0.05316637885,
  },
  {
    percentile: 52.153667055,
    z_score: -0.05308558469,
  },
  {
    percentile: 52.27008149,
    z_score: -0.05251222632,
  },
  {
    percentile: 52.386495925,
    z_score: -0.05230768827,
  },
  {
    percentile: 52.502910361,
    z_score: -0.05128992828,
  },
  {
    percentile: 52.619324796,
    z_score: -0.05008768382,
  },
  {
    percentile: 52.735739232,
    z_score: -0.04997176199,
  },
  {
    percentile: 52.852153667,
    z_score: -0.04871513284,
  },
  {
    percentile: 52.968568102,
    z_score: -0.04854452202,
  },
  {
    percentile: 53.084982538,
    z_score: -0.04835443999,
  },
  {
    percentile: 53.201396973,
    z_score: -0.04796042333,
  },
  {
    percentile: 53.317811409,
    z_score: -0.04743556119,
  },
  {
    percentile: 53.434225844,
    z_score: -0.04635907908,
  },
  {
    percentile: 53.550640279,
    z_score: -0.0442828523,
  },
  {
    percentile: 53.667054715,
    z_score: -0.04383056254,
  },
  {
    percentile: 53.78346915,
    z_score: -0.04263513356,
  },
  {
    percentile: 53.899883586,
    z_score: -0.04259951939,
  },
  {
    percentile: 54.016298021,
    z_score: -0.04242345429,
  },
  {
    percentile: 54.132712456,
    z_score: -0.04167774813,
  },
  {
    percentile: 54.249126892,
    z_score: -0.04116740239,
  },
  {
    percentile: 54.365541327,
    z_score: -0.04082621747,
  },
  {
    percentile: 54.481955763,
    z_score: -0.04071963555,
  },
  {
    percentile: 54.598370198,
    z_score: -0.04024396835,
  },
  {
    percentile: 54.714784633,
    z_score: -0.03992662085,
  },
  {
    percentile: 54.831199069,
    z_score: -0.03980905268,
  },
  {
    percentile: 54.947613504,
    z_score: -0.03963035985,
  },
  {
    percentile: 55.064027939,
    z_score: -0.03810994657,
  },
  {
    percentile: 55.180442375,
    z_score: -0.03803870431,
  },
  {
    percentile: 55.29685681,
    z_score: -0.03687853659,
  },
  {
    percentile: 55.413271246,
    z_score: -0.03664796719,
  },
  {
    percentile: 55.529685681,
    z_score: -0.03663455662,
  },
  {
    percentile: 55.646100116,
    z_score: -0.03642589105,
  },
  {
    percentile: 55.762514552,
    z_score: -0.03627475204,
  },
  {
    percentile: 55.878928987,
    z_score: -0.03599254902,
  },
  {
    percentile: 55.995343423,
    z_score: -0.03453416235,
  },
  {
    percentile: 56.111757858,
    z_score: -0.03428808161,
  },
  {
    percentile: 56.228172293,
    z_score: -0.03321727072,
  },
  {
    percentile: 56.344586729,
    z_score: -0.03312773522,
  },
  {
    percentile: 56.461001164,
    z_score: -0.032988008,
  },
  {
    percentile: 56.5774156,
    z_score: -0.0329155934,
  },
  {
    percentile: 56.693830035,
    z_score: -0.03178284447,
  },
  {
    percentile: 56.81024447,
    z_score: -0.03113791849,
  },
  {
    percentile: 56.926658906,
    z_score: -0.03102392626,
  },
  {
    percentile: 57.043073341,
    z_score: -0.03080603291,
  },
  {
    percentile: 57.159487776,
    z_score: -0.03043639579,
  },
  {
    percentile: 57.275902212,
    z_score: -0.03036336927,
  },
  {
    percentile: 57.392316647,
    z_score: -0.03005110844,
  },
  {
    percentile: 57.508731083,
    z_score: -0.02947028863,
  },
  {
    percentile: 57.625145518,
    z_score: -0.02920154812,
  },
  {
    percentile: 57.741559953,
    z_score: -0.02908680636,
  },
  {
    percentile: 57.857974389,
    z_score: -0.02900769278,
  },
  {
    percentile: 57.974388824,
    z_score: -0.02774709968,
  },
  {
    percentile: 58.09080326,
    z_score: -0.02762302754,
  },
  {
    percentile: 58.207217695,
    z_score: -0.0268432434,
  },
  {
    percentile: 58.32363213,
    z_score: -0.02683298583,
  },
  {
    percentile: 58.440046566,
    z_score: -0.02666923546,
  },
  {
    percentile: 58.556461001,
    z_score: -0.02608226949,
  },
  {
    percentile: 58.672875437,
    z_score: -0.02537453171,
  },
  {
    percentile: 58.789289872,
    z_score: -0.02523957046,
  },
  {
    percentile: 58.905704307,
    z_score: -0.02502781908,
  },
  {
    percentile: 59.022118743,
    z_score: -0.024929947,
  },
  {
    percentile: 59.138533178,
    z_score: -0.02489433235,
  },
  {
    percentile: 59.254947614,
    z_score: -0.02434327178,
  },
  {
    percentile: 59.371362049,
    z_score: -0.0241093071,
  },
  {
    percentile: 59.487776484,
    z_score: -0.02306394344,
  },
  {
    percentile: 59.60419092,
    z_score: -0.02216374617,
  },
  {
    percentile: 59.720605355,
    z_score: -0.02084499286,
  },
  {
    percentile: 59.83701979,
    z_score: -0.0205895486,
  },
  {
    percentile: 59.953434226,
    z_score: -0.01975995208,
  },
  {
    percentile: 60.069848661,
    z_score: -0.01953433861,
  },
  {
    percentile: 60.186263097,
    z_score: -0.01946420817,
  },
  {
    percentile: 60.302677532,
    z_score: -0.01915083454,
  },
  {
    percentile: 60.419091967,
    z_score: -0.01807037372,
  },
  {
    percentile: 60.535506403,
    z_score: -0.01764067013,
  },
  {
    percentile: 60.651920838,
    z_score: -0.01738874296,
  },
  {
    percentile: 60.768335274,
    z_score: -0.01692959585,
  },
  {
    percentile: 60.884749709,
    z_score: -0.0165237053,
  },
  {
    percentile: 61.001164144,
    z_score: -0.01600936346,
  },
  {
    percentile: 61.11757858,
    z_score: -0.0156180972,
  },
  {
    percentile: 61.233993015,
    z_score: -0.01395993809,
  },
  {
    percentile: 61.350407451,
    z_score: -0.01153291807,
  },
  {
    percentile: 61.466821886,
    z_score: -0.01085016196,
  },
  {
    percentile: 61.583236321,
    z_score: -0.01011173097,
  },
  {
    percentile: 61.699650757,
    z_score: -0.009571454577,
  },
  {
    percentile: 61.816065192,
    z_score: -0.007937224629,
  },
  {
    percentile: 61.932479627,
    z_score: -0.007789923876,
  },
  {
    percentile: 62.048894063,
    z_score: -0.006130453364,
  },
  {
    percentile: 62.165308498,
    z_score: -0.006002398253,
  },
  {
    percentile: 62.281722934,
    z_score: -0.005698071989,
  },
  {
    percentile: 62.398137369,
    z_score: -0.003992710107,
  },
  {
    percentile: 62.514551804,
    z_score: -0.00368757267,
  },
  {
    percentile: 62.63096624,
    z_score: -0.003486858166,
  },
  {
    percentile: 62.747380675,
    z_score: -0.002904070841,
  },
  {
    percentile: 62.863795111,
    z_score: -0.001416289809,
  },
  {
    percentile: 62.980209546,
    z_score: -0.00110356981,
  },
  {
    percentile: 63.096623981,
    z_score: 0.00005814072913,
  },
  {
    percentile: 63.213038417,
    z_score: 0.000594773109,
  },
  {
    percentile: 63.329452852,
    z_score: 0.002570632037,
  },
  {
    percentile: 63.445867288,
    z_score: 0.003051821937,
  },
  {
    percentile: 63.562281723,
    z_score: 0.003156262032,
  },
  {
    percentile: 63.678696158,
    z_score: 0.00316032992,
  },
  {
    percentile: 63.795110594,
    z_score: 0.003939217025,
  },
  {
    percentile: 63.911525029,
    z_score: 0.004001697842,
  },
  {
    percentile: 64.027939464,
    z_score: 0.005117769499,
  },
  {
    percentile: 64.1443539,
    z_score: 0.005917917418,
  },
  {
    percentile: 64.260768335,
    z_score: 0.006224942993,
  },
  {
    percentile: 64.377182771,
    z_score: 0.007205734302,
  },
  {
    percentile: 64.493597206,
    z_score: 0.008238499403,
  },
  {
    percentile: 64.610011641,
    z_score: 0.008733281643,
  },
  {
    percentile: 64.726426077,
    z_score: 0.008789081372,
  },
  {
    percentile: 64.842840512,
    z_score: 0.009097311022,
  },
  {
    percentile: 64.959254948,
    z_score: 0.01094589481,
  },
  {
    percentile: 65.075669383,
    z_score: 0.01166032183,
  },
  {
    percentile: 65.192083818,
    z_score: 0.01269702486,
  },
  {
    percentile: 65.308498254,
    z_score: 0.01333889643,
  },
  {
    percentile: 65.424912689,
    z_score: 0.01429628735,
  },
  {
    percentile: 65.541327125,
    z_score: 0.01435034597,
  },
  {
    percentile: 65.65774156,
    z_score: 0.01582363324,
  },
  {
    percentile: 65.774155995,
    z_score: 0.01788806903,
  },
  {
    percentile: 65.890570431,
    z_score: 0.01929596087,
  },
  {
    percentile: 66.006984866,
    z_score: 0.01981468435,
  },
  {
    percentile: 66.123399302,
    z_score: 0.02139021121,
  },
  {
    percentile: 66.239813737,
    z_score: 0.02150156843,
  },
  {
    percentile: 66.356228172,
    z_score: 0.02210569117,
  },
  {
    percentile: 66.472642608,
    z_score: 0.0245079975,
  },
  {
    percentile: 66.589057043,
    z_score: 0.02500263497,
  },
  {
    percentile: 66.705471478,
    z_score: 0.02587481571,
  },
  {
    percentile: 66.821885914,
    z_score: 0.02645776059,
  },
  {
    percentile: 66.938300349,
    z_score: 0.02984055417,
  },
  {
    percentile: 67.054714785,
    z_score: 0.0317665913,
  },
  {
    percentile: 67.17112922,
    z_score: 0.03202550788,
  },
  {
    percentile: 67.287543655,
    z_score: 0.03324715381,
  },
  {
    percentile: 67.403958091,
    z_score: 0.03433303958,
  },
  {
    percentile: 67.520372526,
    z_score: 0.0349851062,
  },
  {
    percentile: 67.636786962,
    z_score: 0.03595857746,
  },
  {
    percentile: 67.753201397,
    z_score: 0.03637944008,
  },
  {
    percentile: 67.869615832,
    z_score: 0.03657069156,
  },
  {
    percentile: 67.986030268,
    z_score: 0.03674910654,
  },
  {
    percentile: 68.102444703,
    z_score: 0.03997792153,
  },
  {
    percentile: 68.218859139,
    z_score: 0.04093670559,
  },
  {
    percentile: 68.335273574,
    z_score: 0.04095814738,
  },
  {
    percentile: 68.451688009,
    z_score: 0.04121282666,
  },
  {
    percentile: 68.568102445,
    z_score: 0.04257892004,
  },
  {
    percentile: 68.68451688,
    z_score: 0.04258046568,
  },
  {
    percentile: 68.800931315,
    z_score: 0.04327754894,
  },
  {
    percentile: 68.917345751,
    z_score: 0.04450246178,
  },
  {
    percentile: 69.033760186,
    z_score: 0.04630745615,
  },
  {
    percentile: 69.150174622,
    z_score: 0.04647220317,
  },
  {
    percentile: 69.266589057,
    z_score: 0.04722375388,
  },
  {
    percentile: 69.383003492,
    z_score: 0.04745520789,
  },
  {
    percentile: 69.499417928,
    z_score: 0.04747181782,
  },
  {
    percentile: 69.615832363,
    z_score: 0.04806690047,
  },
  {
    percentile: 69.732246799,
    z_score: 0.04807071739,
  },
  {
    percentile: 69.848661234,
    z_score: 0.04865990756,
  },
  {
    percentile: 69.965075669,
    z_score: 0.04866580815,
  },
  {
    percentile: 70.081490105,
    z_score: 0.05020900881,
  },
  {
    percentile: 70.19790454,
    z_score: 0.0503262892,
  },
  {
    percentile: 70.314318976,
    z_score: 0.05070265945,
  },
  {
    percentile: 70.430733411,
    z_score: 0.05078891761,
  },
  {
    percentile: 70.547147846,
    z_score: 0.05179717693,
  },
  {
    percentile: 70.663562282,
    z_score: 0.05272565505,
  },
  {
    percentile: 70.779976717,
    z_score: 0.05273284172,
  },
  {
    percentile: 70.896391153,
    z_score: 0.05364246526,
  },
  {
    percentile: 71.012805588,
    z_score: 0.05404910912,
  },
  {
    percentile: 71.129220023,
    z_score: 0.05668599605,
  },
  {
    percentile: 71.245634459,
    z_score: 0.05693237307,
  },
  {
    percentile: 71.362048894,
    z_score: 0.05807764058,
  },
  {
    percentile: 71.478463329,
    z_score: 0.05966368315,
  },
  {
    percentile: 71.594877765,
    z_score: 0.05975422194,
  },
  {
    percentile: 71.7112922,
    z_score: 0.05994454356,
  },
  {
    percentile: 71.827706636,
    z_score: 0.06070048141,
  },
  {
    percentile: 71.944121071,
    z_score: 0.06393235063,
  },
  {
    percentile: 72.060535506,
    z_score: 0.06402566367,
  },
  {
    percentile: 72.176949942,
    z_score: 0.06457885604,
  },
  {
    percentile: 72.293364377,
    z_score: 0.0652902349,
  },
  {
    percentile: 72.409778813,
    z_score: 0.06598140574,
  },
  {
    percentile: 72.526193248,
    z_score: 0.06624128735,
  },
  {
    percentile: 72.642607683,
    z_score: 0.06737192255,
  },
  {
    percentile: 72.759022119,
    z_score: 0.06798308326,
  },
  {
    percentile: 72.875436554,
    z_score: 0.06820187541,
  },
  {
    percentile: 72.99185099,
    z_score: 0.06880996797,
  },
  {
    percentile: 73.108265425,
    z_score: 0.06942707546,
  },
  {
    percentile: 73.22467986,
    z_score: 0.07080319928,
  },
  {
    percentile: 73.341094296,
    z_score: 0.0710383523,
  },
  {
    percentile: 73.457508731,
    z_score: 0.07120041276,
  },
  {
    percentile: 73.573923166,
    z_score: 0.07227263478,
  },
  {
    percentile: 73.690337602,
    z_score: 0.07453854312,
  },
  {
    percentile: 73.806752037,
    z_score: 0.07549892809,
  },
  {
    percentile: 73.923166473,
    z_score: 0.07651023836,
  },
  {
    percentile: 74.039580908,
    z_score: 0.07698117368,
  },
  {
    percentile: 74.155995343,
    z_score: 0.07820727068,
  },
  {
    percentile: 74.272409779,
    z_score: 0.07840206355,
  },
  {
    percentile: 74.388824214,
    z_score: 0.07887054739,
  },
  {
    percentile: 74.50523865,
    z_score: 0.08167840174,
  },
  {
    percentile: 74.621653085,
    z_score: 0.08428129851,
  },
  {
    percentile: 74.73806752,
    z_score: 0.08485396979,
  },
  {
    percentile: 74.854481956,
    z_score: 0.08870111921,
  },
  {
    percentile: 74.970896391,
    z_score: 0.08872693907,
  },
  {
    percentile: 75.087310827,
    z_score: 0.08937446407,
  },
  {
    percentile: 75.203725262,
    z_score: 0.09064738685,
  },
  {
    percentile: 75.320139697,
    z_score: 0.09103853863,
  },
  {
    percentile: 75.436554133,
    z_score: 0.09130903692,
  },
  {
    percentile: 75.552968568,
    z_score: 0.09187676594,
  },
  {
    percentile: 75.669383003,
    z_score: 0.09241114693,
  },
  {
    percentile: 75.785797439,
    z_score: 0.09380976063,
  },
  {
    percentile: 75.902211874,
    z_score: 0.09681309155,
  },
  {
    percentile: 76.01862631,
    z_score: 0.09804216836,
  },
  {
    percentile: 76.135040745,
    z_score: 0.09828617284,
  },
  {
    percentile: 76.25145518,
    z_score: 0.09839173036,
  },
  {
    percentile: 76.367869616,
    z_score: 0.09871465272,
  },
  {
    percentile: 76.484284051,
    z_score: 0.09977144187,
  },
  {
    percentile: 76.600698487,
    z_score: 0.09988922842,
  },
  {
    percentile: 76.717112922,
    z_score: 0.1025653653,
  },
  {
    percentile: 76.833527357,
    z_score: 0.1028483375,
  },
  {
    percentile: 76.949941793,
    z_score: 0.1030042122,
  },
  {
    percentile: 77.066356228,
    z_score: 0.1052690361,
  },
  {
    percentile: 77.182770664,
    z_score: 0.1057504843,
  },
  {
    percentile: 77.299185099,
    z_score: 0.1064043383,
  },
  {
    percentile: 77.415599534,
    z_score: 0.107072839,
  },
  {
    percentile: 77.53201397,
    z_score: 0.1073672373,
  },
  {
    percentile: 77.648428405,
    z_score: 0.1084289721,
  },
  {
    percentile: 77.764842841,
    z_score: 0.1087372044,
  },
  {
    percentile: 77.881257276,
    z_score: 0.108795959,
  },
  {
    percentile: 77.997671711,
    z_score: 0.1088793685,
  },
  {
    percentile: 78.114086147,
    z_score: 0.1093472768,
  },
  {
    percentile: 78.230500582,
    z_score: 0.1104150025,
  },
  {
    percentile: 78.346915017,
    z_score: 0.1130959065,
  },
  {
    percentile: 78.463329453,
    z_score: 0.1139235003,
  },
  {
    percentile: 78.579743888,
    z_score: 0.1157055989,
  },
  {
    percentile: 78.696158324,
    z_score: 0.1158821705,
  },
  {
    percentile: 78.812572759,
    z_score: 0.1167122456,
  },
  {
    percentile: 78.928987194,
    z_score: 0.1184369127,
  },
  {
    percentile: 79.04540163,
    z_score: 0.120982509,
  },
  {
    percentile: 79.161816065,
    z_score: 0.1217393796,
  },
  {
    percentile: 79.278230501,
    z_score: 0.1239580846,
  },
  {
    percentile: 79.394644936,
    z_score: 0.1246445624,
  },
  {
    percentile: 79.511059371,
    z_score: 0.1250337077,
  },
  {
    percentile: 79.627473807,
    z_score: 0.1252818166,
  },
  {
    percentile: 79.743888242,
    z_score: 0.1252947135,
  },
  {
    percentile: 79.860302678,
    z_score: 0.1266261013,
  },
  {
    percentile: 79.976717113,
    z_score: 0.1269264857,
  },
  {
    percentile: 80.093131548,
    z_score: 0.1279949694,
  },
  {
    percentile: 80.209545984,
    z_score: 0.1288474704,
  },
  {
    percentile: 80.325960419,
    z_score: 0.1291984092,
  },
  {
    percentile: 80.442374854,
    z_score: 0.1302993232,
  },
  {
    percentile: 80.55878929,
    z_score: 0.1312517725,
  },
  {
    percentile: 80.675203725,
    z_score: 0.1321624975,
  },
  {
    percentile: 80.791618161,
    z_score: 0.1331683137,
  },
  {
    percentile: 80.908032596,
    z_score: 0.1342144539,
  },
  {
    percentile: 81.024447031,
    z_score: 0.1368329799,
  },
  {
    percentile: 81.140861467,
    z_score: 0.1392787854,
  },
  {
    percentile: 81.257275902,
    z_score: 0.140260341,
  },
  {
    percentile: 81.373690338,
    z_score: 0.140496532,
  },
  {
    percentile: 81.490104773,
    z_score: 0.142257076,
  },
  {
    percentile: 81.606519208,
    z_score: 0.1427249991,
  },
  {
    percentile: 81.722933644,
    z_score: 0.1432716776,
  },
  {
    percentile: 81.839348079,
    z_score: 0.1434811949,
  },
  {
    percentile: 81.955762515,
    z_score: 0.143871099,
  },
  {
    percentile: 82.07217695,
    z_score: 0.1446068695,
  },
  {
    percentile: 82.188591385,
    z_score: 0.1467847314,
  },
  {
    percentile: 82.305005821,
    z_score: 0.1477971936,
  },
  {
    percentile: 82.421420256,
    z_score: 0.1482242493,
  },
  {
    percentile: 82.537834692,
    z_score: 0.1484794731,
  },
  {
    percentile: 82.654249127,
    z_score: 0.1505241466,
  },
  {
    percentile: 82.770663562,
    z_score: 0.1516525644,
  },
  {
    percentile: 82.887077998,
    z_score: 0.1519390176,
  },
  {
    percentile: 83.003492433,
    z_score: 0.1522410497,
  },
  {
    percentile: 83.119906868,
    z_score: 0.1541659137,
  },
  {
    percentile: 83.236321304,
    z_score: 0.1568432329,
  },
  {
    percentile: 83.352735739,
    z_score: 0.1583262531,
  },
  {
    percentile: 83.469150175,
    z_score: 0.1612174773,
  },
  {
    percentile: 83.58556461,
    z_score: 0.1616334594,
  },
  {
    percentile: 83.701979045,
    z_score: 0.1645726339,
  },
  {
    percentile: 83.818393481,
    z_score: 0.1650860793,
  },
  {
    percentile: 83.934807916,
    z_score: 0.1661162909,
  },
  {
    percentile: 84.051222352,
    z_score: 0.1668179292,
  },
  {
    percentile: 84.167636787,
    z_score: 0.1692265369,
  },
  {
    percentile: 84.284051222,
    z_score: 0.1711039335,
  },
  {
    percentile: 84.400465658,
    z_score: 0.1724618883,
  },
  {
    percentile: 84.516880093,
    z_score: 0.1730449601,
  },
  {
    percentile: 84.633294529,
    z_score: 0.1733516065,
  },
  {
    percentile: 84.749708964,
    z_score: 0.1751234001,
  },
  {
    percentile: 84.866123399,
    z_score: 0.1769108071,
  },
  {
    percentile: 84.982537835,
    z_score: 0.1777134144,
  },
  {
    percentile: 85.09895227,
    z_score: 0.1777671958,
  },
  {
    percentile: 85.215366705,
    z_score: 0.1786910636,
  },
  {
    percentile: 85.331781141,
    z_score: 0.179707331,
  },
  {
    percentile: 85.448195576,
    z_score: 0.1807313579,
  },
  {
    percentile: 85.564610012,
    z_score: 0.1816648759,
  },
  {
    percentile: 85.681024447,
    z_score: 0.1817240068,
  },
  {
    percentile: 85.797438882,
    z_score: 0.1817852097,
  },
  {
    percentile: 85.913853318,
    z_score: 0.1826697642,
  },
  {
    percentile: 86.030267753,
    z_score: 0.1834881344,
  },
  {
    percentile: 86.146682189,
    z_score: 0.1839022458,
  },
  {
    percentile: 86.263096624,
    z_score: 0.1852561811,
  },
  {
    percentile: 86.379511059,
    z_score: 0.1877815767,
  },
  {
    percentile: 86.495925495,
    z_score: 0.1881531625,
  },
  {
    percentile: 86.61233993,
    z_score: 0.1883191343,
  },
  {
    percentile: 86.728754366,
    z_score: 0.1896960279,
  },
  {
    percentile: 86.845168801,
    z_score: 0.1908848806,
  },
  {
    percentile: 86.961583236,
    z_score: 0.1909424048,
  },
  {
    percentile: 87.077997672,
    z_score: 0.1921315412,
  },
  {
    percentile: 87.194412107,
    z_score: 0.1929901918,
  },
  {
    percentile: 87.310826542,
    z_score: 0.1942710398,
  },
  {
    percentile: 87.427240978,
    z_score: 0.1966606673,
  },
  {
    percentile: 87.543655413,
    z_score: 0.1971774851,
  },
  {
    percentile: 87.660069849,
    z_score: 0.1974290175,
  },
  {
    percentile: 87.776484284,
    z_score: 0.1994304615,
  },
  {
    percentile: 87.892898719,
    z_score: 0.2041357088,
  },
  {
    percentile: 88.009313155,
    z_score: 0.2053165247,
  },
  {
    percentile: 88.12572759,
    z_score: 0.2085692882,
  },
  {
    percentile: 88.242142026,
    z_score: 0.210316056,
  },
  {
    percentile: 88.358556461,
    z_score: 0.2182751437,
  },
  {
    percentile: 88.474970896,
    z_score: 0.2195589651,
  },
  {
    percentile: 88.591385332,
    z_score: 0.219579085,
  },
  {
    percentile: 88.707799767,
    z_score: 0.219945854,
  },
  {
    percentile: 88.824214203,
    z_score: 0.2203729364,
  },
  {
    percentile: 88.940628638,
    z_score: 0.221821739,
  },
  {
    percentile: 89.057043073,
    z_score: 0.2238878013,
  },
  {
    percentile: 89.173457509,
    z_score: 0.2243101193,
  },
  {
    percentile: 89.289871944,
    z_score: 0.2244038927,
  },
  {
    percentile: 89.40628638,
    z_score: 0.2256679488,
  },
  {
    percentile: 89.522700815,
    z_score: 0.2326562085,
  },
  {
    percentile: 89.63911525,
    z_score: 0.2348717258,
  },
  {
    percentile: 89.755529686,
    z_score: 0.2355485728,
  },
  {
    percentile: 89.871944121,
    z_score: 0.2368529196,
  },
  {
    percentile: 89.988358556,
    z_score: 0.2383378263,
  },
  {
    percentile: 90.104772992,
    z_score: 0.2401396112,
  },
  {
    percentile: 90.221187427,
    z_score: 0.2443374623,
  },
  {
    percentile: 90.337601863,
    z_score: 0.2454341196,
  },
  {
    percentile: 90.454016298,
    z_score: 0.2455408221,
  },
  {
    percentile: 90.570430733,
    z_score: 0.2461604094,
  },
  {
    percentile: 90.686845169,
    z_score: 0.2487469297,
  },
  {
    percentile: 90.803259604,
    z_score: 0.2552490917,
  },
  {
    percentile: 90.91967404,
    z_score: 0.2556635314,
  },
  {
    percentile: 91.036088475,
    z_score: 0.2575975822,
  },
  {
    percentile: 91.15250291,
    z_score: 0.2578759574,
  },
  {
    percentile: 91.268917346,
    z_score: 0.2590588909,
  },
  {
    percentile: 91.385331781,
    z_score: 0.2620844924,
  },
  {
    percentile: 91.501746217,
    z_score: 0.270502164,
  },
  {
    percentile: 91.618160652,
    z_score: 0.2707725674,
  },
  {
    percentile: 91.734575087,
    z_score: 0.2747349725,
  },
  {
    percentile: 91.850989523,
    z_score: 0.2757798773,
  },
  {
    percentile: 91.967403958,
    z_score: 0.2765465878,
  },
  {
    percentile: 92.083818393,
    z_score: 0.2769423221,
  },
  {
    percentile: 92.200232829,
    z_score: 0.2809908432,
  },
  {
    percentile: 92.316647264,
    z_score: 0.2866797118,
  },
  {
    percentile: 92.4330617,
    z_score: 0.2883485794,
  },
  {
    percentile: 92.549476135,
    z_score: 0.2891825637,
  },
  {
    percentile: 92.66589057,
    z_score: 0.2892771953,
  },
  {
    percentile: 92.782305006,
    z_score: 0.2893930579,
  },
  {
    percentile: 92.898719441,
    z_score: 0.2920009125,
  },
  {
    percentile: 93.015133877,
    z_score: 0.2960822198,
  },
  {
    percentile: 93.131548312,
    z_score: 0.2981431233,
  },
  {
    percentile: 93.247962747,
    z_score: 0.3047296228,
  },
  {
    percentile: 93.364377183,
    z_score: 0.3152111043,
  },
  {
    percentile: 93.480791618,
    z_score: 0.3166836168,
  },
  {
    percentile: 93.597206054,
    z_score: 0.3269615553,
  },
  {
    percentile: 93.713620489,
    z_score: 0.3342389755,
  },
  {
    percentile: 93.830034924,
    z_score: 0.3352726476,
  },
  {
    percentile: 93.94644936,
    z_score: 0.3359342089,
  },
  {
    percentile: 94.062863795,
    z_score: 0.3413940062,
  },
  {
    percentile: 94.179278231,
    z_score: 0.3437917863,
  },
  {
    percentile: 94.295692666,
    z_score: 0.3490903486,
  },
  {
    percentile: 94.412107101,
    z_score: 0.3573814263,
  },
  {
    percentile: 94.528521537,
    z_score: 0.3627634008,
  },
  {
    percentile: 94.644935972,
    z_score: 0.3655680239,
  },
  {
    percentile: 94.761350407,
    z_score: 0.3814666774,
  },
  {
    percentile: 94.877764843,
    z_score: 0.3909883661,
  },
  {
    percentile: 94.994179278,
    z_score: 0.3988695373,
  },
  {
    percentile: 95.110593714,
    z_score: 0.4128202787,
  },
  {
    percentile: 95.227008149,
    z_score: 0.415225508,
  },
  {
    percentile: 95.343422584,
    z_score: 0.4167318018,
  },
  {
    percentile: 95.45983702,
    z_score: 0.4420143049,
  },
  {
    percentile: 95.576251455,
    z_score: 0.4594607274,
  },
  {
    percentile: 95.692665891,
    z_score: 0.4676783065,
  },
  {
    percentile: 95.809080326,
    z_score: 0.4873107967,
  },
  {
    percentile: 95.925494761,
    z_score: 0.5007016984,
  },
  {
    percentile: 96.041909197,
    z_score: 0.5386241906,
  },
  {
    percentile: 96.158323632,
    z_score: 0.5469347328,
  },
  {
    percentile: 96.274738068,
    z_score: 0.64051593,
  },
  {
    percentile: 96.391152503,
    z_score: 0.6451713553,
  },
  {
    percentile: 96.507566938,
    z_score: 0.6752003133,
  },
  {
    percentile: 96.623981374,
    z_score: 0.7618559183,
  },
  {
    percentile: 96.740395809,
    z_score: 0.7820757563,
  },
  {
    percentile: 96.856810244,
    z_score: 0.818729905,
  },
  {
    percentile: 96.97322468,
    z_score: 0.8198089847,
  },
  {
    percentile: 97.089639115,
    z_score: 0.8202041776,
  },
  {
    percentile: 97.206053551,
    z_score: 0.8243260772,
  },
  {
    percentile: 97.322467986,
    z_score: 0.9376145775,
  },
  {
    percentile: 97.438882421,
    z_score: 0.9969975289,
  },
  {
    percentile: 97.555296857,
    z_score: 1.285945646,
  },
  {
    percentile: 97.671711292,
    z_score: 1.393805853,
  },
  {
    percentile: 97.788125728,
    z_score: 2.111527455,
  },
  {
    percentile: 97.904540163,
    z_score: 2.654053261,
  },
  {
    percentile: 98.020954598,
    z_score: 2.755668778,
  },
  {
    percentile: 98.137369034,
    z_score: 3.077043042,
  },
  {
    percentile: 98.253783469,
    z_score: 3.102326373,
  },
  {
    percentile: 98.370197905,
    z_score: 3.877677361,
  },
  {
    percentile: 98.48661234,
    z_score: 3.932729664,
  },
  {
    percentile: 98.603026775,
    z_score: 4.524645234,
  },
  {
    percentile: 98.719441211,
    z_score: 4.634853612,
  },
  {
    percentile: 98.835855646,
    z_score: 4.931744089,
  },
  {
    percentile: 98.952270081,
    z_score: 5.645988051,
  },
  {
    percentile: 99.068684517,
    z_score: 5.693333216,
  },
  {
    percentile: 99.185098952,
    z_score: 5.817596734,
  },
  {
    percentile: 99.301513388,
    z_score: 6.54200161,
  },
  {
    percentile: 99.417927823,
    z_score: 6.564807592,
  },
  {
    percentile: 99.534342258,
    z_score: 6.567922194,
  },
  {
    percentile: 99.650756694,
    z_score: 6.582349537,
  },
  {
    percentile: 99.767171129,
    z_score: 7.396261051,
  },
  {
    percentile: 99.883585565,
    z_score: 8.675006756,
  },
  {
    percentile: 100,
    z_score: 8.872689809,
  },
];
