import { getAuthDashMetaCsrfToken, GO_API_ROOT } from '@revelio/auth';
import { Dimension1 } from '@revelio/data-access';
import {
  serializeFilters,
  SKILL_GRANULARITY_FILTERS,
  useActiveFiltersState,
} from '@revelio/filtering';
import { useState, useEffect } from 'react';
import { KdeSalaryData } from './types';
import { omit } from 'lodash';

export const useGetKdePlotData = (
  primaryAndCompetitorRCIDs: number[] | undefined
) => {
  const activeFilters = useActiveFiltersState();
  const hasNoPrimaryAndCompetitorRCIDs = !primaryAndCompetitorRCIDs?.length;

  const csrfToken = getAuthDashMetaCsrfToken();
  const [kdeSalaryData, setKdeSalaryData] = useState<KdeSalaryData | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [compensationLoading, setCompensationLoading] = useState(false);

  useEffect(() => {
    setLoading(compensationLoading);
  }, [compensationLoading]);

  useEffect(() => {
    if (hasNoPrimaryAndCompetitorRCIDs) {
      return;
    }

    setCompensationLoading(true);

    const fetchCompensationData = async () => {
      try {
        const payload = {
          dim1: Dimension1.Company,
          year_range: [[2009, 2024]],
          ...omit(
            serializeFilters(activeFilters, {
              company: primaryAndCompetitorRCIDs,
            }),
            SKILL_GRANULARITY_FILTERS
          ), // skills isn't supported by salary distribution endpoint
        };

        const response = await fetch(`${GO_API_ROOT}/models/compensation`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-request-id': crypto.randomUUID(),
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        const kdeSalaryData: KdeSalaryData | undefined = data
          ?.find(
            (dataSet: { id: string }) => dataSet?.id === 'total_compensation'
          )
          .value?.find((plot: { id: string }) => plot.id === 'kde');

        setKdeSalaryData(kdeSalaryData);
        setCompensationLoading(false);
      } catch (error) {
        console.error('Failed to fetch compensation data:', error);
        setCompensationLoading(false);
      }
    };

    fetchCompensationData();
  }, [
    primaryAndCompetitorRCIDs,
    csrfToken,
    activeFilters,
    setCompensationLoading,
    hasNoPrimaryAndCompetitorRCIDs,
  ]);

  return { kdeSalaryData, loading };
};
