import { Box, Flex, Card, Center, Divider } from '@chakra-ui/react';
import {
  PageTitles,
  AddEntityButtonText,
  PrimaryFilters,
  Views,
  PrimaryView,
} from '@revelio/core';
import {
  AddEntityButton,
  COMPOSITION_GET_DATA,
  FilterChipsContainer,
  GEOGRAPHY_GRANULARITY_FILTERS,
  PrimaryFilterLimits,
  SHARED_SET_ENTITY_LIMIT,
  SelectionCategories,
  createSelectableFiltersMap,
  useActiveFiltersState,
  useViewFilters,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  INDUSTRY_AND_COMPANY_FILTERS,
  useStoredFilterSet,
  FilterSets,
  ViewTypes,
  useSelectionLists,
  LocalSelectionCategories,
  useViewFilterDefaults,
  useSyncFiltersToSearchParams,
  FilterContainer,
  FilterChips,
  FilterMenuLimits,
  FilterMenu,
  Filter,
  GEO_SUMMARY_POSTING_GET_DATA,
  serializeFilters,
  DefaultDates,
  useDefaultLastMonth,
  DateRangeFormattedValues,
  useSelectFilterById,
} from '@revelio/filtering';
import { flatten } from 'lodash';
import { useQuery } from 'urql';
import { Dimension1 } from '@revelio/data-access';
import { Filters } from '@revelio/data-access';
import DashboardPage from '../../DashboardPage';
import { GeographySummaryTopCompanies } from './top-companies/top-companies';
import { GeographySummaryTopSkills } from './top-skills/top-skills';
import { useGetGeographyMapData } from './map/useGetGeographyMapData';
import { GeographyMap } from './map/geography-map';
import {
  MarketTightnessMeter,
  MeterContainer,
  SupplyDemandMeter,
  TimeToFillMeter,
  WagePressureMeter,
} from './meters';
import { transformFiltersToPostingVariables } from '../../postings/utils';
import { useMemo } from 'react';

const GEO_SUMMARY_PRIMARY_VIEW_FILTER = [
  {
    filters: GEOGRAPHY_GRANULARITY_FILTERS,
    isNested: true,
    limit: 1,
  },
];
const GEO_SUMMARY_SECONDARY_FILTERS = [
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
  SelectionCategories.SENIORITY,
  {
    filters: SKILL_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
  {
    filters: INDUSTRY_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 10,
  },
];

export const getGeographyFilterType = (
  category: SelectionCategories,
  activeFilters: Filter[]
): string | undefined => {
  return (
    activeFilters.find(
      (filter) => (filter.id as SelectionCategories) === category
    )?.value as Array<{ id: string }>
  )?.[0]?.id as string;
};

export const GeographySummary = () => {
  useStoredFilterSet({
    sharedSetId: FilterSets.GEOGRAPHY_SUMMARY,
    tab: ViewTypes.GEO,
    primaryEntitiesSync: true,
    limit: 1,
    defaultLimit: 1,
  });

  const primaryFilters = flatten(
    createSelectableFiltersMap(GEO_SUMMARY_PRIMARY_VIEW_FILTER)
  );
  const selectableFiltersMap = createSelectableFiltersMap(
    GEO_SUMMARY_SECONDARY_FILTERS
  );
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  useViewFilters([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    SelectionCategories.DATE_RANGE, //DOES IT NEED DATE RANGE
    SelectionCategories.DATE_RANGE_FULL, //DOES IT NEED THIS
  ]);

  useSelectionLists([
    SelectionCategories.REGION,
    SelectionCategories.COUNTRY,
    SelectionCategories.METRO_AREA,
    ...flattenedSelectableFilters,
  ]);

  const viewFilterDefaultArgs = {
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.GEO,
    presetView: FilterSets.GEOGRAPHY_SUMMARY,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.ENTITY_SUMMARY,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);

  useSyncFiltersToSearchParams({
    primaryFilters,
    syncToPrimaryEntities: true,
  });

  const activeFilters = useActiveFiltersState();
  const metroArea = getGeographyFilterType(
    SelectionCategories.METRO_AREA,
    activeFilters
  );

  const overwrites = {
    metro_area: undefined,
    ...(metroArea && { msa: [metroArea] }),
  };

  const serializedFilters: typeof overwrites & Filters = serializeFilters(
    activeFilters,
    {
      metro_area: undefined,
      ...(metroArea && { msa: [metroArea] }),
    }
  );

  useDefaultLastMonth({
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.GEO,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
  });

  useDefaultLastMonth({
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.GEO,
    dateType: DefaultDates.LAST_START_DATE,
  });

  const dates = activeFilters.find(
    (filter) => filter.id === SelectionCategories.DATE_RANGE
  )?.value as DateRangeFormattedValues;

  const [{ data: compositionData, fetching: compositionLoading }] = useQuery({
    query: COMPOSITION_GET_DATA,
    variables: {
      dim1: Dimension1.Geography,
      filters: {
        ...serializedFilters,
      },
    },
    pause:
      (!serializedFilters?.msa &&
        !serializedFilters?.region &&
        !serializedFilters?.country) ||
      !dates,
  });

  const providerFilter = useSelectFilterById(LocalSelectionCategories.PROVIDER);
  const postingsVariables = useMemo(
    () =>
      transformFiltersToPostingVariables({
        view: PrimaryView.GEOGRAPHY,
        filters: [
          ...activeFilters,
          ...(providerFilter ? [providerFilter] : []),
        ],
      }),
    [activeFilters, providerFilter]
  );

  const [{ data: postingsData, fetching: postingsLoading }] = useQuery({
    query: GEO_SUMMARY_POSTING_GET_DATA,
    variables: postingsVariables,
    pause:
      (!serializedFilters?.msa &&
        !serializedFilters?.region &&
        !serializedFilters?.country) ||
      !dates,
  });

  const { data: mapData, loading: mapLoading } = useGetGeographyMapData();

  const anyRequestsLoading =
    compositionLoading || postingsLoading || mapLoading;

  return (
    <DashboardPage
      title={[PageTitles.GEOGRAPHY, PageTitles.SUMMARY]}
      hideSelectionsMargins
      loading={anyRequestsLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="filterChip"
            showColors
            isPrimaryChip
            limit={PrimaryFilterLimits.GEOGRAPHY_SUMMARY}
            useChipsSkeleton={false}
            min={1}
            addButton={
              <AddEntityButton
                entities={GEO_SUMMARY_PRIMARY_VIEW_FILTER}
                entityName={AddEntityButtonText[PrimaryFilters.GEOGRAPHY]}
                buttonText={AddEntityButtonText[PrimaryFilters.GEOGRAPHY]}
                activeLimit={PrimaryFilterLimits.GEOGRAPHY_SUMMARY}
                limit={SHARED_SET_ENTITY_LIMIT}
                required={1}
                // trialNoResultsMessage={trialNoResultsMessage}
              />
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent={'space-between'}
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={GEO_SUMMARY_SECONDARY_FILTERS}
            variant="filterChip"
            limit={FilterMenuLimits.ENTITY_SUMMARY}
            showGranularity={true}
            viewType={ViewTypes.GEO}
            addButton={
              <FilterMenu
                title="Filter"
                filters={GEO_SUMMARY_SECONDARY_FILTERS}
                selectMenuOpenDefault
                limit={FilterMenuLimits.ENTITY_SUMMARY}
                showFilterSetSaveMenu={false}
                viewIdForDefault={`${Views.ENTITY_SUMMARY}_${ViewTypes.GEO}`}
              />
            }
          />
        </Flex>
      </FilterContainer>
      <Flex width="full" height="full">
        <Flex flexBasis="67%" marginRight="8px" flexDirection="column">
          <Card
            flexBasis="156px"
            marginBottom="16px"
            borderRadius="8px"
            variant="unstyled"
            padding="16px 0"
          >
            <Flex height="full">
              <MeterContainer title="Supply/Demand">
                <SupplyDemandMeter
                  compositionData={compositionData}
                  postingsData={postingsData}
                />
              </MeterContainer>
              <Divider orientation="vertical" />
              <MeterContainer title="Wage Pressure">
                <WagePressureMeter compositionData={compositionData} />
              </MeterContainer>
              <Divider orientation="vertical" />
              <MeterContainer title="Time to Fill">
                <TimeToFillMeter postingsData={postingsData} />
              </MeterContainer>
              <Divider orientation="vertical" />
              <MeterContainer title="MarketTightness">
                <MarketTightnessMeter
                  postingsData={postingsData}
                  compositionData={compositionData}
                />
              </MeterContainer>
            </Flex>
          </Card>
          <Card
            flexGrow="1"
            variant="unstyled"
            borderRadius="8px"
            padding="16px"
          >
            <Center
              height="full"
              backgroundColor="#02d8392b"
              borderRadius="8px"
            >
              <GeographyMap mapData={mapData} />
            </Center>
          </Card>
        </Flex>
        <Flex flexBasis="33%" marginLeft="8px" flexDirection="column">
          <Box flexBasis="50%" marginBottom="8px">
            <GeographySummaryTopCompanies data={compositionData} />
          </Box>
          <Box flexBasis="50%" marginTop="8px">
            <GeographySummaryTopSkills data={compositionData} />
          </Box>
        </Flex>
      </Flex>
    </DashboardPage>
  );
};
