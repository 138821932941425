import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { GroupBase, Select } from 'chakra-react-select';
import { Control, useController, Validate } from 'react-hook-form';
import { useMutation, useQuery } from 'urql';
import { sortBy } from 'lodash';
import {
  AddUserMutationVariables,
  AllClientsTableDocument,
  CreateClientRewriteDocument,
  CreateClientRewriteMutationVariables,
  Role,
  Tab,
} from '@revelio/data-access';
import { OptionValues } from '../adminRewrite/utils/helpers';
import { AddIcon } from '@chakra-ui/icons';
import { AddUserMutation } from '../adminRewrite/userOperations';

type ControlFormValues = {
  client_group: OptionValues;
  name: string;
};
interface Props {
  control: Control<ControlFormValues, object>;
}

export function ClientSelect({ control }: Props) {
  const [{ data, fetching }, refetchClients] = useQuery({
    query: AllClientsTableDocument,
  });

  const selectOptions = sortBy(data?.clients, ['client_name'])
    .map((client) => ({
      label: client?.client_name as string,
      value: client?.client_name as string,
    }))
    .filter((opt) => opt.value);

  const isWhitespaceString = (val: string) => !val.replace(/\s/g, '').length;

  const {
    field: { onChange, onBlur, ref, name, value },
    fieldState: { error },
  } = useController<ControlFormValues>({
    name: 'client_group',
    control,
    rules: {
      validate: ((value: OptionValues, formValues: ControlFormValues) =>
        !value || !value.value || isWhitespaceString(value.value)
          ? 'client is required'
          : undefined) as Validate<string | boolean | OptionValues | undefined>,
    },
  });

  const [, addUser] = useMutation(AddUserMutation);
  const createDummyUserForNewClient = (client_name: string) => {
    const newUser: AddUserMutationVariables = {
      active: false,
      name: client_name + '_dummy_user',
      email: `${client_name.split(' ').join('')}_dummy_email@reveliolabs.com`,
      client_name,
      role: Role.ClientUser,
      password: 'dummypassword',
    };

    return addUser(newUser);
  };

  const [, createClient] = useMutation(CreateClientRewriteDocument);
  const createClientWithDummyUser = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    formData: { inputValue: string }
  ) => {
    const formatData: CreateClientRewriteMutationVariables = {
      client_name: formData.inputValue,
      tabs: [Tab.Deliverables],
      live: false,
      active: false,
      linkup_postings: false,
      unified_postings: false,
      num_seats: '1',
      company_lists: [],
      data_builder_configuration: {},
    };
    return createClient(formatData)
      .then(() => createDummyUserForNewClient(formData.inputValue))
      .then(() => refetchClients({ requestPolicy: 'network-only' }))
      .then(() => {
        onChange({ value: formData.inputValue, label: formData.inputValue });
      });
  };

  const CreateClient = (formData: { inputValue: string }) => (
    <Button
      leftIcon={<AddIcon w="12px" height="12px" />}
      aria-label="create client"
      variant="ghost"
      onClick={(event) => createClientWithDummyUser(event, formData)}
    >
      Create {formData.inputValue} Client
    </Button>
  );

  return (
    <FormControl
      id="deliverable_client"
      isInvalid={!!error?.message}
      mt="10"
      pl="5"
    >
      <FormLabel fontSize="sm" color="text.primary">
        Client
      </FormLabel>
      <Flex>
        <Box style={{ flexGrow: 1 }}>
          <Select<OptionValues, true, GroupBase<OptionValues>>
            name={name}
            value={value as OptionValues}
            options={selectOptions}
            isLoading={fetching}
            placeholder="Select client..."
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            styles={{ control: (styles) => ({ ...styles, flexGrow: 1 }) }}
            noOptionsMessage={CreateClient}
          />
        </Box>
      </Flex>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
