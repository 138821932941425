import { formatPercentageToTwoSigFigs } from '@revelio/d3';
import {
  GeographySummaryMetric,
  SingleOption,
  GeographyBarChartsData,
} from '../../types';

export const getGeographyBarChartProps = (
  compositionData: GeographyBarChartsData,
  selectedChart: SingleOption,
  name: string
) => {
  const data = compositionData[selectedChart?.value as GeographySummaryMetric];
  const formats = {
    base: '.0%',
    tooltip: ',.1f',
    func: (value: number) => formatPercentageToTwoSigFigs(value / 100),
  };

  return {
    chartPosition: 'right',
    chartSize: 'large',
    colorIndex: 0,
    customFormatter: formats.func,
    customMargins: undefined,
    data,
    format: formats.base,
    formatCustomString: '',
    isRenderingOrLoading: undefined,
    metaValue: 'shortName',
    name,
    preformatter: undefined,
    requestHash: undefined,
    singleColor: undefined,
    textAboveBar: false,
    tooltipCustomValue: undefined,
    tooltipFormat: formats.tooltip,
    tooltipFormatCustomString: '%',
    tooltipMetaValue: 'longName',
    ttType: '',
  };
};
