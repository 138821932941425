// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import '../d3-styles.scss';
import { BarChartHorizontalGenerator } from '../plot-generators';
import { useD3 } from '../hooks/useD3';

const BarChartHorizontal = ({
  name,
  data,
  chartPosition,
  chartSize,
  preformatter,
  format,
  customFormatter,
  tooltipFormat,
  formatCustomString,
  tooltipFormatCustomString,
  singleColor,
  colorIndex,
  metaValue,
  tooltipMetaValue,
  ttType,
  textAboveBar,
  height,
  width,
  targetRef,
  requestHash,
  tooltipCustomValue,
  isRenderingOrLoading,
  customMargins,
}) => {
  useD3(
    BarChartHorizontalGenerator,
    {
      name,
      data,
      chartPosition,
      chartSize,
      preformatter,
      format,
      customFormatter,
      tooltipFormat,
      formatCustomString,
      tooltipFormatCustomString,
      singleColor,
      colorIndex,
      metaValue,
      tooltipMetaValue,
      ttType,
      textAboveBar,
      height,
      width,
      targetRef,
      requestHash,
      tooltipCustomValue,
      isRenderingOrLoading,
      customMargins,
    },
    [targetRef.current, width, height, data, requestHash]
  );

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        display: 'grid',
        position: 'relative',
        height: '100%',
      }}
    />
  );
};

BarChartHorizontal.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  chartPosition: PropTypes.string,
  chartSize: PropTypes.string,
  format: PropTypes.string,
  tooltipFormat: PropTypes.string,
  formatCustomString: PropTypes.string,
  tooltipFormatCustomString: PropTypes.string,
  singleColor: PropTypes.bool,
  colorIndex: PropTypes.number,
  metaValue: PropTypes.string.isRequired,
  tooltipMetaValue: PropTypes.string.isRequired,
  ttType: PropTypes.string,
  textAboveBar: PropTypes.bool,
  tooltipCustomValue: PropTypes.string,
};

BarChartHorizontal.defaultProps = {
  name: 'default',
  heading: 'default',
  data: [],
  chartPosition: '',
  chartSize: 'small',
  format: '.0%',
  tooltipFormat: '.1%',
  formatCustomString: '',
  tooltipFormatCustomString: '',
  singleColor: true,
  colorIndex: 0,
  metaValue: 'shortName',
  tooltipMetaValue: 'longName',
  ttType: '',
  textAboveBar: false,
};

export default withResizeDetector(BarChartHorizontal, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
