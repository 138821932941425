import { Card } from '@chakra-ui/react';

import { TalentDiscoveryMapV2 } from '@revelio/d3';
import {
  DefaultDates,
  FilterSets,
  LocalSelectionCategories,
  SelectionCategories,
  TalentDiscoveryFilterProvider,
  useDefaultLastMonth,
  useSelectionLists,
  useStoredFilterSet,
  useViewFilters,
  ViewTypes,
} from '@revelio/filtering';
import { Loading, PageTitles, Views } from '@revelio/core';

import TalentDiscoveryTable from './talent-discovery-table';
import { SplitPane } from './split-pane';
import { useTalentDiscoveryDataFetch } from './data-fetch';
import { TalentDiscoveryFilterMenu } from './talent-discovery-filter-menu';
import DashboardPage from '../DashboardPage';

const selectableFilters = [
  SelectionCategories.COMPANY,
  SelectionCategories.HIGHEST_DEGREE,
  SelectionCategories.ETHNICITY,
  SelectionCategories.GENDER,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.REGION,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K1500,
  SelectionCategories.SKILL_K75,
  SelectionCategories.SKILL_K700,
  SelectionCategories.SKILL_K3000,
  SelectionCategories.SENIORITY,
  SelectionCategories.KEYWORD,
  SelectionCategories.RSID,
  SelectionCategories.NAME,
  LocalSelectionCategories.PRESTIGE,
  LocalSelectionCategories.FLIGHT_RISK,
  LocalSelectionCategories.REMOTE_SUITABILITY,
  SelectionCategories.TOTAL_COMPENSATION,
  // SelectionCategories.BASE_SALARY,
];

const TalentDiscoveryContent = () => {
  const { data, loading } = useTalentDiscoveryDataFetch();
  useStoredFilterSet({ sharedSetId: FilterSets.TALENT_DISCOVERY });
  useViewFilters(selectableFilters);
  useSelectionLists(selectableFilters);
  useDefaultLastMonth({
    view: Views.TALENT_DISCOVERY,
    viewType: ViewTypes.NONE,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
  });

  return (
    <DashboardPage
      title={[PageTitles.TALENT_DISCOVERY]}
      loading={loading}
      selections={<TalentDiscoveryFilterMenu />}
    >
      {loading ? (
        <Card variant="unstyled" width="full" height="full" borderRadius="10px">
          <Loading />
        </Card>
      ) : (
        <SplitPane
          leftContent={
            <TalentDiscoveryTable
              apiData={data?.talent_discovery_search_v1_5}
            />
          }
          rightContent={
            <TalentDiscoveryMapV2
              data={{
                data: data?.talent_discovery_search_v1_5?.map_data || [],
                // get msa selections
                // filters: state?.filterSelections?.find((filterSelection) => filterSelection.name === 'msa')?.values,
              }}
            />
          }
        />
      )}
    </DashboardPage>
  );
};

export const TalentDiscovery = () => {
  return (
    <TalentDiscoveryFilterProvider>
      <TalentDiscoveryContent />
    </TalentDiscoveryFilterProvider>
  );
};
