import {
  fullLogout,
  isUserTrialExpired,
  hasUserCompletedTourAndToS as _hasUserCompletedTourAndToS,
  useAuthStoreOry,
  useDashMetaCsrf,
  overwriteTDV2Tab,
  authStore,
} from '@revelio/auth';
import { useEffect, useState } from 'react';
import { useOpenReplay } from '../../hooks/open-replay';
import { useQuery } from 'urql';
import { USER_DETAILS_QUERY } from '@revelio/data-access';

/** ================================
 * useGraphQLAuthCheck
 ================================ */
interface UseGraphQLAuthCheckProps {
  userId: string | undefined;
  readyToCheck: boolean;
}
const useGraphQLAuthCheck = ({
  userId,
  readyToCheck,
}: UseGraphQLAuthCheckProps) => {
  /** gql auth check */
  const [{ fetching, data, error }] = useQuery({
    query: USER_DETAILS_QUERY,
    variables: { ids: [userId ?? ''] },
    pause: !readyToCheck,
  });

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [hasUserCompletedTourAndToS, setHasUserCompletedTourAndToS] =
    useState(false);
  useEffect(() => {
    if (data) {
      try {
        const user = data.users?.[0];
        if (!user) throw new Error('USER_NOT_FOUND');
        if (!user.active) throw new Error('USER_NOT_ACTIVE');
        if (isUserTrialExpired(user)) throw new Error('USER_TRIAL_EXPIRED');

        setHasUserCompletedTourAndToS(() => _hasUserCompletedTourAndToS(user));

        const userWithCorrectTabs = overwriteTDV2Tab(user);

        authStore.update((state) => ({
          ...state,
          user: userWithCorrectTabs,
        }));

        setIsAuthorized(true);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : `${err}`;
        console.error(errorMessage);
        if (
          ['USER_NOT_FOUND', 'USER_NOT_ACTIVE', 'USER_TRIAL_EXPIRED'].includes(
            errorMessage
          )
        ) {
          fullLogout();
        }
      }
    }
  }, [data, error]);

  return { fetching, isAuthorized, hasUserCompletedTourAndToS };
};

/** ================================
 * useAuthCheck
 ================================ */
export const useAuthCheck = () => {
  /** Get ory session */
  const { ory, fetching: oryFetching } = useAuthStoreOry();
  const userId = ory?.id;
  const isOryAuthorized = ory && !!userId;

  /** Start tracking once ory session received */
  const { tracker } = useOpenReplay();
  useEffect(() => {
    if (ory?.username) tracker.setUserID(ory?.username);
  }, [ory, tracker]);

  /** Get Dash Meta CSRF Token */
  const { dashMetaCsrfToken, fetching: csrfFetching } = useDashMetaCsrf();

  /** Check GQL User Details once userId available and csrf has loaded */
  const {
    fetching: graphQLFetching,
    isAuthorized: isGraphQLAuthorized,
    hasUserCompletedTourAndToS,
  } = useGraphQLAuthCheck({
    userId,
    readyToCheck: !!userId && !!dashMetaCsrfToken,
  });

  return {
    isAuthenticating: oryFetching || csrfFetching || graphQLFetching,
    isAuthorized: isOryAuthorized && isGraphQLAuthorized,
    hasUserCompletedTourAndToS: isOryAuthorized && hasUserCompletedTourAndToS,
  };
};
