import { Button, Flex, Icon } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';
import { FiSliders } from 'react-icons/fi';
import { ScreenerFilterOption } from './types';
import { ScreenerFilterPopover } from './screener-filter-popover';
import { PrimaryFilterTag } from './tags/primary-filter-tag';
import { SubFilterTag } from './tags/sub-filter-tag';
import { useScreenerFilter } from './screener-filter-provider';

interface ScreenerFiltersProps {
  filterOptions: ScreenerFilterOption[];
}

const FilterButton = forwardRef((props, ref: LegacyRef<HTMLButtonElement>) => {
  return (
    <Button
      {...props}
      ref={ref}
      colorScheme="gray"
      borderRadius="4px"
      size="sm"
      paddingX={3}
      height={6}
      backgroundColor="transparent"
      fontSize={'xs'}
      fontWeight={400}
      color="navyBlue.500"
      leftIcon={<Icon as={FiSliders} boxSize={3.5} transform="rotate(90deg)" />}
      data-testid="filter-menu-btn"
    >
      Filter
    </Button>
  );
});

export const ScreenerFilters = ({ filterOptions }: ScreenerFiltersProps) => {
  const { state, dispatch } = useScreenerFilter();

  const primaryFilter = state.filters.primary_filter;

  return (
    <Flex wrap="wrap" fontSize={'sm'} rowGap={2.5}>
      {primaryFilter && (
        <PrimaryFilterTag
          filterOptions={filterOptions}
          dispatch={dispatch}
          primaryFilter={primaryFilter}
        />
      )}
      {state.filters.sub_filters?.map((subFilter) => (
        <SubFilterTag
          key={subFilter.id}
          filterOptions={filterOptions}
          subFilter={subFilter}
        />
      ))}
      <ScreenerFilterPopover
        trigger={<FilterButton />}
        filterOptions={filterOptions}
      />
    </Flex>
  );
};
