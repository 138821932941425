import { CompanyStat } from '../../company-stat';
import { CompanyStatsProps } from '../types';
import { DailyLineChart, DailyLineChartProps } from '../daily-line-chart';
import { formatSimpleInteger } from '../utils/format-simple-integer';
import { calculatePercentageChange } from '../utils/calculate-percentage-change';
import {
  CompanyPostingsStatsMetric,
  getAggregatedCompetitorPostings,
} from '../utils/get-aggregated-competitor-metrics';
import { Text } from '@chakra-ui/react';

interface PostingsChartProps {
  postingsActiveData: CompanyStatsProps['postingsActiveData'];
  selectedCompanyRCID: CompanyStatsProps['selectedCompanyRCID'];
  competitors: CompanyStatsProps['competitors'];
  companyName: string | null | undefined;
}
export const PostingsChart = ({
  postingsActiveData,
  selectedCompanyRCID,
  companyName,
  competitors,
}: PostingsChartProps) => {
  const primaryPostingData = postingsActiveData?.posting?.find(
    (datum) => datum?.metadata?.id === selectedCompanyRCID
  );

  const primaryCompanyCategories = primaryPostingData?.category || [];

  const postingsTimeseries: DailyLineChartProps['timeSeries'] =
    primaryCompanyCategories?.map((datum) => ({
      id: datum?.metadata?.id,
      value: datum?.metrics?.active,
      date: datum?.metadata?.shortName,
    })) || [];

  const { lastValue, percentageChange } =
    calculatePercentageChange(postingsTimeseries);

  const primaryValue = formatSimpleInteger(lastValue);

  const vsCompetitors =
    lastValue -
    getAggregatedCompetitorPostings(
      competitors,
      postingsActiveData?.posting,
      CompanyPostingsStatsMetric.ActivePostings
    );

  return (
    <CompanyStat
      name={CompanyPostingsStatsMetric.ActivePostings}
      plotInfo={<Text color="white">The number of active job postings.</Text>}
      header="Active Postings"
      statValues={{
        primaryValue,
        percentChange: percentageChange,
        vsCompetitors,
      }}
      competitorFormatting={formatSimpleInteger}
      competitorStyles={{
        minWidth: '110px',
      }}
    >
      <DailyLineChart
        name={CompanyPostingsStatsMetric.ActivePostings}
        companyName={companyName}
        timeSeries={postingsTimeseries}
      />
    </CompanyStat>
  );
};
