// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { scaleSqrt, min, max } from 'd3';
import { format as d3Format } from 'd3-format';

import 'mapbox-gl/src/css/mapbox-gl.css';
import './custom-mapbox.css';
import { debounce } from 'lodash';
import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { removeLoadingStatus } from '@revelio/core';

const MAPBOX_TOKEN =
  'pk.eyJ1IjoicmV2ZWxpb2xhYnMiLCJhIjoiY2w3N3pjM2tlMDRuczN2cXB1ZTU3bWZvdyJ9.AluK-n7WOv2tAOhTL-ZzAQ';
mapboxgl.accessToken = MAPBOX_TOKEN;

const TalentDiscoveryMap = (props) => {
  const { data, mapFullscreen, isRenderingOrLoading } = props;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const popup = useRef(null);
  const [lng] = useState(-40.0);
  const [lat] = useState(30.0);
  const [zoom] = useState(1.8);

  const [sourceDataLoaded, setSourceDataLoaded] = useState(false);

  const minZoomLevel = 1.8;
  const maxZoomLevel = 6;

  const [zoomLevel, setZoomLevel] = useState(minZoomLevel * 10);

  const transformed_map_data = (d) => {
    const shareData = d.map((row) => row.share);
    const circleScale = scaleSqrt()
      .domain([min(shareData), max(shareData)])
      .range([3, 35]);
    return d
      ? {
          type: 'FeatureCollection',
          features:
            d.map(({ lat, long, counts, share, location, type }) => {
              return {
                type: 'Feature',
                properties: {
                  description: `<strong><span>${location}</span></strong><br/><span>${d3Format(
                    '.1%'
                  )(share)}</span>`,
                  counts: counts || 0,
                  share: circleScale(share) || 0,
                  location: location || 'default',
                  type:
                    type === 0 &&
                    (location === 'United States' || location === 'USA')
                      ? 4
                      : type || 0,
                  shareWidth: circleScale(share) < 4 ? 1 : 2,
                },
                geometry: {
                  type: 'Point',
                  coordinates: [long || 0, lat || 0],
                },
              };
            }) || [],
        }
      : {
          type: 'FeatureCollection',
          features: [],
        };
  };

  useEffect(() => {
    if (map.current) {
      return;
    } // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/reveliolabs/cl7f2b55l001414lfc2hq8bcr',
      center: [lng, lat],
      zoom: zoom,
      minZoom: minZoomLevel,
      maxZoom: maxZoomLevel,
      attributionControl: false,
    });
    map.current.on('load', () => {
      map.current.addSource('places', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
      popup.current = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });
      map.current.dragRotate.disable();
      map.current.touchZoomRotate.disableRotation();

      map.current.on('zoom', function () {
        const zoom = map.current.getZoom() * 10;
        setZoomLevel(zoom);
      });
      removeLoadingStatus(['tabChange']);
      isRenderingOrLoading?.next(false);
    });

    const resizer = new ResizeObserver(
      debounce(() => map.current.resize(), 100)
    );

    resizer.observe(mapContainer.current);

    map.current.on('data', (e) => {
      if (e?.source?.type === 'geojson') {
        addPoints(e.source.data);
      }
    });

    map.current.on('sourcedata', (e) => {
      if (e?.isSourceLoaded && e?.sourceId === 'places') {
        setSourceDataLoaded(true);
      }
    });

    return () => {
      resizer.disconnect();
      map.current.remove();
    };
  }, [isRenderingOrLoading, lat, lng, zoom]);

  const addPoints = (d) => {
    for (const feature of d.features) {
      const symbol = feature.properties.type;
      const layerID = `places-${symbol}`;

      // Add a layer for this symbol type if it hasn't been added already.
      if (!map.current.getLayer(layerID)) {
        const placeMap = {
          'places-0': { min: 1, max: 7 }, // GLOBAL
          'places-4': { min: 1, max: 2.7 }, // USA
          'places-3': { min: 2.7, max: 3.8 }, // STATE
          'places-2': { min: 3.8, max: 7 }, // MSA
        };
        map.current.addLayer({
          id: layerID,
          type: 'circle',
          source: 'places',
          paint: {
            'circle-color': '#6B79AD',
            'circle-radius': ['number', ['get', 'share'], 0],
            'circle-stroke-width': ['number', ['get', 'shareWidth'], 0],
            'circle-stroke-color': '#ffffff',
            'circle-opacity': 0.9,
          },
          filter: ['==', 'type', feature.properties.type],
          minzoom: placeMap[layerID].min,
          maxzoom: placeMap[layerID].max,
        });

        map.current.on('mousemove', layerID, (e) => {
          const zoomLevel = map.current.getZoom();
          if (
            placeMap[layerID].min > zoomLevel ||
            placeMap[layerID].max < zoomLevel
          ) {
            return;
          }
          // Change the cursor style as a UI indicator.
          map.current.getCanvas().style.cursor = 'pointer';

          // Copy coordinates array.
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.current
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(map.current);
        });

        map.current.on('mouseleave', layerID, () => {
          map.current.getCanvas().style.cursor = '';
          popup.current.remove();
        });

        map.current.on('zoom', () => {
          popup.current.remove();
        });
      }
    }
  };

  const zoomToLargest = useCallback(() => {
    let largestCountry = { lat: 0, long: 0 };
    if (data.length > 0) {
      largestCountry = data
        .filter((d) => d.type === 0)
        .reduce((a, b) => (a.share > b.share ? a : b));
      map.current.flyTo({
        center: [largestCountry.long, largestCountry.lat],
      });
    }
  }, [data]);

  useEffect(() => {
    if (map.current && data && sourceDataLoaded) {
      const placesDataSource = map.current.getSource('places');
      if (placesDataSource) {
        const transformedData = transformed_map_data(data);
        placesDataSource.setData(transformedData);
        zoomToLargest();
      }
    }
  }, [data, sourceDataLoaded, zoomToLargest]);

  return (
    <div
      ref={mapContainer}
      className="map-container"
      style={{ position: 'relative', width: '100%', height: '100%' }}
    >
      <Box
        zIndex={1}
        width={mapFullscreen ? '15%' : '25%'}
        position="absolute"
        bottom="0"
        right="0"
        padding="8px 16px 0px 16px"
      >
        <Slider
          aria-label="slider-ex-1"
          defaultValue={minZoomLevel * 10}
          value={zoomLevel}
          focusThumbOnChange={false}
          zIndex={2}
          onChange={(val) => {
            setZoomLevel(val);
            map.current.setZoom(val / 10);
          }}
          min={minZoomLevel * 10}
          max={maxZoomLevel * 10}
        >
          <SliderTrack backgroundColor="green.100">
            <SliderFilledTrack backgroundColor="green.500" />
          </SliderTrack>
          <SliderThumb backgroundColor="green.500" />
        </Slider>
      </Box>
    </div>
  );
};

TalentDiscoveryMap.defaultProps = {
  data: [],
  mapFullscreen: false,
};

export default TalentDiscoveryMap;
