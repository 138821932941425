import { useQuery } from 'urql';

import {
  serializeTDFilters,
  talentDiscoveryFilterTransformation,
  useActiveFiltersState,
  useTalentDiscoveryFilterContext,
} from '@revelio/filtering';
import { TalentDiscoveryV1_5Filter } from '@revelio/data-access';
import { FeatureFlag } from '@revelio/core';
import {
  TALENT_DISCOVERY_DATA,
  TALENT_DISCOVERY_DATA_WITH_EDUCATION,
} from './graphql-operations';
import { useUnleashFlag } from '../../../hooks/unleash/useUnleashFlag';

export const useTalentDiscoveryDataFetch = () => {
  const activeFilters = useActiveFiltersState();
  const talentDiscoveryFilterApi = useTalentDiscoveryFilterContext();
  const numOfFilterSelections = activeFilters.length;
  const shouldQueryEducationCol = useUnleashFlag(
    FeatureFlag.TalentDiscoveryQueryEducation
  );

  const [{ data, fetching }] = useQuery({
    query: shouldQueryEducationCol
      ? TALENT_DISCOVERY_DATA_WITH_EDUCATION
      : TALENT_DISCOVERY_DATA,
    variables: {
      query: {
        ...(talentDiscoveryFilterTransformation({
          skillFilters: talentDiscoveryFilterApi.skillFilterApi.skillFilters,
          filters: serializeTDFilters(activeFilters),
        }) as TalentDiscoveryV1_5Filter),
        page: 1,
        num_results: 5000,
      },
    },
    pause: numOfFilterSelections === 0,
  });

  return {
    data: numOfFilterSelections > 0 ? data : undefined,
    loading: fetching,
  };
};
