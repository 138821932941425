import {
  FilterName,
  POSTING_GET_DATA,
  useActiveFiltersState,
} from '@revelio/filtering';
import { useMemo, useState } from 'react';
import {
  transformFiltersToPayQuery,
  transformFiltersToPostingsQuery,
} from './transform-filters';
import {
  CompensationResponseTimeToFillValues,
  CompensationTypes,
  PrimaryView,
  timeToFillDataTransformer,
} from '@revelio/core';
import { isQueryReady } from './is-query-ready';
import { useGetCompensationBody } from './use-get-compensation-body';
import { getCompanyLookup } from './company-lookup';
import { useFetchCompensation } from './use-fetch-compensation';
import { useQuery } from 'urql';
import { getLineChartData } from '../../postings/utils';
import { transformData } from './transform-data';
import { getDim1FromView } from './get-dim1-from-view';

export const usePayDataFetch = ({
  view,
  primaryFilters,
  compensationType,
}: {
  view: PrimaryView;
  primaryFilters: FilterName[];
  compensationType: CompensationTypes;
}) => {
  /** ======== Filters ======== */
  const activeFilters = useActiveFiltersState();

  const companyLookup = useMemo(() => {
    if (view === PrimaryView.COMPANY) {
      return getCompanyLookup(activeFilters);
    }
    return undefined;
  }, [activeFilters, view]);

  const isReady = isQueryReady({ activeFilters, primaryFilters });

  const compensationFilters = useMemo(
    () => transformFiltersToPayQuery({ filters: activeFilters }),
    [activeFilters]
  );

  const [compensationLoading, setCompensationLoading] =
    useState<boolean>(false);
  const { compensationBody } = useGetCompensationBody({
    view,
    filters: compensationFilters,
    isReady,
    setLoading: setCompensationLoading,
  });

  /** ======== Compensation Fetch ======== */
  const { data: compensationData } = useFetchCompensation({
    compensationBody,
    setLoading: setCompensationLoading,
  });

  /** ======== Data Transformation ======== */
  const {
    overtimeData,
    kdeData,
    topCompanyData,
    topGeographyData,
    topRoleData,
  } = useMemo(
    () =>
      transformData({
        data: compensationData,
        compensationType,
        companyLookup,
      }),
    [compensationType, compensationData, companyLookup]
  );

  /** ======== POSTINGS ======== */
  const postingsFilters = useMemo(
    () => transformFiltersToPostingsQuery({ filters: activeFilters }),
    [activeFilters]
  );

  const [{ data: postingsData, fetching: postingsFetching }] = useQuery({
    query: POSTING_GET_DATA,
    variables: { dim1: getDim1FromView(view), filters: postingsFilters },
    pause: !isReady,
  });

  const timeToFillData = useMemo(() => {
    if (postingsData) {
      const lineData = getLineChartData({
        entities: postingsData?.posting,
        metricKey: 'time_to_fill',
      });
      return timeToFillDataTransformer(
        lineData as unknown as CompensationResponseTimeToFillValues[]
      );
    }
    return [];
  }, [postingsData]);

  return {
    overtimeData,
    timeToFillData,
    kdeData,
    topCompanyData,
    topGeographyData,
    topRoleData,
    loading: compensationLoading || postingsFetching,
  };
};
