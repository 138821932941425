export const calculatePercentageChange = (
  timeseries:
    | ({
        value?: number | null | undefined;
      } | null)[]
    | null
    | undefined
): { lastValue: number; percentageChange: number } => {
  const firstValue = timeseries?.[0]?.value;
  const lastValue = timeseries?.[timeseries.length - 1]?.value || 0;

  const percentageChange = firstValue
    ? ((lastValue - firstValue) / firstValue) * 100
    : 0;

  return { lastValue, percentageChange };
};
