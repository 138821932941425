import { mtMetro } from './mt_metro';
import { sdrMetro } from './sdr_metro';
import { ttfMetro } from './ttf_metro';
import { wgMetro } from './wg_metro';

export const granularityLookup = {
  supplyDemand: {
    mean: 1992,
    std: 13321,
    lookup: sdrMetro,
  },
  timeToFill: {
    mean: 38.0,
    std: 12.9,
    lookup: ttfMetro,
  },
  wageGrowth: {
    mean: 0.0298,
    std: 0.1048,
    lookup: wgMetro,
  },
  marketTightness: {
    lookup: mtMetro,
  },
};
