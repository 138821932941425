import { useScreenerFilter } from '@revelio/filtering';
import { ScreenerDimension } from '@revelio/data-access';
import { Button, Icon, useToast } from '@chakra-ui/react';
import { FiDownload } from 'react-icons/fi';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { useState } from 'react';
import { serialiseScreenerFilters } from './screener-table/utils';

interface DownloadScreenerButtonProps {
  view: ScreenerDimension;
}

export const DownloadScreenerButton = ({
  view,
}: DownloadScreenerButtonProps) => {
  const { state } = useScreenerFilter();
  const toast = useToast();

  const filters = serialiseScreenerFilters(state.filters, view);

  const [loading, setLoading] = useState(false);

  const triggerDownload = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${environment.GO_API_ROOT}/api/v2/screener/downloads`,
        { ...filters, pagination: { page: 1 }, sorting: state.sorting },
        {
          responseType: 'blob',
          withCredentials: true,
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'screener_data.xls');
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Error',
        description: 'Error downloading csv.',
        status: 'error',
        duration: 4000,
        position: 'top-right',
      });
    }
  };

  if (
    Object.keys(filters.primary_selector)?.length === 0 &&
    Object.keys(filters.segments)?.length === 0
  ) {
    return null;
  }

  return (
    <Button
      leftIcon={<Icon as={FiDownload} boxSize={3.5} />}
      size="sm"
      variant="white"
      onClick={triggerDownload}
      height="24px"
      data-testid="download-screener-data-button"
      isDisabled={loading}
    >
      Download Data
    </Button>
  );
};
