import { PageTitles } from '@revelio/core';
import { Screener } from '../../../screener/screener';
import { SelectionCategories } from '@revelio/filtering';
import { generateFilterOptions } from '../../utils/generateFilterOptions';
import { ScreenerDimension } from '@revelio/data-access';

const title = [PageTitles.ROLE, PageTitles.SCREENER];

export const RoleScreener = () => {
  const filterOptions = generateFilterOptions(
    SelectionCategories.JOB_CATEGORY,
    'Role'
  );

  return (
    <Screener
      title={title}
      filterOptions={filterOptions}
      view={ScreenerDimension.Role}
    />
  );
};

export default RoleScreener;
