export const sdrMetro = [
  {
    percentile: 0,
    z_score: -0.1495447821,
  },
  {
    percentile: 0.1170960187,
    z_score: -0.1494886411,
  },
  {
    percentile: 0.2341920375,
    z_score: -0.1494660347,
  },
  {
    percentile: 0.3512880562,
    z_score: -0.1494398929,
  },
  {
    percentile: 0.4683840749,
    z_score: -0.1494237717,
  },
  {
    percentile: 0.5854800937,
    z_score: -0.1493589412,
  },
  {
    percentile: 0.7025761124,
    z_score: -0.1493306985,
  },
  {
    percentile: 0.8196721311,
    z_score: -0.1492925613,
  },
  {
    percentile: 0.9367681499,
    z_score: -0.1492893873,
  },
  {
    percentile: 1.053864169,
    z_score: -0.149200168,
  },
  {
    percentile: 1.170960187,
    z_score: -0.1491736922,
  },
  {
    percentile: 1.288056206,
    z_score: -0.1490510255,
  },
  {
    percentile: 1.405152225,
    z_score: -0.1490253126,
  },
  {
    percentile: 1.522248244,
    z_score: -0.1490090373,
  },
  {
    percentile: 1.639344262,
    z_score: -0.1489480802,
  },
  {
    percentile: 1.756440281,
    z_score: -0.148924835,
  },
  {
    percentile: 1.8735363,
    z_score: -0.1489154264,
  },
  {
    percentile: 1.990632319,
    z_score: -0.1488661058,
  },
  {
    percentile: 2.107728337,
    z_score: -0.1488561773,
  },
  {
    percentile: 2.224824356,
    z_score: -0.1488457087,
  },
  {
    percentile: 2.341920375,
    z_score: -0.148711335,
  },
  {
    percentile: 2.459016393,
    z_score: -0.1486917797,
  },
  {
    percentile: 2.576112412,
    z_score: -0.1486914946,
  },
  {
    percentile: 2.693208431,
    z_score: -0.1486765231,
  },
  {
    percentile: 2.81030445,
    z_score: -0.1486674613,
  },
  {
    percentile: 2.927400468,
    z_score: -0.148638548,
  },
  {
    percentile: 3.044496487,
    z_score: -0.1485750832,
  },
  {
    percentile: 3.161592506,
    z_score: -0.1485372675,
  },
  {
    percentile: 3.278688525,
    z_score: -0.1485317969,
  },
  {
    percentile: 3.395784543,
    z_score: -0.1484951597,
  },
  {
    percentile: 3.512880562,
    z_score: -0.1484805885,
  },
  {
    percentile: 3.629976581,
    z_score: -0.1484685356,
  },
  {
    percentile: 3.7470726,
    z_score: -0.1484521746,
  },
  {
    percentile: 3.864168618,
    z_score: -0.1484340283,
  },
  {
    percentile: 3.981264637,
    z_score: -0.1484259398,
  },
  {
    percentile: 4.098360656,
    z_score: -0.1484067666,
  },
  {
    percentile: 4.215456674,
    z_score: -0.1483945157,
  },
  {
    percentile: 4.332552693,
    z_score: -0.1483893354,
  },
  {
    percentile: 4.449648712,
    z_score: -0.1483709774,
  },
  {
    percentile: 4.566744731,
    z_score: -0.1482893967,
  },
  {
    percentile: 4.683840749,
    z_score: -0.1482711243,
  },
  {
    percentile: 4.800936768,
    z_score: -0.1482519733,
  },
  {
    percentile: 4.918032787,
    z_score: -0.1481790494,
  },
  {
    percentile: 5.035128806,
    z_score: -0.1481625662,
  },
  {
    percentile: 5.152224824,
    z_score: -0.1480856604,
  },
  {
    percentile: 5.269320843,
    z_score: -0.1480811815,
  },
  {
    percentile: 5.386416862,
    z_score: -0.1480597073,
  },
  {
    percentile: 5.503512881,
    z_score: -0.1479702657,
  },
  {
    percentile: 5.620608899,
    z_score: -0.1479701603,
  },
  {
    percentile: 5.737704918,
    z_score: -0.1479663043,
  },
  {
    percentile: 5.854800937,
    z_score: -0.1479335622,
  },
  {
    percentile: 5.971896956,
    z_score: -0.1478949844,
  },
  {
    percentile: 6.088992974,
    z_score: -0.1478855568,
  },
  {
    percentile: 6.206088993,
    z_score: -0.1478663772,
  },
  {
    percentile: 6.323185012,
    z_score: -0.1478557228,
  },
  {
    percentile: 6.44028103,
    z_score: -0.1478027398,
  },
  {
    percentile: 6.557377049,
    z_score: -0.1477792873,
  },
  {
    percentile: 6.674473068,
    z_score: -0.1476441466,
  },
  {
    percentile: 6.791569087,
    z_score: -0.1476298668,
  },
  {
    percentile: 6.908665105,
    z_score: -0.14762253,
  },
  {
    percentile: 7.025761124,
    z_score: -0.1475442908,
  },
  {
    percentile: 7.142857143,
    z_score: -0.1474686377,
  },
  {
    percentile: 7.259953162,
    z_score: -0.1474659987,
  },
  {
    percentile: 7.37704918,
    z_score: -0.147431089,
  },
  {
    percentile: 7.494145199,
    z_score: -0.1474285454,
  },
  {
    percentile: 7.611241218,
    z_score: -0.14742417,
  },
  {
    percentile: 7.728337237,
    z_score: -0.1473419105,
  },
  {
    percentile: 7.845433255,
    z_score: -0.1473167915,
  },
  {
    percentile: 7.962529274,
    z_score: -0.1472852666,
  },
  {
    percentile: 8.079625293,
    z_score: -0.1472650594,
  },
  {
    percentile: 8.196721311,
    z_score: -0.1470670324,
  },
  {
    percentile: 8.31381733,
    z_score: -0.1470514311,
  },
  {
    percentile: 8.430913349,
    z_score: -0.1469571254,
  },
  {
    percentile: 8.548009368,
    z_score: -0.1469008391,
  },
  {
    percentile: 8.665105386,
    z_score: -0.146899671,
  },
  {
    percentile: 8.782201405,
    z_score: -0.1467420266,
  },
  {
    percentile: 8.899297424,
    z_score: -0.146697245,
  },
  {
    percentile: 9.016393443,
    z_score: -0.1466962002,
  },
  {
    percentile: 9.133489461,
    z_score: -0.1466357955,
  },
  {
    percentile: 9.25058548,
    z_score: -0.1465576943,
  },
  {
    percentile: 9.367681499,
    z_score: -0.1464755232,
  },
  {
    percentile: 9.484777518,
    z_score: -0.1464670318,
  },
  {
    percentile: 9.601873536,
    z_score: -0.1463789021,
  },
  {
    percentile: 9.718969555,
    z_score: -0.146338481,
  },
  {
    percentile: 9.836065574,
    z_score: -0.1462921338,
  },
  {
    percentile: 9.953161593,
    z_score: -0.1462713861,
  },
  {
    percentile: 10.070257611,
    z_score: -0.1462101838,
  },
  {
    percentile: 10.18735363,
    z_score: -0.1462000277,
  },
  {
    percentile: 10.304449649,
    z_score: -0.1461159012,
  },
  {
    percentile: 10.421545667,
    z_score: -0.1460366567,
  },
  {
    percentile: 10.538641686,
    z_score: -0.1460237,
  },
  {
    percentile: 10.655737705,
    z_score: -0.1460054565,
  },
  {
    percentile: 10.772833724,
    z_score: -0.1459343685,
  },
  {
    percentile: 10.889929742,
    z_score: -0.1458862163,
  },
  {
    percentile: 11.007025761,
    z_score: -0.1458465418,
  },
  {
    percentile: 11.12412178,
    z_score: -0.1458186692,
  },
  {
    percentile: 11.241217799,
    z_score: -0.1458181585,
  },
  {
    percentile: 11.358313817,
    z_score: -0.1458151845,
  },
  {
    percentile: 11.475409836,
    z_score: -0.145664239,
  },
  {
    percentile: 11.592505855,
    z_score: -0.1455609645,
  },
  {
    percentile: 11.709601874,
    z_score: -0.145552542,
  },
  {
    percentile: 11.826697892,
    z_score: -0.1454874063,
  },
  {
    percentile: 11.943793911,
    z_score: -0.1454221607,
  },
  {
    percentile: 12.06088993,
    z_score: -0.1453211179,
  },
  {
    percentile: 12.177985948,
    z_score: -0.1453191257,
  },
  {
    percentile: 12.295081967,
    z_score: -0.1453032459,
  },
  {
    percentile: 12.412177986,
    z_score: -0.1452453829,
  },
  {
    percentile: 12.529274005,
    z_score: -0.145232835,
  },
  {
    percentile: 12.646370023,
    z_score: -0.1452293211,
  },
  {
    percentile: 12.763466042,
    z_score: -0.1452289147,
  },
  {
    percentile: 12.880562061,
    z_score: -0.1452172713,
  },
  {
    percentile: 12.99765808,
    z_score: -0.1451812412,
  },
  {
    percentile: 13.114754098,
    z_score: -0.1451452585,
  },
  {
    percentile: 13.231850117,
    z_score: -0.1449982746,
  },
  {
    percentile: 13.348946136,
    z_score: -0.1449878345,
  },
  {
    percentile: 13.466042155,
    z_score: -0.144984483,
  },
  {
    percentile: 13.583138173,
    z_score: -0.1449718072,
  },
  {
    percentile: 13.700234192,
    z_score: -0.1449584141,
  },
  {
    percentile: 13.817330211,
    z_score: -0.1448732631,
  },
  {
    percentile: 13.93442623,
    z_score: -0.1448663984,
  },
  {
    percentile: 14.051522248,
    z_score: -0.1448610885,
  },
  {
    percentile: 14.168618267,
    z_score: -0.1448163681,
  },
  {
    percentile: 14.285714286,
    z_score: -0.1448042944,
  },
  {
    percentile: 14.402810304,
    z_score: -0.1447802864,
  },
  {
    percentile: 14.519906323,
    z_score: -0.1447778118,
  },
  {
    percentile: 14.637002342,
    z_score: -0.1447649164,
  },
  {
    percentile: 14.754098361,
    z_score: -0.1447231833,
  },
  {
    percentile: 14.871194379,
    z_score: -0.1447128773,
  },
  {
    percentile: 14.988290398,
    z_score: -0.1447014734,
  },
  {
    percentile: 15.105386417,
    z_score: -0.1446910588,
  },
  {
    percentile: 15.222482436,
    z_score: -0.1446825522,
  },
  {
    percentile: 15.339578454,
    z_score: -0.1446723957,
  },
  {
    percentile: 15.456674473,
    z_score: -0.1446561783,
  },
  {
    percentile: 15.573770492,
    z_score: -0.1446197721,
  },
  {
    percentile: 15.690866511,
    z_score: -0.144603068,
  },
  {
    percentile: 15.807962529,
    z_score: -0.1446028322,
  },
  {
    percentile: 15.925058548,
    z_score: -0.1446004471,
  },
  {
    percentile: 16.042154567,
    z_score: -0.1445742577,
  },
  {
    percentile: 16.159250585,
    z_score: -0.1445595735,
  },
  {
    percentile: 16.276346604,
    z_score: -0.1445577773,
  },
  {
    percentile: 16.393442623,
    z_score: -0.1445067375,
  },
  {
    percentile: 16.510538642,
    z_score: -0.144497915,
  },
  {
    percentile: 16.62763466,
    z_score: -0.1444937908,
  },
  {
    percentile: 16.744730679,
    z_score: -0.1444761991,
  },
  {
    percentile: 16.861826698,
    z_score: -0.1444354622,
  },
  {
    percentile: 16.978922717,
    z_score: -0.1444305262,
  },
  {
    percentile: 17.096018735,
    z_score: -0.1443879991,
  },
  {
    percentile: 17.213114754,
    z_score: -0.1443338275,
  },
  {
    percentile: 17.330210773,
    z_score: -0.1443253494,
  },
  {
    percentile: 17.447306792,
    z_score: -0.1443159422,
  },
  {
    percentile: 17.56440281,
    z_score: -0.1442978764,
  },
  {
    percentile: 17.681498829,
    z_score: -0.1442867044,
  },
  {
    percentile: 17.798594848,
    z_score: -0.1442478989,
  },
  {
    percentile: 17.915690867,
    z_score: -0.1441790235,
  },
  {
    percentile: 18.032786885,
    z_score: -0.1441668042,
  },
  {
    percentile: 18.149882904,
    z_score: -0.1441632876,
  },
  {
    percentile: 18.266978923,
    z_score: -0.1441608758,
  },
  {
    percentile: 18.384074941,
    z_score: -0.1441281472,
  },
  {
    percentile: 18.50117096,
    z_score: -0.1441078508,
  },
  {
    percentile: 18.618266979,
    z_score: -0.144099057,
  },
  {
    percentile: 18.735362998,
    z_score: -0.144081548,
  },
  {
    percentile: 18.852459016,
    z_score: -0.1440810388,
  },
  {
    percentile: 18.969555035,
    z_score: -0.1440685126,
  },
  {
    percentile: 19.086651054,
    z_score: -0.1440647173,
  },
  {
    percentile: 19.203747073,
    z_score: -0.1440576112,
  },
  {
    percentile: 19.320843091,
    z_score: -0.1440474404,
  },
  {
    percentile: 19.43793911,
    z_score: -0.1440419436,
  },
  {
    percentile: 19.555035129,
    z_score: -0.1440413613,
  },
  {
    percentile: 19.672131148,
    z_score: -0.1440397705,
  },
  {
    percentile: 19.789227166,
    z_score: -0.1440384622,
  },
  {
    percentile: 19.906323185,
    z_score: -0.144030956,
  },
  {
    percentile: 20.023419204,
    z_score: -0.1440157696,
  },
  {
    percentile: 20.140515222,
    z_score: -0.1439995736,
  },
  {
    percentile: 20.257611241,
    z_score: -0.143993318,
  },
  {
    percentile: 20.37470726,
    z_score: -0.143962397,
  },
  {
    percentile: 20.491803279,
    z_score: -0.1439617076,
  },
  {
    percentile: 20.608899297,
    z_score: -0.1439586828,
  },
  {
    percentile: 20.725995316,
    z_score: -0.1439199306,
  },
  {
    percentile: 20.843091335,
    z_score: -0.1439111309,
  },
  {
    percentile: 20.960187354,
    z_score: -0.1438489521,
  },
  {
    percentile: 21.077283372,
    z_score: -0.1438468819,
  },
  {
    percentile: 21.194379391,
    z_score: -0.143825053,
  },
  {
    percentile: 21.31147541,
    z_score: -0.1437996569,
  },
  {
    percentile: 21.428571429,
    z_score: -0.1437940717,
  },
  {
    percentile: 21.545667447,
    z_score: -0.1437864527,
  },
  {
    percentile: 21.662763466,
    z_score: -0.1437324447,
  },
  {
    percentile: 21.779859485,
    z_score: -0.1436846102,
  },
  {
    percentile: 21.896955504,
    z_score: -0.1436712142,
  },
  {
    percentile: 22.014051522,
    z_score: -0.1436547721,
  },
  {
    percentile: 22.131147541,
    z_score: -0.1436231732,
  },
  {
    percentile: 22.24824356,
    z_score: -0.1435922575,
  },
  {
    percentile: 22.365339578,
    z_score: -0.1435594316,
  },
  {
    percentile: 22.482435597,
    z_score: -0.1435347271,
  },
  {
    percentile: 22.599531616,
    z_score: -0.1435336699,
  },
  {
    percentile: 22.716627635,
    z_score: -0.1435223657,
  },
  {
    percentile: 22.833723653,
    z_score: -0.1435083132,
  },
  {
    percentile: 22.950819672,
    z_score: -0.1435054969,
  },
  {
    percentile: 23.067915691,
    z_score: -0.1434621402,
  },
  {
    percentile: 23.18501171,
    z_score: -0.1434576013,
  },
  {
    percentile: 23.302107728,
    z_score: -0.1434164872,
  },
  {
    percentile: 23.419203747,
    z_score: -0.1433805667,
  },
  {
    percentile: 23.536299766,
    z_score: -0.1433721217,
  },
  {
    percentile: 23.653395785,
    z_score: -0.1433622411,
  },
  {
    percentile: 23.770491803,
    z_score: -0.1433406777,
  },
  {
    percentile: 23.887587822,
    z_score: -0.1433383771,
  },
  {
    percentile: 24.004683841,
    z_score: -0.1433189131,
  },
  {
    percentile: 24.121779859,
    z_score: -0.1433043449,
  },
  {
    percentile: 24.238875878,
    z_score: -0.1433030493,
  },
  {
    percentile: 24.355971897,
    z_score: -0.1432845429,
  },
  {
    percentile: 24.473067916,
    z_score: -0.1432563637,
  },
  {
    percentile: 24.590163934,
    z_score: -0.1432552462,
  },
  {
    percentile: 24.707259953,
    z_score: -0.1432384531,
  },
  {
    percentile: 24.824355972,
    z_score: -0.1432257425,
  },
  {
    percentile: 24.941451991,
    z_score: -0.1432011638,
  },
  {
    percentile: 25.058548009,
    z_score: -0.1431897966,
  },
  {
    percentile: 25.175644028,
    z_score: -0.1431492861,
  },
  {
    percentile: 25.292740047,
    z_score: -0.1431486375,
  },
  {
    percentile: 25.409836066,
    z_score: -0.1431290765,
  },
  {
    percentile: 25.526932084,
    z_score: -0.1431180486,
  },
  {
    percentile: 25.644028103,
    z_score: -0.1430974311,
  },
  {
    percentile: 25.761124122,
    z_score: -0.1430873092,
  },
  {
    percentile: 25.878220141,
    z_score: -0.1430462652,
  },
  {
    percentile: 25.995316159,
    z_score: -0.1429479742,
  },
  {
    percentile: 26.112412178,
    z_score: -0.1429466044,
  },
  {
    percentile: 26.229508197,
    z_score: -0.142933828,
  },
  {
    percentile: 26.346604215,
    z_score: -0.1429291221,
  },
  {
    percentile: 26.463700234,
    z_score: -0.142923217,
  },
  {
    percentile: 26.580796253,
    z_score: -0.1428920952,
  },
  {
    percentile: 26.697892272,
    z_score: -0.1428575246,
  },
  {
    percentile: 26.81498829,
    z_score: -0.1428194583,
  },
  {
    percentile: 26.932084309,
    z_score: -0.1428164683,
  },
  {
    percentile: 27.049180328,
    z_score: -0.1428006029,
  },
  {
    percentile: 27.166276347,
    z_score: -0.1427574979,
  },
  {
    percentile: 27.283372365,
    z_score: -0.142753877,
  },
  {
    percentile: 27.400468384,
    z_score: -0.1427445326,
  },
  {
    percentile: 27.517564403,
    z_score: -0.1427362586,
  },
  {
    percentile: 27.634660422,
    z_score: -0.1426983506,
  },
  {
    percentile: 27.75175644,
    z_score: -0.1426684444,
  },
  {
    percentile: 27.868852459,
    z_score: -0.1426621242,
  },
  {
    percentile: 27.985948478,
    z_score: -0.1426481893,
  },
  {
    percentile: 28.103044496,
    z_score: -0.142626315,
  },
  {
    percentile: 28.220140515,
    z_score: -0.1425372657,
  },
  {
    percentile: 28.337236534,
    z_score: -0.1425232883,
  },
  {
    percentile: 28.454332553,
    z_score: -0.1425210827,
  },
  {
    percentile: 28.571428571,
    z_score: -0.1425004017,
  },
  {
    percentile: 28.68852459,
    z_score: -0.1423950202,
  },
  {
    percentile: 28.805620609,
    z_score: -0.1423862905,
  },
  {
    percentile: 28.922716628,
    z_score: -0.1423079797,
  },
  {
    percentile: 29.039812646,
    z_score: -0.1422702052,
  },
  {
    percentile: 29.156908665,
    z_score: -0.1422644682,
  },
  {
    percentile: 29.274004684,
    z_score: -0.1422634507,
  },
  {
    percentile: 29.391100703,
    z_score: -0.1422515308,
  },
  {
    percentile: 29.508196721,
    z_score: -0.1422385114,
  },
  {
    percentile: 29.62529274,
    z_score: -0.1422080119,
  },
  {
    percentile: 29.742388759,
    z_score: -0.1421828612,
  },
  {
    percentile: 29.859484778,
    z_score: -0.1421120969,
  },
  {
    percentile: 29.976580796,
    z_score: -0.1420716935,
  },
  {
    percentile: 30.093676815,
    z_score: -0.1419973097,
  },
  {
    percentile: 30.210772834,
    z_score: -0.1419916708,
  },
  {
    percentile: 30.327868852,
    z_score: -0.1419081337,
  },
  {
    percentile: 30.444964871,
    z_score: -0.141896783,
  },
  {
    percentile: 30.56206089,
    z_score: -0.141885211,
  },
  {
    percentile: 30.679156909,
    z_score: -0.141771699,
  },
  {
    percentile: 30.796252927,
    z_score: -0.1416608571,
  },
  {
    percentile: 30.913348946,
    z_score: -0.1416293774,
  },
  {
    percentile: 31.030444965,
    z_score: -0.1414541229,
  },
  {
    percentile: 31.147540984,
    z_score: -0.141402568,
  },
  {
    percentile: 31.264637002,
    z_score: -0.1413522228,
  },
  {
    percentile: 31.381733021,
    z_score: -0.1413496204,
  },
  {
    percentile: 31.49882904,
    z_score: -0.1410003521,
  },
  {
    percentile: 31.615925059,
    z_score: -0.1409315964,
  },
  {
    percentile: 31.733021077,
    z_score: -0.1407265938,
  },
  {
    percentile: 31.850117096,
    z_score: -0.1406212199,
  },
  {
    percentile: 31.967213115,
    z_score: -0.1404859915,
  },
  {
    percentile: 32.084309133,
    z_score: -0.1404800323,
  },
  {
    percentile: 32.201405152,
    z_score: -0.1404480658,
  },
  {
    percentile: 32.318501171,
    z_score: -0.1403927567,
  },
  {
    percentile: 32.43559719,
    z_score: -0.1400121952,
  },
  {
    percentile: 32.552693208,
    z_score: -0.1399947706,
  },
  {
    percentile: 32.669789227,
    z_score: -0.1397979601,
  },
  {
    percentile: 32.786885246,
    z_score: -0.139457028,
  },
  {
    percentile: 32.903981265,
    z_score: -0.1394124212,
  },
  {
    percentile: 33.021077283,
    z_score: -0.1389542282,
  },
  {
    percentile: 33.138173302,
    z_score: -0.1387668789,
  },
  {
    percentile: 33.255269321,
    z_score: -0.1386807995,
  },
  {
    percentile: 33.37236534,
    z_score: -0.1383593353,
  },
  {
    percentile: 33.489461358,
    z_score: -0.1383467774,
  },
  {
    percentile: 33.606557377,
    z_score: -0.1383370225,
  },
  {
    percentile: 33.723653396,
    z_score: -0.1381188134,
  },
  {
    percentile: 33.840749415,
    z_score: -0.1379454656,
  },
  {
    percentile: 33.957845433,
    z_score: -0.1378853232,
  },
  {
    percentile: 34.074941452,
    z_score: -0.1378583193,
  },
  {
    percentile: 34.192037471,
    z_score: -0.1377918643,
  },
  {
    percentile: 34.309133489,
    z_score: -0.1377529209,
  },
  {
    percentile: 34.426229508,
    z_score: -0.1377502584,
  },
  {
    percentile: 34.543325527,
    z_score: -0.1375114432,
  },
  {
    percentile: 34.660421546,
    z_score: -0.1373990096,
  },
  {
    percentile: 34.777517564,
    z_score: -0.1372162308,
  },
  {
    percentile: 34.894613583,
    z_score: -0.1370068977,
  },
  {
    percentile: 35.011709602,
    z_score: -0.1369155154,
  },
  {
    percentile: 35.128805621,
    z_score: -0.1368597399,
  },
  {
    percentile: 35.245901639,
    z_score: -0.136753388,
  },
  {
    percentile: 35.362997658,
    z_score: -0.1367329067,
  },
  {
    percentile: 35.480093677,
    z_score: -0.1367171596,
  },
  {
    percentile: 35.597189696,
    z_score: -0.1366310514,
  },
  {
    percentile: 35.714285714,
    z_score: -0.1361268289,
  },
  {
    percentile: 35.831381733,
    z_score: -0.1360315054,
  },
  {
    percentile: 35.948477752,
    z_score: -0.1357708536,
  },
  {
    percentile: 36.06557377,
    z_score: -0.1357385787,
  },
  {
    percentile: 36.182669789,
    z_score: -0.1355021361,
  },
  {
    percentile: 36.299765808,
    z_score: -0.1348994321,
  },
  {
    percentile: 36.416861827,
    z_score: -0.13483546,
  },
  {
    percentile: 36.533957845,
    z_score: -0.1346138824,
  },
  {
    percentile: 36.651053864,
    z_score: -0.1346039942,
  },
  {
    percentile: 36.768149883,
    z_score: -0.1345966269,
  },
  {
    percentile: 36.885245902,
    z_score: -0.1345406198,
  },
  {
    percentile: 37.00234192,
    z_score: -0.1344449665,
  },
  {
    percentile: 37.119437939,
    z_score: -0.1344357874,
  },
  {
    percentile: 37.236533958,
    z_score: -0.134304277,
  },
  {
    percentile: 37.353629977,
    z_score: -0.1342105943,
  },
  {
    percentile: 37.470725995,
    z_score: -0.1341495546,
  },
  {
    percentile: 37.587822014,
    z_score: -0.1339274483,
  },
  {
    percentile: 37.704918033,
    z_score: -0.133683895,
  },
  {
    percentile: 37.822014052,
    z_score: -0.1334661144,
  },
  {
    percentile: 37.93911007,
    z_score: -0.1332171126,
  },
  {
    percentile: 38.056206089,
    z_score: -0.1330361599,
  },
  {
    percentile: 38.173302108,
    z_score: -0.133030628,
  },
  {
    percentile: 38.290398126,
    z_score: -0.1330217977,
  },
  {
    percentile: 38.407494145,
    z_score: -0.133002067,
  },
  {
    percentile: 38.524590164,
    z_score: -0.132614732,
  },
  {
    percentile: 38.641686183,
    z_score: -0.1325638784,
  },
  {
    percentile: 38.758782201,
    z_score: -0.1324659795,
  },
  {
    percentile: 38.87587822,
    z_score: -0.1323531861,
  },
  {
    percentile: 38.992974239,
    z_score: -0.1321366807,
  },
  {
    percentile: 39.110070258,
    z_score: -0.1320030416,
  },
  {
    percentile: 39.227166276,
    z_score: -0.1319813734,
  },
  {
    percentile: 39.344262295,
    z_score: -0.1319685842,
  },
  {
    percentile: 39.461358314,
    z_score: -0.1317986215,
  },
  {
    percentile: 39.578454333,
    z_score: -0.1317254383,
  },
  {
    percentile: 39.695550351,
    z_score: -0.1315391028,
  },
  {
    percentile: 39.81264637,
    z_score: -0.1312265146,
  },
  {
    percentile: 39.929742389,
    z_score: -0.1307177279,
  },
  {
    percentile: 40.046838407,
    z_score: -0.1306386845,
  },
  {
    percentile: 40.163934426,
    z_score: -0.130628891,
  },
  {
    percentile: 40.281030445,
    z_score: -0.1303583917,
  },
  {
    percentile: 40.398126464,
    z_score: -0.130289902,
  },
  {
    percentile: 40.515222482,
    z_score: -0.1302600787,
  },
  {
    percentile: 40.632318501,
    z_score: -0.1302551495,
  },
  {
    percentile: 40.74941452,
    z_score: -0.1299429615,
  },
  {
    percentile: 40.866510539,
    z_score: -0.1298899351,
  },
  {
    percentile: 40.983606557,
    z_score: -0.1297834337,
  },
  {
    percentile: 41.100702576,
    z_score: -0.129783102,
  },
  {
    percentile: 41.217798595,
    z_score: -0.1296619142,
  },
  {
    percentile: 41.334894614,
    z_score: -0.1294021778,
  },
  {
    percentile: 41.451990632,
    z_score: -0.1292658269,
  },
  {
    percentile: 41.569086651,
    z_score: -0.1292513591,
  },
  {
    percentile: 41.68618267,
    z_score: -0.1291280529,
  },
  {
    percentile: 41.803278689,
    z_score: -0.1290900983,
  },
  {
    percentile: 41.920374707,
    z_score: -0.1290272685,
  },
  {
    percentile: 42.037470726,
    z_score: -0.12896263,
  },
  {
    percentile: 42.154566745,
    z_score: -0.1288402487,
  },
  {
    percentile: 42.271662763,
    z_score: -0.1287323213,
  },
  {
    percentile: 42.388758782,
    z_score: -0.1286875975,
  },
  {
    percentile: 42.505854801,
    z_score: -0.1284717003,
  },
  {
    percentile: 42.62295082,
    z_score: -0.1284063853,
  },
  {
    percentile: 42.740046838,
    z_score: -0.1282918711,
  },
  {
    percentile: 42.857142857,
    z_score: -0.1282880626,
  },
  {
    percentile: 42.974238876,
    z_score: -0.1282041134,
  },
  {
    percentile: 43.091334895,
    z_score: -0.1280275851,
  },
  {
    percentile: 43.208430913,
    z_score: -0.127608498,
  },
  {
    percentile: 43.325526932,
    z_score: -0.1274314155,
  },
  {
    percentile: 43.442622951,
    z_score: -0.1274255715,
  },
  {
    percentile: 43.55971897,
    z_score: -0.1273646536,
  },
  {
    percentile: 43.676814988,
    z_score: -0.1272669531,
  },
  {
    percentile: 43.793911007,
    z_score: -0.1271361081,
  },
  {
    percentile: 43.911007026,
    z_score: -0.1265553791,
  },
  {
    percentile: 44.028103044,
    z_score: -0.1264937275,
  },
  {
    percentile: 44.145199063,
    z_score: -0.1264753314,
  },
  {
    percentile: 44.262295082,
    z_score: -0.1263825515,
  },
  {
    percentile: 44.379391101,
    z_score: -0.1262714938,
  },
  {
    percentile: 44.496487119,
    z_score: -0.1259783784,
  },
  {
    percentile: 44.613583138,
    z_score: -0.1258229801,
  },
  {
    percentile: 44.730679157,
    z_score: -0.1257034452,
  },
  {
    percentile: 44.847775176,
    z_score: -0.1256497719,
  },
  {
    percentile: 44.964871194,
    z_score: -0.1256406129,
  },
  {
    percentile: 45.081967213,
    z_score: -0.1255267883,
  },
  {
    percentile: 45.199063232,
    z_score: -0.1253922543,
  },
  {
    percentile: 45.316159251,
    z_score: -0.1253688863,
  },
  {
    percentile: 45.433255269,
    z_score: -0.1253172525,
  },
  {
    percentile: 45.550351288,
    z_score: -0.125185444,
  },
  {
    percentile: 45.667447307,
    z_score: -0.1247495796,
  },
  {
    percentile: 45.784543326,
    z_score: -0.1247462667,
  },
  {
    percentile: 45.901639344,
    z_score: -0.1246889396,
  },
  {
    percentile: 46.018735363,
    z_score: -0.1245922232,
  },
  {
    percentile: 46.135831382,
    z_score: -0.1245299465,
  },
  {
    percentile: 46.2529274,
    z_score: -0.1244853805,
  },
  {
    percentile: 46.370023419,
    z_score: -0.1244015794,
  },
  {
    percentile: 46.487119438,
    z_score: -0.1241576565,
  },
  {
    percentile: 46.604215457,
    z_score: -0.1240392701,
  },
  {
    percentile: 46.721311475,
    z_score: -0.1239001849,
  },
  {
    percentile: 46.838407494,
    z_score: -0.1238669353,
  },
  {
    percentile: 46.955503513,
    z_score: -0.1238336609,
  },
  {
    percentile: 47.072599532,
    z_score: -0.1236777451,
  },
  {
    percentile: 47.18969555,
    z_score: -0.1234847098,
  },
  {
    percentile: 47.306791569,
    z_score: -0.1234706474,
  },
  {
    percentile: 47.423887588,
    z_score: -0.1233187206,
  },
  {
    percentile: 47.540983607,
    z_score: -0.1233049106,
  },
  {
    percentile: 47.658079625,
    z_score: -0.1231302511,
  },
  {
    percentile: 47.775175644,
    z_score: -0.1230825382,
  },
  {
    percentile: 47.892271663,
    z_score: -0.1230193444,
  },
  {
    percentile: 48.009367681,
    z_score: -0.1227966164,
  },
  {
    percentile: 48.1264637,
    z_score: -0.1227593897,
  },
  {
    percentile: 48.243559719,
    z_score: -0.1225259207,
  },
  {
    percentile: 48.360655738,
    z_score: -0.1225244198,
  },
  {
    percentile: 48.477751756,
    z_score: -0.1223825091,
  },
  {
    percentile: 48.594847775,
    z_score: -0.1222912914,
  },
  {
    percentile: 48.711943794,
    z_score: -0.1221658701,
  },
  {
    percentile: 48.829039813,
    z_score: -0.1221273234,
  },
  {
    percentile: 48.946135831,
    z_score: -0.122086932,
  },
  {
    percentile: 49.06323185,
    z_score: -0.1217858282,
  },
  {
    percentile: 49.180327869,
    z_score: -0.1215979831,
  },
  {
    percentile: 49.297423888,
    z_score: -0.1214620267,
  },
  {
    percentile: 49.414519906,
    z_score: -0.1214585636,
  },
  {
    percentile: 49.531615925,
    z_score: -0.1212310735,
  },
  {
    percentile: 49.648711944,
    z_score: -0.1208007654,
  },
  {
    percentile: 49.765807963,
    z_score: -0.1207382232,
  },
  {
    percentile: 49.882903981,
    z_score: -0.1204878407,
  },
  {
    percentile: 50,
    z_score: -0.1202254383,
  },
  {
    percentile: 50.117096019,
    z_score: -0.1200923678,
  },
  {
    percentile: 50.234192037,
    z_score: -0.1199873424,
  },
  {
    percentile: 50.351288056,
    z_score: -0.1199584233,
  },
  {
    percentile: 50.468384075,
    z_score: -0.1198075638,
  },
  {
    percentile: 50.585480094,
    z_score: -0.1196834327,
  },
  {
    percentile: 50.702576112,
    z_score: -0.1195729696,
  },
  {
    percentile: 50.819672131,
    z_score: -0.1193975893,
  },
  {
    percentile: 50.93676815,
    z_score: -0.1192643046,
  },
  {
    percentile: 51.053864169,
    z_score: -0.1191048617,
  },
  {
    percentile: 51.170960187,
    z_score: -0.1190638628,
  },
  {
    percentile: 51.288056206,
    z_score: -0.119038629,
  },
  {
    percentile: 51.405152225,
    z_score: -0.1188413918,
  },
  {
    percentile: 51.522248244,
    z_score: -0.1186928009,
  },
  {
    percentile: 51.639344262,
    z_score: -0.1185195352,
  },
  {
    percentile: 51.756440281,
    z_score: -0.1184334731,
  },
  {
    percentile: 51.8735363,
    z_score: -0.1183918953,
  },
  {
    percentile: 51.990632319,
    z_score: -0.1183866078,
  },
  {
    percentile: 52.107728337,
    z_score: -0.1183446637,
  },
  {
    percentile: 52.224824356,
    z_score: -0.118312061,
  },
  {
    percentile: 52.341920375,
    z_score: -0.1182978128,
  },
  {
    percentile: 52.459016393,
    z_score: -0.118060711,
  },
  {
    percentile: 52.576112412,
    z_score: -0.1180129185,
  },
  {
    percentile: 52.693208431,
    z_score: -0.1175413516,
  },
  {
    percentile: 52.81030445,
    z_score: -0.1175412565,
  },
  {
    percentile: 52.927400468,
    z_score: -0.1173540159,
  },
  {
    percentile: 53.044496487,
    z_score: -0.1173292412,
  },
  {
    percentile: 53.161592506,
    z_score: -0.1171760199,
  },
  {
    percentile: 53.278688525,
    z_score: -0.117081649,
  },
  {
    percentile: 53.395784543,
    z_score: -0.1170242669,
  },
  {
    percentile: 53.512880562,
    z_score: -0.116869381,
  },
  {
    percentile: 53.629976581,
    z_score: -0.1166294684,
  },
  {
    percentile: 53.7470726,
    z_score: -0.1166134799,
  },
  {
    percentile: 53.864168618,
    z_score: -0.1165460598,
  },
  {
    percentile: 53.981264637,
    z_score: -0.1165405468,
  },
  {
    percentile: 54.098360656,
    z_score: -0.1163828976,
  },
  {
    percentile: 54.215456674,
    z_score: -0.1162869204,
  },
  {
    percentile: 54.332552693,
    z_score: -0.1160753353,
  },
  {
    percentile: 54.449648712,
    z_score: -0.1159503848,
  },
  {
    percentile: 54.566744731,
    z_score: -0.1158768839,
  },
  {
    percentile: 54.683840749,
    z_score: -0.115857215,
  },
  {
    percentile: 54.800936768,
    z_score: -0.115818227,
  },
  {
    percentile: 54.918032787,
    z_score: -0.1157144272,
  },
  {
    percentile: 55.035128806,
    z_score: -0.1156743564,
  },
  {
    percentile: 55.152224824,
    z_score: -0.1153855054,
  },
  {
    percentile: 55.269320843,
    z_score: -0.115371615,
  },
  {
    percentile: 55.386416862,
    z_score: -0.1153202979,
  },
  {
    percentile: 55.503512881,
    z_score: -0.1152104534,
  },
  {
    percentile: 55.620608899,
    z_score: -0.115071451,
  },
  {
    percentile: 55.737704918,
    z_score: -0.1148264412,
  },
  {
    percentile: 55.854800937,
    z_score: -0.1145458984,
  },
  {
    percentile: 55.971896956,
    z_score: -0.1145082355,
  },
  {
    percentile: 56.088992974,
    z_score: -0.1143712733,
  },
  {
    percentile: 56.206088993,
    z_score: -0.1139539182,
  },
  {
    percentile: 56.323185012,
    z_score: -0.11385436,
  },
  {
    percentile: 56.44028103,
    z_score: -0.1133032027,
  },
  {
    percentile: 56.557377049,
    z_score: -0.1132375898,
  },
  {
    percentile: 56.674473068,
    z_score: -0.1131348062,
  },
  {
    percentile: 56.791569087,
    z_score: -0.1128487388,
  },
  {
    percentile: 56.908665105,
    z_score: -0.1128302011,
  },
  {
    percentile: 57.025761124,
    z_score: -0.1125946024,
  },
  {
    percentile: 57.142857143,
    z_score: -0.1125032839,
  },
  {
    percentile: 57.259953162,
    z_score: -0.1117711691,
  },
  {
    percentile: 57.37704918,
    z_score: -0.1116957536,
  },
  {
    percentile: 57.494145199,
    z_score: -0.1105637319,
  },
  {
    percentile: 57.611241218,
    z_score: -0.1096994304,
  },
  {
    percentile: 57.728337237,
    z_score: -0.1088646593,
  },
  {
    percentile: 57.845433255,
    z_score: -0.1084862555,
  },
  {
    percentile: 57.962529274,
    z_score: -0.108447888,
  },
  {
    percentile: 58.079625293,
    z_score: -0.1083114032,
  },
  {
    percentile: 58.196721311,
    z_score: -0.1081094277,
  },
  {
    percentile: 58.31381733,
    z_score: -0.1077287722,
  },
  {
    percentile: 58.430913349,
    z_score: -0.1076128683,
  },
  {
    percentile: 58.548009368,
    z_score: -0.1074199806,
  },
  {
    percentile: 58.665105386,
    z_score: -0.1073692036,
  },
  {
    percentile: 58.782201405,
    z_score: -0.1063932999,
  },
  {
    percentile: 58.899297424,
    z_score: -0.1061381587,
  },
  {
    percentile: 59.016393443,
    z_score: -0.1061132658,
  },
  {
    percentile: 59.133489461,
    z_score: -0.1056743497,
  },
  {
    percentile: 59.25058548,
    z_score: -0.1055086175,
  },
  {
    percentile: 59.367681499,
    z_score: -0.1049574513,
  },
  {
    percentile: 59.484777518,
    z_score: -0.1048700558,
  },
  {
    percentile: 59.601873536,
    z_score: -0.1046543785,
  },
  {
    percentile: 59.718969555,
    z_score: -0.1038043745,
  },
  {
    percentile: 59.836065574,
    z_score: -0.1037452681,
  },
  {
    percentile: 59.953161593,
    z_score: -0.1036322805,
  },
  {
    percentile: 60.070257611,
    z_score: -0.103096029,
  },
  {
    percentile: 60.18735363,
    z_score: -0.1029292465,
  },
  {
    percentile: 60.304449649,
    z_score: -0.1028370385,
  },
  {
    percentile: 60.421545667,
    z_score: -0.1026255262,
  },
  {
    percentile: 60.538641686,
    z_score: -0.1023431169,
  },
  {
    percentile: 60.655737705,
    z_score: -0.1022510882,
  },
  {
    percentile: 60.772833724,
    z_score: -0.1022149253,
  },
  {
    percentile: 60.889929742,
    z_score: -0.1020318107,
  },
  {
    percentile: 61.007025761,
    z_score: -0.1019780044,
  },
  {
    percentile: 61.12412178,
    z_score: -0.1018090087,
  },
  {
    percentile: 61.241217799,
    z_score: -0.1017283806,
  },
  {
    percentile: 61.358313817,
    z_score: -0.1013937103,
  },
  {
    percentile: 61.475409836,
    z_score: -0.1013584756,
  },
  {
    percentile: 61.592505855,
    z_score: -0.1010604581,
  },
  {
    percentile: 61.709601874,
    z_score: -0.1005636743,
  },
  {
    percentile: 61.826697892,
    z_score: -0.1002588232,
  },
  {
    percentile: 61.943793911,
    z_score: -0.1002217972,
  },
  {
    percentile: 62.06088993,
    z_score: -0.1001626589,
  },
  {
    percentile: 62.177985948,
    z_score: -0.09968563508,
  },
  {
    percentile: 62.295081967,
    z_score: -0.09944242303,
  },
  {
    percentile: 62.412177986,
    z_score: -0.09891323573,
  },
  {
    percentile: 62.529274005,
    z_score: -0.09859608516,
  },
  {
    percentile: 62.646370023,
    z_score: -0.09854143998,
  },
  {
    percentile: 62.763466042,
    z_score: -0.09823863414,
  },
  {
    percentile: 62.880562061,
    z_score: -0.09809115705,
  },
  {
    percentile: 62.99765808,
    z_score: -0.09631640011,
  },
  {
    percentile: 63.114754098,
    z_score: -0.0957790827,
  },
  {
    percentile: 63.231850117,
    z_score: -0.09523115906,
  },
  {
    percentile: 63.348946136,
    z_score: -0.09522203147,
  },
  {
    percentile: 63.466042155,
    z_score: -0.09513757611,
  },
  {
    percentile: 63.583138173,
    z_score: -0.09500423246,
  },
  {
    percentile: 63.700234192,
    z_score: -0.09491069466,
  },
  {
    percentile: 63.817330211,
    z_score: -0.09398277096,
  },
  {
    percentile: 63.93442623,
    z_score: -0.09384281622,
  },
  {
    percentile: 64.051522248,
    z_score: -0.09331751094,
  },
  {
    percentile: 64.168618267,
    z_score: -0.09317790255,
  },
  {
    percentile: 64.285714286,
    z_score: -0.09268074859,
  },
  {
    percentile: 64.402810304,
    z_score: -0.092444301,
  },
  {
    percentile: 64.519906323,
    z_score: -0.09218617769,
  },
  {
    percentile: 64.637002342,
    z_score: -0.09175098966,
  },
  {
    percentile: 64.754098361,
    z_score: -0.09134014476,
  },
  {
    percentile: 64.871194379,
    z_score: -0.09071250063,
  },
  {
    percentile: 64.988290398,
    z_score: -0.08988263328,
  },
  {
    percentile: 65.105386417,
    z_score: -0.08911331137,
  },
  {
    percentile: 65.222482436,
    z_score: -0.08901248421,
  },
  {
    percentile: 65.339578454,
    z_score: -0.08892370564,
  },
  {
    percentile: 65.456674473,
    z_score: -0.08822743209,
  },
  {
    percentile: 65.573770492,
    z_score: -0.08782936308,
  },
  {
    percentile: 65.690866511,
    z_score: -0.08781368055,
  },
  {
    percentile: 65.807962529,
    z_score: -0.08769338661,
  },
  {
    percentile: 65.925058548,
    z_score: -0.08683648977,
  },
  {
    percentile: 66.042154567,
    z_score: -0.08646929422,
  },
  {
    percentile: 66.159250585,
    z_score: -0.08637043478,
  },
  {
    percentile: 66.276346604,
    z_score: -0.08594809403,
  },
  {
    percentile: 66.393442623,
    z_score: -0.08584835754,
  },
  {
    percentile: 66.510538642,
    z_score: -0.0856625165,
  },
  {
    percentile: 66.62763466,
    z_score: -0.08543701134,
  },
  {
    percentile: 66.744730679,
    z_score: -0.08518705722,
  },
  {
    percentile: 66.861826698,
    z_score: -0.08502856748,
  },
  {
    percentile: 66.978922717,
    z_score: -0.08458492471,
  },
  {
    percentile: 67.096018735,
    z_score: -0.08453232148,
  },
  {
    percentile: 67.213114754,
    z_score: -0.08412208135,
  },
  {
    percentile: 67.330210773,
    z_score: -0.08401870901,
  },
  {
    percentile: 67.447306792,
    z_score: -0.08320483931,
  },
  {
    percentile: 67.56440281,
    z_score: -0.08307158927,
  },
  {
    percentile: 67.681498829,
    z_score: -0.08302881388,
  },
  {
    percentile: 67.798594848,
    z_score: -0.08257452436,
  },
  {
    percentile: 67.915690867,
    z_score: -0.08234780428,
  },
  {
    percentile: 68.032786885,
    z_score: -0.08230609255,
  },
  {
    percentile: 68.149882904,
    z_score: -0.08222206628,
  },
  {
    percentile: 68.266978923,
    z_score: -0.08214727096,
  },
  {
    percentile: 68.384074941,
    z_score: -0.08160830986,
  },
  {
    percentile: 68.50117096,
    z_score: -0.08086846908,
  },
  {
    percentile: 68.618266979,
    z_score: -0.08071609503,
  },
  {
    percentile: 68.735362998,
    z_score: -0.0805446102,
  },
  {
    percentile: 68.852459016,
    z_score: -0.07986859866,
  },
  {
    percentile: 68.969555035,
    z_score: -0.07915206067,
  },
  {
    percentile: 69.086651054,
    z_score: -0.07891217332,
  },
  {
    percentile: 69.203747073,
    z_score: -0.0788724064,
  },
  {
    percentile: 69.320843091,
    z_score: -0.07699897159,
  },
  {
    percentile: 69.43793911,
    z_score: -0.07645573167,
  },
  {
    percentile: 69.555035129,
    z_score: -0.07623258949,
  },
  {
    percentile: 69.672131148,
    z_score: -0.07593709738,
  },
  {
    percentile: 69.789227166,
    z_score: -0.0757425345,
  },
  {
    percentile: 69.906323185,
    z_score: -0.07525009582,
  },
  {
    percentile: 70.023419204,
    z_score: -0.07510903938,
  },
  {
    percentile: 70.140515222,
    z_score: -0.074856833,
  },
  {
    percentile: 70.257611241,
    z_score: -0.07151748699,
  },
  {
    percentile: 70.37470726,
    z_score: -0.07071106372,
  },
  {
    percentile: 70.491803279,
    z_score: -0.0700714681,
  },
  {
    percentile: 70.608899297,
    z_score: -0.06929077418,
  },
  {
    percentile: 70.725995316,
    z_score: -0.0690355299,
  },
  {
    percentile: 70.843091335,
    z_score: -0.06861089581,
  },
  {
    percentile: 70.960187354,
    z_score: -0.06841168676,
  },
  {
    percentile: 71.077283372,
    z_score: -0.06770753329,
  },
  {
    percentile: 71.194379391,
    z_score: -0.06763690934,
  },
  {
    percentile: 71.31147541,
    z_score: -0.06731033433,
  },
  {
    percentile: 71.428571429,
    z_score: -0.06720228154,
  },
  {
    percentile: 71.545667447,
    z_score: -0.06681017866,
  },
  {
    percentile: 71.662763466,
    z_score: -0.0667023082,
  },
  {
    percentile: 71.779859485,
    z_score: -0.0663308669,
  },
  {
    percentile: 71.896955504,
    z_score: -0.06625815678,
  },
  {
    percentile: 72.014051522,
    z_score: -0.06574857743,
  },
  {
    percentile: 72.131147541,
    z_score: -0.06509843422,
  },
  {
    percentile: 72.24824356,
    z_score: -0.06507613743,
  },
  {
    percentile: 72.365339578,
    z_score: -0.0643074889,
  },
  {
    percentile: 72.482435597,
    z_score: -0.06390622435,
  },
  {
    percentile: 72.599531616,
    z_score: -0.06380862641,
  },
  {
    percentile: 72.716627635,
    z_score: -0.06234831516,
  },
  {
    percentile: 72.833723653,
    z_score: -0.06218583753,
  },
  {
    percentile: 72.950819672,
    z_score: -0.06214757005,
  },
  {
    percentile: 73.067915691,
    z_score: -0.06062917558,
  },
  {
    percentile: 73.18501171,
    z_score: -0.06057635714,
  },
  {
    percentile: 73.302107728,
    z_score: -0.06055515136,
  },
  {
    percentile: 73.419203747,
    z_score: -0.05975643692,
  },
  {
    percentile: 73.536299766,
    z_score: -0.05861925179,
  },
  {
    percentile: 73.653395785,
    z_score: -0.05742359728,
  },
  {
    percentile: 73.770491803,
    z_score: -0.05680486934,
  },
  {
    percentile: 73.887587822,
    z_score: -0.05671345841,
  },
  {
    percentile: 74.004683841,
    z_score: -0.05510478999,
  },
  {
    percentile: 74.121779859,
    z_score: -0.05368827328,
  },
  {
    percentile: 74.238875878,
    z_score: -0.05333692803,
  },
  {
    percentile: 74.355971897,
    z_score: -0.05307590913,
  },
  {
    percentile: 74.473067916,
    z_score: -0.05205184644,
  },
  {
    percentile: 74.590163934,
    z_score: -0.05202010016,
  },
  {
    percentile: 74.707259953,
    z_score: -0.05140727806,
  },
  {
    percentile: 74.824355972,
    z_score: -0.05057146541,
  },
  {
    percentile: 74.941451991,
    z_score: -0.04993456783,
  },
  {
    percentile: 75.058548009,
    z_score: -0.0498734559,
  },
  {
    percentile: 75.175644028,
    z_score: -0.04786767882,
  },
  {
    percentile: 75.292740047,
    z_score: -0.04751036948,
  },
  {
    percentile: 75.409836066,
    z_score: -0.04729074972,
  },
  {
    percentile: 75.526932084,
    z_score: -0.04683809903,
  },
  {
    percentile: 75.644028103,
    z_score: -0.04645553098,
  },
  {
    percentile: 75.761124122,
    z_score: -0.04512568078,
  },
  {
    percentile: 75.878220141,
    z_score: -0.04490970403,
  },
  {
    percentile: 75.995316159,
    z_score: -0.04335637784,
  },
  {
    percentile: 76.112412178,
    z_score: -0.0431731431,
  },
  {
    percentile: 76.229508197,
    z_score: -0.04146125454,
  },
  {
    percentile: 76.346604215,
    z_score: -0.04015964812,
  },
  {
    percentile: 76.463700234,
    z_score: -0.03826363006,
  },
  {
    percentile: 76.580796253,
    z_score: -0.0380439711,
  },
  {
    percentile: 76.697892272,
    z_score: -0.03694406285,
  },
  {
    percentile: 76.81498829,
    z_score: -0.0357654555,
  },
  {
    percentile: 76.932084309,
    z_score: -0.03571781524,
  },
  {
    percentile: 77.049180328,
    z_score: -0.03519707353,
  },
  {
    percentile: 77.166276347,
    z_score: -0.03494249129,
  },
  {
    percentile: 77.283372365,
    z_score: -0.03493574445,
  },
  {
    percentile: 77.400468384,
    z_score: -0.0342718005,
  },
  {
    percentile: 77.517564403,
    z_score: -0.03380942635,
  },
  {
    percentile: 77.634660422,
    z_score: -0.03372245762,
  },
  {
    percentile: 77.75175644,
    z_score: -0.0336907119,
  },
  {
    percentile: 77.868852459,
    z_score: -0.03347744395,
  },
  {
    percentile: 77.985948478,
    z_score: -0.03176384292,
  },
  {
    percentile: 78.103044496,
    z_score: -0.03098982842,
  },
  {
    percentile: 78.220140515,
    z_score: -0.03070547258,
  },
  {
    percentile: 78.337236534,
    z_score: -0.0296515235,
  },
  {
    percentile: 78.454332553,
    z_score: -0.02934670118,
  },
  {
    percentile: 78.571428571,
    z_score: -0.02904173668,
  },
  {
    percentile: 78.68852459,
    z_score: -0.02850984889,
  },
  {
    percentile: 78.805620609,
    z_score: -0.02619517748,
  },
  {
    percentile: 78.922716628,
    z_score: -0.02532290052,
  },
  {
    percentile: 79.039812646,
    z_score: -0.02212893379,
  },
  {
    percentile: 79.156908665,
    z_score: -0.02193371547,
  },
  {
    percentile: 79.274004684,
    z_score: -0.02038424589,
  },
  {
    percentile: 79.391100703,
    z_score: -0.02030751668,
  },
  {
    percentile: 79.508196721,
    z_score: -0.01912565353,
  },
  {
    percentile: 79.62529274,
    z_score: -0.01833831096,
  },
  {
    percentile: 79.742388759,
    z_score: -0.01798521087,
  },
  {
    percentile: 79.859484778,
    z_score: -0.01610854875,
  },
  {
    percentile: 79.976580796,
    z_score: -0.01590907119,
  },
  {
    percentile: 80.093676815,
    z_score: -0.0138230823,
  },
  {
    percentile: 80.210772834,
    z_score: -0.01377096474,
  },
  {
    percentile: 80.327868852,
    z_score: -0.01348760336,
  },
  {
    percentile: 80.444964871,
    z_score: -0.01126171818,
  },
  {
    percentile: 80.56206089,
    z_score: -0.01097350394,
  },
  {
    percentile: 80.679156909,
    z_score: -0.01042707207,
  },
  {
    percentile: 80.796252927,
    z_score: -0.008541109718,
  },
  {
    percentile: 80.913348946,
    z_score: -0.008512461809,
  },
  {
    percentile: 81.030444965,
    z_score: -0.00773645881,
  },
  {
    percentile: 81.147540984,
    z_score: -0.007039429653,
  },
  {
    percentile: 81.264637002,
    z_score: -0.005712539794,
  },
  {
    percentile: 81.381733021,
    z_score: -0.00426986432,
  },
  {
    percentile: 81.49882904,
    z_score: -0.003710083737,
  },
  {
    percentile: 81.615925059,
    z_score: -0.001115832957,
  },
  {
    percentile: 81.733021077,
    z_score: -0.000885199952,
  },
  {
    percentile: 81.850117096,
    z_score: -0.0007113525045,
  },
  {
    percentile: 81.967213115,
    z_score: -0.0003573637212,
  },
  {
    percentile: 82.084309133,
    z_score: 0.0003549582501,
  },
  {
    percentile: 82.201405152,
    z_score: 0.001774420665,
  },
  {
    percentile: 82.318501171,
    z_score: 0.002524915285,
  },
  {
    percentile: 82.43559719,
    z_score: 0.005341280044,
  },
  {
    percentile: 82.552693208,
    z_score: 0.005543141166,
  },
  {
    percentile: 82.669789227,
    z_score: 0.005638801448,
  },
  {
    percentile: 82.786885246,
    z_score: 0.005767166875,
  },
  {
    percentile: 82.903981265,
    z_score: 0.007413121761,
  },
  {
    percentile: 83.021077283,
    z_score: 0.007663869086,
  },
  {
    percentile: 83.138173302,
    z_score: 0.008590121695,
  },
  {
    percentile: 83.255269321,
    z_score: 0.009315941822,
  },
  {
    percentile: 83.37236534,
    z_score: 0.01051328556,
  },
  {
    percentile: 83.489461358,
    z_score: 0.01104788612,
  },
  {
    percentile: 83.606557377,
    z_score: 0.01476612204,
  },
  {
    percentile: 83.723653396,
    z_score: 0.01603540335,
  },
  {
    percentile: 83.840749415,
    z_score: 0.01891267562,
  },
  {
    percentile: 83.957845433,
    z_score: 0.0198416255,
  },
  {
    percentile: 84.074941452,
    z_score: 0.02301036369,
  },
  {
    percentile: 84.192037471,
    z_score: 0.02304981732,
  },
  {
    percentile: 84.309133489,
    z_score: 0.02464402126,
  },
  {
    percentile: 84.426229508,
    z_score: 0.02658493924,
  },
  {
    percentile: 84.543325527,
    z_score: 0.02849111105,
  },
  {
    percentile: 84.660421546,
    z_score: 0.02962183399,
  },
  {
    percentile: 84.777517564,
    z_score: 0.03654793519,
  },
  {
    percentile: 84.894613583,
    z_score: 0.03687036956,
  },
  {
    percentile: 85.011709602,
    z_score: 0.04089596328,
  },
  {
    percentile: 85.128805621,
    z_score: 0.04158387319,
  },
  {
    percentile: 85.245901639,
    z_score: 0.04230598,
  },
  {
    percentile: 85.362997658,
    z_score: 0.04236418235,
  },
  {
    percentile: 85.480093677,
    z_score: 0.04242079371,
  },
  {
    percentile: 85.597189696,
    z_score: 0.04589439161,
  },
  {
    percentile: 85.714285714,
    z_score: 0.04924363161,
  },
  {
    percentile: 85.831381733,
    z_score: 0.04977307398,
  },
  {
    percentile: 85.948477752,
    z_score: 0.04999797783,
  },
  {
    percentile: 86.06557377,
    z_score: 0.05326162181,
  },
  {
    percentile: 86.182669789,
    z_score: 0.05330628661,
  },
  {
    percentile: 86.299765808,
    z_score: 0.05365796026,
  },
  {
    percentile: 86.416861827,
    z_score: 0.05682130841,
  },
  {
    percentile: 86.533957845,
    z_score: 0.05819725168,
  },
  {
    percentile: 86.651053864,
    z_score: 0.05854396929,
  },
  {
    percentile: 86.768149883,
    z_score: 0.06022937906,
  },
  {
    percentile: 86.885245902,
    z_score: 0.06102808546,
  },
  {
    percentile: 87.00234192,
    z_score: 0.06264790018,
  },
  {
    percentile: 87.119437939,
    z_score: 0.06265206528,
  },
  {
    percentile: 87.236533958,
    z_score: 0.0632342171,
  },
  {
    percentile: 87.353629977,
    z_score: 0.06340448162,
  },
  {
    percentile: 87.470725995,
    z_score: 0.0720103119,
  },
  {
    percentile: 87.587822014,
    z_score: 0.07367833175,
  },
  {
    percentile: 87.704918033,
    z_score: 0.0740096144,
  },
  {
    percentile: 87.822014052,
    z_score: 0.0749301798,
  },
  {
    percentile: 87.93911007,
    z_score: 0.07583037751,
  },
  {
    percentile: 88.056206089,
    z_score: 0.07598551463,
  },
  {
    percentile: 88.173302108,
    z_score: 0.07785726663,
  },
  {
    percentile: 88.290398126,
    z_score: 0.08167755708,
  },
  {
    percentile: 88.407494145,
    z_score: 0.0826144501,
  },
  {
    percentile: 88.524590164,
    z_score: 0.08369097594,
  },
  {
    percentile: 88.641686183,
    z_score: 0.0862680711,
  },
  {
    percentile: 88.758782201,
    z_score: 0.08864507979,
  },
  {
    percentile: 88.87587822,
    z_score: 0.0892254304,
  },
  {
    percentile: 88.992974239,
    z_score: 0.09188527073,
  },
  {
    percentile: 89.110070258,
    z_score: 0.09328476167,
  },
  {
    percentile: 89.227166276,
    z_score: 0.09455461888,
  },
  {
    percentile: 89.344262295,
    z_score: 0.09751145101,
  },
  {
    percentile: 89.461358314,
    z_score: 0.09841883975,
  },
  {
    percentile: 89.578454333,
    z_score: 0.0991351874,
  },
  {
    percentile: 89.695550351,
    z_score: 0.0999113642,
  },
  {
    percentile: 89.81264637,
    z_score: 0.102663252,
  },
  {
    percentile: 89.929742389,
    z_score: 0.1057588601,
  },
  {
    percentile: 90.046838407,
    z_score: 0.1118410875,
  },
  {
    percentile: 90.163934426,
    z_score: 0.1122464483,
  },
  {
    percentile: 90.281030445,
    z_score: 0.1132826301,
  },
  {
    percentile: 90.398126464,
    z_score: 0.1147638056,
  },
  {
    percentile: 90.515222482,
    z_score: 0.1212100237,
  },
  {
    percentile: 90.632318501,
    z_score: 0.1226585575,
  },
  {
    percentile: 90.74941452,
    z_score: 0.1256994581,
  },
  {
    percentile: 90.866510539,
    z_score: 0.1300915731,
  },
  {
    percentile: 90.983606557,
    z_score: 0.1336029355,
  },
  {
    percentile: 91.100702576,
    z_score: 0.1391716781,
  },
  {
    percentile: 91.217798595,
    z_score: 0.141634118,
  },
  {
    percentile: 91.334894614,
    z_score: 0.1599117275,
  },
  {
    percentile: 91.451990632,
    z_score: 0.160717935,
  },
  {
    percentile: 91.569086651,
    z_score: 0.1636436436,
  },
  {
    percentile: 91.68618267,
    z_score: 0.1663528149,
  },
  {
    percentile: 91.803278689,
    z_score: 0.168221795,
  },
  {
    percentile: 91.920374707,
    z_score: 0.1701264208,
  },
  {
    percentile: 92.037470726,
    z_score: 0.1803949915,
  },
  {
    percentile: 92.154566745,
    z_score: 0.1847885651,
  },
  {
    percentile: 92.271662763,
    z_score: 0.1879676591,
  },
  {
    percentile: 92.388758782,
    z_score: 0.193586727,
  },
  {
    percentile: 92.505854801,
    z_score: 0.2051444657,
  },
  {
    percentile: 92.62295082,
    z_score: 0.2096791274,
  },
  {
    percentile: 92.740046838,
    z_score: 0.2121687978,
  },
  {
    percentile: 92.857142857,
    z_score: 0.2281213454,
  },
  {
    percentile: 92.974238876,
    z_score: 0.2328606072,
  },
  {
    percentile: 93.091334895,
    z_score: 0.2419298673,
  },
  {
    percentile: 93.208430913,
    z_score: 0.245324745,
  },
  {
    percentile: 93.325526932,
    z_score: 0.2486440343,
  },
  {
    percentile: 93.442622951,
    z_score: 0.2581466348,
  },
  {
    percentile: 93.55971897,
    z_score: 0.2688711333,
  },
  {
    percentile: 93.676814988,
    z_score: 0.2696860265,
  },
  {
    percentile: 93.793911007,
    z_score: 0.2731497781,
  },
  {
    percentile: 93.911007026,
    z_score: 0.2818370028,
  },
  {
    percentile: 94.028103044,
    z_score: 0.2858349378,
  },
  {
    percentile: 94.145199063,
    z_score: 0.287402451,
  },
  {
    percentile: 94.262295082,
    z_score: 0.3003867658,
  },
  {
    percentile: 94.379391101,
    z_score: 0.3432131835,
  },
  {
    percentile: 94.496487119,
    z_score: 0.3471153801,
  },
  {
    percentile: 94.613583138,
    z_score: 0.3632370315,
  },
  {
    percentile: 94.730679157,
    z_score: 0.3709708245,
  },
  {
    percentile: 94.847775176,
    z_score: 0.3755738605,
  },
  {
    percentile: 94.964871194,
    z_score: 0.376216532,
  },
  {
    percentile: 95.081967213,
    z_score: 0.3936310285,
  },
  {
    percentile: 95.199063232,
    z_score: 0.4021848925,
  },
  {
    percentile: 95.316159251,
    z_score: 0.404784413,
  },
  {
    percentile: 95.433255269,
    z_score: 0.404981164,
  },
  {
    percentile: 95.550351288,
    z_score: 0.4236871207,
  },
  {
    percentile: 95.667447307,
    z_score: 0.4367862248,
  },
  {
    percentile: 95.784543326,
    z_score: 0.4492276979,
  },
  {
    percentile: 95.901639344,
    z_score: 0.4523934134,
  },
  {
    percentile: 96.018735363,
    z_score: 0.4528883822,
  },
  {
    percentile: 96.135831382,
    z_score: 0.453401725,
  },
  {
    percentile: 96.2529274,
    z_score: 0.4589848654,
  },
  {
    percentile: 96.370023419,
    z_score: 0.4652799769,
  },
  {
    percentile: 96.487119438,
    z_score: 0.4658419833,
  },
  {
    percentile: 96.604215457,
    z_score: 0.4792246982,
  },
  {
    percentile: 96.721311475,
    z_score: 0.4849149277,
  },
  {
    percentile: 96.838407494,
    z_score: 0.4881671799,
  },
  {
    percentile: 96.955503513,
    z_score: 0.4899156877,
  },
  {
    percentile: 97.072599532,
    z_score: 0.5020839025,
  },
  {
    percentile: 97.18969555,
    z_score: 0.5100805483,
  },
  {
    percentile: 97.306791569,
    z_score: 0.5173977044,
  },
  {
    percentile: 97.423887588,
    z_score: 0.5260096423,
  },
  {
    percentile: 97.540983607,
    z_score: 0.5321384453,
  },
  {
    percentile: 97.658079625,
    z_score: 0.5422404188,
  },
  {
    percentile: 97.775175644,
    z_score: 0.5889460131,
  },
  {
    percentile: 97.892271663,
    z_score: 0.6089564377,
  },
  {
    percentile: 98.009367681,
    z_score: 0.6090440395,
  },
  {
    percentile: 98.1264637,
    z_score: 0.6431329935,
  },
  {
    percentile: 98.243559719,
    z_score: 0.721094832,
  },
  {
    percentile: 98.360655738,
    z_score: 0.7575556928,
  },
  {
    percentile: 98.477751756,
    z_score: 0.8604558237,
  },
  {
    percentile: 98.594847775,
    z_score: 1.334202242,
  },
  {
    percentile: 98.711943794,
    z_score: 1.371568291,
  },
  {
    percentile: 98.829039813,
    z_score: 1.448659521,
  },
  {
    percentile: 98.946135831,
    z_score: 1.547772932,
  },
  {
    percentile: 99.06323185,
    z_score: 1.714599414,
  },
  {
    percentile: 99.180327869,
    z_score: 2.005599177,
  },
  {
    percentile: 99.297423888,
    z_score: 2.650686203,
  },
  {
    percentile: 99.414519906,
    z_score: 3.318454332,
  },
  {
    percentile: 99.531615925,
    z_score: 12.441003612,
  },
  {
    percentile: 99.648711944,
    z_score: 25.332192064,
  },
  {
    percentile: 99.765807963,
    z_score: 26,
  },
  {
    percentile: 99.765807963,
    z_score: 27,
  },
  {
    percentile: 99.765807963,
    z_score: 28,
  },
];
