import { PageTitles } from '@revelio/core';
import { SelectionCategories } from '@revelio/filtering';
import { Screener } from '../../../screener/screener';
import { generateFilterOptions } from '../../utils/generateFilterOptions';
import { ScreenerDimension } from '@revelio/data-access';

const title = [PageTitles.COMPANY, PageTitles.SCREENER];

export const CompanyScreener = () => {
  const filterOptions = generateFilterOptions(SelectionCategories.INDUSTRY);

  return (
    <Screener
      title={title}
      filterOptions={filterOptions}
      view={ScreenerDimension.Company}
    />
  );
};

export default CompanyScreener;
