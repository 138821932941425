import {
  GetScreenerDataQuery,
  ScreenerReq,
  ScreenerReqSegments,
} from '@revelio/data-access';
import { ScreenerFilterState, getSegmentsText } from '@revelio/filtering';

export type TransformedScreenerEntityInfo = {
  [key: string]: number | string;
};

const getFilterInfo = (
  key: string,
  index: number,
  filters: Omit<ScreenerReq, 'pagination'>,
  state: ScreenerFilterState
): string => {
  const segments = filters.segments[key as keyof ScreenerReqSegments];

  if ((segments?.length || 0) > index) {
    const treeItems = state?.['filters']?.['sub_filters']?.filter(
      (subFilter) => subFilter.filter.name === key
    )?.[index]?.['treeItems'];

    return Object.keys(treeItems || {}).length
      ? ` (${getSegmentsText(treeItems)})`
      : '(All Employees)';
  }

  return `${index + 1}`;
};

const deserialiseDatum =
  (filters: Omit<ScreenerReq, 'pagination'>, state: ScreenerFilterState) =>
  (
    datum: GetScreenerDataQuery['screener']['entities'][number]
  ): TransformedScreenerEntityInfo =>
    Object.keys(datum).reduce((acc, key) => {
      const value = datum[key as keyof typeof datum];

      if (Array.isArray(value)) {
        value.forEach((val, index) => {
          const filterInfo =
            index === 0 ? '' : getFilterInfo(key, index - 1, filters, state);
          const newKey = index === 0 ? key : `${key}_${filterInfo}`;

          if (acc[newKey]) {
            let i = 2;
            while (acc[`${newKey}_${i}`]) {
              i++;
            }
            acc[`${newKey}_${i}`] = val;
          } else {
            acc[newKey] = val;
          }
        });
      }

      if (typeof value === 'string' || typeof value === 'number') {
        acc[key] = value;
      }

      return acc;
    }, {} as TransformedScreenerEntityInfo);

export const deserialiseScreenerData = (
  data: GetScreenerDataQuery['screener']['entities'],
  filters: Omit<ScreenerReq, 'pagination'>,
  state: ScreenerFilterState
): TransformedScreenerEntityInfo[] =>
  data?.map(deserialiseDatum(filters, state)) || [];
