import { PropsWithChildren } from 'react';
import { Center, Flex, Text } from '@chakra-ui/react';

export const MeterContainer = ({
  title,
  children,
}: PropsWithChildren & { title: string }) => {
  return (
    <Flex flexDirection="column" flexBasis="25%" height="full" paddingX="16px">
      <Text fontSize="xs" fontWeight="600" color="#2D426A">
        {title}
      </Text>
      <Center
        width="full"
        height={'100px'}
        maxHeight={'100px'}
        paddingTop="15px"
        flexGrow="1"
      >
        {children}
      </Center>
    </Flex>
  );
};
