import { Box, Flex, Text } from '@chakra-ui/layout';
import { Icon, IconButton } from '@chakra-ui/react';
import { FiRefreshCw } from 'react-icons/fi';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import {
  TypeRowProps,
  TypeSortInfo,
} from '@inovua/reactdatagrid-community/types';
import { ReactDataGridLoading } from '@revelio/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { formatDistanceToNow, intlFormat } from 'date-fns';

import '../../../styles/table-custom.scss';
import {
  UserOption,
  UserSelector,
} from '../../deliverables/status/user-selector';
import { isRoleRevelioAdmin, useGetLoggedInUser } from '@revelio/auth';
import { ReportsStatusColumn } from './reports-status-column';
import { useReportTableData } from './use-report-table-data';
import { useAllReports } from '../hooks/use-all-reports';
import { DeliveryStatus } from '@revelio/data-access';
import { usePrevious } from 'react-use';
import styles from './report-table.module.scss';
import { useNavigate } from 'react-router-dom';

const gridStyle = { minHeight: '100%' };
const defaultSortInfo: TypeSortInfo = { name: 'created_at', dir: -1 };

export const ReportsTable = ({
  onInitialized,
}: {
  onInitialized?: () => void;
}) => {
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
  const [timeDistance, setTimeDistance] = useState<string>('');
  const minsPast = useRef<number>(0);
  const [selectedUserOption, setSelectedUserOption] = useState<UserOption>();
  const { loggedInUser } = useGetLoggedInUser();
  const { data, fetching, refresh } = useReportTableData(selectedUserOption);
  const { reports } = useAllReports({
    selectedUserId: selectedUserOption?.value,
    isGroupOption: selectedUserOption?.isGroupOption,
  });
  const previousFetch = usePrevious(fetching);
  const [hasInitialized, setHasInitialized] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (hasInitialized && onInitialized) {
      onInitialized();
    }
  }, [hasInitialized, onInitialized]);

  useEffect(() => {
    if (previousFetch === true && fetching === false) {
      setHasInitialized(true);
    }
  }, [previousFetch, fetching]);

  /** When we get logged in user, set them as the selected user if there is
   * not already a xselected user */
  useEffect(() => {
    if (loggedInUser && loggedInUser?.id) {
      const loggedInUserId = loggedInUser.id;
      setSelectedUserOption((prevState) => {
        if (!prevState) {
          return {
            value: loggedInUserId || '',
            label: loggedInUser.name || '',
            group: loggedInUser.client_name || '',
            secondaryLabel: loggedInUser.email || '',
          };
        } else {
          return prevState;
        }
      });
    }
  }, [loggedInUser]);

  const showUserSelector = isRoleRevelioAdmin(loggedInUser.role);

  const refreshNow = useCallback(() => {
    setLastUpdate(new Date());
    refresh({ requestPolicy: 'network-only' });
    minsPast.current = 0;
  }, [refresh]);

  const intChecker = useCallback(() => {
    minsPast.current++;
    if (minsPast.current >= 4) {
      refreshNow();
    }
  }, [refreshNow]);

  useEffect(() => {
    setTimeDistance(formatDistanceToNow(lastUpdate, { addSuffix: true }));

    const intRefresh = setInterval(() => {
      setTimeDistance(formatDistanceToNow(lastUpdate, { addSuffix: true }));
      intChecker();
    }, 60000);

    return () => clearInterval(intRefresh);
  }, [intChecker, lastUpdate]);

  const viewReportPageLink = useCallback(
    ({ viewReportLink }: { viewReportLink: string }) => {
      if (viewReportLink) {
        return `${viewReportLink}?userId=${selectedUserOption?.value}`;
      }
      return null;
    },
    [selectedUserOption?.value]
  );

  const columns = [
    {
      name: 'name',
      header: 'Name',
      minWidth: 30,
      defaultFlex: 1,
    },
    {
      name: 'reportType',
      header: 'Report Type',
      minWidth: 30,
      defaultFlex: 0.67,
      render: ({ value }: { value: string }) => <div>{value}</div>,
    },
    !selectedUserOption?.isGroupOption
      ? {
          name: 'client',
          header: 'Client',
          minWidth: 30,
          defaultFlex: 0.67,
        }
      : {
          name: 'user',
          header: 'User',
          minWidth: 30,
          defaultFlex: 0.67,
        },
    {
      name: 'created_at',
      header: 'Creation',
      minWidth: 30,
      defaultFlex: 1.1,
      render: ({ value }: { value: string }) => (
        <div>
          {intlFormat(new Date(value), {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeZoneName: 'short',
          })}
        </div>
      ),
    },
    {
      name: 'updated_at',
      header: 'Updated',
      minWidth: 30,
      defaultFlex: 0.5,
      render: ({ value }: { value: string }) => (
        <div>{formatDistanceToNow(new Date(value), { addSuffix: true })}</div>
      ),
    },
    {
      name: 'status',
      minWidth: 30,
      defaultFlex: 0.7,
      render: ({
        value,
        data: tableData,
      }: {
        value: string;
        data: (typeof data)[number];
      }) => {
        return (
          <ReportsStatusColumn
            viewReportLink={viewReportPageLink({
              viewReportLink: tableData.viewReportLink,
            })}
            value={value}
            report={reports.find((r) => r.id === tableData.id) || null}
          />
        );
      },
      header: () => (
        <div>
          <span>Status</span>
          <IconButton
            aria-label="Refresh Status"
            icon={<FiRefreshCw />}
            colorScheme="gray"
            variant="outline"
            size="xs"
            ml={2}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              refreshNow();
            }}
          />
        </div>
      ),
    },
  ];

  const rowClassName = useCallback(({ data }: TypeRowProps) => {
    if (data.status !== DeliveryStatus.Finished) {
      return styles.unClickableRow;
    }
    return '';
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="end">
        {showUserSelector && (
          <UserSelector
            selectedUserOption={selectedUserOption}
            setSelectedUserOption={setSelectedUserOption}
          />
        )}
      </Box>
      <Box
        bg="white"
        rounded="lg"
        h={`calc(100vh - ${showUserSelector ? '169px' : '100px'})`}
        p={4}
      >
        <ReactDataGrid
          columns={columns}
          dataSource={data}
          style={gridStyle}
          rowHeight={50}
          emptyText="No reports created yet"
          theme="rl-light"
          onRowClick={({ data }) => {
            const link = viewReportPageLink({
              viewReportLink: data.viewReportLink,
            });
            link && navigate(link);
          }}
          rowClassName={rowClassName}
          defaultSortInfo={defaultSortInfo}
          showCellBorders={false}
          showZebraRows={false}
          loading={fetching}
          renderLoadMask={ReactDataGridLoading}
        />
      </Box>
      {lastUpdate && (
        <Flex alignItems="center" justifyContent="flex-end">
          <Text fontSize="xs" pt={2} opacity={0.5}>
            <Icon as={FiRefreshCw} mr={1} top="1px" pos="relative" />{' '}
            <span>Last updated: {timeDistance}</span>
          </Text>
        </Flex>
      )}
    </>
  );
};
