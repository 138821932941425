import { GetScreenerDataQuery, ScreenerDimension } from '@revelio/data-access';
import { TransformedScreenerEntityInfo } from './transformEntityData';
import { FORMAT_MAP, SubFilterValues } from '@revelio/filtering';
import { capitalize } from 'lodash';

type DataItem = NonNullable<
  GetScreenerDataQuery['screener']['entities']
>[number];
type ColumnKey = keyof DataItem;

const COLUMN_WHITELIST: ColumnKey[] = [
  'long_name',
  'headcount',
  'inflow',
  'outflow',
  'hiring',
  'attrition',
  'growth',
  'salary',
  'tenure',
];

const rateColumns: ColumnKey[] = ['hiring', 'attrition', 'growth'];

const formatHeader = (name: ColumnKey, view: ScreenerDimension) => {
  if (name === 'long_name') {
    if (view === ScreenerDimension.Company) {
      return 'Company Name';
    }

    if (view === ScreenerDimension.Geography) {
      return 'Metro Area';
    }

    if (view === ScreenerDimension.Role) {
      return 'Role';
    }
  }

  // Split the name into words while preserving the text inside parentheses
  const words = name.replace(/_/g, ' ').match(/(\([^)]+\)|[^\s]+)/g);

  const parsedName = words
    ?.map((word) => {
      if (word.startsWith('(') && word.endsWith(')')) {
        return word;
      }

      return capitalize(word);
    })
    .join(' ');

  if (parsedName?.includes('Yoy')) {
    return parsedName.replace('Growth Yoy', 'YoY Growth');
  }

  if (parsedName?.includes('Growth')) {
    return parsedName.replace('Growth', 'Cumulative Growth');
  }

  if (rateColumns.includes(name)) {
    return `${parsedName} Rate`;
  }

  return parsedName;
};

const formatSalary = (value: number): string | number => {
  return value >= 1000 ? `${(value / 1000).toFixed(0)}k` : value.toFixed(2);
};

const roundValue = (value: number): string | number => {
  return Math.round(value).toLocaleString();
};

const formatDefault = (value: number): string | number => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const applyFormattingRule = (
  value: number,
  columnName: ColumnKey
): string | number => {
  if (isNaN(value)) {
    return value;
  }

  const isRateColumn = rateColumns.some((rateColumn) =>
    columnName.startsWith(rateColumn)
  );

  if (isRateColumn) {
    return formatDefault(value * 100);
  }

  const rawColumnName = columnName.split('_')[0].trim();

  if (['salary'].includes(rawColumnName)) {
    return formatSalary(value);
  }

  if (['headcount', 'inflow', 'outflow'].includes(rawColumnName)) {
    return roundValue(value);
  }

  return formatDefault(value);
};

interface RenderFieldWithFormatProps {
  value: string | number;
  column: { name: keyof DataItem } | null | undefined;
}

const renderFieldWithFormat = ({
  value,
  column,
}: RenderFieldWithFormatProps) => {
  if (!column) {
    return value;
  }

  if (typeof value === 'string') {
    return value;
  }

  const formattedValue = applyFormattingRule(value, column.name);

  const foundKey = Object.keys(FORMAT_MAP).find((key) =>
    column?.name?.toLowerCase().includes(key)
  );

  if (foundKey) {
    const format = FORMAT_MAP[foundKey as SubFilterValues];

    return format.type === 'prefix'
      ? `${format.text}${formattedValue}`
      : `${formattedValue}${format.text}`;
  }

  return formattedValue;
};
export const getScreenerColumns = (
  data: TransformedScreenerEntityInfo[],
  view: ScreenerDimension
) => {
  if (!data || data.length === 0) {
    return [];
  }

  // Filter and map the keys to COLUMN_WHITELIST
  const keys = Object.keys(data[0] || {}).filter((columnName) =>
    COLUMN_WHITELIST.some((whitelistItem) =>
      columnName.toLowerCase().startsWith(whitelistItem)
    )
  ) as ColumnKey[];

  // Group columns by their base names
  const groupedColumns = keys.reduce(
    (acc, name) => {
      const baseColumnName = name.includes('growth_yoy')
        ? 'growthYoy'
        : name.split('_')[0].trim();

      return {
        ...acc,
        [baseColumnName]: [...(acc[baseColumnName] || []), name],
      };
    },
    {} as { [key: string]: ColumnKey[] }
  );

  // Map the grouped columns to the final column definitions
  const columns = Object.entries(groupedColumns).flatMap(([key, group]) =>
    group.map((name, index) => {
      const header = formatHeader(name, view);
      const render = (cellData: { value: string | number }) =>
        renderFieldWithFormat({
          value: cellData.value,
          column: { name },
        });

      return {
        name,
        header,
        render,
        minWidth: 30,
        defaultFlex: 1,
        columnNumber: index,
        key,
      };
    })
  );

  return columns;
};
