import {
  boxPlotDataTransformer,
  CompensationPlotTypes,
  CompensationResponse,
  CompensationTypes,
  getCompDataById,
  salaryOverTimeDataTransformer,
} from '@revelio/core';

export const transformData = ({
  data,
  compensationType,
  companyLookup,
}: {
  data: CompensationResponse[] | undefined;
  compensationType: CompensationTypes;
  companyLookup: { [key: string | number]: string } | undefined;
}) => {
  if (data) {
    const topCompanyData = boxPlotDataTransformer(
      data,
      compensationType,
      CompensationPlotTypes.TOP_COMPANY,
      companyLookup
    );
    const topGeographyData = boxPlotDataTransformer(
      data,
      compensationType,
      CompensationPlotTypes.TOP_GEOGRAPHY,
      companyLookup
    );
    const topRoleData = boxPlotDataTransformer(
      data,
      compensationType,
      CompensationPlotTypes.TOP_ROLE,
      companyLookup
    );

    return {
      overtimeData: salaryOverTimeDataTransformer(
        data,
        compensationType,
        companyLookup
      ),
      kdeData:
        getCompDataById(data, compensationType, 'kde', companyLookup)?.value ||
        [],
      topCompanyData:
        (topCompanyData?.[0] as unknown[])?.length > 0 ? topCompanyData : [],
      topGeographyData:
        (topGeographyData?.[0] as unknown[])?.length > 0
          ? topGeographyData
          : [],
      topRoleData:
        (topRoleData?.[0] as unknown[])?.length > 0 ? topRoleData : [],
    };
  }

  return {
    overtimeData: [],
    kdeData: [],
    topCompanyData: [],
    topGeographyData: [],
    topRoleData: [],
  };
};
