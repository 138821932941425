import { granularityLookup } from './granularityLookup';

interface CalculateMeterValueProps {
  value: number;
  metric: 'supplyDemand' | 'timeToFill' | 'wageGrowth' | 'marketTightness';
}

const calculateZScore = ({ value, metric }: CalculateMeterValueProps) => {
  if (metric === 'marketTightness') {
    return 0;
  }

  const zScoreComponents = granularityLookup[metric];

  const zScore = (() => {
    const mean = zScoreComponents.mean;
    const std = zScoreComponents.std;

    return (value - mean) / std;
  })();

  return zScore;
};

export const calculateMeterValue = ({
  value,
  metric,
}: CalculateMeterValueProps) => {
  const zScoreComponents = granularityLookup[metric];
  const lookup = zScoreComponents.lookup;

  const zScore = (() => {
    if (metric === 'marketTightness') {
      return value;
    }

    return calculateZScore({ value, metric });
  })();

  // Find the closest z_score and corresponding percentile from the JSON data
  const closestZScore = lookup.reduce((prev, curr) =>
    Math.abs(curr.z_score - zScore) < Math.abs(prev.z_score - zScore)
      ? curr
      : prev
  );

  return closestZScore.percentile;
};

interface CalculateMarketTightnessMeterValueProps {
  wageGrowth: number;
  supplyDemand: number;
  timeToFill: number;
}
export const calculateMarketTightnessMeterValue = ({
  wageGrowth,
  supplyDemand,
  timeToFill,
}: CalculateMarketTightnessMeterValueProps) => {
  const wageZscore = calculateZScore({
    value: wageGrowth,
    metric: 'wageGrowth',
  });

  const supplyDemandZscore = calculateZScore({
    value: supplyDemand,
    metric: 'supplyDemand',
  });

  const timeToFillZscore = calculateZScore({
    value: timeToFill,
    metric: 'timeToFill',
  });

  const marketTightnessZScore =
    0.4 * supplyDemandZscore + 0.4 * timeToFillZscore + 0.2 * wageZscore;

  return calculateMeterValue({
    value: marketTightnessZScore,
    metric: 'marketTightness',
  });
};
