import {
  Filter,
  FilterName,
  RangeFullFilter,
  SelectionCategories,
} from '@revelio/filtering';
import { useMemo } from 'react';

type UseIsQueryReadyProps = {
  activeFilters: Filter[];
  primaryFilters: FilterName[];
};
export const useIsQueryReady = ({
  activeFilters,
  primaryFilters,
}: UseIsQueryReadyProps) => {
  /** check that activeFilters has primaryFilter */
  const filtersHasAtLeastOnePrimaryFilter = useMemo(
    () => activeFilters.some((filter) => primaryFilters.includes(filter.id)),
    [activeFilters, primaryFilters]
  );

  /** check that filter has full date range set */
  const filtersHasDateSet = useMemo(
    () =>
      activeFilters.some((filter) => {
        /** TODO: Typecasting this as Filters aren't typed by id */
        const dateRangeFullFilter = filter as unknown as RangeFullFilter;
        return (
          dateRangeFullFilter.id === SelectionCategories.DATE_RANGE_FULL &&
          dateRangeFullFilter.value.startDate &&
          dateRangeFullFilter.value.endDate
        );
      }),
    [activeFilters]
  );

  return filtersHasAtLeastOnePrimaryFilter && filtersHasDateSet;
};
