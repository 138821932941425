import {
  SelectionCategories,
  FilterContainer,
  useSingleOrMoreFilterState,
  TabsFilter,
  FilterMenu,
  FilterChips,
  SelectFilter,
  useViewFilters,
  AddEntityButton,
  requireAtLeastOneFilterValueOf,
  FilterTypes,
  FilterItem,
  EndpointSegment,
  ViewTypes,
  provideBasePlotConfigDefaults,
  useViewFilterDefaults,
  useStoredFilterSet,
  FilterSets,
  OtherFilterNames,
  PrimaryFilterLimits,
  FilterMenuLimits,
  LocalSelectionCategories,
  SubFilter,
  SubFilterDisplayMode,
  useSelectionLists,
  usePrimaryFilter,
  upsertFilter,
  BooleanFilter,
  useManyPreFetchPlotConfigProviders,
  useDefaultLastMonth,
  DefaultDates,
  PlotConfig,
  useSyncFiltersToSearchParams,
  getPrimaryDataView,
  PrimaryDataView,
  FilterMenuItemOrConfig,
  createSelectableFiltersMap,
  SHARED_SET_ENTITY_LIMIT,
  Tab,
  FilterChipsContainer,
  FiltersUsedInTabs,
} from '@revelio/filtering';
import {
  Grid,
  GridItem,
  Flex,
  Switch,
  FormLabel,
  Spacer,
  useBreakpointValue,
} from '@chakra-ui/react';
import { tap } from 'rxjs/operators';
import { ChangeEvent, useMemo, useState } from 'react';
import { pipe } from 'rxjs';
import { D3ChartNames, ISankeyDiagram } from '@revelio/d3';
import { flatten, get, startCase } from 'lodash';
import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  useResponsivePageGridDefs,
  useGlobalLoaderMaxWait,
  Views,
} from '@revelio/core';
import { DefaultCard } from '@revelio/composed';
import { BaseTransitionsPlotConfigLookup } from './transitions-plots.config';
import { customFormatterLookup } from '@revelio/filtering';
import DashboardPage from '../DashboardPage';

/* eslint-disable-next-line */
export interface TransitionsProps {
  title: PageTitles[];
  viewType: Tab;
  primaryFilter: PrimaryFilters;
  primaryViewFilters: FilterMenuItemOrConfig[];
  selectableFilters: FilterMenuItemOrConfig[];
  additionalNonActiveFilters: (
    | SelectionCategories
    | LocalSelectionCategories
  )[];
  otherFilters: OtherFilterNames[];
  sharedFilterSetId?: FilterSets;
  filterSet: FilterSets;
  primaryFilterLimit: PrimaryFilterLimits;
  filterMenuLimit: FilterMenuLimits;
  trialNoResultsMessage?: JSX.Element;
  isGqlQuery?: boolean;
  isGoRequest?: boolean;
}

export function Transitions({
  title,
  viewType,
  primaryFilter,
  primaryViewFilters,
  selectableFilters,
  additionalNonActiveFilters,
  otherFilters,
  sharedFilterSetId = FilterSets.NONE,
  filterSet,
  primaryFilterLimit,
  filterMenuLimit,
  trialNoResultsMessage,
  isGqlQuery = true,
  isGoRequest = true,
}: TransitionsProps) {
  const [showUni, setShowUni] = useState<boolean>(false);

  const brokenOutFilterIds: (SelectionCategories | LocalSelectionCategories)[] =
    isGqlQuery
      ? [
          SelectionCategories.PRIMARY_FILTER,
          LocalSelectionCategories.INFLOW_OR_OUTFLOW,
        ]
      : [
          LocalSelectionCategories.INFLOW_OR_OUTFLOW,
          LocalSelectionCategories.N_ITEMS_SANKEY,
          SelectionCategories.PRIMARY_FILTER,
          SelectionCategories.DATE_RANGE,
        ];

  const {
    templateColumns,
    templateRows,
    bigPlotColSpan,
    bigPlotRowSpan,
    gridItemMinHeight,
    tallGridItemMinHeight,
  } = useResponsivePageGridDefs(Views.TRANSITION);

  const view = Views.TRANSITION;

  const isSlim = useBreakpointValue({ md: true, base: false });

  const TransitionPlotsConfig = useMemo(
    () => BaseTransitionsPlotConfigLookup[viewType],
    [viewType]
  );

  const primaryFilters = flatten(
    createSelectableFiltersMap(primaryViewFilters)
  );

  const selectableFiltersMap = createSelectableFiltersMap(selectableFilters);
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  useGlobalLoaderMaxWait(true);

  const storedFilterSetArgs = {
    sharedSetId: sharedFilterSetId,
    tab: viewType,
    primaryEntitiesSync: true,
    limit: primaryFilterLimit,
    filterNames: primaryFilters,
    uniqueSetId: filterSet,
    defaultLimit: 1,
  };

  useStoredFilterSet(storedFilterSetArgs);

  useViewFilters([...primaryFilters, ...selectableFiltersMap]);

  useSelectionLists([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    ...FiltersUsedInTabs,
  ]);

  const viewFilterDefaultArgs = {
    view,
    viewType,
    presetView: sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [...otherFilters, LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.TRANSITIONS,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);

  useDefaultLastMonth({
    view,
    viewType,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
    dateKey: SelectionCategories.DATE_RANGE,
  });

  useSyncFiltersToSearchParams({
    primaryFilters,
    syncToPrimaryEntities: true,
  });

  usePrimaryFilter(primaryFilter);

  const primaryDataView: PrimaryDataView = useMemo(
    () => getPrimaryDataView(viewType),
    [viewType]
  );

  const additionalOperatorsBeforeQuery = pipe(
    requireAtLeastOneFilterValueOf([
      ...primaryFilters,
      OtherFilterNames.LAST_MONTH,
    ])
    // TODO: likely toss this but possibly refactor
    // preFetchWithModifiedQuery((payload) => {
    //   const newPayload = produce(payload, (draft) => {
    //     const additionalFilters = draft.additionalFilters;
    //     const inOrOutFilter = additionalFilters.find(
    //       (f) => f.id == LocalSelectionCategories.INFLOW_OR_OUTFLOW
    //     ) as SelectFilter<FilterItem<ValidValueTypes>>;
    //     const prefetchValue =
    //       inOrOutFilter.value.id == OtherFilterNames.OUTFLOW
    //         ? OtherFilterNames.INFLOW
    //         : OtherFilterNames.OUTFLOW;
    //     inOrOutFilter.value = {
    //       id: prefetchValue,
    //       label: prefetchValue,
    //       value: prefetchValue,
    //     };
    //   });
    //   return [newPayload];
    // }),
    // map((pl) => {
    //   const { includeInGlobalLoader, endpoint } = pl;
    //   if (
    //     includeInGlobalLoader &&
    //     [EndpointSegment.UNIVERSITY, EndpointSegment.TRANSITION].includes(
    //       (endpoint as CalculatedEndpoint).name as EndpointSegment
    //     )
    //   ) {
    //     return { ...pl, includeInGlobalLoader: false };
    //   }
    //   return pl;
    // })
  );

  const transtionsDefaults = provideBasePlotConfigDefaults({
    view: Views.TRANSITION,
    viewType: ViewTypes.SHARES_SANKEY,
    endpoint: EndpointSegment.TRANSITION,
    chartType: D3ChartNames.SankeyDiagram,
    chartProps: {
      name: Views.TRANSITION,
      heading: Views.TRANSITION,
      ttMainFormat: ',.0f',
      ttSecondaryFormat: ',.0f',
      chartSize: 'large',
      inflows: true,
      isUniversity: false,
    },
    additionalNonActiveFilters: [
      ...additionalNonActiveFilters,
      LocalSelectionCategories.N_ITEMS_SANKEY,
    ],
    brokenOutFilterIds,
    metaData: {
      requiredParams: [
        LocalSelectionCategories.INFLOW_OR_OUTFLOW,
        LocalSelectionCategories.N_ITEMS_SANKEY,
        SelectionCategories.PRIMARY_FILTER,
        SelectionCategories.DATE_RANGE,
      ],
      isGoRequest,
      pageGroupName: 'transitions',
      primaryDataView,
    },
    preFetchConfig:
      viewType === ViewTypes.COMPANY
        ? {
            endpoint: EndpointSegment.UNIVERSITY,
          }
        : undefined,
  });

  const {
    mappers: {
      currentConfigMappers: [
        {
          name: transitionsName,
          additionalNonActiveFilters: transitionsAdditionalNonActiveFilters,
          brokenOutFilterIds: transitionsBrokenOutFilterIds,
          endpointMapper,
          downloadEndpointMapper,
          plotConfigMapper,
          updater: transitionUpdater,
          dataProvider: transitionDataProvider,
          metaData: transitionMetaData,
          endpointSegment,
        },
      ],
      preFetchConfigMappers: [transitionsPreFetchConfigMapper],
    },
  } = useManyPreFetchPlotConfigProviders([transtionsDefaults({})]);

  const viewDefaultsForPlots = provideBasePlotConfigDefaults({
    view: Views.TRANSITION,
    viewType: ViewTypes.SHARES_TRANSITION,
    chartType: D3ChartNames.StackedBarChartHorizontal,
    chartProps: {
      chartPosition: 'right',
      ttMainFormat: '.0%',
      ttSecondaryFormat: ',',
      chartStyle: '.transitions-plot-side',
      hideLegend: true,
    },
    additionalNonActiveFilters: [
      ...additionalNonActiveFilters,
      LocalSelectionCategories.N_ITEMS_SANKEY,
    ],
    brokenOutFilterIds: brokenOutFilterIds,
  });

  const {
    mappers: { currentConfigMappers },
  } = useManyPreFetchPlotConfigProviders(
    TransitionPlotsConfig.map(({ endpoint, chartProps }) => {
      return viewDefaultsForPlots({
        endpoint,
        chartProps,
        metaData: {
          requiredParams: [
            LocalSelectionCategories.N_ITEMS_SANKEY,
            LocalSelectionCategories.INFLOW_OR_OUTFLOW,
            SelectionCategories.PRIMARY_FILTER,
            SelectionCategories.DATE_RANGE,
          ],
          isGoRequest,
          pageGroupName: 'transitions',
          primaryDataView,
        },
      });
    })
  );

  const [[, uniFilterState] = [undefined, undefined]] =
    useSingleOrMoreFilterState<[SelectFilter<FilterItem>, BooleanFilter]>(
      [
        LocalSelectionCategories.INFLOW_OR_OUTFLOW,
        LocalSelectionCategories.IS_UNIVERSITY,
      ],
      pipe(
        tap((filters) => {
          const inOrOutflowFilter = (filters as SelectFilter<FilterItem>[])[0];
          const isUniversityState = get(filters, '[1].value', false);
          const preFetchUpdater = transitionsPreFetchConfigMapper.updater;

          if (inOrOutflowFilter?.value?.id) {
            const inOrOut = inOrOutflowFilter.value.id;
            const updates: Partial<PlotConfig> = {
              chartProps: {
                inflows: inOrOut == OtherFilterNames.INFLOW,
                isUniversity: isUniversityState,
              },
            };
            const preFetchUpdates: Partial<PlotConfig> = {
              chartProps: {
                inflows: !(updates.chartProps as ISankeyDiagram)?.inflows,
                isUniversity: !(updates.chartProps as ISankeyDiagram)
                  ?.isUniversity,
              },
            };

            // University toggle is only on Company > Transitions
            // TODO: TEMPORARILY DISABLING UNI TOGGLE
            // eslint-disable-next-line no-constant-condition
            if (false && viewType === ViewTypes.COMPANY) {
              setShowUni(inOrOutflowFilter.value.id == OtherFilterNames.INFLOW);

              updates['endpoint'] =
                inOrOut == OtherFilterNames.INFLOW && isUniversityState
                  ? EndpointSegment.UNIVERSITY
                  : EndpointSegment.TRANSITION;

              preFetchUpdates['endpoint'] =
                updates.endpoint == EndpointSegment.UNIVERSITY
                  ? EndpointSegment.TRANSITION
                  : EndpointSegment.UNIVERSITY;
              preFetchUpdater.next(preFetchUpdates);
            }

            transitionUpdater.next(updates);
          }
        })
      )
    );

  const handleUniversityToggle = (e: ChangeEvent<HTMLInputElement>) => {
    upsertFilter(LocalSelectionCategories.IS_UNIVERSITY, {
      type: FilterTypes.BOOLEAN,
      value: e.target.checked,
    });
  };

  return (
    <DashboardPage
      title={title}
      loaderActiveOnInit={true}
      hideSelectionsMargins
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            showColors={false}
            variant="companyChip"
            isPrimaryChip={true}
            view={view}
            min={1}
            limit={primaryFilterLimit}
            addButton={
              <AddEntityButton
                limit={SHARED_SET_ENTITY_LIMIT}
                activeLimit={1}
                entities={primaryViewFilters}
                entityName={AddEntityButtonText[primaryFilter]}
                buttonText={AddEntityButtonText[primaryFilter]}
                required={1}
                trialNoResultsMessage={trialNoResultsMessage}
              />
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent={'space-between'}
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={selectableFilters}
            variant="filterChip"
            limit={filterMenuLimit}
            showGranularity={true}
            viewType={viewType}
            addButton={
              <FilterMenu
                title="Filter"
                filters={[
                  ...selectableFilters,
                  // SelectionCategories.SAVED_FILTER_SET,
                ]}
                selectMenuOpenDefault
                limit={filterMenuLimit}
                showFilterSetSaveMenu={false}
                viewIdForDefault={`${view}_${viewType}`}
              />
            }
          />
        </Flex>

        <TabsFilter
          filterName={LocalSelectionCategories.INFLOW_OR_OUTFLOW}
          initialValue={{
            id: OtherFilterNames.INFLOW,
            label: startCase(OtherFilterNames.INFLOW),
            index: 0,
          }}
          filterMetadata={{
            isMulti: false,
            customFormatter:
              customFormatterLookup[LocalSelectionCategories.INFLOW_OR_OUTFLOW],
          }}
        ></TabsFilter>
      </FilterContainer>

      <Grid
        height="100%"
        templateRows={templateRows}
        templateColumns={templateColumns}
        gap={4}
        data-testid="plots-grid"
      >
        <GridItem
          rowSpan={bigPlotRowSpan}
          colSpan={bigPlotColSpan}
          minH={tallGridItemMinHeight}
        >
          <DefaultCard
            cardConfig={{
              header: transitionsName,
              switch: true,
              footer: (
                <Flex mt="-24px">
                  <Spacer />
                  <SubFilter
                    displayMode={SubFilterDisplayMode.SIMPLE}
                    hideTriggerDefault={false}
                    filterName={LocalSelectionCategories.N_ITEMS_SANKEY}
                    showGrouped={false}
                    selectionLimit={1}
                    selectionLists={[LocalSelectionCategories.N_ITEMS_SANKEY]}
                    // eslint-disable-next-line no-template-curly-in-string
                    displayValueTemplate="Show: ${value}"
                    placementOverride="auto"
                    defaultState={{
                      isMulti: false,
                      type: FilterTypes.SELECT,
                      selectionListId: LocalSelectionCategories.N_ITEMS_SANKEY,
                      value: { id: 10, label: '10' },
                    }}
                  />
                </Flex>
              ),
              endpointSegment: endpointSegment,
              view: Views.TRANSITION,
            }}
            topRight={
              showUni && (
                <>
                  <FormLabel
                    htmlFor="uni-toggle"
                    margin="0"
                    px="4px"
                    fontSize="12px"
                    color="text.primary"
                  >
                    University
                  </FormLabel>
                  <Switch
                    id="uni-toggle"
                    size="sm"
                    isChecked={uniFilterState?.value}
                    onChange={handleUniversityToggle}
                    colorScheme="green"
                    paddingRight="4px"
                  />
                </>
              )
            }
            plotConfig={{
              additionalNonActiveFilters: transitionsAdditionalNonActiveFilters,
              brokenOutFilterIds: transitionsBrokenOutFilterIds,
              additionalOperatorsBeforeQuery: additionalOperatorsBeforeQuery,
              endpoint: endpointMapper,
              chartTypeAndProps: plotConfigMapper,
              dataProvider: transitionDataProvider,
              requiredParams: transitionMetaData?.requiredParams,
              preFetchConfig: transitionsPreFetchConfigMapper,
              isGqlQuery,
            }}
            downloadConfig={{
              endpoint: downloadEndpointMapper,
              isGoRequest: transitionMetaData?.isGoRequest,
            }}
            p="12px 12px 8px 16px"
          />
        </GridItem>

        {currentConfigMappers.map((config, i) => (
          <GridItem
            className="transitions-plot-side"
            key={i}
            rowSpan={1}
            colSpan={1}
            minH={gridItemMinHeight}
          >
            <DefaultCard
              cardConfig={{
                header: config.name,
                slim: isSlim,
                endpointSegment: config.endpointSegment,
                view: Views.TRANSITION,
              }}
              plotConfig={{
                additionalNonActiveFilters: config.additionalNonActiveFilters,
                brokenOutFilterIds: config.brokenOutFilterIds,
                additionalOperatorsBeforeQuery: additionalOperatorsBeforeQuery,
                endpoint: config.endpointMapper,
                chartTypeAndProps: config.plotConfigMapper,
                dataProvider: config.dataProvider,
                requiredParams: config.metaData?.requiredParams,
                isGqlQuery,
              }}
              downloadConfig={{
                endpoint: config.downloadEndpointMapper,
                isGoRequest: config.metaData?.isGoRequest,
              }}
            />
          </GridItem>
        ))}
      </Grid>
    </DashboardPage>
  );
}

export default Transitions;
