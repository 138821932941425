import { useEffect } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useDisclosure,
} from '@chakra-ui/react';
import { GroupBase, Select } from 'chakra-react-select';
import { Control, useController } from 'react-hook-form';
import { useQuery } from 'urql';
import { sortBy } from 'lodash';

import { UserFormValues } from '../user-form';
import { OptionValues } from '../utils/helpers';
import { graphql, Role } from '@revelio/data-access';
import AdminClientAdd from '../admin-client-add';

export const AllClientsQuery = graphql(`
  query AllClientsTable {
    clients {
      client_name
      active
      num_seats
      users {
        id
      }
    }
  }
`);

export const CreateClientMutation = graphql(`
  mutation CreateClientRewrite(
    $client_name: String!
    $tabs: [Tab!]!
    $live: Boolean!
    $active: Boolean!
    $linkup_postings: Boolean!
    $unified_postings: Boolean!
    $num_seats: String!
    $company_lists: [String!]
    $data_builder_configuration: PipelineClientConfigInput!
    $reports_configuration: ClientReportsConfigInput
  ) {
    createClientGroup(
      input: {
        client_name: $client_name
        tabs: $tabs
        live: $live
        active: $active
        linkup_postings: $linkup_postings
        unified_postings: $unified_postings
        num_seats: $num_seats
        company_lists: $company_lists
        data_builder_configuration: $data_builder_configuration
        reports_configuration: $reports_configuration
      }
    ) {
      client_name
    }
  }
`);

export type ClientGroupOption = {
  label: string;
  value: string;
};
interface Props {
  control: Control<UserFormValues, object>;
  defaultClientName?: string | null;
}
export function AdminUserClientGroupSelect({
  control,
  defaultClientName,
}: Props) {
  const [{ data, fetching }, refetchClients] = useQuery({
    query: AllClientsQuery,
  });
  const selectOptions = sortBy(data?.clients, ['client_name']).map(
    (client) => ({
      label: client?.client_name as string,
      value: client?.client_name as string,
    })
  );

  const {
    field: { onChange, onBlur, ref, name, value },
    fieldState: { error },
  } = useController<UserFormValues>({
    name: 'client_group',
    control,
    defaultValue: defaultClientName
      ? {
          label: defaultClientName as string,
          value: defaultClientName as Role,
        }
      : undefined,
    rules: { required: 'Client group is required.' },
  });

  useEffect(() => {
    // This is here because because when a new client group is created, it is not immediately added to the list of clients.
    // Only after the user is added to the client group will it show up -so the clients need to be refetched the next time the user page is opened.
    refetchClients({ requestPolicy: 'network-only' });
  }, [refetchClients]);

  const { isOpen, onClose } = useDisclosure();

  return (
    <FormControl id="client_group" isInvalid={!!error?.message}>
      <FormLabel fontSize="sm" fontWeight="semibold">
        Change Client group
      </FormLabel>

      <Flex>
        <Box style={{ flexGrow: 1 }}>
          <Select<OptionValues, true, GroupBase<OptionValues>>
            name={name}
            value={value as ClientGroupOption}
            options={selectOptions}
            isLoading={fetching}
            placeholder="Select client group..."
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            styles={{ control: (styles) => ({ ...styles, flexGrow: 1 }) }}
          />
        </Box>
        <AdminClientAdd isOpen={isOpen} onClose={onClose} />
      </Flex>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
