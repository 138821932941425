import { compact, get, has } from 'lodash';
import {
  Item,
  LocalSelectionCategories,
  SelectionList,
  TreeItem,
} from '../../engine/filters.model';

export interface Config {
  id: string | number;
  parentId: string | number;
}

/**
 * Unflattens an array to a tree with runtime O(n)
 */

export function arrayToTree(...args: SelectionList[]): {
  rootItems: TreeItem[];
  lookup: { [id: string | number]: TreeItem };
} {
  const rootItems: TreeItem[] = [];

  const lookup: { [id: string | number]: TreeItem } = {};

  if (compact(args).length == 0) {
    return { rootItems, lookup };
  }

  // The effective parent is the list we want to use as the
  // top level root nodes for the tree structure.
  const effectiveParentList =
    args.length === 1
      ? args[0]
      : args.find(
          ({ parent }) => !parent || !args.find((li) => li.id == parent)
        );

  args.forEach((arg) => {
    const { id, label, value: selectionValues, parent } = arg;

    let children: TreeItem[] = [];

    const isEffectiveParent = id == effectiveParentList?.id;

    (selectionValues as Item[])?.forEach((item: Item) => {
      // if item has a sub list, it is non selectable
      const isSelectableParent = !has(item, 'list');

      let parentId: string | undefined = undefined;

      const itemId = label + '.' + item.id;
      const parentIdNum = get(item, `${parent}`, get(item, 'parentId'));
      if (id === LocalSelectionCategories.PROVIDER) {
        children = [];
        if (!isSelectableParent) {
          item.list.forEach((listItem: TreeItem) => {
            children.push(listItem);
          });
        }
      } else if (!isEffectiveParent && parentIdNum) {
        parentId = parent + '.' + parentIdNum;
      }

      if (children.length > 0) {
        children.forEach((child) => {
          const childId = `${label}.${child.id}`;

          if (!Object.prototype.hasOwnProperty.call(lookup, childId)) {
            lookup[childId] = {
              item: null,
              children: [],
              id: childId,
              parentId: itemId,
              selectionListId: id,
            };
          }
          lookup[childId].item = child;
        });
      }

      if (!Object.prototype.hasOwnProperty.call(lookup, itemId)) {
        lookup[itemId] = {
          item: null,
          children: [],
          id: itemId,
          parentId,
          selectionListId: id,
        };
      }

      lookup[itemId].item = item;

      const TreeItem = lookup[itemId];

      if (typeof parentId === 'undefined') {
        rootItems.push({ ...TreeItem, isSelectableParent });

        if (children.length > 0) {
          children.forEach((child) => {
            const childId = `${label}.${child.id}`;
            lookup[itemId].children.push({
              ...child,
              id: childId,
              item: child,
              children: [],
            });
          });
        }
      } else {
        if (!Object.prototype.hasOwnProperty.call(lookup, parentId) && parent) {
          lookup[parentId] = {
            item: null,
            children: [],
            id: parentId,
            selectionListId: parent,
          };
        }

        lookup[parentId].children.push(TreeItem);
      }
    });
  });
  /* rootItems.sort(nodeSort); */

  return { rootItems, lookup };
}
