import {
  OtherFilterNames,
  PrimaryFilterValues,
  ScreenerFilterOption,
  SelectionCategories,
} from '@revelio/filtering';
import { capitalize } from 'lodash';

const SCREENER_SUB_FILTERS: ScreenerFilterOption[] = [
  {
    value: SelectionCategories.HEADCOUNT,
    label: 'Headcount',
  },
  { value: OtherFilterNames.INFLOW, label: 'Inflow' },
  { value: OtherFilterNames.OUTFLOW, label: 'Outflow' },
  {
    value: SelectionCategories.HIRING_RATE,
    label: 'Hiring Rate',
  },
  {
    value: SelectionCategories.ATTRITION_RATE,
    label: 'Attrition Rate',
  },
  {
    value: SelectionCategories.GROWTH_RATE,
    label: 'Growth Rate (Cumulative)',
  },
  {
    value: 'growth_yoy',
    label: 'Growth Rate (YoY)',
  },
  {
    value: SelectionCategories.AVERAGE_SALARY,
    label: 'Average Salary',
  },
  {
    value: SelectionCategories.AVERAGE_TENURE,
    label: 'Average Tenure',
  },
];

export const generateFilterOptions = (
  primaryFilter: PrimaryFilterValues,
  primaryFilterLabel?: string
): ScreenerFilterOption[] => {
  const labelPostfix = (() => {
    if (primaryFilter === SelectionCategories.INDUSTRY) {
      return '(of entire company)';
    }

    return undefined;
  })();

  const filterGranularities = (() => {
    if (primaryFilter === SelectionCategories.JOB_CATEGORY) {
      return [SelectionCategories.ROLE_K150];
    }

    if (primaryFilter === SelectionCategories.REGION) {
      return [SelectionCategories.COUNTRY];
    }

    return undefined;
  })();

  const filterOptions: ScreenerFilterOption[] = [
    {
      label: primaryFilterLabel || capitalize(primaryFilter),
      value: primaryFilter,
      primary: true,
      filterGranularities,
    },
    ...SCREENER_SUB_FILTERS.map((filter) => ({ ...filter, labelPostfix })),
  ];

  return filterOptions;
};
