import { TimeFrameView } from '@revelio/core';
import { Filter, FilterName, SelectionCategories } from '@revelio/filtering';
import { useMemo } from 'react';

export const useIsQueryReady = ({
  filters,
  primaryFilters,
  timeframe,
}: {
  filters: Filter[];
  primaryFilters: FilterName[];
  timeframe: TimeFrameView;
}) => {
  const isSnapshot = timeframe === TimeFrameView.SNAPSHOT;
  /** check that filters has primaryFilter */
  const filtersHasAtLeastOnePrimaryFilter = useMemo(
    () => filters.some((filter) => primaryFilters.includes(filter.id)),
    [filters, primaryFilters]
  );

  const filtersHasDateSet = useMemo(() => {
    if (isSnapshot) {
      return filters.some(
        (filter) => filter.id === SelectionCategories.SNAPSHOT_DATE
      );
    }
    return filters.some(
      (filter) => filter.id === SelectionCategories.DATE_RANGE
    );
  }, [filters, isSnapshot]);

  return filtersHasAtLeastOnePrimaryFilter && filtersHasDateSet;
};
