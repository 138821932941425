import { Box, Text, Divider } from '@chakra-ui/react';
import {
  Filter,
  FilterName,
  FilterOrSubfilterName,
  Item,
  SelectionCategories,
  TDSelections,
  TreeItem,
} from '../../engine/filters.model';
import { get, reduce } from 'lodash';
import { createElement, useEffect, useState } from 'react';
import { TDRangeInput } from './td-range-input/td-range-input';
import {
  TDStateConfig,
  defaultOption,
} from './talent-discovery-filter-menu.config';
import { Views } from '@revelio/core';
import { TDCreatableSelect } from './td-creatable-select/td-creatable-select';
import { SubmitRefHandle } from '../filter-menu/types';
import CustomFilterRange from '../date-range/custom-date-range';
import { useSingleOrMoreFilterState } from '../../engine/filters.engine';
import TDPasteSelect from './td-paste-select/TDPasteSelect';
import { useAsyncTreeState } from '../tree/async-tree/useAsyncTreeState';
import { SchoolSearchTreeRef } from '../tree/search-trees/school-search-tree/school-search-tree';
import { SimpleFilterRange } from '../filter-range-slider/filter-range-slider-simplified';
import { TDNameInput } from './td-name-input/td-name-input';
import { operatorOptions } from '../../engine/filters.constants';
import { FilterTreeItemData } from '../collection';

/* eslint-disable-next-line */
export interface TalentDiscoveryFilterMenuProps {
  // TODO: need to fix this typing
  selections: any; // eslint-disable-line
  setIsSubmitDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  defaultLimit?: number;
  TDState: TDStateConfig;
  setTDState: React.Dispatch<React.SetStateAction<TDStateConfig>>;
  showDivider?: boolean;
  submitRef: React.MutableRefObject<SubmitRefHandle>;
  dateRangeState?: any;
  onToggleBarChange?: any;
  filterTreeItemData?: FilterTreeItemData;
}

export function TalentDiscoveryFilterMenu({
  selections,
  defaultLimit = 6,
  setIsSubmitDisabled,
  TDState,
  setTDState,
  showDivider = false,
  submitRef,
  filterTreeItemData,
  dateRangeState = {},
  onToggleBarChange,
}: TalentDiscoveryFilterMenuProps) {
  // // shape of object held in the tempTypes state
  type TempTree = {
    [key in FilterName]: (Item | null)[];
  };

  const [filtersToggleState] = useSingleOrMoreFilterState<Filter[]>([
    SelectionCategories.COMPANY,
    SelectionCategories.TOTAL_COMPENSATION,
    // SelectionCategories.BASE_SALARY,
  ]);

  const handleSetTempTree = (
    vals: any,
    categories: FilterOrSubfilterName[]
  ) => {
    // group selected values by selection list id
    vals = reduce(
      vals,
      (res: TempTree, val: TreeItem, key: string) => {
        (res[val.selectionListId] || (res[val.selectionListId] = [])).push(
          val.item
        );
        return res;
      },
      {} as TempTree
    );

    // set categories with no selections to empty array
    categories.forEach((cat) => {
      if (!Object.keys(vals).includes(cat)) {
        vals[cat] = [];
      }
    });

    setTDState((prev: TDStateConfig) => ({
      ...prev,
      ...vals,
    }));
  };

  // // TODO: Temporary State Handling for current company, need to incorporate
  // // this better into the rest of the code

  const [asyncTreeSelections, setAsyncTreeSelections] = useAsyncTreeState(
    SelectionCategories.COMPANY
  );

  useEffect(() => {
    setTDState((prev: TDStateConfig) => {
      const formattedCompanyValues = Object.values(asyncTreeSelections).map(
        (company: any) => {
          const { company_id, primary_name, has_subsidiaries, rcid } = company;
          return {
            id: Number(company_id),
            label: primary_name,
            data: {
              hasSubsidiaries: has_subsidiaries,
              shortName: primary_name,
              rcid,
            },
          };
        }
      );

      return {
        ...prev,
        [SelectionCategories.COMPANY]: {
          formatted: formattedCompanyValues,
          unformatted: asyncTreeSelections,
        },
      };
    });
  }, [asyncTreeSelections, setTDState]);

  const [dateRangeError, setDateRangeError] = useState('');

  return (
    <>
      {selections && showDivider && <Divider my={2} />}
      {selections?.map(
        ({
          keyName,
          name,
          hideTitle,
          placeholderText,
          selectionCategory,
          defaultState,
          type,
          comp,
          props,
          limit,
        }: TDSelections) => (
          <Box key={name}>
            {!hideTitle && (
              <Text fontSize="sm" pb={1}>
                {name}
              </Text>
            )}

            {type === 'schoolSearchTree' &&
              createElement<any>(SchoolSearchTreeRef, {
                setTDState,
                ref: (element: any) =>
                  (submitRef.current[`${selectionCategory[0]}`] = {
                    filterName: selectionCategory[0],
                    value: element,
                  }),
                ...props[0],
              })}

            {/* TODO: properly type create element */}
            {type === 'SimpleFilterRange' &&
              createElement<any>(SimpleFilterRange, {
                filterName: selectionCategory[0],
                prefix: '$',
                value: TDState,
                setValue: setTDState,
                setIsSubmitDisabled,
                toggleBarData: filtersToggleState,
                onToggleBarChange: (toggleIndex: number) => {
                  const isCurrent = toggleIndex === 0;

                  setTDState((prev) => {
                    const prevCurrent = get(prev, 'current', []);

                    let updatedCurrent = [];

                    if (!isCurrent) {
                      updatedCurrent = prevCurrent.filter(
                        (id: FilterOrSubfilterName) =>
                          !selectionCategory.includes(id)
                      );
                    } else {
                      updatedCurrent = [...prevCurrent, ...selectionCategory];
                    }

                    return {
                      ...prev,
                      current: updatedCurrent,
                    };
                  });
                },
                ref: (element: any) =>
                  (submitRef.current[`${selectionCategory[0]}`] = {
                    filterName: selectionCategory[0],
                    value: element,
                  }),
                ...props[0],
              })}

            {/* TODO: properly type create element */}
            {type === 'asyncTree' &&
              createElement<any>(comp[0], {
                selections: asyncTreeSelections,
                setSelections: setAsyncTreeSelections,
                toggleBarData: filtersToggleState,
                onToggleBarChange: (toggleIndex: number) => {
                  const isCurrent = toggleIndex === 0;

                  setTDState((prev) => {
                    const prevCurrent = get(prev, 'current', []);

                    let updatedCurrent = [];

                    if (!isCurrent) {
                      updatedCurrent = prevCurrent.filter(
                        (id: FilterOrSubfilterName) =>
                          !selectionCategory.includes(id)
                      );
                    } else {
                      updatedCurrent = [...prevCurrent, ...selectionCategory];
                    }

                    return {
                      ...prev,
                      current: updatedCurrent,
                    };
                  });
                },
                ref: (element: any) =>
                  (submitRef.current[`${selectionCategory[0]}`] = {
                    filterName: selectionCategory[0],
                    value: element,
                  }),
                ...props[0],
              })}

            {type === 'tree' &&
              selectionCategory &&
              createElement<any>(comp[0], {
                placeholder: placeholderText || name,
                selectionLists: selectionCategory,
                submitOnBlur: false,
                setTempSelections: (val: any) =>
                  handleSetTempTree(val, selectionCategory),
                limit: limit || defaultLimit,
                formatOverride: Views.SCREENER,
                onToggleBarChange: (toggleIndex: number) => {
                  const isCurrent = toggleIndex === 0;

                  setTDState((prev) => {
                    const prevCurrent = get(prev, 'current', []);

                    let updatedCurrent = [];

                    if (!isCurrent) {
                      updatedCurrent = prevCurrent.filter(
                        (id: FilterOrSubfilterName) =>
                          !selectionCategory.includes(id)
                      );
                    } else {
                      updatedCurrent = [...prevCurrent, ...selectionCategory];
                    }

                    return {
                      ...prev,
                      current: updatedCurrent,
                    };
                  });
                },
                ref: (element: any) =>
                  (submitRef.current[`${selectionCategory[0]}`] = {
                    filterName: selectionCategory[0],
                    value: element,
                  }),
                filterTreeItemData,
                ...(props?.[0] || []),
              })}

            {type === 'selectOp' && (
              <TDRangeInput
                name={name}
                keyName={keyName}
                operatorOptions={operatorOptions}
                TDState={TDState}
                setTDState={setTDState}
                defaultOption={defaultOption}
                selectionCategory={selectionCategory}
                defaultState={defaultState}
              />
            )}

            {type === 'selectCreate' && (
              <TDCreatableSelect
                name={name}
                keyName={keyName}
                placeholderText={placeholderText || name}
                TDState={TDState}
                setTDState={setTDState}
                selectionCategory={selectionCategory}
                defaultState={defaultState}
                props={props}
              />
            )}

            {type === 'customDate' && (
              <CustomFilterRange
                dateRangeValue={TDState[keyName]}
                setDateRangeValue={(newState) => {
                  setTDState((prev) => {
                    return { ...prev, [keyName]: newState };
                  });
                }}
                showYearDropdown
                scrollableYearDropdown
                dateFormat={'yyyy-MM-dd'}
                maxEndDate={new Date()}
                autocomplete="off"
                error={dateRangeError}
                setError={setDateRangeError}
              />
            )}

            {type === 'customKeyword' && (
              <TDPasteSelect state={TDState} setState={setTDState} />
            )}

            {type === 'nameInput' && (
              <TDNameInput
                state={TDState}
                setState={setTDState}
                setIsSubmitDisabled={setIsSubmitDisabled}
                isTypeaheadDisabled={false}
              />
            )}
          </Box>
        )
      )}
    </>
  );
}

export default TalentDiscoveryFilterMenu;
