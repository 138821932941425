import { CompanyStat } from '../../company-stat';
import { MonthlyLineChartProps, MonthlyLineChart } from '../monthly-line-chart';
import {
  CompanySentimentMetric,
  getAggregatedCompetitorSentiment,
} from '../utils/get-aggregated-competitor-metrics';
import { CompanyStatsProps } from '../types';
import { calculatePercentageChange } from '../utils/calculate-percentage-change';
import { Text } from '@chakra-ui/react';

interface SentimentChartProps {
  sentimentData: CompanyStatsProps['sentimentData'];
  selectedCompanyRCID: CompanyStatsProps['selectedCompanyRCID'];
  competitors: CompanyStatsProps['competitors'];
  companyName: string | null | undefined;
}
export const SentimentChart = ({
  selectedCompanyRCID,
  companyName,
  competitors,
  sentimentData,
}: SentimentChartProps) => {
  const primarySentimentData = sentimentData?.sentiment2d?.find(
    (datum) => datum?.metadata?.id === selectedCompanyRCID
  );
  const primaryCompanyCategories = primarySentimentData?.category || [];

  const sentimentTimeseries: MonthlyLineChartProps['timeSeries'] =
    primaryCompanyCategories?.map((datum) => ({
      id: datum?.metadata?.id,
      value: datum?.metrics?.overallRating,
      date: datum?.metadata?.shortName,
      count: datum?.metrics?.overallRatingCount,
    })) || [];

  const { lastValue, percentageChange } =
    calculatePercentageChange(sentimentTimeseries);

  const aggregatedCompetitorSentiment = sentimentData?.sentiment2d
    ? getAggregatedCompetitorSentiment(
        competitors,
        sentimentData.sentiment2d,
        CompanySentimentMetric.OverallRating
      )
    : 0;

  const vsCompetitors = lastValue - aggregatedCompetitorSentiment;

  return (
    <CompanyStat
      name={CompanySentimentMetric.OverallRating}
      plotInfo={
        <Text color="white">
          The average score given by workers on the topic of Overall
        </Text>
      }
      header="Sentiment"
      statValues={{
        primaryValue: lastValue.toFixed(2),
        percentChange: percentageChange,
        vsCompetitors,
      }}
      competitorFormatting={(value: number) => value.toFixed(2)}
    >
      <MonthlyLineChart
        name={CompanySentimentMetric.OverallRating}
        companyName={companyName}
        timeSeries={sentimentTimeseries}
        chartProps={{
          ttType: 'percent',
          ttMainFormat: '.2f',
          ttSecondaryFormat: ',',
          ttPosition: 'left',
        }}
      />
    </CompanyStat>
  );
};
