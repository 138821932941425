import { CompensationFilters } from '@revelio/data-access';
import {
  Filter,
  FilterName,
  FilterParameterKeys,
  FilterTypes,
  formatAndBreakoutFilters,
  GqlFilterKeyMapper,
  LocalSelectionCategories,
  SelectionCategories,
} from '@revelio/filtering';
import { mapKeys } from 'lodash';

export const transformFiltersToPayQuery = ({
  filters,
}: {
  filters: Filter[];
}): CompensationFilters => {
  const { formattedFilter } = formatAndBreakoutFilters(filters, [
    SelectionCategories.PRIMARY_FILTER,
  ]);

  const mappedFilters: CompensationFilters = mapKeys(
    formattedFilter,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof formattedFilter;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  return mappedFilters;
};

export const transformFiltersToPostingsQuery = ({
  filters,
}: {
  filters: Filter[];
}) => {
  const filtersWithProvider: Filter[] = [
    ...filters,
    {
      id: LocalSelectionCategories.PROVIDER,
      value: [
        {
          id: 1,
          label: 'Other (Indeed, ZipRecruiter, etc.)',
          shortName: 'Other (Indeed, ZipRecruiter, etc.)',
          parentId: -1,
        },
        {
          id: 2,
          label: 'Website Postings',
          data: { shortName: 'Website Postings' },
        },
        {
          id: 3,
          label: 'LinkedIn',
          shortName: 'LinkedIn',
          parentId: -1,
        },
      ],
      isMulti: true,
      label: LocalSelectionCategories.PROVIDER,
      selectionListId: LocalSelectionCategories.PROVIDER,
      type: FilterTypes.SELECT,
    },
  ] as Filter[];

  const { formattedFilter } = formatAndBreakoutFilters(filtersWithProvider, [
    SelectionCategories.PRIMARY_FILTER,
  ]);

  const mappedFilters: CompensationFilters = mapKeys(
    formattedFilter,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof formattedFilter;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  return mappedFilters;
};
