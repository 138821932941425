import { PrimaryView } from '@revelio/core';
import { GroupedBarData } from '@revelio/d3';
import {
  FilterName,
  LocalSelectionCategories,
  POSTING_TOP_ROLES_GET_DATA,
  useActiveFiltersState,
  useSelectFilterById,
} from '@revelio/filtering';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'urql';
import {
  getGroupedBarData,
  transformFiltersToPostingVariables,
} from '../utils';
import { useIsQueryReady } from './use-is-query-ready';

type UsePostingsTopDataFetchProps = {
  view: PrimaryView;
  primaryFilters: FilterName[];
};

export const usePostingsTopDataFetch = ({
  view,
  primaryFilters,
}: UsePostingsTopDataFetchProps) => {
  /** ======== Get Filter State ======== */
  const activeFilters = useActiveFiltersState();
  const providerFilter = useSelectFilterById(LocalSelectionCategories.PROVIDER);

  /** ======== Transform Filters ======== */
  const queryFilters = useMemo(
    () =>
      transformFiltersToPostingVariables({
        view,
        filters: [
          ...activeFilters,
          ...(providerFilter ? [providerFilter] : []),
        ],
      }),
    [view, activeFilters, providerFilter]
  );

  /** ======== Fetch ======== */
  const isQueryReady = useIsQueryReady({ activeFilters, primaryFilters });
  const [{ data, fetching, error }] = useQuery({
    query: POSTING_TOP_ROLES_GET_DATA,
    variables: queryFilters,
    pause: !isQueryReady,
  });

  const loading = useMemo(() => {
    const hasFetchBeenCalled = fetching || !!data || !!error;
    return fetching || !hasFetchBeenCalled;
  }, [data, error, fetching]);

  /** ======== Top Charts ======== */
  const [topData, setTopData] = useState<GroupedBarData[]>([]);
  useEffect(() => {
    setTopData(getGroupedBarData(data?.posting));
  }, [data]);

  return { topData, loading, error };
};
