export const ttfMetro = [
  {
    percentile: 0,
    z_score: -2.878161689,
  },
  {
    percentile: 0.1117318436,
    z_score: -2.444645551,
  },
  {
    percentile: 0.2234636872,
    z_score: -2.019142581,
  },
  {
    percentile: 0.3351955307,
    z_score: -1.742265294,
  },
  {
    percentile: 0.4469273743,
    z_score: -1.740617572,
  },
  {
    percentile: 0.5586592179,
    z_score: -1.711001905,
  },
  {
    percentile: 0.6703910615,
    z_score: -1.664750088,
  },
  {
    percentile: 0.782122905,
    z_score: -1.6315499,
  },
  {
    percentile: 0.8938547486,
    z_score: -1.58175658,
  },
  {
    percentile: 1.005586592,
    z_score: -1.509939982,
  },
  {
    percentile: 1.117318436,
    z_score: -1.503641671,
  },
  {
    percentile: 1.229050279,
    z_score: -1.498642106,
  },
  {
    percentile: 1.340782123,
    z_score: -1.487775822,
  },
  {
    percentile: 1.452513966,
    z_score: -1.45196663,
  },
  {
    percentile: 1.56424581,
    z_score: -1.429169706,
  },
  {
    percentile: 1.675977654,
    z_score: -1.425295952,
  },
  {
    percentile: 1.787709497,
    z_score: -1.361340905,
  },
  {
    percentile: 1.899441341,
    z_score: -1.353626771,
  },
  {
    percentile: 2.011173184,
    z_score: -1.286389815,
  },
  {
    percentile: 2.122905028,
    z_score: -1.240438276,
  },
  {
    percentile: 2.234636872,
    z_score: -1.22254251,
  },
  {
    percentile: 2.346368715,
    z_score: -1.200067179,
  },
  {
    percentile: 2.458100559,
    z_score: -1.198951558,
  },
  {
    percentile: 2.569832402,
    z_score: -1.193053567,
  },
  {
    percentile: 2.681564246,
    z_score: -1.18221171,
  },
  {
    percentile: 2.793296089,
    z_score: -1.136534156,
  },
  {
    percentile: 2.905027933,
    z_score: -1.11337713,
  },
  {
    percentile: 3.016759777,
    z_score: -1.084730135,
  },
  {
    percentile: 3.12849162,
    z_score: -1.075376214,
  },
  {
    percentile: 3.240223464,
    z_score: -1.069487013,
  },
  {
    percentile: 3.351955307,
    z_score: -1.066221597,
  },
  {
    percentile: 3.463687151,
    z_score: -1.040239849,
  },
  {
    percentile: 3.575418994,
    z_score: -1.026701161,
  },
  {
    percentile: 3.687150838,
    z_score: -1.021919881,
  },
  {
    percentile: 3.798882682,
    z_score: -1.012325037,
  },
  {
    percentile: 3.910614525,
    z_score: -1.011142669,
  },
  {
    percentile: 4.022346369,
    z_score: -1.004784691,
  },
  {
    percentile: 4.134078212,
    z_score: -0.9862829218,
  },
  {
    percentile: 4.245810056,
    z_score: -0.9583846011,
  },
  {
    percentile: 4.357541899,
    z_score: -0.9278769658,
  },
  {
    percentile: 4.469273743,
    z_score: -0.891805536,
  },
  {
    percentile: 4.581005587,
    z_score: -0.890534874,
  },
  {
    percentile: 4.69273743,
    z_score: -0.8847696748,
  },
  {
    percentile: 4.804469274,
    z_score: -0.8791448133,
  },
  {
    percentile: 4.916201117,
    z_score: -0.8619533799,
  },
  {
    percentile: 5.027932961,
    z_score: -0.8545442705,
  },
  {
    percentile: 5.139664804,
    z_score: -0.8431296274,
  },
  {
    percentile: 5.251396648,
    z_score: -0.8399241891,
  },
  {
    percentile: 5.363128492,
    z_score: -0.8396099076,
  },
  {
    percentile: 5.474860335,
    z_score: -0.8325571655,
  },
  {
    percentile: 5.586592179,
    z_score: -0.8309447612,
  },
  {
    percentile: 5.698324022,
    z_score: -0.8222181587,
  },
  {
    percentile: 5.810055866,
    z_score: -0.8213005188,
  },
  {
    percentile: 5.921787709,
    z_score: -0.8155119819,
  },
  {
    percentile: 6.033519553,
    z_score: -0.8092177163,
  },
  {
    percentile: 6.145251397,
    z_score: -0.8082579129,
  },
  {
    percentile: 6.25698324,
    z_score: -0.8036960853,
  },
  {
    percentile: 6.368715084,
    z_score: -0.8014319358,
  },
  {
    percentile: 6.480446927,
    z_score: -0.8003345176,
  },
  {
    percentile: 6.592178771,
    z_score: -0.7969385656,
  },
  {
    percentile: 6.703910615,
    z_score: -0.7946383203,
  },
  {
    percentile: 6.815642458,
    z_score: -0.7945712632,
  },
  {
    percentile: 6.927374302,
    z_score: -0.7919653715,
  },
  {
    percentile: 7.039106145,
    z_score: -0.7895056517,
  },
  {
    percentile: 7.150837989,
    z_score: -0.7870320849,
  },
  {
    percentile: 7.262569832,
    z_score: -0.7863422991,
  },
  {
    percentile: 7.374301676,
    z_score: -0.7850399756,
  },
  {
    percentile: 7.48603352,
    z_score: -0.7846888982,
  },
  {
    percentile: 7.597765363,
    z_score: -0.7821483521,
  },
  {
    percentile: 7.709497207,
    z_score: -0.7820899299,
  },
  {
    percentile: 7.82122905,
    z_score: -0.7819671734,
  },
  {
    percentile: 7.932960894,
    z_score: -0.7786091841,
  },
  {
    percentile: 8.044692737,
    z_score: -0.7762071864,
  },
  {
    percentile: 8.156424581,
    z_score: -0.7748844812,
  },
  {
    percentile: 8.268156425,
    z_score: -0.7705446733,
  },
  {
    percentile: 8.379888268,
    z_score: -0.7662959603,
  },
  {
    percentile: 8.491620112,
    z_score: -0.7619931038,
  },
  {
    percentile: 8.603351955,
    z_score: -0.760766239,
  },
  {
    percentile: 8.715083799,
    z_score: -0.7598132035,
  },
  {
    percentile: 8.826815642,
    z_score: -0.7582914275,
  },
  {
    percentile: 8.938547486,
    z_score: -0.7582337832,
  },
  {
    percentile: 9.05027933,
    z_score: -0.7571673264,
  },
  {
    percentile: 9.162011173,
    z_score: -0.755314699,
  },
  {
    percentile: 9.273743017,
    z_score: -0.7526618984,
  },
  {
    percentile: 9.38547486,
    z_score: -0.7525438872,
  },
  {
    percentile: 9.497206704,
    z_score: -0.7499299828,
  },
  {
    percentile: 9.608938547,
    z_score: -0.7485931194,
  },
  {
    percentile: 9.720670391,
    z_score: -0.7481731957,
  },
  {
    percentile: 9.832402235,
    z_score: -0.7474449027,
  },
  {
    percentile: 9.944134078,
    z_score: -0.7459862941,
  },
  {
    percentile: 10.055865922,
    z_score: -0.7457911128,
  },
  {
    percentile: 10.167597765,
    z_score: -0.743938252,
  },
  {
    percentile: 10.279329609,
    z_score: -0.7430788787,
  },
  {
    percentile: 10.391061453,
    z_score: -0.7411579495,
  },
  {
    percentile: 10.502793296,
    z_score: -0.7404123088,
  },
  {
    percentile: 10.61452514,
    z_score: -0.7401486701,
  },
  {
    percentile: 10.726256983,
    z_score: -0.7383891603,
  },
  {
    percentile: 10.837988827,
    z_score: -0.7352658708,
  },
  {
    percentile: 10.94972067,
    z_score: -0.7345834754,
  },
  {
    percentile: 11.061452514,
    z_score: -0.7335978449,
  },
  {
    percentile: 11.173184358,
    z_score: -0.7324280019,
  },
  {
    percentile: 11.284916201,
    z_score: -0.7321737762,
  },
  {
    percentile: 11.396648045,
    z_score: -0.7310016772,
  },
  {
    percentile: 11.508379888,
    z_score: -0.7306558119,
  },
  {
    percentile: 11.620111732,
    z_score: -0.728886578,
  },
  {
    percentile: 11.731843575,
    z_score: -0.7275199201,
  },
  {
    percentile: 11.843575419,
    z_score: -0.7269600478,
  },
  {
    percentile: 11.955307263,
    z_score: -0.7246331197,
  },
  {
    percentile: 12.067039106,
    z_score: -0.719633165,
  },
  {
    percentile: 12.17877095,
    z_score: -0.7190032795,
  },
  {
    percentile: 12.290502793,
    z_score: -0.7186881422,
  },
  {
    percentile: 12.402234637,
    z_score: -0.717481659,
  },
  {
    percentile: 12.51396648,
    z_score: -0.716796463,
  },
  {
    percentile: 12.625698324,
    z_score: -0.7165679087,
  },
  {
    percentile: 12.737430168,
    z_score: -0.7162708971,
  },
  {
    percentile: 12.849162011,
    z_score: -0.7155089978,
  },
  {
    percentile: 12.960893855,
    z_score: -0.7138138223,
  },
  {
    percentile: 13.072625698,
    z_score: -0.7129064511,
  },
  {
    percentile: 13.184357542,
    z_score: -0.7115296023,
  },
  {
    percentile: 13.296089385,
    z_score: -0.7112339132,
  },
  {
    percentile: 13.407821229,
    z_score: -0.7091474416,
  },
  {
    percentile: 13.519553073,
    z_score: -0.707588403,
  },
  {
    percentile: 13.631284916,
    z_score: -0.7074311066,
  },
  {
    percentile: 13.74301676,
    z_score: -0.7069536943,
  },
  {
    percentile: 13.854748603,
    z_score: -0.7051800262,
  },
  {
    percentile: 13.966480447,
    z_score: -0.703381698,
  },
  {
    percentile: 14.078212291,
    z_score: -0.7025509523,
  },
  {
    percentile: 14.189944134,
    z_score: -0.701381965,
  },
  {
    percentile: 14.301675978,
    z_score: -0.7008241153,
  },
  {
    percentile: 14.413407821,
    z_score: -0.6999771888,
  },
  {
    percentile: 14.525139665,
    z_score: -0.6997448227,
  },
  {
    percentile: 14.636871508,
    z_score: -0.6990116288,
  },
  {
    percentile: 14.748603352,
    z_score: -0.6988782925,
  },
  {
    percentile: 14.860335196,
    z_score: -0.6988777479,
  },
  {
    percentile: 14.972067039,
    z_score: -0.698809135,
  },
  {
    percentile: 15.083798883,
    z_score: -0.6970430906,
  },
  {
    percentile: 15.195530726,
    z_score: -0.6954272634,
  },
  {
    percentile: 15.30726257,
    z_score: -0.693085477,
  },
  {
    percentile: 15.418994413,
    z_score: -0.6927178299,
  },
  {
    percentile: 15.530726257,
    z_score: -0.6891869857,
  },
  {
    percentile: 15.642458101,
    z_score: -0.6878333969,
  },
  {
    percentile: 15.754189944,
    z_score: -0.6863620304,
  },
  {
    percentile: 15.865921788,
    z_score: -0.6846320039,
  },
  {
    percentile: 15.977653631,
    z_score: -0.6842354179,
  },
  {
    percentile: 16.089385475,
    z_score: -0.6836891593,
  },
  {
    percentile: 16.201117318,
    z_score: -0.6825069473,
  },
  {
    percentile: 16.312849162,
    z_score: -0.6820737211,
  },
  {
    percentile: 16.424581006,
    z_score: -0.6820489831,
  },
  {
    percentile: 16.536312849,
    z_score: -0.6819405404,
  },
  {
    percentile: 16.648044693,
    z_score: -0.6816911377,
  },
  {
    percentile: 16.759776536,
    z_score: -0.6815632469,
  },
  {
    percentile: 16.87150838,
    z_score: -0.681054562,
  },
  {
    percentile: 16.983240223,
    z_score: -0.6799599444,
  },
  {
    percentile: 17.094972067,
    z_score: -0.678641051,
  },
  {
    percentile: 17.206703911,
    z_score: -0.6784152973,
  },
  {
    percentile: 17.318435754,
    z_score: -0.67695171,
  },
  {
    percentile: 17.430167598,
    z_score: -0.6746266489,
  },
  {
    percentile: 17.541899441,
    z_score: -0.6740135666,
  },
  {
    percentile: 17.653631285,
    z_score: -0.6739557668,
  },
  {
    percentile: 17.765363128,
    z_score: -0.6738844311,
  },
  {
    percentile: 17.877094972,
    z_score: -0.6717358034,
  },
  {
    percentile: 17.988826816,
    z_score: -0.6704268674,
  },
  {
    percentile: 18.100558659,
    z_score: -0.6700209464,
  },
  {
    percentile: 18.212290503,
    z_score: -0.669831444,
  },
  {
    percentile: 18.324022346,
    z_score: -0.6665958222,
  },
  {
    percentile: 18.43575419,
    z_score: -0.6648602724,
  },
  {
    percentile: 18.547486034,
    z_score: -0.6622213966,
  },
  {
    percentile: 18.659217877,
    z_score: -0.6621050191,
  },
  {
    percentile: 18.770949721,
    z_score: -0.6615969566,
  },
  {
    percentile: 18.882681564,
    z_score: -0.6614929481,
  },
  {
    percentile: 18.994413408,
    z_score: -0.6608019954,
  },
  {
    percentile: 19.106145251,
    z_score: -0.6591850792,
  },
  {
    percentile: 19.217877095,
    z_score: -0.658312559,
  },
  {
    percentile: 19.329608939,
    z_score: -0.6583082026,
  },
  {
    percentile: 19.441340782,
    z_score: -0.6580389629,
  },
  {
    percentile: 19.553072626,
    z_score: -0.6576437772,
  },
  {
    percentile: 19.664804469,
    z_score: -0.6558188439,
  },
  {
    percentile: 19.776536313,
    z_score: -0.6557654005,
  },
  {
    percentile: 19.888268156,
    z_score: -0.6548930358,
  },
  {
    percentile: 20,
    z_score: -0.654158675,
  },
  {
    percentile: 20.111731844,
    z_score: -0.65362004,
  },
  {
    percentile: 20.223463687,
    z_score: -0.6526995996,
  },
  {
    percentile: 20.335195531,
    z_score: -0.6525700752,
  },
  {
    percentile: 20.446927374,
    z_score: -0.6521722446,
  },
  {
    percentile: 20.558659218,
    z_score: -0.6502317895,
  },
  {
    percentile: 20.670391061,
    z_score: -0.649097731,
  },
  {
    percentile: 20.782122905,
    z_score: -0.6490964863,
  },
  {
    percentile: 20.893854749,
    z_score: -0.6488025864,
  },
  {
    percentile: 21.005586592,
    z_score: -0.6486005594,
  },
  {
    percentile: 21.117318436,
    z_score: -0.6476307986,
  },
  {
    percentile: 21.229050279,
    z_score: -0.6446073947,
  },
  {
    percentile: 21.340782123,
    z_score: -0.6446054499,
  },
  {
    percentile: 21.452513966,
    z_score: -0.643221911,
  },
  {
    percentile: 21.56424581,
    z_score: -0.642483505,
  },
  {
    percentile: 21.675977654,
    z_score: -0.6419808101,
  },
  {
    percentile: 21.787709497,
    z_score: -0.64062022,
  },
  {
    percentile: 21.899441341,
    z_score: -0.6398583984,
  },
  {
    percentile: 22.011173184,
    z_score: -0.6396136633,
  },
  {
    percentile: 22.122905028,
    z_score: -0.6389164873,
  },
  {
    percentile: 22.234636872,
    z_score: -0.6346619399,
  },
  {
    percentile: 22.346368715,
    z_score: -0.6330216081,
  },
  {
    percentile: 22.458100559,
    z_score: -0.6325375057,
  },
  {
    percentile: 22.569832402,
    z_score: -0.6324723934,
  },
  {
    percentile: 22.681564246,
    z_score: -0.6318551102,
  },
  {
    percentile: 22.793296089,
    z_score: -0.6307425225,
  },
  {
    percentile: 22.905027933,
    z_score: -0.6297526912,
  },
  {
    percentile: 23.016759777,
    z_score: -0.6291031242,
  },
  {
    percentile: 23.12849162,
    z_score: -0.6281185828,
  },
  {
    percentile: 23.240223464,
    z_score: -0.6277706949,
  },
  {
    percentile: 23.351955307,
    z_score: -0.6272851922,
  },
  {
    percentile: 23.463687151,
    z_score: -0.6268831608,
  },
  {
    percentile: 23.575418994,
    z_score: -0.6261442102,
  },
  {
    percentile: 23.687150838,
    z_score: -0.6256286018,
  },
  {
    percentile: 23.798882682,
    z_score: -0.6242654445,
  },
  {
    percentile: 23.910614525,
    z_score: -0.6233796996,
  },
  {
    percentile: 24.022346369,
    z_score: -0.6225139473,
  },
  {
    percentile: 24.134078212,
    z_score: -0.621995305,
  },
  {
    percentile: 24.245810056,
    z_score: -0.6217222535,
  },
  {
    percentile: 24.357541899,
    z_score: -0.6206925147,
  },
  {
    percentile: 24.469273743,
    z_score: -0.6190938018,
  },
  {
    percentile: 24.581005587,
    z_score: -0.6189708898,
  },
  {
    percentile: 24.69273743,
    z_score: -0.6187911114,
  },
  {
    percentile: 24.804469274,
    z_score: -0.6186686661,
  },
  {
    percentile: 24.916201117,
    z_score: -0.6176624984,
  },
  {
    percentile: 25.027932961,
    z_score: -0.6174832646,
  },
  {
    percentile: 25.139664804,
    z_score: -0.6173606636,
  },
  {
    percentile: 25.251396648,
    z_score: -0.6158826069,
  },
  {
    percentile: 25.363128492,
    z_score: -0.6155314518,
  },
  {
    percentile: 25.474860335,
    z_score: -0.6151431118,
  },
  {
    percentile: 25.586592179,
    z_score: -0.6143994159,
  },
  {
    percentile: 25.698324022,
    z_score: -0.6138166726,
  },
  {
    percentile: 25.810055866,
    z_score: -0.6121589931,
  },
  {
    percentile: 25.921787709,
    z_score: -0.611588152,
  },
  {
    percentile: 26.033519553,
    z_score: -0.6098732172,
  },
  {
    percentile: 26.145251397,
    z_score: -0.6094683075,
  },
  {
    percentile: 26.25698324,
    z_score: -0.6078352882,
  },
  {
    percentile: 26.368715084,
    z_score: -0.6075865857,
  },
  {
    percentile: 26.480446927,
    z_score: -0.6066337059,
  },
  {
    percentile: 26.592178771,
    z_score: -0.6053274149,
  },
  {
    percentile: 26.703910615,
    z_score: -0.6050509405,
  },
  {
    percentile: 26.815642458,
    z_score: -0.6042220618,
  },
  {
    percentile: 26.927374302,
    z_score: -0.6034266339,
  },
  {
    percentile: 27.039106145,
    z_score: -0.6018970008,
  },
  {
    percentile: 27.150837989,
    z_score: -0.5995328102,
  },
  {
    percentile: 27.262569832,
    z_score: -0.5985317768,
  },
  {
    percentile: 27.374301676,
    z_score: -0.5973590555,
  },
  {
    percentile: 27.48603352,
    z_score: -0.5957097776,
  },
  {
    percentile: 27.597765363,
    z_score: -0.5944458835,
  },
  {
    percentile: 27.709497207,
    z_score: -0.5926872294,
  },
  {
    percentile: 27.82122905,
    z_score: -0.5901167332,
  },
  {
    percentile: 27.932960894,
    z_score: -0.5892972674,
  },
  {
    percentile: 28.044692737,
    z_score: -0.5869333879,
  },
  {
    percentile: 28.156424581,
    z_score: -0.5851417498,
  },
  {
    percentile: 28.268156425,
    z_score: -0.5845943243,
  },
  {
    percentile: 28.379888268,
    z_score: -0.5832582388,
  },
  {
    percentile: 28.491620112,
    z_score: -0.5817415192,
  },
  {
    percentile: 28.603351955,
    z_score: -0.5802339013,
  },
  {
    percentile: 28.715083799,
    z_score: -0.5798899031,
  },
  {
    percentile: 28.826815642,
    z_score: -0.578279677,
  },
  {
    percentile: 28.938547486,
    z_score: -0.5771873931,
  },
  {
    percentile: 29.05027933,
    z_score: -0.5758521633,
  },
  {
    percentile: 29.162011173,
    z_score: -0.5745337367,
  },
  {
    percentile: 29.273743017,
    z_score: -0.5743080608,
  },
  {
    percentile: 29.38547486,
    z_score: -0.5740434108,
  },
  {
    percentile: 29.497206704,
    z_score: -0.573810967,
  },
  {
    percentile: 29.608938547,
    z_score: -0.5699608626,
  },
  {
    percentile: 29.720670391,
    z_score: -0.5695780459,
  },
  {
    percentile: 29.832402235,
    z_score: -0.5669543396,
  },
  {
    percentile: 29.944134078,
    z_score: -0.5669206555,
  },
  {
    percentile: 30.055865922,
    z_score: -0.5664934193,
  },
  {
    percentile: 30.167597765,
    z_score: -0.5642913481,
  },
  {
    percentile: 30.279329609,
    z_score: -0.5636779546,
  },
  {
    percentile: 30.391061453,
    z_score: -0.5602849587,
  },
  {
    percentile: 30.502793296,
    z_score: -0.5576600856,
  },
  {
    percentile: 30.61452514,
    z_score: -0.5573533499,
  },
  {
    percentile: 30.726256983,
    z_score: -0.5573117309,
  },
  {
    percentile: 30.837988827,
    z_score: -0.5548859287,
  },
  {
    percentile: 30.94972067,
    z_score: -0.5522714019,
  },
  {
    percentile: 31.061452514,
    z_score: -0.5517155748,
  },
  {
    percentile: 31.173184358,
    z_score: -0.5507873552,
  },
  {
    percentile: 31.284916201,
    z_score: -0.5490338353,
  },
  {
    percentile: 31.396648045,
    z_score: -0.5488332086,
  },
  {
    percentile: 31.508379888,
    z_score: -0.5487802319,
  },
  {
    percentile: 31.620111732,
    z_score: -0.5482680464,
  },
  {
    percentile: 31.731843575,
    z_score: -0.5474929223,
  },
  {
    percentile: 31.843575419,
    z_score: -0.5422511886,
  },
  {
    percentile: 31.955307263,
    z_score: -0.5403728119,
  },
  {
    percentile: 32.067039106,
    z_score: -0.5400220457,
  },
  {
    percentile: 32.17877095,
    z_score: -0.5395951207,
  },
  {
    percentile: 32.290502793,
    z_score: -0.5386255155,
  },
  {
    percentile: 32.402234637,
    z_score: -0.5375658266,
  },
  {
    percentile: 32.51396648,
    z_score: -0.5336565222,
  },
  {
    percentile: 32.625698324,
    z_score: -0.5326713584,
  },
  {
    percentile: 32.737430168,
    z_score: -0.5322484786,
  },
  {
    percentile: 32.849162011,
    z_score: -0.5286475435,
  },
  {
    percentile: 32.960893855,
    z_score: -0.5286175934,
  },
  {
    percentile: 33.072625698,
    z_score: -0.5261299461,
  },
  {
    percentile: 33.184357542,
    z_score: -0.5252144845,
  },
  {
    percentile: 33.296089385,
    z_score: -0.5216351756,
  },
  {
    percentile: 33.407821229,
    z_score: -0.5205615619,
  },
  {
    percentile: 33.519553073,
    z_score: -0.5181437723,
  },
  {
    percentile: 33.631284916,
    z_score: -0.5174416953,
  },
  {
    percentile: 33.74301676,
    z_score: -0.5159906326,
  },
  {
    percentile: 33.854748603,
    z_score: -0.5158838236,
  },
  {
    percentile: 33.966480447,
    z_score: -0.5126676499,
  },
  {
    percentile: 34.078212291,
    z_score: -0.5118106882,
  },
  {
    percentile: 34.189944134,
    z_score: -0.5111853146,
  },
  {
    percentile: 34.301675978,
    z_score: -0.5102233331,
  },
  {
    percentile: 34.413407821,
    z_score: -0.5098994831,
  },
  {
    percentile: 34.525139665,
    z_score: -0.5087819944,
  },
  {
    percentile: 34.636871508,
    z_score: -0.5041881941,
  },
  {
    percentile: 34.748603352,
    z_score: -0.5032603634,
  },
  {
    percentile: 34.860335196,
    z_score: -0.5013734295,
  },
  {
    percentile: 34.972067039,
    z_score: -0.498180827,
  },
  {
    percentile: 35.083798883,
    z_score: -0.4980590818,
  },
  {
    percentile: 35.195530726,
    z_score: -0.4977963767,
  },
  {
    percentile: 35.30726257,
    z_score: -0.4964864295,
  },
  {
    percentile: 35.418994413,
    z_score: -0.4948135804,
  },
  {
    percentile: 35.530726257,
    z_score: -0.4944535569,
  },
  {
    percentile: 35.642458101,
    z_score: -0.4926589627,
  },
  {
    percentile: 35.754189944,
    z_score: -0.4909315811,
  },
  {
    percentile: 35.865921788,
    z_score: -0.4889877809,
  },
  {
    percentile: 35.977653631,
    z_score: -0.4877382785,
  },
  {
    percentile: 36.089385475,
    z_score: -0.4873401367,
  },
  {
    percentile: 36.201117318,
    z_score: -0.4862750801,
  },
  {
    percentile: 36.312849162,
    z_score: -0.4841463672,
  },
  {
    percentile: 36.424581006,
    z_score: -0.4808156831,
  },
  {
    percentile: 36.536312849,
    z_score: -0.4796466958,
  },
  {
    percentile: 36.648044693,
    z_score: -0.474633672,
  },
  {
    percentile: 36.759776536,
    z_score: -0.4727399701,
  },
  {
    percentile: 36.87150838,
    z_score: -0.4726084231,
  },
  {
    percentile: 36.983240223,
    z_score: -0.4717763549,
  },
  {
    percentile: 37.094972067,
    z_score: -0.4707047638,
  },
  {
    percentile: 37.206703911,
    z_score: -0.4696593887,
  },
  {
    percentile: 37.318435754,
    z_score: -0.4590008105,
  },
  {
    percentile: 37.430167598,
    z_score: -0.458667781,
  },
  {
    percentile: 37.541899441,
    z_score: -0.4561197668,
  },
  {
    percentile: 37.653631285,
    z_score: -0.4446183851,
  },
  {
    percentile: 37.765363128,
    z_score: -0.442073327,
  },
  {
    percentile: 37.877094972,
    z_score: -0.4387960863,
  },
  {
    percentile: 37.988826816,
    z_score: -0.4377538229,
  },
  {
    percentile: 38.100558659,
    z_score: -0.4303450247,
  },
  {
    percentile: 38.212290503,
    z_score: -0.42893667,
  },
  {
    percentile: 38.324022346,
    z_score: -0.4276442261,
  },
  {
    percentile: 38.43575419,
    z_score: -0.422052193,
  },
  {
    percentile: 38.547486034,
    z_score: -0.4205519654,
  },
  {
    percentile: 38.659217877,
    z_score: -0.4199016982,
  },
  {
    percentile: 38.770949721,
    z_score: -0.4074407465,
  },
  {
    percentile: 38.882681564,
    z_score: -0.4052278622,
  },
  {
    percentile: 38.994413408,
    z_score: -0.4044563166,
  },
  {
    percentile: 39.106145251,
    z_score: -0.4032035469,
  },
  {
    percentile: 39.217877095,
    z_score: -0.4023937274,
  },
  {
    percentile: 39.329608939,
    z_score: -0.4020476287,
  },
  {
    percentile: 39.441340782,
    z_score: -0.3995043599,
  },
  {
    percentile: 39.553072626,
    z_score: -0.3993387397,
  },
  {
    percentile: 39.664804469,
    z_score: -0.3990677886,
  },
  {
    percentile: 39.776536313,
    z_score: -0.3972109604,
  },
  {
    percentile: 39.888268156,
    z_score: -0.3963581216,
  },
  {
    percentile: 40,
    z_score: -0.395345575,
  },
  {
    percentile: 40.111731844,
    z_score: -0.3946059243,
  },
  {
    percentile: 40.223463687,
    z_score: -0.3939877854,
  },
  {
    percentile: 40.335195531,
    z_score: -0.3900291605,
  },
  {
    percentile: 40.446927374,
    z_score: -0.3888029958,
  },
  {
    percentile: 40.558659218,
    z_score: -0.3840765594,
  },
  {
    percentile: 40.670391061,
    z_score: -0.3831980491,
  },
  {
    percentile: 40.782122905,
    z_score: -0.3822641506,
  },
  {
    percentile: 40.893854749,
    z_score: -0.3799593157,
  },
  {
    percentile: 41.005586592,
    z_score: -0.3783292525,
  },
  {
    percentile: 41.117318436,
    z_score: -0.3781267587,
  },
  {
    percentile: 41.229050279,
    z_score: -0.3751828587,
  },
  {
    percentile: 41.340782123,
    z_score: -0.3729768201,
  },
  {
    percentile: 41.452513966,
    z_score: -0.3726988677,
  },
  {
    percentile: 41.56424581,
    z_score: -0.3712628189,
  },
  {
    percentile: 41.675977654,
    z_score: -0.3691493533,
  },
  {
    percentile: 41.787709497,
    z_score: -0.3688334382,
  },
  {
    percentile: 41.899441341,
    z_score: -0.3688088557,
  },
  {
    percentile: 42.011173184,
    z_score: -0.3677379648,
  },
  {
    percentile: 42.122905028,
    z_score: -0.3635953607,
  },
  {
    percentile: 42.234636872,
    z_score: -0.3602346487,
  },
  {
    percentile: 42.346368715,
    z_score: -0.3586322796,
  },
  {
    percentile: 42.458100559,
    z_score: -0.3550602055,
  },
  {
    percentile: 42.569832402,
    z_score: -0.3533970805,
  },
  {
    percentile: 42.681564246,
    z_score: -0.3521866298,
  },
  {
    percentile: 42.793296089,
    z_score: -0.3518253616,
  },
  {
    percentile: 42.905027933,
    z_score: -0.351487898,
  },
  {
    percentile: 43.016759777,
    z_score: -0.3494381445,
  },
  {
    percentile: 43.12849162,
    z_score: -0.3485703696,
  },
  {
    percentile: 43.240223464,
    z_score: -0.3484763185,
  },
  {
    percentile: 43.351955307,
    z_score: -0.3480920237,
  },
  {
    percentile: 43.463687151,
    z_score: -0.3457664182,
  },
  {
    percentile: 43.575418994,
    z_score: -0.3419556768,
  },
  {
    percentile: 43.687150838,
    z_score: -0.3414449693,
  },
  {
    percentile: 43.798882682,
    z_score: -0.3405410987,
  },
  {
    percentile: 43.910614525,
    z_score: -0.3388963327,
  },
  {
    percentile: 44.022346369,
    z_score: -0.3388232078,
  },
  {
    percentile: 44.134078212,
    z_score: -0.3387167099,
  },
  {
    percentile: 44.245810056,
    z_score: -0.3367401591,
  },
  {
    percentile: 44.357541899,
    z_score: -0.3365921201,
  },
  {
    percentile: 44.469273743,
    z_score: -0.3325276197,
  },
  {
    percentile: 44.581005587,
    z_score: -0.3307270354,
  },
  {
    percentile: 44.69273743,
    z_score: -0.3285301764,
  },
  {
    percentile: 44.804469274,
    z_score: -0.3282190843,
  },
  {
    percentile: 44.916201117,
    z_score: -0.3270814474,
  },
  {
    percentile: 45.027932961,
    z_score: -0.3250423515,
  },
  {
    percentile: 45.139664804,
    z_score: -0.3249738163,
  },
  {
    percentile: 45.251396648,
    z_score: -0.3234248129,
  },
  {
    percentile: 45.363128492,
    z_score: -0.3197975061,
  },
  {
    percentile: 45.474860335,
    z_score: -0.3175927122,
  },
  {
    percentile: 45.586592179,
    z_score: -0.3175196651,
  },
  {
    percentile: 45.698324022,
    z_score: -0.3160935737,
  },
  {
    percentile: 45.810055866,
    z_score: -0.3151686992,
  },
  {
    percentile: 45.921787709,
    z_score: -0.3138365033,
  },
  {
    percentile: 46.033519553,
    z_score: -0.3110105367,
  },
  {
    percentile: 46.145251397,
    z_score: -0.306090008,
  },
  {
    percentile: 46.25698324,
    z_score: -0.3050879634,
  },
  {
    percentile: 46.368715084,
    z_score: -0.3021935393,
  },
  {
    percentile: 46.480446927,
    z_score: -0.299888471,
  },
  {
    percentile: 46.592178771,
    z_score: -0.2995079881,
  },
  {
    percentile: 46.703910615,
    z_score: -0.2965761459,
  },
  {
    percentile: 46.815642458,
    z_score: -0.2947481787,
  },
  {
    percentile: 46.927374302,
    z_score: -0.2930608602,
  },
  {
    percentile: 47.039106145,
    z_score: -0.2929665758,
  },
  {
    percentile: 47.150837989,
    z_score: -0.2924907193,
  },
  {
    percentile: 47.262569832,
    z_score: -0.2917618039,
  },
  {
    percentile: 47.374301676,
    z_score: -0.2887392557,
  },
  {
    percentile: 47.48603352,
    z_score: -0.2878262834,
  },
  {
    percentile: 47.597765363,
    z_score: -0.2876587185,
  },
  {
    percentile: 47.709497207,
    z_score: -0.279845555,
  },
  {
    percentile: 47.82122905,
    z_score: -0.2788563461,
  },
  {
    percentile: 47.932960894,
    z_score: -0.2753174894,
  },
  {
    percentile: 48.044692737,
    z_score: -0.2743750337,
  },
  {
    percentile: 48.156424581,
    z_score: -0.2733111441,
  },
  {
    percentile: 48.268156425,
    z_score: -0.2731155738,
  },
  {
    percentile: 48.379888268,
    z_score: -0.2724996131,
  },
  {
    percentile: 48.491620112,
    z_score: -0.2712389863,
  },
  {
    percentile: 48.603351955,
    z_score: -0.2676727466,
  },
  {
    percentile: 48.715083799,
    z_score: -0.2655183623,
  },
  {
    percentile: 48.826815642,
    z_score: -0.2623356394,
  },
  {
    percentile: 48.938547486,
    z_score: -0.2606147924,
  },
  {
    percentile: 49.05027933,
    z_score: -0.2514424391,
  },
  {
    percentile: 49.162011173,
    z_score: -0.2477937394,
  },
  {
    percentile: 49.273743017,
    z_score: -0.244556095,
  },
  {
    percentile: 49.38547486,
    z_score: -0.2422441032,
  },
  {
    percentile: 49.497206704,
    z_score: -0.2409973234,
  },
  {
    percentile: 49.608938547,
    z_score: -0.2391022991,
  },
  {
    percentile: 49.720670391,
    z_score: -0.2354375742,
  },
  {
    percentile: 49.832402235,
    z_score: -0.2315624206,
  },
  {
    percentile: 49.944134078,
    z_score: -0.2308857818,
  },
  {
    percentile: 50.055865922,
    z_score: -0.2279152767,
  },
  {
    percentile: 50.167597765,
    z_score: -0.2237653602,
  },
  {
    percentile: 50.279329609,
    z_score: -0.220088733,
  },
  {
    percentile: 50.391061453,
    z_score: -0.2185089237,
  },
  {
    percentile: 50.502793296,
    z_score: -0.2183639964,
  },
  {
    percentile: 50.61452514,
    z_score: -0.2141116272,
  },
  {
    percentile: 50.726256983,
    z_score: -0.2138931082,
  },
  {
    percentile: 50.837988827,
    z_score: -0.2135459982,
  },
  {
    percentile: 50.94972067,
    z_score: -0.211416663,
  },
  {
    percentile: 51.061452514,
    z_score: -0.2049510984,
  },
  {
    percentile: 51.173184358,
    z_score: -0.2024321008,
  },
  {
    percentile: 51.284916201,
    z_score: -0.2016596216,
  },
  {
    percentile: 51.396648045,
    z_score: -0.2015160168,
  },
  {
    percentile: 51.508379888,
    z_score: -0.2014508267,
  },
  {
    percentile: 51.620111732,
    z_score: -0.1998556923,
  },
  {
    percentile: 51.731843575,
    z_score: -0.1945114282,
  },
  {
    percentile: 51.843575419,
    z_score: -0.1937061984,
  },
  {
    percentile: 51.955307263,
    z_score: -0.1935646161,
  },
  {
    percentile: 52.067039106,
    z_score: -0.1930672889,
  },
  {
    percentile: 52.17877095,
    z_score: -0.1916081358,
  },
  {
    percentile: 52.290502793,
    z_score: -0.1885102067,
  },
  {
    percentile: 52.402234637,
    z_score: -0.1883287169,
  },
  {
    percentile: 52.51396648,
    z_score: -0.1880684233,
  },
  {
    percentile: 52.625698324,
    z_score: -0.1865563713,
  },
  {
    percentile: 52.737430168,
    z_score: -0.1863078244,
  },
  {
    percentile: 52.849162011,
    z_score: -0.1859806293,
  },
  {
    percentile: 52.960893855,
    z_score: -0.1844260248,
  },
  {
    percentile: 53.072625698,
    z_score: -0.1837509418,
  },
  {
    percentile: 53.184357542,
    z_score: -0.1825546494,
  },
  {
    percentile: 53.296089385,
    z_score: -0.1783746272,
  },
  {
    percentile: 53.407821229,
    z_score: -0.1736903543,
  },
  {
    percentile: 53.519553073,
    z_score: -0.1735422374,
  },
  {
    percentile: 53.631284916,
    z_score: -0.1729669622,
  },
  {
    percentile: 53.74301676,
    z_score: -0.1708579308,
  },
  {
    percentile: 53.854748603,
    z_score: -0.1701487748,
  },
  {
    percentile: 53.966480447,
    z_score: -0.1693426115,
  },
  {
    percentile: 54.078212291,
    z_score: -0.1689559052,
  },
  {
    percentile: 54.189944134,
    z_score: -0.16467203,
  },
  {
    percentile: 54.301675978,
    z_score: -0.1630043153,
  },
  {
    percentile: 54.413407821,
    z_score: -0.1603459914,
  },
  {
    percentile: 54.525139665,
    z_score: -0.1589587963,
  },
  {
    percentile: 54.636871508,
    z_score: -0.1526611855,
  },
  {
    percentile: 54.748603352,
    z_score: -0.1517309433,
  },
  {
    percentile: 54.860335196,
    z_score: -0.1513788546,
  },
  {
    percentile: 54.972067039,
    z_score: -0.1505268716,
  },
  {
    percentile: 55.083798883,
    z_score: -0.1490871666,
  },
  {
    percentile: 55.195530726,
    z_score: -0.1477948005,
  },
  {
    percentile: 55.30726257,
    z_score: -0.1437688851,
  },
  {
    percentile: 55.418994413,
    z_score: -0.1419367949,
  },
  {
    percentile: 55.530726257,
    z_score: -0.1344379908,
  },
  {
    percentile: 55.642458101,
    z_score: -0.1338735287,
  },
  {
    percentile: 55.754189944,
    z_score: -0.1337272789,
  },
  {
    percentile: 55.865921788,
    z_score: -0.1330693103,
  },
  {
    percentile: 55.977653631,
    z_score: -0.1311216205,
  },
  {
    percentile: 56.089385475,
    z_score: -0.1281084074,
  },
  {
    percentile: 56.201117318,
    z_score: -0.1260977057,
  },
  {
    percentile: 56.312849162,
    z_score: -0.1245669057,
  },
  {
    percentile: 56.424581006,
    z_score: -0.1239638585,
  },
  {
    percentile: 56.536312849,
    z_score: -0.1226048243,
  },
  {
    percentile: 56.648044693,
    z_score: -0.1217204796,
  },
  {
    percentile: 56.759776536,
    z_score: -0.120334218,
  },
  {
    percentile: 56.87150838,
    z_score: -0.1165570829,
  },
  {
    percentile: 56.983240223,
    z_score: -0.1152670505,
  },
  {
    percentile: 57.094972067,
    z_score: -0.114536346,
  },
  {
    percentile: 57.206703911,
    z_score: -0.1121078988,
  },
  {
    percentile: 57.318435754,
    z_score: -0.108071637,
  },
  {
    percentile: 57.430167598,
    z_score: -0.1074205142,
  },
  {
    percentile: 57.541899441,
    z_score: -0.104797508,
  },
  {
    percentile: 57.653631285,
    z_score: -0.1000671042,
  },
  {
    percentile: 57.765363128,
    z_score: -0.09909781012,
  },
  {
    percentile: 57.877094972,
    z_score: -0.09753550416,
  },
  {
    percentile: 57.988826816,
    z_score: -0.09677959484,
  },
  {
    percentile: 58.100558659,
    z_score: -0.09642587253,
  },
  {
    percentile: 58.212290503,
    z_score: -0.09486730061,
  },
  {
    percentile: 58.324022346,
    z_score: -0.09279802119,
  },
  {
    percentile: 58.43575419,
    z_score: -0.08890894278,
  },
  {
    percentile: 58.547486034,
    z_score: -0.08626843334,
  },
  {
    percentile: 58.659217877,
    z_score: -0.08240557099,
  },
  {
    percentile: 58.770949721,
    z_score: -0.08099177084,
  },
  {
    percentile: 58.882681564,
    z_score: -0.07927286866,
  },
  {
    percentile: 58.994413408,
    z_score: -0.07851851518,
  },
  {
    percentile: 59.106145251,
    z_score: -0.07758321645,
  },
  {
    percentile: 59.217877095,
    z_score: -0.07407283173,
  },
  {
    percentile: 59.329608939,
    z_score: -0.07321182479,
  },
  {
    percentile: 59.441340782,
    z_score: -0.07258365068,
  },
  {
    percentile: 59.553072626,
    z_score: -0.06734331726,
  },
  {
    percentile: 59.664804469,
    z_score: -0.06665913258,
  },
  {
    percentile: 59.776536313,
    z_score: -0.06566510054,
  },
  {
    percentile: 59.888268156,
    z_score: -0.0654158535,
  },
  {
    percentile: 60,
    z_score: -0.06500448697,
  },
  {
    percentile: 60.111731844,
    z_score: -0.0627511506,
  },
  {
    percentile: 60.223463687,
    z_score: -0.06275076164,
  },
  {
    percentile: 60.335195531,
    z_score: -0.06202768073,
  },
  {
    percentile: 60.446927374,
    z_score: -0.05895270041,
  },
  {
    percentile: 60.558659218,
    z_score: -0.05550968396,
  },
  {
    percentile: 60.670391061,
    z_score: -0.05170236542,
  },
  {
    percentile: 60.782122905,
    z_score: -0.04973507192,
  },
  {
    percentile: 60.893854749,
    z_score: -0.04629501159,
  },
  {
    percentile: 61.005586592,
    z_score: -0.0461948149,
  },
  {
    percentile: 61.117318436,
    z_score: -0.0447051671,
  },
  {
    percentile: 61.229050279,
    z_score: -0.04386306373,
  },
  {
    percentile: 61.340782123,
    z_score: -0.04318860311,
  },
  {
    percentile: 61.452513966,
    z_score: -0.04195979342,
  },
  {
    percentile: 61.56424581,
    z_score: -0.04112655839,
  },
  {
    percentile: 61.675977654,
    z_score: -0.04103818616,
  },
  {
    percentile: 61.787709497,
    z_score: -0.04078333806,
  },
  {
    percentile: 61.899441341,
    z_score: -0.04002299457,
  },
  {
    percentile: 62.011173184,
    z_score: -0.03645994435,
  },
  {
    percentile: 62.122905028,
    z_score: -0.03415658743,
  },
  {
    percentile: 62.234636872,
    z_score: -0.03410151037,
  },
  {
    percentile: 62.346368715,
    z_score: -0.03313299425,
  },
  {
    percentile: 62.458100559,
    z_score: -0.03234176715,
  },
  {
    percentile: 62.569832402,
    z_score: -0.02952007907,
  },
  {
    percentile: 62.681564246,
    z_score: -0.0279785437,
  },
  {
    percentile: 62.793296089,
    z_score: -0.02713916307,
  },
  {
    percentile: 62.905027933,
    z_score: -0.0263165856,
  },
  {
    percentile: 63.016759777,
    z_score: -0.02617282514,
  },
  {
    percentile: 63.12849162,
    z_score: -0.02368323307,
  },
  {
    percentile: 63.240223464,
    z_score: -0.02123728257,
  },
  {
    percentile: 63.351955307,
    z_score: -0.0209387929,
  },
  {
    percentile: 63.463687151,
    z_score: -0.02027895726,
  },
  {
    percentile: 63.575418994,
    z_score: -0.0189663651,
  },
  {
    percentile: 63.687150838,
    z_score: -0.01874068918,
  },
  {
    percentile: 63.798882682,
    z_score: -0.01728316966,
  },
  {
    percentile: 63.910614525,
    z_score: -0.0136638755,
  },
  {
    percentile: 64.022346369,
    z_score: -0.002957065971,
  },
  {
    percentile: 64.134078212,
    z_score: -0.001868127128,
  },
  {
    percentile: 64.245810056,
    z_score: -0.0005741273625,
  },
  {
    percentile: 64.357541899,
    z_score: 0.0001592999423,
  },
  {
    percentile: 64.469273743,
    z_score: 0.0002575518182,
  },
  {
    percentile: 64.581005587,
    z_score: 0.001437041084,
  },
  {
    percentile: 64.69273743,
    z_score: 0.001881002648,
  },
  {
    percentile: 64.804469274,
    z_score: 0.003416781377,
  },
  {
    percentile: 64.916201117,
    z_score: 0.005292979906,
  },
  {
    percentile: 65.027932961,
    z_score: 0.01079096196,
  },
  {
    percentile: 65.139664804,
    z_score: 0.01300890276,
  },
  {
    percentile: 65.251396648,
    z_score: 0.01971484612,
  },
  {
    percentile: 65.363128492,
    z_score: 0.02279208242,
  },
  {
    percentile: 65.474860335,
    z_score: 0.02460060151,
  },
  {
    percentile: 65.586592179,
    z_score: 0.03076020843,
  },
  {
    percentile: 65.698324022,
    z_score: 0.03421629401,
  },
  {
    percentile: 65.810055866,
    z_score: 0.03465652154,
  },
  {
    percentile: 65.921787709,
    z_score: 0.03799490713,
  },
  {
    percentile: 66.033519553,
    z_score: 0.03854824489,
  },
  {
    percentile: 66.145251397,
    z_score: 0.03901624432,
  },
  {
    percentile: 66.25698324,
    z_score: 0.03915914907,
  },
  {
    percentile: 66.368715084,
    z_score: 0.0404442805,
  },
  {
    percentile: 66.480446927,
    z_score: 0.04123169577,
  },
  {
    percentile: 66.592178771,
    z_score: 0.04299758459,
  },
  {
    percentile: 66.703910615,
    z_score: 0.0440745434,
  },
  {
    percentile: 66.815642458,
    z_score: 0.04455467845,
  },
  {
    percentile: 66.927374302,
    z_score: 0.05089460829,
  },
  {
    percentile: 67.039106145,
    z_score: 0.0541152161,
  },
  {
    percentile: 67.150837989,
    z_score: 0.05414337697,
  },
  {
    percentile: 67.262569832,
    z_score: 0.05433622447,
  },
  {
    percentile: 67.374301676,
    z_score: 0.05530334033,
  },
  {
    percentile: 67.48603352,
    z_score: 0.05584547597,
  },
  {
    percentile: 67.597765363,
    z_score: 0.05723601618,
  },
  {
    percentile: 67.709497207,
    z_score: 0.0619895244,
  },
  {
    percentile: 67.82122905,
    z_score: 0.06306912815,
  },
  {
    percentile: 67.932960894,
    z_score: 0.06590901966,
  },
  {
    percentile: 68.044692737,
    z_score: 0.06628864686,
  },
  {
    percentile: 68.156424581,
    z_score: 0.06935919302,
  },
  {
    percentile: 68.268156425,
    z_score: 0.07343396203,
  },
  {
    percentile: 68.379888268,
    z_score: 0.07425241649,
  },
  {
    percentile: 68.491620112,
    z_score: 0.07655904069,
  },
  {
    percentile: 68.603351955,
    z_score: 0.07698689922,
  },
  {
    percentile: 68.715083799,
    z_score: 0.07865375824,
  },
  {
    percentile: 68.826815642,
    z_score: 0.08111176657,
  },
  {
    percentile: 68.938547486,
    z_score: 0.08747923494,
  },
  {
    percentile: 69.05027933,
    z_score: 0.08847038866,
  },
  {
    percentile: 69.162011173,
    z_score: 0.0921425817,
  },
  {
    percentile: 69.273743017,
    z_score: 0.09335427704,
  },
  {
    percentile: 69.38547486,
    z_score: 0.0950538089,
  },
  {
    percentile: 69.497206704,
    z_score: 0.09817865419,
  },
  {
    percentile: 69.608938547,
    z_score: 0.1023111452,
  },
  {
    percentile: 69.720670391,
    z_score: 0.109983971,
  },
  {
    percentile: 69.832402235,
    z_score: 0.1115836174,
  },
  {
    percentile: 69.944134078,
    z_score: 0.1123278578,
  },
  {
    percentile: 70.055865922,
    z_score: 0.1145156152,
  },
  {
    percentile: 70.167597765,
    z_score: 0.1152985185,
  },
  {
    percentile: 70.279329609,
    z_score: 0.1155690028,
  },
  {
    percentile: 70.391061453,
    z_score: 0.1158541122,
  },
  {
    percentile: 70.502793296,
    z_score: 0.1163169773,
  },
  {
    percentile: 70.61452514,
    z_score: 0.1230891221,
  },
  {
    percentile: 70.726256983,
    z_score: 0.1243978246,
  },
  {
    percentile: 70.837988827,
    z_score: 0.1246354806,
  },
  {
    percentile: 70.94972067,
    z_score: 0.1274398987,
  },
  {
    percentile: 71.061452514,
    z_score: 0.134747489,
  },
  {
    percentile: 71.173184358,
    z_score: 0.1355922373,
  },
  {
    percentile: 71.284916201,
    z_score: 0.1399364016,
  },
  {
    percentile: 71.396648045,
    z_score: 0.1399489262,
  },
  {
    percentile: 71.508379888,
    z_score: 0.1402024518,
  },
  {
    percentile: 71.620111732,
    z_score: 0.1473665149,
  },
  {
    percentile: 71.731843575,
    z_score: 0.1517555655,
  },
  {
    percentile: 71.843575419,
    z_score: 0.1539872755,
  },
  {
    percentile: 71.955307263,
    z_score: 0.1558327461,
  },
  {
    percentile: 72.067039106,
    z_score: 0.1568169763,
  },
  {
    percentile: 72.17877095,
    z_score: 0.1651004729,
  },
  {
    percentile: 72.290502793,
    z_score: 0.1669643802,
  },
  {
    percentile: 72.402234637,
    z_score: 0.1672111379,
  },
  {
    percentile: 72.51396648,
    z_score: 0.1684800885,
  },
  {
    percentile: 72.625698324,
    z_score: 0.1732833061,
  },
  {
    percentile: 72.737430168,
    z_score: 0.1781300097,
  },
  {
    percentile: 72.849162011,
    z_score: 0.1810801331,
  },
  {
    percentile: 72.960893855,
    z_score: 0.1811546583,
  },
  {
    percentile: 73.072625698,
    z_score: 0.1825322071,
  },
  {
    percentile: 73.184357542,
    z_score: 0.1866065094,
  },
  {
    percentile: 73.296089385,
    z_score: 0.190450546,
  },
  {
    percentile: 73.407821229,
    z_score: 0.1904695273,
  },
  {
    percentile: 73.519553073,
    z_score: 0.1908151592,
  },
  {
    percentile: 73.631284916,
    z_score: 0.1953765201,
  },
  {
    percentile: 73.74301676,
    z_score: 0.2004058025,
  },
  {
    percentile: 73.854748603,
    z_score: 0.2052950586,
  },
  {
    percentile: 73.966480447,
    z_score: 0.2107833944,
  },
  {
    percentile: 74.078212291,
    z_score: 0.2119733078,
  },
  {
    percentile: 74.189944134,
    z_score: 0.2128654318,
  },
  {
    percentile: 74.301675978,
    z_score: 0.2134586771,
  },
  {
    percentile: 74.413407821,
    z_score: 0.2144616552,
  },
  {
    percentile: 74.525139665,
    z_score: 0.2157917507,
  },
  {
    percentile: 74.636871508,
    z_score: 0.2190985526,
  },
  {
    percentile: 74.748603352,
    z_score: 0.2215607617,
  },
  {
    percentile: 74.860335196,
    z_score: 0.2220037897,
  },
  {
    percentile: 74.972067039,
    z_score: 0.2236147938,
  },
  {
    percentile: 75.083798883,
    z_score: 0.2248485044,
  },
  {
    percentile: 75.195530726,
    z_score: 0.2305403452,
  },
  {
    percentile: 75.30726257,
    z_score: 0.231403297,
  },
  {
    percentile: 75.418994413,
    z_score: 0.2373446183,
  },
  {
    percentile: 75.530726257,
    z_score: 0.2433019648,
  },
  {
    percentile: 75.642458101,
    z_score: 0.2433551748,
  },
  {
    percentile: 75.754189944,
    z_score: 0.2433775791,
  },
  {
    percentile: 75.865921788,
    z_score: 0.2456800803,
  },
  {
    percentile: 75.977653631,
    z_score: 0.2544059048,
  },
  {
    percentile: 76.089385475,
    z_score: 0.2549579979,
  },
  {
    percentile: 76.201117318,
    z_score: 0.2573358022,
  },
  {
    percentile: 76.312849162,
    z_score: 0.2606899797,
  },
  {
    percentile: 76.424581006,
    z_score: 0.2682687544,
  },
  {
    percentile: 76.536312849,
    z_score: 0.2698191581,
  },
  {
    percentile: 76.648044693,
    z_score: 0.2763225299,
  },
  {
    percentile: 76.759776536,
    z_score: 0.2783470009,
  },
  {
    percentile: 76.87150838,
    z_score: 0.2804324611,
  },
  {
    percentile: 76.983240223,
    z_score: 0.2812902008,
  },
  {
    percentile: 77.094972067,
    z_score: 0.2845931908,
  },
  {
    percentile: 77.206703911,
    z_score: 0.2885473815,
  },
  {
    percentile: 77.318435754,
    z_score: 0.2903102364,
  },
  {
    percentile: 77.430167598,
    z_score: 0.295738283,
  },
  {
    percentile: 77.541899441,
    z_score: 0.2971811776,
  },
  {
    percentile: 77.653631285,
    z_score: 0.2982126278,
  },
  {
    percentile: 77.765363128,
    z_score: 0.302667024,
  },
  {
    percentile: 77.877094972,
    z_score: 0.3027401489,
  },
  {
    percentile: 77.988826816,
    z_score: 0.3038814421,
  },
  {
    percentile: 78.100558659,
    z_score: 0.3066793257,
  },
  {
    percentile: 78.212290503,
    z_score: 0.3083847698,
  },
  {
    percentile: 78.324022346,
    z_score: 0.3119602668,
  },
  {
    percentile: 78.43575419,
    z_score: 0.3128208069,
  },
  {
    percentile: 78.547486034,
    z_score: 0.3136969834,
  },
  {
    percentile: 78.659217877,
    z_score: 0.3140133653,
  },
  {
    percentile: 78.770949721,
    z_score: 0.3191160061,
  },
  {
    percentile: 78.882681564,
    z_score: 0.3272155236,
  },
  {
    percentile: 78.994413408,
    z_score: 0.3280583271,
  },
  {
    percentile: 79.106145251,
    z_score: 0.3293706081,
  },
  {
    percentile: 79.217877095,
    z_score: 0.3301985532,
  },
  {
    percentile: 79.329608939,
    z_score: 0.3304939312,
  },
  {
    percentile: 79.441340782,
    z_score: 0.3320904658,
  },
  {
    percentile: 79.553072626,
    z_score: 0.350246759,
  },
  {
    percentile: 79.664804469,
    z_score: 0.3555921901,
  },
  {
    percentile: 79.776536313,
    z_score: 0.3557154911,
  },
  {
    percentile: 79.888268156,
    z_score: 0.3657456618,
  },
  {
    percentile: 80,
    z_score: 0.3697228791,
  },
  {
    percentile: 80.111731844,
    z_score: 0.3759969187,
  },
  {
    percentile: 80.223463687,
    z_score: 0.3777288123,
  },
  {
    percentile: 80.335195531,
    z_score: 0.3855843726,
  },
  {
    percentile: 80.446927374,
    z_score: 0.3922555427,
  },
  {
    percentile: 80.558659218,
    z_score: 0.3993121745,
  },
  {
    percentile: 80.670391061,
    z_score: 0.4020870315,
  },
  {
    percentile: 80.782122905,
    z_score: 0.4029274234,
  },
  {
    percentile: 80.893854749,
    z_score: 0.4097091366,
  },
  {
    percentile: 81.005586592,
    z_score: 0.4123221853,
  },
  {
    percentile: 81.117318436,
    z_score: 0.4159790532,
  },
  {
    percentile: 81.229050279,
    z_score: 0.4241694323,
  },
  {
    percentile: 81.340782123,
    z_score: 0.4303351848,
  },
  {
    percentile: 81.452513966,
    z_score: 0.4355240974,
  },
  {
    percentile: 81.56424581,
    z_score: 0.4417569071,
  },
  {
    percentile: 81.675977654,
    z_score: 0.4502070351,
  },
  {
    percentile: 81.787709497,
    z_score: 0.455245186,
  },
  {
    percentile: 81.899441341,
    z_score: 0.4552991739,
  },
  {
    percentile: 82.011173184,
    z_score: 0.4553777443,
  },
  {
    percentile: 82.122905028,
    z_score: 0.4607751407,
  },
  {
    percentile: 82.234636872,
    z_score: 0.4622460405,
  },
  {
    percentile: 82.346368715,
    z_score: 0.4636549398,
  },
  {
    percentile: 82.458100559,
    z_score: 0.4636747768,
  },
  {
    percentile: 82.569832402,
    z_score: 0.4642792242,
  },
  {
    percentile: 82.681564246,
    z_score: 0.4669140548,
  },
  {
    percentile: 82.681564246,
    z_score: 0.4669140548,
  },
  {
    percentile: 82.905027933,
    z_score: 0.4698966955,
  },
  {
    percentile: 83.016759777,
    z_score: 0.4726907673,
  },
  {
    percentile: 83.12849162,
    z_score: 0.4752373034,
  },
  {
    percentile: 83.240223464,
    z_score: 0.4755981048,
  },
  {
    percentile: 83.351955307,
    z_score: 0.4780114603,
  },
  {
    percentile: 83.463687151,
    z_score: 0.4840161046,
  },
  {
    percentile: 83.575418994,
    z_score: 0.4858296024,
  },
  {
    percentile: 83.687150838,
    z_score: 0.486351901,
  },
  {
    percentile: 83.798882682,
    z_score: 0.4877713022,
  },
  {
    percentile: 83.910614525,
    z_score: 0.491128358,
  },
  {
    percentile: 84.022346369,
    z_score: 0.4915856999,
  },
  {
    percentile: 84.134078212,
    z_score: 0.4970643116,
  },
  {
    percentile: 84.245810056,
    z_score: 0.4983461757,
  },
  {
    percentile: 84.357541899,
    z_score: 0.5033394403,
  },
  {
    percentile: 84.469273743,
    z_score: 0.5106765917,
  },
  {
    percentile: 84.581005587,
    z_score: 0.5140056422,
  },
  {
    percentile: 84.69273743,
    z_score: 0.5186728786,
  },
  {
    percentile: 84.804469274,
    z_score: 0.522993005,
  },
  {
    percentile: 84.916201117,
    z_score: 0.5261861521,
  },
  {
    percentile: 85.027932961,
    z_score: 0.5267678063,
  },
  {
    percentile: 85.139664804,
    z_score: 0.5289669991,
  },
  {
    percentile: 85.251396648,
    z_score: 0.5296102649,
  },
  {
    percentile: 85.363128492,
    z_score: 0.5375792467,
  },
  {
    percentile: 85.474860335,
    z_score: 0.5629139946,
  },
  {
    percentile: 85.586592179,
    z_score: 0.5748423014,
  },
  {
    percentile: 85.698324022,
    z_score: 0.5757501394,
  },
  {
    percentile: 85.810055866,
    z_score: 0.5783967944,
  },
  {
    percentile: 85.921787709,
    z_score: 0.5809609116,
  },
  {
    percentile: 86.033519553,
    z_score: 0.5865334189,
  },
  {
    percentile: 86.145251397,
    z_score: 0.5943016183,
  },
  {
    percentile: 86.25698324,
    z_score: 0.595125207,
  },
  {
    percentile: 86.368715084,
    z_score: 0.5969433724,
  },
  {
    percentile: 86.480446927,
    z_score: 0.6270238493,
  },
  {
    percentile: 86.592178771,
    z_score: 0.627138282,
  },
  {
    percentile: 86.703910615,
    z_score: 0.6279479459,
  },
  {
    percentile: 86.815642458,
    z_score: 0.6288549282,
  },
  {
    percentile: 86.927374302,
    z_score: 0.6422217731,
  },
  {
    percentile: 87.039106145,
    z_score: 0.6442085147,
  },
  {
    percentile: 87.150837989,
    z_score: 0.6564000711,
  },
  {
    percentile: 87.262569832,
    z_score: 0.6807844286,
  },
  {
    percentile: 87.374301676,
    z_score: 0.683215054,
  },
  {
    percentile: 87.48603352,
    z_score: 0.6877656017,
  },
  {
    percentile: 87.597765363,
    z_score: 0.6941872136,
  },
  {
    percentile: 87.709497207,
    z_score: 0.7005526594,
  },
  {
    percentile: 87.82122905,
    z_score: 0.7072484897,
  },
  {
    percentile: 87.932960894,
    z_score: 0.7109910849,
  },
  {
    percentile: 88.044692737,
    z_score: 0.7129636683,
  },
  {
    percentile: 88.156424581,
    z_score: 0.7197395471,
  },
  {
    percentile: 88.268156425,
    z_score: 0.7323236442,
  },
  {
    percentile: 88.379888268,
    z_score: 0.7750023765,
  },
  {
    percentile: 88.491620112,
    z_score: 0.7782900414,
  },
  {
    percentile: 88.603351955,
    z_score: 0.7961518124,
  },
  {
    percentile: 88.715083799,
    z_score: 0.7981163054,
  },
  {
    percentile: 88.826815642,
    z_score: 0.8295689635,
  },
  {
    percentile: 88.938547486,
    z_score: 0.8299455568,
  },
  {
    percentile: 89.05027933,
    z_score: 0.8414877795,
  },
  {
    percentile: 89.162011173,
    z_score: 0.8563441166,
  },
  {
    percentile: 89.273743017,
    z_score: 0.8639254585,
  },
  {
    percentile: 89.38547486,
    z_score: 0.878177815,
  },
  {
    percentile: 89.497206704,
    z_score: 0.9331945869,
  },
  {
    percentile: 89.608938547,
    z_score: 0.9420037272,
  },
  {
    percentile: 89.720670391,
    z_score: 0.9440628158,
  },
  {
    percentile: 89.832402235,
    z_score: 0.9540072592,
  },
  {
    percentile: 89.944134078,
    z_score: 0.96072316,
  },
  {
    percentile: 90.055865922,
    z_score: 0.9663603128,
  },
  {
    percentile: 90.167597765,
    z_score: 0.9718654517,
  },
  {
    percentile: 90.279329609,
    z_score: 1.011461269,
  },
  {
    percentile: 90.391061453,
    z_score: 1.018409691,
  },
  {
    percentile: 90.502793296,
    z_score: 1.026086874,
  },
  {
    percentile: 90.61452514,
    z_score: 1.028836681,
  },
  {
    percentile: 90.726256983,
    z_score: 1.063322934,
  },
  {
    percentile: 90.837988827,
    z_score: 1.121667279,
  },
  {
    percentile: 90.94972067,
    z_score: 1.128978681,
  },
  {
    percentile: 91.061452514,
    z_score: 1.150154488,
  },
  {
    percentile: 91.173184358,
    z_score: 1.212284214,
  },
  {
    percentile: 91.284916201,
    z_score: 1.463725233,
  },
  {
    percentile: 91.396648045,
    z_score: 1.468037581,
  },
  {
    percentile: 91.508379888,
    z_score: 1.58418849,
  },
  {
    percentile: 91.620111732,
    z_score: 1.606058906,
  },
  {
    percentile: 91.731843575,
    z_score: 1.70098531,
  },
  {
    percentile: 91.843575419,
    z_score: 1.775863975,
  },
  {
    percentile: 91.955307263,
    z_score: 1.7860036,
  },
  {
    percentile: 92.067039106,
    z_score: 1.78938586,
  },
  {
    percentile: 92.17877095,
    z_score: 1.806889164,
  },
  {
    percentile: 92.290502793,
    z_score: 1.82828209,
  },
  {
    percentile: 92.402234637,
    z_score: 1.846756165,
  },
  {
    percentile: 92.51396648,
    z_score: 1.878673945,
  },
  {
    percentile: 92.625698324,
    z_score: 1.893109113,
  },
  {
    percentile: 92.737430168,
    z_score: 1.976177146,
  },
  {
    percentile: 92.849162011,
    z_score: 1.979701767,
  },
  {
    percentile: 92.960893855,
    z_score: 1.979708146,
  },
  {
    percentile: 93.072625698,
    z_score: 1.996832444,
  },
  {
    percentile: 93.184357542,
    z_score: 1.998362233,
  },
  {
    percentile: 93.296089385,
    z_score: 2.009795779,
  },
  {
    percentile: 93.407821229,
    z_score: 2.01854502,
  },
  {
    percentile: 93.519553073,
    z_score: 2.051423613,
  },
  {
    percentile: 93.631284916,
    z_score: 2.071568671,
  },
  {
    percentile: 93.74301676,
    z_score: 2.085300285,
  },
  {
    percentile: 93.854748603,
    z_score: 2.108727639,
  },
  {
    percentile: 93.966480447,
    z_score: 2.119335341,
  },
  {
    percentile: 94.078212291,
    z_score: 2.136497213,
  },
  {
    percentile: 94.189944134,
    z_score: 2.188065057,
  },
  {
    percentile: 94.301675978,
    z_score: 2.244811077,
  },
  {
    percentile: 94.413407821,
    z_score: 2.254638521,
  },
  {
    percentile: 94.525139665,
    z_score: 2.291819582,
  },
  {
    percentile: 94.636871508,
    z_score: 2.372829304,
  },
  {
    percentile: 94.748603352,
    z_score: 2.394865185,
  },
  {
    percentile: 94.860335196,
    z_score: 2.404512306,
  },
  {
    percentile: 94.972067039,
    z_score: 2.440328032,
  },
  {
    percentile: 95.083798883,
    z_score: 2.445773426,
  },
  {
    percentile: 95.195530726,
    z_score: 2.487544709,
  },
  {
    percentile: 95.30726257,
    z_score: 2.506144342,
  },
  {
    percentile: 95.418994413,
    z_score: 2.528861996,
  },
  {
    percentile: 95.530726257,
    z_score: 2.54461598,
  },
  {
    percentile: 95.642458101,
    z_score: 2.548860337,
  },
  {
    percentile: 95.754189944,
    z_score: 2.553635315,
  },
  {
    percentile: 95.865921788,
    z_score: 2.578646058,
  },
  {
    percentile: 95.977653631,
    z_score: 2.633134852,
  },
  {
    percentile: 96.089385475,
    z_score: 2.654239791,
  },
  {
    percentile: 96.201117318,
    z_score: 2.665550814,
  },
  {
    percentile: 96.312849162,
    z_score: 2.666982274,
  },
  {
    percentile: 96.424581006,
    z_score: 2.706914543,
  },
  {
    percentile: 96.536312849,
    z_score: 2.740073501,
  },
  {
    percentile: 96.648044693,
    z_score: 2.773421028,
  },
  {
    percentile: 96.759776536,
    z_score: 2.774757036,
  },
  {
    percentile: 96.87150838,
    z_score: 2.80215834,
  },
  {
    percentile: 96.983240223,
    z_score: 2.803685873,
  },
  {
    percentile: 97.094972067,
    z_score: 2.865297578,
  },
  {
    percentile: 97.206703911,
    z_score: 2.869505372,
  },
  {
    percentile: 97.318435754,
    z_score: 2.89769736,
  },
  {
    percentile: 97.430167598,
    z_score: 3.033879438,
  },
  {
    percentile: 97.541899441,
    z_score: 3.061629098,
  },
  {
    percentile: 97.653631285,
    z_score: 3.076928696,
  },
  {
    percentile: 97.765363128,
    z_score: 3.08149558,
  },
  {
    percentile: 97.877094972,
    z_score: 3.096299174,
  },
  {
    percentile: 97.988826816,
    z_score: 3.169544742,
  },
  {
    percentile: 98.100558659,
    z_score: 3.212098851,
  },
  {
    percentile: 98.212290503,
    z_score: 3.23870006,
  },
  {
    percentile: 98.324022346,
    z_score: 3.244091933,
  },
  {
    percentile: 98.43575419,
    z_score: 3.261458555,
  },
  {
    percentile: 98.547486034,
    z_score: 3.262830736,
  },
  {
    percentile: 98.659217877,
    z_score: 3.300881439,
  },
  {
    percentile: 98.770949721,
    z_score: 3.306338814,
  },
  {
    percentile: 98.882681564,
    z_score: 3.381261898,
  },
  {
    percentile: 98.994413408,
    z_score: 3.385751923,
  },
  {
    percentile: 99.106145251,
    z_score: 3.445872503,
  },
  {
    percentile: 99.217877095,
    z_score: 3.835925916,
  },
  {
    percentile: 99.329608939,
    z_score: 3.837920592,
  },
  {
    percentile: 99.441340782,
    z_score: 4.109579172,
  },
  {
    percentile: 99.553072626,
    z_score: 4.258657529,
  },
  {
    percentile: 99.664804469,
    z_score: 4.489895536,
  },
  {
    percentile: 99.776536313,
    z_score: 4.9499876,
  },
  {
    percentile: 99.888268156,
    z_score: 5.290046522,
  },
  {
    percentile: 100,
    z_score: 5.601216359,
  },
];
