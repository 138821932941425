import {
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  useDisclosure,
  Switch,
  FormLabel,
  Flex,
  Button,
  Icon,
  Portal,
  PlacementWithLogical,
  SystemStyleObject,
} from '@chakra-ui/react';
import {
  overrideLoadingState,
  PopoverTrigger,
  setGlobalLoaderEnableState,
} from '@revelio/core';
import { useEffect$, useUntilDestroyed } from '@ngneat/react-rxjs';
import {
  get,
  isUndefined,
  isNil,
  find,
  isEmpty as _isEmpty,
  template,
  delay,
  uniq,
  intersection,
  truncate,
  isArray,
} from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { EMPTY, Observable, of, combineLatest, pipe } from 'rxjs';
import {
  auditTime,
  filter,
  pairwise,
  tap,
  startWith,
  isEmpty,
  withLatestFrom,
} from 'rxjs/operators';
import { SubfilterConfigs, ViewTypes } from '../../data-api/data-api.model';
import {
  queryFilterEngine,
  toggleStatusOnGlobalLoaderOrSkipOne,
  useSelectionLists,
  useSingleOrMoreFilterState,
} from '../../engine/filters.engine';
import {
  AnyFilter,
  BooleanFilter,
  Filter,
  FilterItem,
  FilterList,
  FilterTypes,
  LocalSelectionCategories,
  OtherFilterNames,
  SelectFilter,
  SelectionCategories,
  SelectionList,
  SelectionListIdNames,
  SubFilterNames,
  ValidValueTypes,
  ValueItem,
} from '../../engine/filters.model';
import {
  getManyFiltersState,
  getSingleFilterState,
  selectionListDataSource,
  upsertFilter,
} from '../../engine/filters.repository';
import { Tree } from '../tree/tree/tree';
import FilterSelect from '../select/select';
import { SelectionListsToNotSortSelectedToTop } from '../filter-menu/lookups.config';

const subfiltersToIncludeHeader: string[] = [SubFilterNames.SUB_ROLE];

interface ISelectionListsResponse {
  selectionLists: SelectionList[];
}

export enum SubFilterDisplayMode {
  SIMPLE = 'simple',
  FULL = 'full',
}

export interface SubFilterProps {
  metadata?: { [key: string]: any };
  hideTriggerDefault?: boolean;
  externalTriggerDisplayCtrl?: Observable<boolean>;
  placementOverride?: PlacementWithLogical;
  defaultState?: Partial<
    SelectFilter<FilterList<ValidValueTypes> | FilterItem<ValidValueTypes>>
  >;
  treeCtrl$?: Observable<Partial<SubfilterConfigs>>;
  filterName?: string | SelectionListIdNames;
  selectionLists?: SelectionListIdNames[];
  showGrouped?: boolean;
  selectionLimit?: number;
  staticTreeChildrenSelect?: boolean;
  displayValueTemplate?: string;
  displayMode?: SubFilterDisplayMode;
  simpleSubfilterStyles?: any;
  subfiltersMap?: any;
}

export function SubFilter({
  treeCtrl$ = of({}),
  metadata,
  placementOverride = 'bottom',
  hideTriggerDefault = false,
  externalTriggerDisplayCtrl = EMPTY,
  defaultState = {},
  filterName,
  selectionLists = [],
  showGrouped = true,
  selectionLimit = 6,
  staticTreeChildrenSelect = false,
  //eslint-disable-next-line
  displayValueTemplate = '${value}',
  displayMode = SubFilterDisplayMode.FULL,
  simpleSubfilterStyles = {},
  subfiltersMap,
  ...props
}: SubFilterProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const parentLists = subfiltersMap
    ? (Object.keys(subfiltersMap) as Array<SelectionListIdNames>)
    : selectionLists;

  const subLists = subfiltersMap
    ? (Object.values(subfiltersMap) as Array<SelectionListIdNames>)
    : [];

  const [mappedSelectionLists, setMappedSelectionLists] = useState(() => {
    let mappedLists: SelectionListIdNames[] = [];

    if (subfiltersMap) {
      const parentFilterName = selectionLists[0];
      const childFilterName = subfiltersMap[selectionLists[0]];

      if (parentFilterName === childFilterName) {
        mappedLists = [parentFilterName];
      } else {
        mappedLists = [parentFilterName, childFilterName];
      }
    }

    return mappedLists;
  });

  const selectionListsToFetch = uniq([...parentLists, ...subLists]);

  // @NOTE: this is so Skills lists still coming from the old backend aren't tried to be fetched from the new and empty results overwrite the old one's
  if (
    intersection(selectionListsToFetch, [
      SelectionCategories.SKILL,
      SelectionCategories.KEYWORD,
      SelectionCategories.KEYWORDS_CATEGORY,
    ]).length == 0
  ) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSelectionLists(selectionListsToFetch);
  }

  const [groupedState] = useSingleOrMoreFilterState<BooleanFilter>(
    OtherFilterNames.GROUPED
  );

  const { untilDestroyed } = useUntilDestroyed();

  const templateDisplay = template(displayValueTemplate);

  const handleToggle = () => {
    // toggleStatusOnGlobalLoaderOrSkipOne() // TODO: enable this if 'grouped' is changed to per plot setting
    upsertFilter(OtherFilterNames.GROUPED, {
      type: FilterTypes.BOOLEAN,
      value: !groupedState?.value,
    });
  };

  const parentFilterState$ = useRef(
    displayMode === SubFilterDisplayMode.SIMPLE
      ? EMPTY
      : getManyFiltersState(parentLists)
  );

  const { current: subfilterState$ } = useRef(
    isUndefined(filterName)
      ? of([])
      : getManyFiltersState([filterName as SubFilterNames])
  );

  const hideTrigger = useRef(hideTriggerDefault);
  const [displayText, setDisplayText] = useState('');
  const [disableChildren, setDisableChildren] = useState(
    !staticTreeChildrenSelect
  );
  const [disableParentSelect, setDisableParentSelect] = useState(
    staticTreeChildrenSelect
  );
  const [parentFilter, setParentFilter] = useState<(string | number)[]>([]);

  const [childFilter, setChildFilter] = useState<(string | number)[]>([]);

  const [expandRoots, setExpandRoots] = useState<boolean>(false);

  const initialFocusRef = useRef<any>(null);

  useSingleOrMoreFilterState<SelectFilter<FilterItem<ValueItem>>>(
    LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME,
    pipe(
      tap((filter) => {
        if (
          (filter as SelectFilter<FilterItem<ValueItem>>)?.value &&
          hideTriggerDefault
        ) {
          const isSnapshot =
            (filter as SelectFilter<FilterItem<ValueItem>>).value.id ==
            ViewTypes.SNAPSHOT;
          if (hideTrigger.current != isSnapshot) {
            hideTrigger.current = isSnapshot;
          }
        }
      })
    )
  );

  useEffect$(() =>
    externalTriggerDisplayCtrl.pipe(
      tap((next) => {
        if (hideTrigger.current != next) {
          hideTrigger.current = next;
        }
      })
    )
  );

  useEffect$(
    () =>
      parentFilterState$.current.pipe(
        isEmpty(),
        auditTime(0),
        tap((isEmptyObs) => {
          if (isEmptyObs && !_isEmpty(defaultState)) {
            upsertFilter(filterName as SelectionCategories, defaultState);
          }
        })
      ),
    []
  );

  useEffect$(() =>
    combineLatest([
      parentFilterState$.current.pipe(startWith(null), pairwise()),
      selectionListDataSource.data$({
        key: selectionListsToFetch,
      }),
    ]).pipe(
      withLatestFrom(subfilterState$),
      untilDestroyed(),
      auditTime(0),
      filter<any>((stuff) => {
        return !isUndefined(stuff[1]);
      }),
      tap(
        (
          value: [
            [SelectFilter<FilterList>[][], ISelectionListsResponse],
            AnyFilter<any>[],
          ]
        ): any => {
          const [[filter, list], [subfilterState]] = value;

          //  Assumptions:
          //  1. Primary Selections from MappedSubfilters are restricted to a single level.
          //     we will never have Primary Selections for these across levels.
          //  2. Selection List Ids for Nested Lists are in the following order: [parentId, childId]

          const isMappedSubfilter = subLists.length > 0;

          const [prevFilter, curFilter] = filter;

          const parentId =
            isMappedSubfilter && curFilter.length > 0
              ? curFilter[0]?.selectionListId
              : selectionLists[0];

          const childId = isMappedSubfilter
            ? subfiltersMap?.[parentId as SelectionListIdNames]
            : selectionLists[1];

          const isNestedMappedSubfilter =
            isMappedSubfilter && parentId !== childId;

          const isNestedList = isMappedSubfilter
            ? isNestedMappedSubfilter
            : selectionLists.length > 1;

          if (isMappedSubfilter) {
            setMappedSelectionLists(
              isNestedMappedSubfilter ? [parentId, childId] : [parentId]
            );
          }

          const curParentFilter = isMappedSubfilter
            ? curFilter[0]
            : curFilter.find((listItem) => listItem?.id === parentId);

          const curChildFilter = isNestedList
            ? curFilter.find((listItem) => listItem?.id === childId)
            : undefined;

          const childList = list.selectionLists.find((li) => {
            if (isMappedSubfilter) {
              return li.id === childId;
            }

            return li.id === selectionLists[isNestedList ? 1 : 0];
          });

          const prevValuelen = prevFilter?.reduce(
            (prev, curr) => prev + (curr?.value?.length || 0),
            0
          );

          const curValuelen = curFilter?.reduce(
            (prev, curr) => prev + (curr?.value?.length || 0),
            0
          );

          const parentIdFilter = curParentFilter?.value.map((v) => v.id) || [];

          const childIdFilter =
            curChildFilter?.value.map((v: FilterItem) => {
              const parentId = v.parentId;

              if (parentId && !parentIdFilter.includes(parentId)) {
                parentIdFilter.push(parentId);
              }

              return v.id;
            }) || [];

          if (!staticTreeChildrenSelect) {
            setDisableChildren(isNestedList && !curValuelen);
            setDisableParentSelect(isNestedList && curValuelen > 0);

            setParentFilter(parentIdFilter);
            setChildFilter(childIdFilter);
          }

          const isSubFiltersAlreadySet = !isUndefined(subfilterState);
          if (!isNil(curValuelen) && curValuelen > 0) {
            let defaultsToUpsert = [];

            if (isSubFiltersAlreadySet) {
              if (subfilterState.value) {
                defaultsToUpsert = subfilterState.value.filter((val: any) => {
                  const subFilterParentIds = get(val, parentId || '');
                  if (isArray(subFilterParentIds)) {
                    // in the case of supporting multiple parents, subfilter parent ids are arrays
                    return intersection(parentIdFilter, subFilterParentIds)
                      .length;
                  } else {
                    return parentIdFilter.includes(
                      isNestedList ? subFilterParentIds : val.id
                    );
                  }
                });
              }
            }

            if (defaultsToUpsert.length === 0) {
              defaultsToUpsert = [
                find(childList?.value, (v) => {
                  if (isUndefined(parentId) || v.id == 0) {
                    return false;
                  }

                  const parentListId = get(v, parentId);

                  if (isArray(parentListId)) {
                    return (
                      intersection(parentIdFilter, parentListId).length > 0
                    );
                  }

                  return parentIdFilter.includes(parentListId || v.id);
                }),
              ];
            }

            if (defaultsToUpsert.length > 0) {
              const defaultValue = {
                ...defaultState,
                selectionListId:
                  curValuelen && isNestedList ? childId : parentId,
                value: defaultsToUpsert,
              };

              upsertFilter(filterName as SelectionCategories, defaultValue);
            }
          }

          if (
            !isNil(prevValuelen) &&
            (isNil(curValuelen) || curValuelen === 0)
          ) {
            upsertFilter(filterName as SelectionCategories, defaultState);
          }
        }
      )
    )
  );

  useEffect$(() =>
    combineLatest([
      getSingleFilterState(filterName as OtherFilterNames),
      parentFilterState$.current.pipe(startWith('nothing')),
    ]).pipe(
      untilDestroyed<any>(),
      tap(([filterState, parentFilterState]: [SelectFilter, any]) => {
        if (filterState) {
          const toReduceValue = filterState.isMulti
            ? (filterState?.value as FilterList<ValidValueTypes>)
            : [filterState?.value as FilterItem<ValidValueTypes>];

          const toDisplay = toReduceValue.reduce(
            (result: any, cur: any, i: number) => {
              if (i == 0) {
                const displayLabel = get(
                  cur,
                  'shortName',
                  get(cur, 'label', 'Unknown')
                );

                return truncate(displayLabel, { length: 20, omission: '...' });
              }
              if (i == 1) {
                return 2;
              }
              return result + 1;
            },
            ''
          );
          const finalToDisplay = Number.isInteger(toDisplay)
            ? `${toDisplay} Selections`
            : toDisplay;
          setDisplayText(
            templateDisplay({
              value: finalToDisplay,
            })
          );
        } else {
          setDisplayText('No Selection');
        }
      })
    )
  );

  const entities: Filter[] = queryFilterEngine(selectionLists);

  useEffect(() => {
    if (entities) {
      setExpandRoots(entities.length > 0);
    }
  }, [entities]);

  if (!selectionLists) {
    return <span></span>;
  }

  return (
    <Popover
      isLazy={true}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={placementOverride}
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Button
          data-testid={`plot-sub-filter-${filterName}-trigger`}
          display={hideTrigger.current ? 'none' : 'flex'}
          variant="outline"
          colorScheme="gray"
          color="text.primary"
          fontWeight="normal"
          borderColor="#E2E8F0"
          aria-label="Plot Sub-filter"
          size="xs"
          fontSize="10px"
          rightIcon={<Icon boxSize={3} as={FiChevronDown} />}
          textOverflow="ellipsis"
        >
          {displayText}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          width="auto"
          boxShadow="2xl"
          // workaround to fix focus shadow bug
          // ensures popover has box shadow when open
          sx={{
            ':focus:not(:focus-visible)': {
              shadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
            },
          }}
        >
          <PopoverArrow />
          <PopoverBody
            p={displayMode === SubFilterDisplayMode.SIMPLE ? 0 : undefined}
            data-testid="subfilter-menu-popover"
          >
            {displayMode == SubFilterDisplayMode.SIMPLE ? (
              <FilterSelect
                filterName={filterName as SelectionListIdNames}
                selectionList={selectionLists[0] as SelectionListIdNames}
                onClose={onClose}
                isClearable={false}
                isMulti={selectionLimit > 1}
                chakraStyles={{
                  control: (provided: SystemStyleObject) => ({
                    ...provided,
                    display: 'none',
                  }),

                  menu: (provided: SystemStyleObject) => ({
                    ...provided,
                    position: 'static',
                    padding: '0',
                  }),
                  menuList: (provided: SystemStyleObject) => ({
                    ...provided,
                    padding: '0',
                    border: 'none',
                    borderRadius: '0px',
                    minWidth: '100px',
                  }),
                  ...simpleSubfilterStyles,
                }}
              />
            ) : (
              <Tree
                placeholder="Select a subfilter"
                filterName={filterName}
                selectionLists={
                  subfiltersMap ? mappedSelectionLists : selectionLists
                }
                showHeader={subfiltersToIncludeHeader.includes(
                  filterName as string
                )}
                showBreadcrumbsByDefault={true}
                disableParentSelect={disableParentSelect}
                disableChildren={disableChildren}
                parentFilter={parentFilter}
                childFilter={childFilter}
                width="300px"
                required={1}
                limit={selectionLimit}
                expandRootsByDefault={expandRoots}
                onClose={() => {
                  toggleStatusOnGlobalLoaderOrSkipOne();
                  setGlobalLoaderEnableState('DISABLE', 1000);
                  delay(() => overrideLoadingState({}), 1000);
                  onClose();
                }}
                sortSelectedToTop={
                  !SelectionListsToNotSortSelectedToTop.includes(
                    filterName || ''
                  )
                }
                additionalBottomButtons={
                  showGrouped && (
                    <Flex alignItems="flex-start">
                      <FormLabel
                        htmlFor="grouped-toggle"
                        margin="0"
                        mr="4px"
                        fontSize="12px"
                        color="text.primary"
                      >
                        Grouped
                      </FormLabel>
                      <Switch
                        size="sm"
                        id="grouped-toggle"
                        isChecked={groupedState?.value}
                        onChange={handleToggle}
                        colorScheme="green"
                      />
                    </Flex>
                  )
                }
                internalControl
                initialFocusRef={initialFocusRef}
              />
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default SubFilter;
