import {
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import { ScreenerFilterOption, SubFilterValues } from './types';
import { SelectFilter } from './select-filter';
import { PrimaryFilter } from './primary-filter';
import { SubFilter } from './sub-filter';
import { FilterSelectDropdown } from './filter-select-dropdown';
import { formatOptionLabel } from './utils';
import { ScreenerSubFilterState } from './screener-filter-reducer';

interface ScreenerFilterPopoverProps {
  filterOptions: ScreenerFilterOption[];
  trigger: ReactNode;
  selectedFilterOverride?: ScreenerFilterOption;
  subFilterOverride?: ScreenerSubFilterState;
}

export const ScreenerFilterPopover = ({
  filterOptions,
  trigger,
  selectedFilterOverride,
  subFilterOverride,
}: ScreenerFilterPopoverProps) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [localSelectedFilter, setSelectedFilter] =
    useState<ScreenerFilterOption | null>(null);

  const selectedFilter = selectedFilterOverride || localSelectedFilter;

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [showSubFilter, setShowSubFilter] = useState(
    selectedFilter && !selectedFilter.primary
  );
  useEffect(() => {
    setShowSubFilter(selectedFilter && !selectedFilter.primary);
  }, [selectedFilter]);

  const handleOpenFilterMenu = () => {
    setShowSubFilter(selectedFilter && !selectedFilter.primary);
    setIsFilterMenuOpen(true);
  };

  const handleClose = () => {
    setIsFilterMenuOpen(false);

    setTimeout(() => {
      setCurrentStep(0);
      setShowSubFilter(false);
      setSelectedFilter(null);
    }, 100); // wait for the popover to close
  };

  const handleCloseFilterMenu = () => {
    if (currentStep === 2 || selectedFilter?.primary) return;

    handleClose();
  };

  const [segmentCount, setSegmentCount] = useState(0);

  return (
    <Popover
      placement="bottom-start"
      closeOnBlur
      onClose={handleCloseFilterMenu}
      isOpen={isFilterMenuOpen}
      onOpen={handleOpenFilterMenu}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent
        minWidth="325px"
        padding={3}
        color="navyBlue.500"
        boxShadow="2xl"
        data-testid={`screener-filter-popover-content-${isFilterMenuOpen ? 'open' : 'closed'}`}
      >
        {(() => {
          if (!isFilterMenuOpen) {
            return null;
          }

          if (!selectedFilter) {
            return (
              <SelectFilter
                filterOptions={filterOptions}
                setSelectedFilter={setSelectedFilter}
                closeMenu={handleCloseFilterMenu}
              />
            );
          }

          if (selectedFilter.primary) {
            return (
              <>
                <PopoverHeader
                  marginBottom={2}
                  padding={0}
                  fontWeight="semibold"
                  borderBottomWidth={0}
                >
                  {!selectedFilterOverride && (
                    <FilterSelectDropdown
                      filterOptions={filterOptions}
                      selectedFilter={selectedFilter}
                      onChangeSelectedFilter={setSelectedFilter}
                    />
                  )}
                  {!!selectedFilterOverride && (
                    <Text fontSize="sm" color="gray.500">
                      {selectedFilter?.label}
                    </Text>
                  )}
                </PopoverHeader>
                <PrimaryFilter
                  selectedFilter={selectedFilter}
                  closeMenu={handleClose}
                />
              </>
            );
          }

          if (showSubFilter) {
            return (
              <SubFilter
                filterSelectDropdown={
                  !selectedFilterOverride ? (
                    <FilterSelectDropdown
                      filterOptions={filterOptions}
                      selectedFilter={selectedFilter}
                      onChangeSelectedFilter={setSelectedFilter}
                      formatOptionLabel={formatOptionLabel(segmentCount)}
                    />
                  ) : (
                    <Text fontSize="sm" color="gray.500">
                      {selectedFilter?.label}
                    </Text>
                  )
                }
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setSegmentCount={setSegmentCount}
                selectedFilterValue={selectedFilter.value as SubFilterValues}
                closeMenu={handleCloseFilterMenu}
                closeMenuFromTree={handleClose}
                subFilterOverride={subFilterOverride}
              />
            );
          }

          return null;
        })()}
      </PopoverContent>
    </Popover>
  );
};
