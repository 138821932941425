/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { D3ChartNames, ID3ChartProps } from '@revelio/d3';
import {
  CalculatedEndpoint,
  EndpointMapper,
  DownloadEndpointMapper,
  RequestConfig,
  ViewTypes,
  ViewsForDefaultsOnly,
  FilterFormatOverrides,
  FilterSets,
  PlotAdditionalQueryParams,
  SharedFilterSets,
  PlotConfig,
  DataFetcher,
} from '../data-api/data-api.model';
import { PrimaryFilters, Views } from '@revelio/core';
import type { Observable, UnaryFunction } from 'rxjs';
import type { Client } from 'urql';
import { KibanaLogger } from '@revelio/iso-utility';
import type { AuthStore } from '@revelio/auth';

export enum FilterTypes {
  DATE_RANGE = 'DATE_RANGE',
  DATE_RANGE_FULL = 'DATE_RANGE_FULL',
  SELECT = 'SELECT',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  BOARD = 'BOARD',
  BOARD_LAYOFF = 'BOARD_LAYOFF',
  LAYOFF_MSA_STATE = 'LAYOFF_MSA_STATE',
  LAYOFF_DATE = 'LAYOFF_DATE',
  STRING_LIST = 'STRING_LIST',
  TALENT = 'TALENT',
  TALENT_OTHER = 'TALENT_OTHER',
}

export enum FilterLabelOverrides {
  SNAPSHOT_DATE = 'Date',
  JOB_CATEGORY = 'role',
  JA_ROLE = 'role',
  ROLE_K50 = 'role',
  JA_INDUSTRY = 'industry',
  JA_MSA = 'metro area',
  JA_SENIORITY = 'seniority',
  DATE_RANGE = 'Date Range',
  PROVIDER = 'posting source',
  HEADCOUNT = 'headcount',
  HIRING_RATE = 'hiring_rate',
  ATTRITION_RATE = 'attrition_rate',
  GROWTH_RATE = 'growth_rate',
  AVERAGE_TENURE = 'average_tenure',
  AVERAGE_SALARY = 'average_salary',
  COMPANY_NAME = 'company_name',
  COMPANY_CLEANED = 'company_name',
  MSA = 'metro area',
  FULL_MSA = 'metro area',
  TALENTDISCOVERY_REGION = 'Select a Region',
  TALENTDISCOVERY_COUNTRY = 'Select a Country',
  TALENTDISCOVERY_STATE = 'Select a State',
  TALENTDISCOVERY_MSA = 'Select a MSA',
  TALENTDISCOVERY_SKILL = 'Select a Skill',
  TALENTDISCOVERY_ETHNICITY = 'Ethnicity',
  STATE = 'state',
  COMPANY_FULL = 'company',
  INDUSTRY_FULL = 'industry',
  TAXONOMY_JOB = 'job',
  TAXONOMY_SKILL = 'skill',
  TAXONOMY_ACTIVITY = 'activity',
  HIGHEST_DEGREE = 'education',
  COMPANY_NEW_DASHBOARD = 'company',
  INDUSTRY_NEW_DASHBOARD = 'industry',
  DATA_METRIC = 'Data Adjustments',
  DIVERSITY_INCLUSION = 'Diversity & Inclusion',
  RSID = 'school',
  FLIGHT_RISK = 'flight risk',
  REMOTE_SUITABILITY = 'remote suitability',
  BASE_SALARY = 'Base Salary',
  TOTAL_COMPENSATION = 'Pay',
  SKILL = 'Skill',
  METRIC_MODE = 'Mode',
  RAW_TITLE = 'Title',
}

export enum FilterSetLabelOverrides {
  COMPANY_COMPOSITIONS = 'Company Compositions',
  COMPANY_SKILLS = 'Company Skills',
  COMPANY_TRANSITIONS = 'Company Transitions',
  COMPANY_POSTINGS = 'Company Job Postings',
  COMPANY_SENTIMENT_RATINGS = 'Company Sentiment Ratings',
  COMPANY_SENTIMENT_EFFECTS = 'Company Sentiment Effects',
  COMPANY_SENTIMENT = 'Company Sentiment Reviews',
  GEOGRAPHY_COMPOSITIONS = 'Geography Compositions',
  GEOGRAPHY_SKILLS = 'Geography Skills',
  GEOGRAPHY_TRANSITIONS = 'Geography Transitions',
  GEOGRAPHY_POSTINGS = 'Geography Job Postings',
  GEOGRAPHY_SENTIMENT_RATINGS = 'Geography Sentiment Ratings',
  GEOGRAPHY_SENTIMENT_EFFECTS = 'Geography Sentiment Effects',
  GEOGRAPHY_SENTIMENT = 'Geography Sentiment Reviews',
  ROLE_COMPOSITIONS = 'Role Compositions',
  ROLE_SKILLS = 'Role Skills',
  ROLE_TRANSITIONS = 'Role Transitions',
  ROLE_POSTINGS = 'Role Job Postings',
  ROLE_SENTIMENT_RATINGS = 'Role Sentiment Ratings',
  ROLE_SENTIMENT_EFFECTS = 'Role Sentiment Effects',
  ROLE_SENTIMENT = 'Role Sentiment Reviews',
  SKILL_POSTINGS = 'Skill Postings',
  SKILL = 'Skill',
}

export enum SelectionCategories {
  PRIMARY_FILTER = 'primary_filter',
  REGION = 'region',
  JOB_CATEGORY = 'job_category',
  COMPANY = 'company',
  COMPANY_REPORT = 'company_report',
  COMPANY_FULL = 'company_full',
  COMPANY_NEW_DASHBOARD = 'company_new_dashboard',
  SNAPSHOT_DATE = 'snapshot_date',
  SENIORITY = 'seniority',
  ROLE_K50 = 'role_k50',
  ROLE_K150 = 'role_k150',
  ROLE_K300 = 'role_k300',
  ROLE_K500 = 'role_k500',
  ROLE_K1000 = 'role_k1000',
  ROLE_K1250 = 'role_k1250',
  ROLE_K1500 = 'role_k1500',
  COUNTRY = 'country',
  SKILL = 'skill',
  SKILL_MAPPED = 'skill_mapped',
  SKILL_K25 = 'skill_k25',
  SKILL_K50 = 'skill_k50',
  SKILL_K75 = 'skill_k75',
  SKILL_K700 = 'skill_k700',
  SKILL_K2500 = 'skill_k2500',
  SKILL_K3000 = 'skill_k3000',
  GENDER = 'gender',
  ETHNICITY = 'ethnicity',
  EDUCATION = 'education',
  HIGHEST_DEGREE = 'highest_degree',
  DEFAULT_COMPANIES = 'defaultCompanies',
  ROLES_TREE = 'roles_tree',
  REGIONS_TREE = 'regions_tree',
  START_TIME = 'start_time',
  END_TIME = 'end_time',
  INDUSTRY = 'industry',
  INDUSTRY_FULL = 'industry_full',
  INDUSTRY_NEW_DASHBOARD = 'industry_new_dashboard',
  KEYWORDS_CATEGORY = 'keyword_category',
  KEYWORD = 'keywords',
  RAW_TITLE = 'title_raw',
  MSA = 'msa',
  METRO_AREA = 'metro_area',
  FULL_MSA = 'full_msa',
  JA_ROLE = 'ja_role',
  JA_INDUSTRY = 'ja_industry',
  JA_MSA = 'ja_msa',
  JA_SENIORITY = 'ja_seniority',
  ROLE_OR_SENIORITY_OR_INDUSTRY_OR_METRO_AREA = 'role_or_seniority_or_industry_or_metro_area',
  JOBS_OR_SKILLS_OR_ACTIVITIES = 'jobs_or_skills_or_activities',
  DATE_RANGE = 'date_range',
  DATE_RANGE_FULL = 'date_range_full',
  POSTING_METRIC = 'posting_metric',
  HEADCOUNT = 'headcount',
  HIRING_RATE = 'hiring',
  ATTRITION_RATE = 'attrition',
  GROWTH_RATE = 'growth',
  AVERAGE_TENURE = 'tenure',
  AVERAGE_SALARY = 'salary',
  COMPANY_NAME = 'company_name',
  COMPANY_CLEANED = 'company_cleaned',
  CURRENT_COMPANY = 'current_company',
  STATE = 'state',
  TALENTDISCOVERY_REGION = 'talentdiscovery_region',
  TALENTDISCOVERY_COUNTRY = 'talentdiscovery_country',
  TALENTDISCOVERY_STATE = 'talentdiscovery_state',
  TALENTDISCOVERY_MSA = 'talentdiscovery_msa',
  TALENTDISCOVERY_SKILL = 'talentdiscovery_skill',
  TALENTDISCOVERY_ETHNICITY = 'talentdiscovery_ethnicity',
  PREVIOUS_JOB_CATEGORY = 'previous_job_category',
  CURRENT_JOB_CATEGORY = 'current_job_category',
  PREVIOUS_JOB = 'previous_job',
  CURRENT_JOB = 'current_job',
  SKILL_JOB_CATEGORY = 'skill_category',
  TALENT_OTHER = 'talent_other',
  TALENT_OTHER_1 = 'talent_other_1',
  TALENT_OTHER_2 = 'talent_other_2',
  TALENT_OTHER_3 = 'talent_other_3',
  TALENT_PAGE_ID = 'page',
  TALENT_PAGE_SIZE = 'num_results',
  COUNT = 'count',
  TAXONOMY_JOB = 'taxonomy_job',
  TAXONOMY_SKILL = 'taxonomy_skill',
  TAXONOMY_ACTIVITY = 'taxonomy_activity',
  OTHER = 'other',
  SAVED_FILTER_SET = 'saved_filter_set',
  COMPANY_RCID_MAPPINGS = 'company_rcid_mappings',
  METRIC_MODE = 'metric_mode',
  //...

  // TALENT
  GEOGRAPHY = 'geography',
  DIVERSITY_INCLUSION = 'diversity_and_inclusion',
  EXPERIENCE = 'experience',
  SKILLS = 'skill',
  ROLES = 'role',
  TENURE = 'tenure',
  RSID = 'rsid',
  NAME = 'name',

  BASE_SALARY = 'base_salary',
  TOTAL_COMPENSATION = 'total_compensation',

  //COMPENSATION
  TOP_GEOGRAPHIES = 'top_geographies',
  TOP_COMPANIES = 'top_companies',
  TOP_ROLES = 'top_roles',
}

export enum LocalSelectionCategories {
  PRIMARY_ENTITIES = 'primary_entities',

  PRIMARY_FILTER = 'primary_filter',
  SNAPSHOT_OR_OVER_TIME = 'snapshot_or_over_time',
  TAXONOMY_TAB_VIEWS = 'taxonomy_tab_views',
  POSTING_METRIC = 'posting_metric',
  TIMESPAN = 'timespan',
  INFLOW_OR_OUTFLOW = 'inflow_or_outflow',
  N_ITEMS_SANKEY = 'n_items_sankey',
  IS_UNIVERSITY = 'is_university',
  DATA_METRIC = 'raw_metrics',
  BASE_SALARY_OR_TOTAL_COMP = 'base_salary_or_total_comp',
  PROVIDER = 'provider',
  PRESTIGE = 'prestige',
  FLIGHT_RISK = 'flight_risk',
  REMOTE_SUITABILITY = 'remote_suitability',
  METRIC_MODE = 'metric_mode',
  POSTING_GRANULARITY = 'posting_granularity',
}

export enum SubFilterNames {
  SUB_ROLE = 'sub_role',
  SUB_REGION = 'sub_region',
  SUB_INDUSTRY = 'sub_industry',
  SUB_SKILL = 'sub_skill',
  SUB_SKILL_OVERTIME = 'sub_skill_overtime',
  SUB_SENIORITY = 'sub_seniority',
  SUB_GENDER = 'sub_gender',
  SUB_ETHNICITY = 'sub_ethnicity',
  SUB_EDUCATION = 'sub_education',
  SUB_KEYWORD = 'sub_keyword',
}

export enum OtherFilterNames {
  SUBFILTER = 'subfilter',
  GROUPED = 'grouped',
  LAST_MONTH = 'last_month',
  LAST_START_DATE = 'maxWeek',
  INFLOW = 'inflow', // boolean
  OUTFLOW = 'outflow',
  LATEST = 'latest',
  DIM1 = 'dim1',
  //...
}

export enum QueryParameterKeys {
  START_TIME = 'start_time',
  END_TIME = 'end_time',
  INFLOW = 'inflow',
}

export enum FilterParameterKeys {
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  INFLOW = 'inflow',
  ROLEK50 = 'rolek50',
  ROLEK150 = 'rolek150',
  ROLEK500 = 'rolek500',
  ROLEK1500 = 'rolek1500',
  MSA = 'msa',
  DIM1 = 'dim1',
  N_ITEMS_SANKEY = 'node_limit',
}

export enum ViewsWithFilters {
  OVERVIEW = 'OVERVIEW',
  COMPANY_POSTINGS = 'COMPANY_POSTINGS',
  KEYWORD_POSTINGS = 'KEYWORD_POSTINGS',
  TRANSITIONS = 'TRANSITIONS',
  JOB_ANALYSIS_OVERVIEW = 'JOB_ANALYSIS_OVERVIEW',
  JOB_ANALYSIS_OVER_TIME = 'JOB_ANALYSIS_OVER_TIME',
  JOB_ANALYSIS = 'JOB_ANALYSIS',
  TALENT_DISCOVERY = 'TALENT_DISCOVERY',
}

export enum PrimaryFilterLimits {
  COMPANY_OVERVIEW = 6,
  COMPANY_SUMMARY = 1,
  GEOGRAPHY_SUMMARY = 1,
  ENTITY_SUMMARY = 1,
  ROLE_SUMMARY = 1,
  TRANSITIONS = 1,
  POSTINGS = 6,
  COMPENSATION = 2,
  COMPANY_SENTIMENT_RATINGS = 6,
  COMPANY_SENTIMENT_EFFECTS = 6,
  COMPANY_SENTIMENT_REVIEWS = 1,
  SENTIMENT = 1,
  GEO_OVERVIEW = 6,
  GEO_ACTIVITY_ANALYSIS = 2,
  SKILLS_POSTINGS = 1,
  ROLE_OVERVIEW = 6,
  ROLE_ACTIVITY_ANALYSIS = 2,
  OVERVIEW = 6,
  SKILLS = 6,

  // Default Limits
  POSTINGS_DEFAULT = 1,
  COMPENSATION_DEFAULT = 1,
  SKILLS_DEFAULT = 1,
}

export enum FilterMenuLimits {
  COMPANY_OVERVIEW = 10,
  ENTITY_SUMMARY = 10, //TODO??
  TRANSITIONS = 10,
  POSTINGS = 10,
  COMPENSATION = 10, //TODO
  COMPANY_SENTIMENT_RATINGS = 10,
  COMPANY_SENTIMENT_EFFECTS = 10,
  COMPANY_SENTIMENT_REVIEWS = 10,
  SENTIMENT = 10,
  COMPANY_SCREENER_INDUSTRY = 6,
  COMPANY_SCREENER = 3,
  GEO_OVERVIEW = 10,
  GEO_ACTIVITY_ANALYSIS = 3,
  SKILLS_POSTINGS = 10,
  ROLE_OVERVIEW = 10,
  ROLE_ACTIVITY_ANALYSIS = 3,
  TALENT_DISCOVERY = 6,
  SAVED_FILTER_SET = 1,
}

export enum OPValues {
  BETWEEN = 'between',
  LESS = 'less',
  GREATER = 'greater',
}

// omits K from each member of union type T
type DistributiveOmit<T, K extends PropertyKey> = T extends any
  ? Omit<T, K>
  : never;

export interface FilteringInitConfig {
  apiRoot: string;
  goApiRoot: string;
  companyMapperUrl: string;
}

export interface FilterStoreRootProps {
  cachedDate?: string;
}

export type OperatorOptionType = {
  label: string;
  value: OPValues;
  filterName: OPValues;
};

export type OperatorOptionsType = OperatorOptionType[];

export type SelectionListIdNames =
  | SelectionCategories
  | LocalSelectionCategories
  | SubFilterNames;

export interface SelectionListResponse<
  T extends ValidValueTypes = ValidValueTypes,
> {
  list: FilterList<T>;
  parent?: SelectionListIdNames;
}

export type FilterName = SelectionListIdNames | OtherFilterNames;
/** TODO/jbellizzi: FilterName already contains SubFilterNames via SelectionListIdNames.
 * keeping this type for all depencies, but we can switch all dependencies to FilterName since they are equal */
export type FilterOrSubfilterName = FilterName;

export type ViewFilterDefaultsValue$<T = FilterList> = {
  [key in
    | SelectionCategories
    | LocalSelectionCategories
    | SubFilterNames
    | OtherFilterNames]: Observable<T>;
};

export interface GetViewFilterDefaults {
  view: Views | ViewsForDefaultsOnly;
  viewType?: ViewTypes;
  viewFilters: FilterName[];
  onlyConsiderTheseFiltersToTriggerDefaults?: (
    | SelectionListIdNames
    | OtherFilterNames
  )[];
  presetView?: FilterSets;
  sharedView?: SharedFilterSets;
  considerPresetFiltersToTriggerDefaults?: boolean;
  limit?: PrimaryFilterLimits;
  deepLinkLimit?: PrimaryFilterLimits;
  syncPrimaryEntities?: boolean;
  primaryFilters?: (SelectionListIdNames | OtherFilterNames)[];
  dateKey?: SelectionCategories;
  uniqueFilterSet?: FilterSets;
  supportPrimaryEntities?: boolean;
}

export interface GetViewDefaultMonth {
  view: Views;
  viewType: ViewTypes;
  dateType: DefaultDates;
  dateKey?:
    | SelectionCategories.DATE_RANGE
    | SelectionCategories.SNAPSHOT_DATE
    | SelectionCategories.DATE_RANGE_FULL;
}

export enum DefaultDates {
  DEFAULT_LAST_MONTH = 'last_month',
  OLD_LAST_MONTH = 'old_last_month',
  LAST_START_DATE = 'maxWeek',
  TALENT_START_DATE = 'start_date',
  TALENT_END_DATE = 'end_date',
}

export interface IFilterLimit {
  filterNames: SelectionListIdNames[];
  limit: number;
}

export interface DateFilter<T = string> extends Omit<FilterBase, 'value'> {
  value: T;
  isMaximumRange: boolean;
  type: FilterTypes.DATE;
}

export interface BoardFilter extends Omit<FilterBase<boolean>, 'value'> {
  type: FilterTypes.BOARD;
  isMulti: false;
  value: { [key: string]: any };
}

export interface StoredFilterSet {
  id: ViewsWithFilters | string;
  entities: Filter[];
  count?: number;
}

export interface UserStoredFilterSet extends StoredFilterSet {
  name: string;
  label: string;
  view: string;
  creator: string;
  lastModified: Date;
  isDefault: boolean;
}

export type FilterNameIds = Filter['id'];
export type FilterNameIdsOrString = FilterNameIds | PropertyKey;

export type ProvidedFilterOrRawValueMap = {
  [keys in Filter['id']]?:
    | Partial<Filter>
    | (string | number)[]
    | { [key: string]: any }
    | string
    | number
    | boolean;
};

export interface InternalBuildDataRequest {
  endpoint: EndpointMapper | RequestConfig | string | DownloadEndpointMapper;
  requestMethod?: RequestMethod;
  brokenOutFilterIds?: FilterBase['id'][] | Observable<FilterBase['id'][]>;
  additionalNonActiveFilters?:
    | FilterBase['id'][]
    | Observable<FilterBase['id'][]>;
  additionalOperatorsBeforeQuery?: AdditionalOperatorsBeforeQueryFn;
  includeInGlobalLoader?: boolean;
  preFetchQuery: boolean;
  requiredParams?: SelectionListIdNames[];
  customGetRequest?: boolean;
  isGqlQuery?: boolean;
  isGoRequest?: boolean;
  gqlClient?: Client;
  view?: Views;
  dataFetcher?: PlotConfig['dataFetcher'];
  kibanaLogger?: KibanaLogger<AuthStore>;
}

export interface BuildDataRequest {
  /** if useing dataFetcher then provide a name string here for tracking purposes */
  endpoint: EndpointMapper | RequestConfig | string | DownloadEndpointMapper;
  requestMethod?: RequestMethod;
  brokenOutFilterIds?: FilterBase['id'][] | Observable<FilterBase['id'][]>;
  additionalNonActiveFilters?:
    | FilterBase['id'][]
    | Observable<FilterBase['id'][]>;
  additionalOperatorsBeforeQuery?: AdditionalOperatorsBeforeQueryFn;
  additionalOperators?: UnaryFunction<any, any>;
  includeInGlobalLoader?: boolean;
  preFetchQuery?: boolean;
  requiredParams?: SelectionListIdNames[];
  removeQueryFromLoaderOnResponse?: boolean;
  initialLoadingState?: boolean;
  toggleLoadingStatusBeforeQuery?: boolean;
  isGqlQuery?: boolean;
  isGoRequest?: boolean;
  view?: Views;
  dataFetcher?: PlotConfig['dataFetcher'];
  kibanaLogger?: KibanaLogger<AuthStore>;
  skip?: boolean;
}

export interface DownloadBuildRequest {
  endpoint: DownloadEndpointMapper | string | RequestConfig;
  brokenOutFilterIds?: FilterBase['id'][] | Observable<FilterBase['id'][]>;
  additionalNonActiveFilters?:
    | FilterBase['id'][]
    | Observable<FilterBase['id'][]>;
  requestMethod?: RequestMethod;
  additionalOperatorsBeforeQuery?: AdditionalOperatorsBeforeQueryFn;
  customGetRequest?: boolean;
  callback?: () => void;
  isGoRequest?: boolean;
  view?: Views;
  kibanaLogger?: KibanaLogger<AuthStore>;
}

export enum RequestMethod {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export interface IQueryEndpoint {
  url: URL;
  requestMethod?: RequestMethod;
  requestHash: string;
  search?: string;
  body?: string;
  catchErrorBool?: boolean;
  catchFn?: (e: any) => Observable<any>;
  includeInGlobalLoader?: boolean;
  preFetchQuery?: boolean;
  abortController?: AbortController;
  isGoRequest?: boolean;
  kibanaLogger?: KibanaLogger<AuthStore>;
}

export interface IInternalDataFetch {
  url: URL;
  method?: RequestMethod;
  search?: string;
  body?: string;
  abortController?: AbortController;
  isGoRequest?: boolean;
  kibanaLogger?: KibanaLogger<AuthStore>;
}

export interface ChartConfigForPlot {
  chartType: D3ChartNames;
  chartProps: DistributiveOmit<ID3ChartProps, 'data'>;
}

export interface PlotConfigs extends BuildDataRequest {
  name: string;
  chartTypeAndProps: Observable<ChartConfigForPlot> | ChartConfigForPlot;
}

export interface IFilterTypeHandler {
  [key: string]: (f: any, idx?: number) => any;
}

export interface SerializedFiltersForQuery
  extends EnumeratedFilters<ValidValueTypes | ValidValueTypes[]> {
  filters?: EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>;
}

export type ProvideAdditionalOperators<S = Filter, R = Filter> = (
  obs: Observable<S>
) => Observable<R>;

export interface ISelectFilter {
  filterName: SelectableCategories;
  isParentListDisabled: boolean;
  value: SelectableCategories | SelectableCategories[];
  isMulti: boolean;
  label: string;
  isNested?: boolean;
  limit?: number;
  showDivider?: boolean;
}

export interface DateValSelectFilter {
  label: string;
  value: number;
  filterName: string;
}

export interface Item {
  id: string | number;
  parentId?: string;
  label?: string;
  longName?: string;
  shortName?: string;
  [key: string]: any;
}
export interface TreeItem<T = string> {
  item: Item | null;
  children: TreeItem<T>[];
  id: string;
  parentId?: string;
  selectionListId: SelectionListIdNames;
  isMulti?: boolean;
  isSelectableParent?: boolean;
}

export type SelectableCategories =
  | SelectionCategories
  | LocalSelectionCategories;

export type FilterMenuItem = SelectableCategories | SelectableCategories[];

export type TDSelections = {
  keyName: string;
  name: string;
  hideTitle: boolean;
  showDivider?: boolean;
  placeholderText: string;
  selectionCategory: FilterName[];

  // TODO: "freeform" fields, figure out exact typings
  defaultState?: any; // eslint-disable-line
  type: string;
  comp: any[]; // eslint-disable-line
  props: Record<any, any>[]; // eslint-disable-line
  limit?: number;
};

export type NestedMenuItemConfig = Partial<MenuItemConfig & TDMenuItemConfig>;

export type MenuItemConfig = {
  filters: SelectableCategories[];
  isNested?: boolean;
  limit?: number;
};

type TDMenuItemConfig = {
  isNested: boolean;
  isAggregateChip: boolean;
  heading: SelectableCategories | string;
  selections: Partial<TDSelections>[];
  showDivider?: boolean;
};

export type FilterMenuItemOrConfig = FilterMenuItem | NestedMenuItemConfig;

export type SelectableFiltersMenuProp = {
  snapshot: FilterMenuItemOrConfig[];
  overtime: FilterMenuItemOrConfig[];
};

export type SelectableFiltersList = FilterMenuItem[];

export type AdditionalOperatorsBeforeQueryFnObservableArgs = {
  dataFetcher?: DataFetcher<unknown>;
  endpoint: CalculatedEndpoint | string | RequestConfig;
  filters: Filter[];
  additionalFilters: Filter[];
  brokenOutFilterIds$: (
    | SubFilterNames
    | SelectionListIdNames
    | OtherFilterNames
  )[];
  includeInGlobalLoader: boolean;
  preFetchQuery: boolean;
  plotRequestHash: string;
};
export type AdditionalOperatorsBeforeQueryFn = UnaryFunction<
  Observable<PlotAdditionalQueryParams>,
  Observable<PlotAdditionalQueryParams>
>;

/** ================================
 * Filter Values
 ================================ */
/** ======== Filter Value Types ======== */
export type DateRangeFormattedValues = {
  startDate: string;
  endDate: string;
};

export type ValueItem = {
  id: string | number;
  label?: string;
};

export type ValidValueTypes =
  | string
  | number
  | boolean
  | [number | Date, number | Date]
  | DateRangeFormattedValues
  | ValueItem;

/** ================================
 * Filter Items
 ================================ */
/** ======== Filter Item ======== */
export interface FilterItem<T extends ValidValueTypes = ValidValueTypes> {
  id: string | number;
  label?: string;
  value?: T; // same as id
  parentId?: string | number;
  shortName?: string;
  longName?: string;
  topCleanedTitles?: string;
  topRawTitles?: string;
  sedol?: string | null;
  isin?: string | null;
  ticker?: string;
  rcid?: string;
  rank?: number;
  index?: number;
  selectionListId?: string;
  isActive?: boolean;
}

export type FilterList<T extends ValidValueTypes = ValidValueTypes> =
  FilterItem<T>[]; // index's are unique and constant

export interface OPSelectFilter {
  filterName: OPValues;
  value: OPValues;
  label: string;
}

/** ======== Screen Filter Item ======== */
export type ScreenEmployeeTypesSelectionCategories =
  | SelectionCategories.REGION
  | SelectionCategories.INDUSTRY
  | SelectionCategories.SENIORITY;

export type ScreenerType = {
  employeeTypes: {
    [key in ScreenEmployeeTypesSelectionCategories]?: ValueItem[];
  };
  opValue: OPSelectFilter;
  isPercentage: boolean;
  startValue: number;
  endValue: number;
};

/** ======== Talent Discovery Filter Item ======== */
export type TalentDiscoveryCompanyValueType = {
  value: string;
  label: string;
};

export type TalentDiscoveryValue = {
  keyName: string;
  opValue: OPSelectFilter;
  startValue: number;
  endValue: number;
  companyValue?: TalentDiscoveryCompanyValueType[];
};

/** ================================
 * Filters
 ================================ */
export type FilterValue =
  | ValidValueTypes
  | FilterItem<ValidValueTypes>
  | FilterList<ValidValueTypes>
  | ScreenerType
  | TalentDiscoveryValue;

/** ======== Filter Base ========
 * Defines the base properties for all filters */
export interface FilterBase<V extends FilterValue = FilterValue> {
  id: FilterName;
  label: string;
  value: V;
  isUserSubmitted?: boolean;
  linkedFilters?: SelectionCategories[];
  customFormatter?: (filter: Filter, idx: number) => any;
  formatOverride?: Views | ViewsForDefaultsOnly | FilterFormatOverrides;
}

/** ======== Select Filter ======== */
export interface SelectFilter<T extends FilterValue = FilterValue>
  extends FilterBase<T> {
  selectionListId?: SelectionListIdNames;
  isMulti: boolean;
  type: FilterTypes.SELECT;
  primaryFilter?: PrimaryFilters;
}

export type DefaultSelectFilter<T extends ValidValueTypes = string> =
  | SelectFilter<FilterItem<T>>
  | undefined;

/** ======== Range Filter ======== */
export interface RangeFilter extends FilterBase<DateRangeFormattedValues> {
  isMaximumRange: boolean;
  type: FilterTypes.DATE_RANGE;
}

/** ======== Range Full Filter ======== */
export interface RangeFullFilter extends FilterBase<DateRangeFormattedValues> {
  isMaximumRange: boolean;
  type: FilterTypes.DATE_RANGE_FULL;
}

/** ======== Boolean Filter ======== */
export interface BooleanFilter extends FilterBase<boolean> {
  type: FilterTypes.BOOLEAN;
}

/** All filter types */
export type AnyFilter<T extends FilterValue = FilterValue> =
  | SelectFilter<T>
  | RangeFilter
  | BooleanFilter;

export type Filter = SelectFilter<FilterValue> | RangeFilter | BooleanFilter;

/** ================================
 * Selection Lists
 ================================ */
/** Selection list entities store all values for each filter */
export interface SelectionList<T extends ValidValueTypes = ValidValueTypes> {
  id: SelectionListIdNames;
  label: SelectionListIdNames;
  value: FilterList<T>;
  parent?: SelectionListIdNames;
}

/** ================================
 * View Filter Defaults
 ================================ */
export type ViewFilterDefaultIds = Views | ViewsForDefaultsOnly;

export type EnumeratedFilters<T> = {
  [key in FilterName]?: T;
};

export type MappedFilter = {
  [key in FilterName | FilterParameterKeys]?:
    | ValidValueTypes
    | ValidValueTypes[];
};

export interface ViewFilterDefaults<
  T extends ValidValueTypes = ValidValueTypes,
> {
  id: ViewFilterDefaultIds;
  filters: FilterName[];
  full?: boolean;
  meta?: { [key: string]: any };
  value: EnumeratedFilters<T | T[]>;
}
