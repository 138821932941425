import {
  CompensationCategory,
  CompensationDimension,
  CompensationFilters,
  Dimension1,
  GetCompensationDataQuery,
} from '@revelio/data-access';
import {
  GET_COMPENSATION_HELPER_DATA,
  getSelectedLastMonth,
  getStartDateConst,
  getYearFromDate,
} from '@revelio/filtering';
import { useEffect, useState } from 'react';
import { OperationResult, useClient } from 'urql';
// import { getDim1FromView } from '../utils';
import { PrimaryView, Views } from '@revelio/core';
import { omit } from 'lodash';
import { getDim1FromView } from './get-dim1-from-view';

export type TopEntities = {
  topCompanies: CompensationCategory[];
  topGeographies: CompensationCategory[];
  topRoles: CompensationCategory[];
};

const getTopEntities = (
  result: OperationResult<GetCompensationDataQuery>
): CompensationCategory[] =>
  result?.data?.compensation?.[0]?.category
    ?.filter((c): c is CompensationCategory => !!c)
    ?.slice(0, 6) || [];

type TopList = {
  id: number;
  label: number;
};

export type CompensationBody = CompensationFilters & {
  dim1: Dimension1;
  year_range: [[number, number]];
  top_companies?: TopList[];
  top_geographies?: TopList[];
  top_roles?: TopList[];
};

const getTopList = (topList: CompensationCategory[]): TopList[] => {
  return topList
    .map((category) => category.metadata?.id)
    .filter((id): id is number => !!id)
    .map((id) => ({ id, label: id }));
};

const stripDate = (filters: CompensationFilters): CompensationFilters =>
  omit(filters, ['start_date', 'end_date']);

export const useGetCompensationBody = ({
  view,
  filters,
  isReady,
  setLoading,
}: {
  view: PrimaryView;
  filters: CompensationFilters;
  isReady: boolean;
  setLoading: (loading: boolean) => void;
}) => {
  const [compensationBody, setCompensationBody] = useState<CompensationBody>();

  const urqlClient = useClient();

  useEffect(() => {
    setCompensationBody(undefined);
    if (isReady) {
      setLoading(true);
      const companyQuery = urqlClient
        .query(GET_COMPENSATION_HELPER_DATA, {
          dim1: getDim1FromView(view),
          dim2: CompensationDimension.Company,
          filters: stripDate(filters),
        })
        .toPromise();

      const geographyQuery = urqlClient
        .query(GET_COMPENSATION_HELPER_DATA, {
          dim1: getDim1FromView(view),
          dim2: CompensationDimension.Geography,
          filters: stripDate(filters),
        })
        .toPromise();

      const roleQuery = urqlClient
        .query(GET_COMPENSATION_HELPER_DATA, {
          dim1: getDim1FromView(view),
          dim2: CompensationDimension.Role,
          filters: stripDate(filters),
        })
        .toPromise();

      Promise.all([companyQuery, geographyQuery, roleQuery]).then(
        ([companyData, geographyData, roleData]) => {
          const topCompanies = getTopEntities(companyData);
          const topGeographies = getTopEntities(geographyData);
          const topRoles = getTopEntities(roleData);

          const startYear =
            getYearFromDate(filters?.start_date) ||
            getYearFromDate(getStartDateConst(Views.COMPENSATION));

          const compensationLastMonth = getSelectedLastMonth(
            Views.COMPENSATION
          );

          const endYear =
            getYearFromDate(filters?.end_date) ||
            (compensationLastMonth
              ? getYearFromDate(compensationLastMonth)
              : new Date().getFullYear());

          const top_companies = getTopList(topCompanies);
          const top_geographies = getTopList(topGeographies);
          const top_roles = getTopList(topRoles);

          setCompensationBody({
            ...filters,
            dim1: getDim1FromView(view),
            year_range: [[Number(startYear), Number(endYear)]],
            ...(top_companies.length > 0 && { top_companies }),
            ...(top_geographies.length > 0 && { top_geographies }),
            ...(top_roles.length > 0 && { top_roles }),
          });
        }
      );
    }
  }, [filters, urqlClient, view, isReady, setLoading]);

  return { compensationBody };
};
