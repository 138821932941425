import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Collapse,
  CollapseProps,
  Divider,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { BehaviorSubject, map, pipe, Subject } from 'rxjs';
import { FixedSizeTree, TreeWalkerValue } from 'react-vtree';
import { AsyncTreeCheckboxNode } from './async-tree-node/async-tree-checkbox-node';
import { get, isEmpty, isUndefined, omit } from 'lodash';
import styles from '../tree/tree.module.css';
import {
  EMPTY_NODE,
  NO_RESULTS_NODE,
  PLACEHOLDER_NODE,
} from './async-tree.constants';
import {
  CompanyResultItem,
  NodeMeta,
  TreeData,
  TreeNode,
} from './async-tree.types';
import React, {
  ElementType,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { AnyFilter, Item } from '../../../engine/filters.model';
import { ExpansionTree } from '../expansion-tree/expansion-tree/expansion-tree';
import { AsyncTreeClickableNode } from './async-tree-node/aync-tree-clickable-node';
import { ToggleSelect } from '../tree/toggle-select/toggle-select';
import { useTypeaheadFetch } from './hooks/useTypeaheadFetch';

const searchInput$ = new Subject<string>();

interface WithCollapseProps {
  cond: boolean;
  collapseProps?: CollapseProps;
  children: React.ReactNode[] | React.ReactNode;
}

const WithCollapse = ({
  cond,
  collapseProps = {},
  children,
}: WithCollapseProps) => {
  const Wrapper = cond ? Collapse : React.Fragment;

  return <Wrapper {...(cond ? collapseProps : {})}>{children}</Wrapper>;
};

export interface AsyncTreeProps {
  isSingleSelect?: boolean; // toggle to make each selection clickable rather than as checkbox selectable list
  apiEndpoint: string;
  placeholderListNodes: TreeNode[];
  width?: BoxProps['width'];
  maxHeight?: number;
  treeHeight?: number;
  innerHeight?: number;
  selections?: Record<string, Item | CompanyResultItem>;
  setSelections: React.Dispatch<React.SetStateAction<any>>;
  setTempSelections?: any;
  sortFn?: (a: TreeNode, b: TreeNode) => number;
  forwardedRef?: React.ForwardedRef<unknown>;
  searchInputRef?: React.MutableRefObject<HTMLInputElement | null>;
  inputGroupSize?: string;
  showPlaceholderNode?: boolean;
  collapseTree?: boolean;
  showToggleBar?: boolean;
  filterNameForToggle?: string;
  treeNodeType?: 'checkboxes' | 'list-item';
  onToggleBarChange?: (index: number) => void;
  toggleBarData?: AnyFilter[];
  fetchResults: any;
  operatorsAfterQuery?: any;
}

export function AsyncTree({
  placeholderListNodes,
  width,
  maxHeight,
  apiEndpoint,
  selections = {},
  setSelections,
  setTempSelections,
  treeHeight = 280,
  sortFn,
  forwardedRef,
  isSingleSelect = false,
  searchInputRef,
  inputGroupSize = 'md',
  showPlaceholderNode = true,
  collapseTree = false,
  showToggleBar = false,
  filterNameForToggle = '',
  onToggleBarChange,
  toggleBarData,
  treeNodeType,
  fetchResults,
  operatorsAfterQuery,
}: AsyncTreeProps) {
  const selectedTreeHeight = 80;

  const [search, setSearch] = useState<string>('');

  const selectionRef = useRef<
    BehaviorSubject<{
      [key: string]: Item | CompanyResultItem;
    }>
  >(new BehaviorSubject({}));
  const select = (item: Item, isChecked: boolean) => {
    const newItem = get(item, 'data', item);

    const id = get(newItem, 'rcid', newItem.id);

    if (isSingleSelect) {
      setSelections(item);
      return;
    }

    setSelections((prev: Record<string, Item>) => {
      const updatedSelections = isChecked
        ? {
            ...prev,
            [id]: { primary_name: item.label, ...newItem },
          }
        : omit(prev, id);

      setTempSelections?.(updatedSelections);

      return updatedSelections;
    });
  };

  const handleClearSelections = () => {
    setSelections({});
  };

  const placeholderTreeWalker = useCallback(
    function* PlaceholderTreeWalker(): any {
      if (!isEmpty(placeholderListNodes)) {
        for (const treeItem of placeholderListNodes) {
          yield getNodeData(treeItem, 0);
        }

        while (true) {
          const parentMeta = yield;

          for (let i = 0; i < parentMeta.node.children.length; i++) {
            yield getNodeData(
              parentMeta.node.children[i],
              parentMeta.nestingLevel + 1
            );
          }
        }
      } else {
        yield getNodeData(
          {
            id: showPlaceholderNode ? PLACEHOLDER_NODE : EMPTY_NODE,
          },
          0
        );
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [placeholderListNodes]
  );

  const [treeWalker, setTreeWalker] = useState(() => placeholderTreeWalker);

  const { searchString, isLoading } = useTypeaheadFetch(searchInput$, {
    apiEndpoint,
    fetchResults,
    operatorsAfterQuery: pipe(
      operatorsAfterQuery,
      map((res: any) => {
        if (!isUndefined(sortFn)) {
          res.sort(sortFn);
        }

        setTreeWalker(
          () =>
            function* dataTreeWalker(): any {
              // TODO: Need to figure out treewalker typing
              if (res.length === 0) {
                /// no results
                yield getNodeData({ id: NO_RESULTS_NODE }, 0);
              }

              for (const item of res) {
                yield getNodeData(item, 0);
              }
            }
        );
      })
    ),
  });

  const getNodeData = (
    node: TreeNode,
    nestingLevel: number
  ): TreeWalkerValue<TreeData, NodeMeta> => {
    const { id, item, children = [] } = node;

    const nodeItem = get(item, 'data', item);

    const primary_name = get(item, 'label');

    const nodeId = nodeItem?.rcid || id;

    const isLeaf = children.length === 0;

    return {
      data: {
        id: nodeId.toString(),
        item: { primary_name, ...nodeItem },
        isOpenByDefault: false,
        nestingLevel,
        isSelectedByDefault: false,
        disableSelect: !isLeaf,
        isLeaf,
        select,
        selectionLookup: selectionRef.current as BehaviorSubject<{
          [key: string]: Item;
        }>, //temporarily assuming always Item to support CompanyResultItem elsewhere here. Need to rething types to resolve properly
      },
      nestingLevel,
      node,
    };
  };

  useImperativeHandle(forwardedRef, () => ({
    handleClearSelections,
  }));

  useEffect(() => {
    searchInput$.next(search);

    if (isEmpty(search)) {
      setTreeWalker(() => placeholderTreeWalker);
    }
  }, [placeholderTreeWalker, search]);

  useEffect(() => {
    selectionRef.current.next(selections);
  }, [selections]);

  const treeNode: ElementType = (() => {
    if (treeNodeType === 'checkboxes') {
      return AsyncTreeCheckboxNode;
    } else if (treeNodeType === 'list-item') {
      return AsyncTreeClickableNode;
    } else {
      return isSingleSelect ? AsyncTreeClickableNode : AsyncTreeCheckboxNode;
    }
  })();

  return (
    <Box
      width={width}
      background="white"
      borderRadius="3px"
      height="fit-content"
      max-height={maxHeight}
    >
      <Box width="100%" borderBottomRadius="3px">
        <Box marginBottom="2">
          <InputGroup size={inputGroupSize}>
            <Input
              placeholder="Search..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.toLowerCase());
              }}
              backgroundColor="#ffffff"
              ref={searchInputRef}
              data-testid="async-tree-search-input"
            />
            {isLoading && (
              <InputRightElement
                mr={6}
                children={
                  <Spinner
                    w={3.5}
                    h={3.5}
                    color="green.600"
                    data-testid="async-tree-search-input-loading"
                  />
                }
              />
            )}
            <InputRightElement
              children={
                <>
                  {/* Render Search or Close icon */}
                  {search === '' ? (
                    <SearchIcon w={3.5} h={3.5} color="silver.600" />
                  ) : (
                    <CloseIcon
                      w={2.5}
                      h={2.5}
                      color="silver.600"
                      onClick={() => {
                        setSearch('');
                      }}
                    />
                  )}
                </>
              }
            />
          </InputGroup>
        </Box>
        {!isSingleSelect && Object.values(selections).length > 0 && (
          <>
            <ExpansionTree
              selections={selections}
              selectionLookup={selectionRef.current}
              select={select}
              height={selectedTreeHeight}
              defaultIsOpen
            />
            <Divider my={2} />
          </>
        )}
        <Box
          onWheel={(e) => {
            e.stopPropagation();
          }}
        >
          {collapseTree && searchString.length === 0 && (
            <Text fontSize="xs" pt={1}>
              {'Start typing to see results'}
            </Text>
          )}
          {
            <WithCollapse
              cond={collapseTree}
              collapseProps={{ in: searchString.length > 0 }}
            >
              <FixedSizeTree
                className={styles.fixedSizedTree}
                treeWalker={treeWalker}
                itemSize={24}
                height={treeHeight}
              >
                {treeNode}
              </FixedSizeTree>
            </WithCollapse>
          }

          {showToggleBar &&
            (searchString.length > 0 ||
              Object.values(selections)?.length > 0) && (
              <ToggleSelect
                filterName={filterNameForToggle}
                data={toggleBarData}
                onChange={onToggleBarChange}
              />
            )}
        </Box>
      </Box>
    </Box>
  );
}

const WithAsyncTreeRef = (
  TreeComponent: React.FunctionComponent<AsyncTreeProps>
) => {
  return forwardRef(({ ...rest }: AsyncTreeProps, ref) => (
    <TreeComponent {...rest} forwardedRef={ref} />
  ));
};

export const AsyncTreeRef = React.memo(WithAsyncTreeRef(AsyncTree));
