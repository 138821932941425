import React from 'react';
import { Box } from '@chakra-ui/react';
import Skeleton from 'react-loading-skeleton';

interface FilterChipsLoaderProps {
  children: React.ReactNode;
  loading?: boolean;
  skeletonProps?: React.ComponentProps<typeof Skeleton>;
}

export const FilterChipsLoader = ({
  loading,
  children,
  skeletonProps = {},
}: FilterChipsLoaderProps) => {
  if (loading) {
    return (
      <Box>
        <Skeleton width="155px" height="25px" {...skeletonProps} />
      </Box>
    );
  }

  return children;
};
