import {
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useMemo } from 'react';

import { isEmpty, startCase } from 'lodash';
import { KDEPlot } from '@revelio/d3';

import { MarketTightnessSlider } from './MarketTightnessSlider';
import { Loading } from '@revelio/core';
import { WinnerInterface } from './Game';
import SlotText from './SlotText';
import spotLights from './assets/spot-lights.png';
import { NoResultsPlaceholder } from './NoResults';

type ResultsDataType = Record<string, string>;

interface ResultsModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: ResultsDataType;
  // TODO: Need to type data
  // eslint-disable-next-line
  kdeData: any;
  isLoading?: boolean;
  setIsPlotLoading?: React.Dispatch<boolean>;
  winner: WinnerInterface[];
  setHighestScore?: React.Dispatch<number>;
}

const ResultsModal = ({
  isOpen,
  onClose,
  data,
  kdeData,
  isLoading,
  setIsPlotLoading,
  winner,
  setHighestScore,
}: ResultsModalProps) => {
  const generateStatsListEntities = (results: ResultsDataType) => {
    if (isEmpty(results)) {
      return <NoResultsPlaceholder />;
    }
    return Object.entries(results).map(([key, stat]) => {
      if (key === 'market_tightness') {
        return (
          <Flex key={key} direction="column">
            <Text pb={12} color="white" fontSize="3vh">
              {startCase(key)}
            </Text>
            <MarketTightnessSlider marketTightness={stat} />
          </Flex>
        );
      }
      return (
        <Flex key={key} justify="space-between">
          <Text color="white" fontSize="3vh">
            {startCase(key)}
          </Text>
          <Text
            color={
              /* eslint-disable-next-line no-nested-ternary */
              stat.includes('−')
                ? '#ff2261'
                : stat.includes('+')
                  ? '#9dff4e'
                  : 'white'
            }
            fontSize="3vh"
          >
            {stat}
          </Text>
        </Flex>
      );
    });
  };

  const statsList = useMemo(() => {
    if (!data) {
      return [];
    }
    return generateStatsListEntities(data);
  }, [data]);

  return (
    <Modal onClose={onClose} size="full" isOpen={isOpen}>
      <ModalOverlay background="rgba(30, 11, 93, 0.94)" />
      <ModalContent h="100%" background="none" overflow="hidden">
        <ModalBody
          background="none"
          px={0}
          py={10}
          pointerEvents="none"
          userSelect="none"
        >
          <Grid
            h="100%"
            templateRows="repeat(4, 1fr)"
            templateColumns="repeat(3, 1fr)"
          >
            <GridItem colSpan={2} rowSpan={1}>
              <Flex
                bg="rgba(0, 0, 0, 0.42)"
                color="white"
                h="90%"
                w="100%"
                borderRightRadius="15px"
                justifyContent="flex-start"
                alignItems="center"
                paddingX={10}
                gap={4}
                zIndex={5}
                position="relative"
              >
                <Text
                  color="white"
                  fontWeight="bold"
                  fontSize="5vh"
                  marginLeft={8}
                  paddingRight={0}
                  zIndex={5}
                >
                  Results:
                </Text>
                <Flex position="relative">
                  <img
                    src={spotLights}
                    alt="lights"
                    style={{
                      position: 'absolute',
                      zIndex: 4,
                      mixBlendMode: 'luminosity',
                      transform: 'scale(2)',
                      top: '20%',
                      height: '80vh',
                      width: 'auto',
                    }}
                  />
                  {winner.map((d: WinnerInterface) => (
                    <Flex key={d.id} transform="scaleY(-1)" zIndex={5}>
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column-reverse"
                      >
                        <SlotText
                          textStyleClass={d?.class}
                          text={d?.shortName}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </GridItem>
            <GridItem colSpan={1} rowSpan={4}>
              <Flex h="100%" px={4} pt={4} mr={8}>
                <Flex direction="column" h="100%" w="100%" alignItems="center">
                  <Flex
                    position="relative"
                    h="100%"
                    w="100%"
                    justifyContent="center"
                    px={4}
                    mt={4}
                  >
                    <Flex
                      border="2px solid #FF82FF"
                      borderRadius="10px"
                      position="absolute"
                      px={6}
                      py={1}
                      zIndex={30}
                      bg="black"
                      mt="-14px"
                      left="4"
                      fontSize="3.5vh"
                      fontWeight="bold"
                    >
                      <Text color="white">Key Stats</Text>
                    </Flex>
                    <Flex
                      border="3px solid #FF82FF"
                      background="rgba(0,0,0, 0.40)"
                      borderRadius="15px"
                      w="90%"
                      h="100%"
                      position="absolute"
                      zIndex={10}
                    ></Flex>
                    <Flex
                      border="3px solid #00C3FF"
                      borderRadius="15px"
                      position="absolute"
                      w="90%"
                      h="100%"
                      mt={2}
                      ml={4}
                      zIndex={20}
                    >
                      <Flex
                        direction="column"
                        pt="7vh"
                        pb={4}
                        pl={6}
                        pr={8}
                        w="100%"
                        h="100%"
                        justifyContent="space-between"
                      >
                        {isLoading ? (
                          <Loading transparentBackground reverse />
                        ) : (
                          statsList
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex mt={8} w="100%">
                    <Button
                      fontSize="36px"
                      fontWeight="bold"
                      mr={2}
                      ml={4}
                      h="70px"
                      w="100%"
                      marginX={10}
                      borderRadius="full"
                      background="linear-gradient(0deg, #FD5B33 0%, #BE0016 100%)"
                      onClick={onClose}
                      filter="drop-shadow(0 8px #760f1b) drop-shadow(0 10px #00000051)"
                      _hover={{
                        background:
                          'linear-gradient(0deg, #BE0016 0%, #FD5B33 100%)',
                      }}
                    >
                      Spin Again
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </GridItem>
            <GridItem colSpan={2} rowSpan={3}>
              <Flex h="100%" ml={8} p={4}>
                <Flex position="relative" h="100%" w="100%">
                  <Flex
                    border="2px solid #FF82FF"
                    borderRadius="10px"
                    position="absolute"
                    px={6}
                    py={1}
                    ml={-4}
                    zIndex={30}
                    bg="black"
                    fontSize="3.5vh"
                    fontWeight="bold"
                  >
                    <Text color="white">Pay Distribution</Text>
                  </Flex>
                  <Flex
                    border="3px solid #00C3FF"
                    background="rgba(0,0,0, 0.40)"
                    borderRadius="15px"
                    position="absolute"
                    h="100%"
                    w="100%"
                    mt={2}
                    zIndex={9}
                  />
                  <Flex
                    border="3px solid #FF82FF"
                    borderRadius="15px"
                    position="absolute"
                    h="100%"
                    w="100%"
                    mt={4}
                    ml={2}
                    p={4}
                    zIndex={10}
                  >
                    <Flex
                      display={isLoading ? 'flex' : 'none'}
                      position="absolute"
                      direction="column"
                      justify="center"
                      w="100%"
                      h="100%"
                    >
                      <Loading transparentBackground reverse />
                    </Flex>
                    <Flex
                      visibility={isLoading ? 'hidden' : 'visible'}
                      w="100%"
                      pt={6}
                    >
                      <KDEPlot
                        data={kdeData?.value || []}
                        lineColor="#FA5CFF"
                        gradient="rgba(40, 212, 255, 0.867)"
                        lineStrokeWidth={4}
                        xAxisLabelColor="#fff"
                        xAxisLabelSize="2.5vh"
                        setIsLoading={setIsPlotLoading}
                        customMargins={{
                          left: 60,
                          right: 60,
                          top: 100,
                          bottom: 60,
                        }}
                        axisGridLineClassName="axis-grid-game"
                        showMaxPoint
                        setHighestScore={setHighestScore}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ResultsModal;
