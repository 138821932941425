import { Filter, FilterName, SelectionCategories } from '@revelio/filtering';

export const isQueryReady = ({
  activeFilters,
  primaryFilters,
}: {
  activeFilters: Filter[];
  primaryFilters: FilterName[];
}) => {
  const activeFiltersHasPrimaryFilter = activeFilters.some((filter) =>
    primaryFilters.includes(filter.id)
  );

  const activeFiltersHasDateRange = activeFilters.some(
    (filter) => filter.id === SelectionCategories.DATE_RANGE_FULL
  );

  return activeFiltersHasPrimaryFilter && activeFiltersHasDateRange;
};
