import {
  SelectionCategories,
  FilterContainer,
  FilterMenu,
  FilterChips,
  TabsFilter,
  EndpointSegment,
  provideBasePlotConfigDefaults,
  ViewTypes,
  useViewFilters,
  requireAtLeastOneFilterValueOf,
  useSingleOrMoreFilterState,
  AddEntityButton,
  useViewFilterDefaults,
  useStoredFilterSet,
  PrimaryFilterLimits,
  FilterMenuLimits,
  LocalSelectionCategories,
  useSelectionLists,
  upsertFiltersWithProvidedValue,
  useManyPreFetchPlotConfigProviders,
  useDefaultLastMonth,
  DefaultDates,
  SelectFilter,
  FilterItem,
  ValueItem,
  useSyncFiltersToSearchParams,
  getPrimaryDataView,
  PrimaryDataView,
  createSelectableFiltersMap,
  FilterMenuItemOrConfig,
  FilterNameIds,
  FilterChipsContainer,
  FilterSets,
} from '@revelio/filtering';
import { Grid, GridItem, Flex } from '@chakra-ui/react';
import { tap } from 'rxjs/operators';
import { D3ChartNames } from '@revelio/d3';
import { pipe } from 'rxjs';
import { useEffect, useMemo, useState } from 'react';
import { DefaultCard } from '@revelio/composed';
import { overTimeChartProps, snapshotChartProps } from './chart-props.config';
import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  Views,
  useResponsivePageGridDefs,
} from '@revelio/core';
import useViewflow from '../useViewflow';
import DashboardPage from '../DashboardPage';
import { flatten } from 'lodash';

/* eslint-disable-next-line */
export interface SentimentRatingsProps {
  pageTitle: PageTitles;
  primaryFilter: PrimaryFilters;
  view: Views;
  viewType: ViewTypes;
  trialNoResultsMessage?: JSX.Element;
  sharedFilterSetId: FilterSets;
  filterSet?: FilterSets;
}

export function SentimentRatings(props: SentimentRatingsProps) {
  const view = Views.SENTIMENT_RATING;
  const { templateColumns, templateRows, gridItemMinHeight } =
    useResponsivePageGridDefs(view);

  const {
    primaryViewFilter,
    selectableFilters,
    filterSet,
    viewForDefaultsOnly = props.view,
    isGqlQuery,
  } = useViewflow({
    view: view,
    primaryFilter: props.primaryFilter,
  });

  const primaryDataView: PrimaryDataView = useMemo(
    () => getPrimaryDataView(props.viewType),
    [props.viewType]
  );

  const snapShotDateFilter = SelectionCategories.SNAPSHOT_DATE;
  const dateRangeFilter = SelectionCategories.DATE_RANGE;

  const brokenOutFilterIds = [SelectionCategories.PRIMARY_FILTER];

  const primaryFilters = flatten(createSelectableFiltersMap(primaryViewFilter));

  const selectableFiltersFlattened = flatten(
    createSelectableFiltersMap(selectableFilters)
  );

  const storedFilterSetArgs = {
    sharedSetId: props.sharedFilterSetId,
    tab: props.viewType,
    primaryEntitiesSync: true,
    limit: PrimaryFilterLimits.COMPANY_SENTIMENT_RATINGS,
    filterNames: primaryFilters,
    uniqueSetId: filterSet,
  };

  useStoredFilterSet(storedFilterSetArgs);
  useSelectionLists(selectableFiltersFlattened);
  useViewFilters([
    ...primaryFilters,
    ...selectableFiltersFlattened,
  ] as FilterNameIds[]);

  const viewFilterDefaultArgs = {
    view: viewForDefaultsOnly,
    viewType: props.viewType,
    presetView: props.sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.COMPANY_SENTIMENT_RATINGS,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);

  useDefaultLastMonth({
    view,
    viewType: props.viewType,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
  });
  useSyncFiltersToSearchParams({
    primaryFilters,
    syncToPrimaryEntities: true,
  });

  const [filtersForMenu, setFiltersForMenu] =
    useState<FilterMenuItemOrConfig[]>(selectableFilters);

  const additionalOperatorsBeforeQuery = pipe(
    requireAtLeastOneFilterValueOf(primaryFilters)
  );

  const viewDefaultsForPlots = provideBasePlotConfigDefaults({
    view: view,
    viewType: ViewTypes.SNAPSHOT,
    chartType: D3ChartNames.BarChartHorizontal,
  });

  const {
    mappers: { currentConfigMappers: configMappers, preFetchConfigMappers },
  } = useManyPreFetchPlotConfigProviders(
    [
      EndpointSegment.OVERALL,
      EndpointSegment.BUSINESS,
      EndpointSegment.CAREER,
      EndpointSegment.COMPENSATION,
      EndpointSegment.WORKLIFE,
      EndpointSegment.CULTURE,
      EndpointSegment.LEADERSHIP,
      EndpointSegment.DIVERSITY,
      EndpointSegment.RECOMMEND,
    ].map((endpointSegment) => {
      return viewDefaultsForPlots({
        endpoint: endpointSegment,
        chartProps: snapshotChartProps[endpointSegment],
        brokenOutFilterIds: brokenOutFilterIds,
        additionalNonActiveFilters: [...brokenOutFilterIds, snapShotDateFilter],
        preFetchConfig: {
          viewType: ViewTypes.OVERTIME,
          chartType: D3ChartNames.LineChart,
          chartProps: overTimeChartProps[endpointSegment as EndpointSegment],
          additionalNonActiveFilters: [...brokenOutFilterIds, dateRangeFilter],
          brokenOutFilterIds: brokenOutFilterIds,
        },
        metaData: {
          isGqlQuery: isGqlQuery,
          isGoRequest: true,
          pageGroupName: 'sentiment',
          primaryDataView: primaryDataView,
        },
      });
    })
  );

  const [isSnapshotState, setIsSnapshotState] = useState<boolean>(false);

  useSingleOrMoreFilterState<SelectFilter<FilterItem<ValueItem>>>(
    LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME,
    pipe(
      tap((filter) => {
        const singleFilter = filter as SelectFilter<FilterItem<ValueItem>>;
        if (singleFilter?.value.id) {
          const isSnapshot = singleFilter?.value.id == ViewTypes.SNAPSHOT;
          setIsSnapshotState(isSnapshot);
          const newViewType = (cond: boolean) =>
            cond ? ViewTypes.SNAPSHOT : ViewTypes.OVERTIME;

          const updatedChartType = (cond: boolean) =>
            cond ? D3ChartNames.BarChartHorizontal : D3ChartNames.LineChart;

          const chartPropsConfigLookup = (cond: boolean) =>
            cond ? snapshotChartProps : overTimeChartProps;

          const updatedAdditionalFilters = (cond: boolean) =>
            cond
              ? [snapShotDateFilter, ...brokenOutFilterIds]
              : [dateRangeFilter, ...brokenOutFilterIds];

          const updatedFilterMenuFilters = isSnapshot
            ? [...selectableFilters, snapShotDateFilter]
            : [...selectableFilters, dateRangeFilter];

          setFiltersForMenu(updatedFilterMenuFilters);
          configMappers.forEach(({ endpointSegment, updater }, i) => {
            const preFetchUpdater = preFetchConfigMappers[i].updater;
            updater.next({
              viewType: newViewType(isSnapshot),
              chartType: updatedChartType(isSnapshot),
              chartProps:
                chartPropsConfigLookup(isSnapshot)[
                  endpointSegment as EndpointSegment
                ],
              brokenOutFilterIds: brokenOutFilterIds,
              additionalNonActiveFilters: updatedAdditionalFilters(isSnapshot),
            });
            preFetchUpdater.next({
              viewType: newViewType(!isSnapshot),
              chartType: updatedChartType(!isSnapshot),
              chartProps:
                chartPropsConfigLookup(!isSnapshot)[
                  endpointSegment as EndpointSegment
                ],
              brokenOutFilterIds: brokenOutFilterIds,
              additionalNonActiveFilters: updatedAdditionalFilters(!isSnapshot),
            });
          });
        }
      })
    )
  );

  useEffect(() => {
    if (props.primaryFilter) {
      upsertFiltersWithProvidedValue(
        {
          [SelectionCategories.PRIMARY_FILTER]: props.primaryFilter,
        },
        true
      );
    }
  }, [props.primaryFilter]);

  return (
    <DashboardPage
      title={[props.pageTitle, PageTitles.SENTIMENT_RATINGS]}
      hideSelectionsMargins
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            showColors={!isSnapshotState}
            isPrimaryChip={true}
            view={view}
            min={1}
            limit={PrimaryFilterLimits.COMPANY_SENTIMENT_RATINGS}
            addButton={
              <AddEntityButton
                entities={primaryViewFilter}
                entityName={AddEntityButtonText[props.primaryFilter]}
                buttonText={AddEntityButtonText[props.primaryFilter]}
                limit={PrimaryFilterLimits.COMPANY_SENTIMENT_RATINGS}
                required={1}
                trialNoResultsMessage={props.trialNoResultsMessage}
              />
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={[
              ...selectableFilters,
              snapShotDateFilter,
              dateRangeFilter,
            ]}
            variant="filterChip"
            limit={FilterMenuLimits.COMPANY_SENTIMENT_RATINGS}
            view={view}
            // TODO: need to rename/reconfigure view name so we can remove propsView prop
            propsView={props.view}
            viewType={props.viewType}
            filtersToIgnore={
              isSnapshotState
                ? [SelectionCategories.DATE_RANGE]
                : [SelectionCategories.SNAPSHOT_DATE]
            }
            addButton={
              <FilterMenu
                title="Filter"
                filters={[
                  ...filtersForMenu,
                  // SelectionCategories.SAVED_FILTER_SET,
                ]}
                selectMenuOpenDefault
                limit={FilterMenuLimits.COMPANY_SENTIMENT_RATINGS}
                view={props.view}
                showFilterSetSaveMenu={false}
                viewIdForDefault={`${viewForDefaultsOnly}_${props.viewType}`}
              />
            }
          />
        </Flex>

        <TabsFilter
          filterName={LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME}
        ></TabsFilter>
      </FilterContainer>

      <Grid
        height="100%"
        templateRows={templateRows}
        templateColumns={templateColumns}
        gap={4}
        data-testid="plots-grid"
      >
        {configMappers.map((config, i) => (
          <GridItem
            key={i}
            rowSpan={1}
            colSpan={1}
            minH={gridItemMinHeight}
            className={`sentiment-plot-${config.endpointSegment}`}
          >
            <DefaultCard
              cardConfig={{
                header: config.name,
                endpointSegment: config.endpointSegment,
                view: view,
              }}
              plotConfig={{
                endpoint: config.endpointMapper,
                chartTypeAndProps: config.plotConfigMapper,
                additionalOperatorsBeforeQuery: additionalOperatorsBeforeQuery,
                additionalNonActiveFilters: config.additionalNonActiveFilters,
                brokenOutFilterIds: config.brokenOutFilterIds,
                dataProvider: config.dataProvider,
                preFetchConfig: preFetchConfigMappers[i],
                isGqlQuery: config.metaData?.isGqlQuery,
              }}
              downloadConfig={{
                endpoint: config.downloadEndpointMapper,
                isGoRequest: config.metaData?.isGoRequest,
              }}
            />
          </GridItem>
        ))}
      </Grid>
    </DashboardPage>
  );
}

export default SentimentRatings;
