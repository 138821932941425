import { Box, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { isNumber, startCase } from 'lodash';
import { formatCurrency, formatWithCommas } from '../helpers';
import { EmploymentEntryEn } from '../resumeTypes';
import PercentageBar from './PercentageBar';

interface EmploymentEnrichedProps {
  data?: EmploymentEntryEn;
  variant?: string;
}

export default function EmploymentEnriched({
  data,
  variant,
}: EmploymentEnrichedProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      {data?.role && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Mapped Role</Text>
          <Text sx={styles['enrichedDataValue']}>{data?.role}</Text>
        </Box>
      )}
      {data?.job_family && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Job Family</Text>
          <Text sx={styles['enrichedDataValue']}>
            {startCase(data?.job_family)}
          </Text>
        </Box>
      )}
      {data?.company_name && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Company</Text>
          <Text sx={styles['enrichedDataValue']}>{data?.company_name}</Text>
        </Box>
      )}
      {(data?.location_city || data?.location_state) && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Location</Text>
          <Text sx={styles['enrichedDataValue']}>
            {formatWithCommas([data?.location_city, data?.location_state])}
          </Text>
        </Box>
      )}
      {data?.location_metro && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Metro</Text>
          <Text sx={styles['enrichedDataValue']}>
            {startCase(data?.location_metro)}
          </Text>
        </Box>
      )}
      {data?.location_country && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Country</Text>
          <Text sx={styles['enrichedDataValue']}>
            {startCase(data?.location_country)}
          </Text>
        </Box>
      )}
      {data?.salary && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Salary</Text>
          <Text sx={styles['enrichedDataValue']}>
            {formatCurrency(
              JSON.parse(`${data.salary.min || 0}`),
              data?.salary_currency || 'usd'
            )}
            -
            {formatCurrency(
              JSON.parse(`${data.salary.max || 0}`),
              data?.salary_currency || 'usd'
            )}
          </Text>
        </Box>
      )}
      {data?.seniority && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Seniority</Text>
          <Box sx={styles['enrichedDataValue']} mt="auto" mb="auto">
            {/* seniority is a range from 1 to 7 */}
            <PercentageBar
              percentage={JSON.parse(data?.seniority || '0') / 7}
            />
          </Box>
        </Box>
      )}
      {data?.prestige && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Company Prestige</Text>
          <Box sx={styles['enrichedDataValue']} mt="auto" mb="auto">
            {/* prestige is a range from -1 to 1 */}
            <PercentageBar
              percentage={(JSON.parse(data?.prestige || '0') + 1) / 2}
            />
          </Box>
        </Box>
      )}
      {isNumber(data?.current_tenure) && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Relative Tenure</Text>
          <Box sx={styles['enrichedDataValue']} mt="auto" mb="auto">
            {/* prestige is a range from 1 to 5 */}
            <PercentageBar percentage={(data?.current_tenure || 0) / 5} />
          </Box>
        </Box>
      )}
      {data?.full_time_flag && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Full Time Flag</Text>
          <Text sx={styles['enrichedDataValue']}>{data?.full_time_flag}</Text>
        </Box>
      )}
      {!!data?.activities?.length && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Activities</Text>
          <Box sx={styles['enrichedDataValue']}>
            <ul style={{ paddingLeft: '10px' }}>
              {data?.activities.map((activity, i) => (
                <li key={'activity-' + i}>
                  <Text>{activity}</Text>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      )}
      {!!data?.extracted_skills?.length && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Skills</Text>
          <Text sx={styles['enrichedDataValue']}>
            {data?.extracted_skills?.join(' | ')}
          </Text>
        </Box>
      )}
    </Box>
  );
}
