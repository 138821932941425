import { useQuery } from 'urql';

import {
  Filter,
  FilterList,
  GEOGRAPHY_GRANULARITY_FILTERS,
  GET_COMPANY_SUMMARY_MAP_DATA,
  GET_GEOGRAPHY_SUMMARY_MAP_DATA,
  SelectionCategories,
  serializeTDFilters,
  SkillFilter,
  SkillLevel,
  talentDiscoveryFilterTransformation,
  useActiveFiltersState,
} from '@revelio/filtering';
import {
  MapCoordsGranularity,
  MapCoordsItem,
  MapCoordsReq,
  MapData,
  TalentDiscoveryV1_5Filter,
} from '@revelio/data-access';
import { useEffect, useState } from 'react';
import { getGeographyFilterType } from '../geography-summary';
import { isNumber } from 'lodash';

export const useGetGeographyMapData = () => {
  const activeFilters = useActiveFiltersState();
  const numOfFilterSelections = activeFilters.length;
  const [skillFilters, setSkillFilters] = useState<SkillFilter[]>([]);

  const region = getGeographyFilterType(
    SelectionCategories.REGION,
    activeFilters
  );
  const country = getGeographyFilterType(
    SelectionCategories.COUNTRY,
    activeFilters
  );

  const selectedGeoType = (() => {
    if (region) {
      return 'region';
    }

    if (country) {
      return 'country';
    }

    return 'msa';
  })();

  useEffect(() => {
    const getActiveSkillFilterValues = (
      filterId: string,
      skillLevel: SkillLevel
    ) =>
      (
        (activeFilters?.find((filter) => filter.id === filterId)
          ?.value as FilterList) ?? []
      ).map((val) => ({
        skillLevel,
        id: isNumber(val.id) ? val.id : 0,
      }));

    const activeK75FilterValues = getActiveSkillFilterValues(
      'skill_k75',
      SkillLevel.SkillK75
    );
    const activeK700FilterValues = getActiveSkillFilterValues(
      'skill_k700',
      SkillLevel.SkillK700
    );
    const activeK3000FilterValues = getActiveSkillFilterValues(
      'skill_k3000',
      SkillLevel.SkillK3000
    );

    setSkillFilters([
      ...activeK75FilterValues,
      ...activeK700FilterValues,
      ...activeK3000FilterValues,
    ]);
  }, [activeFilters]);

  const getMapCoordsFromFilter = (
    filter: Filter | undefined,
    expectedId: string,
    granularity: MapCoordsGranularity
  ) => {
    if (filter?.id === expectedId) {
      const idValue = (filter.value as FilterList)[0].id;
      return [
        {
          id: isNumber(idValue) ? idValue : parseInt(idValue),
          granularity,
        },
      ];
    }
    return [];
  };

  const getMapCoordsReqFromFilters = (filters: Filter[]): MapCoordsReq => {
    const selectedGeoFilter = filters.find((filter) =>
      GEOGRAPHY_GRANULARITY_FILTERS.includes(filter.id as SelectionCategories)
    );

    return {
      region: getMapCoordsFromFilter(
        selectedGeoFilter,
        'region',
        MapCoordsGranularity.Country
      ),
      country: getMapCoordsFromFilter(
        selectedGeoFilter,
        'country',
        MapCoordsGranularity.Msa
      ),
      msa: getMapCoordsFromFilter(
        selectedGeoFilter,
        'metro_area',
        MapCoordsGranularity.Msa
      ),
    };
  };

  const [{ data: mapCoordsData, fetching: mapCoordsFetching }] = useQuery({
    query: GET_GEOGRAPHY_SUMMARY_MAP_DATA,
    variables: {
      query: getMapCoordsReqFromFilters(activeFilters),
    },
    pause: numOfFilterSelections === 0,
  });

  const [{ data, fetching }] = useQuery({
    query: GET_COMPANY_SUMMARY_MAP_DATA,
    variables: {
      query: {
        ...(talentDiscoveryFilterTransformation({
          skillFilters: [skillFilters],
          filters: serializeTDFilters(
            activeFilters.filter(
              (filter) =>
                ![
                  SelectionCategories.DATE_RANGE,
                  SelectionCategories.DATE_RANGE_FULL,
                ].includes(filter.id as SelectionCategories)
            )
          ),
        }) as TalentDiscoveryV1_5Filter),
      },
    },
    pause: numOfFilterSelections === 0,
  });

  const filteredData =
    data?.talent_discovery_search_v1_5?.map_data?.filter((item) =>
      mapCoordsData?.mapCoords?.[selectedGeoType]?.[0]?.some(
        (coord) =>
          (item as MapData).id === (coord as unknown as MapCoordsItem).id
      )
    ) || [];

  // if country is selected then MSAs are returned
  const transformedData = country
    ? filteredData.map((d) => ({ ...d, type: 1 }) as MapData)
    : filteredData;

  return {
    data: numOfFilterSelections > 0 ? transformedData : undefined,
    loading: fetching || mapCoordsFetching,
  };
};
