import { useContext, useMemo } from 'react';
import { Maybe } from '@revelio/data-access';
import { LineChart } from '@revelio/d3';
import { ChartsRenderedContext } from '../../../charts-rendered-context';

export interface DailyLineChartProps {
  name: string;
  companyName: string | undefined | null;
  timeSeries:
    | ({
        id?: number | string | null | undefined;
        value?: Maybe<number>;
        date?: string | null | undefined;
      } | null)[]
    | null
    | undefined;
  chartProps?: {
    ttPosition?: 'left' | 'right';
  };
}

export const DailyLineChart = ({
  name,
  timeSeries,
  companyName,
  chartProps,
}: DailyLineChartProps) => {
  const linePlotData = useMemo(
    () => [
      {
        id: 1,
        metadata: {
          shortName: companyName || name,
          longName: companyName,
          type: 'Company',
        },
        value: timeSeries?.map((datum) => {
          const id = (() => {
            if (typeof datum?.id === 'string') {
              return parseInt(datum?.id);
            }

            return datum?.id || 0;
          })();

          return {
            id,
            metadata: {
              longName: datum?.date,
              shortName: datum?.date,
              day: datum?.date ? parseInt(datum?.date.split('-')[2] || '1') : 1,
              month: datum?.date
                ? parseInt(datum?.date.split('-')[1] || '1')
                : 1,
              year: datum?.date
                ? parseInt(datum?.date.split('-')[0] || '2008')
                : 2008,
            },
            value: datum?.value,
          };
        }),
      },
    ],
    [companyName, name, timeSeries]
  );

  const lineColor = '#5BD992';

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'ChartComponent must be used within a ChartsRenderedProvider'
    );
  }
  const { setChartHasRendered } = context;

  if (!timeSeries?.length) {
    return null;
  }

  return (
    <LineChart
      name={name}
      lineColor={lineColor}
      areaFillColor={lineColor}
      hideAxis
      chartSize="small"
      metaValueCompany="shortName"
      yAxisFormat=".0%"
      dateFormat="YMD"
      ttType="single"
      ttCustomString="days"
      ttMainFormat=","
      ttSecondaryFormat=","
      data={linePlotData}
      setChartHasRendered={setChartHasRendered}
      leftFade
      {...chartProps}
    />
  );
};
