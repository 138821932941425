import {
  ScreenerReqFilters,
  ScreenerReqSegmentSelector,
  ScreenerReqSegments,
} from '@revelio/data-access';
import { TreeItem } from '../../engine/filters.model';

export type SingleScreenerSegment = ScreenerReqSegmentSelector & {
  name: keyof ScreenerReqSegments;
};

export type ScreenerSubFilterState = {
  id: string;
  filter: SingleScreenerSegment;
  treeItems?: Record<string, TreeItem>;
};

type PrimaryFilterKey = `${Extract<
  keyof ScreenerReqFilters,
  'industry' | 'region' | 'job_category'
>}.${number}`;

export enum ScreenerSortCol {
  SORT_BY_HEADCOUNT = 'SORT_BY_HEADCOUNT',
  SORT_BY_INFLOW = 'SORT_BY_INFLOW',
  SORT_BY_OUTFLOW = 'SORT_BY_OUTFLOW',
  SORT_BY_HIRING = 'SORT_BY_HIRING',
  SORT_BY_ATTRITION = 'SORT_BY_ATTRITION',
  SORT_BY_GROWTH = 'SORT_BY_GROWTH',
  SORT_BY_SALARY = 'SORT_BY_SALARY',
  SORT_BY_TENURE = 'SORT_BY_TENURE',
  SORT_BY_GROWTHYOY = 'SORT_BY_GROWTH_YOY',
}

export type ScreenerSorting = {
  sort_by: ScreenerSortCol;
  num_col: number;
  ascending: boolean;
};

export interface ScreenerFilterState {
  filters: {
    page: number;
    primary_filter?: Record<PrimaryFilterKey, TreeItem>;
    sub_filters?: ScreenerSubFilterState[];
  };
  sorting: ScreenerSorting | undefined;
}

interface SetPrimaryFilterAction {
  type: 'SET_PRIMARY_FILTER';
  filter: Record<PrimaryFilterKey, TreeItem>;
}

interface AddSubFilterAction {
  type: 'ADD_SUB_FILTER';
  filter: SingleScreenerSegment;
  id?: string;
  treeItems: Record<string, TreeItem>;
}

interface RemoveSubFilterAction {
  type: 'REMOVE_SUB_FILTER';
  filterId: string;
}

interface RemovePrimaryFilterAction {
  type: 'REMOVE_PRIMARY_FILTER';
}

interface UpdatePageAction {
  type: 'UPDATE_PAGE';
  page: number;
}

interface UpdateSubFilterAction {
  type: 'UPDATE_SUB_FILTER';
  filter: SingleScreenerSegment;
  id: string;
  treeItems: Record<string, TreeItem>;
}

interface UpdateSortingAction {
  type: 'UPDATE_SORTING';
  sorting: ScreenerSorting | undefined;
}

export type ScreenerFilterAction =
  | SetPrimaryFilterAction
  | AddSubFilterAction
  | RemoveSubFilterAction
  | UpdatePageAction
  | RemovePrimaryFilterAction
  | UpdateSubFilterAction
  | UpdateSortingAction;

export const initialScreenerFilterState: ScreenerFilterState = {
  filters: {
    page: 1,
    primary_filter: undefined,
    sub_filters: [],
  },
  sorting: undefined,
};

export const screenerFilterReducer = (
  state: ScreenerFilterState,
  action: ScreenerFilterAction
): ScreenerFilterState => {
  switch (action.type) {
    case 'SET_PRIMARY_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          primary_filter: action.filter,
        },
      };
    case 'ADD_SUB_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          sub_filters: [
            ...(state.filters.sub_filters || []),
            {
              id: action.id || `${Date.now()}`,
              filter: action.filter,
              treeItems: action.treeItems,
            },
          ],
        },
      };
    case 'UPDATE_SUB_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          sub_filters: state.filters.sub_filters?.map((filter) =>
            filter.id === action.id
              ? {
                  ...filter,
                  filter: action.filter,
                  treeItems: action.treeItems,
                }
              : filter
          ),
        },
      };
    case 'REMOVE_SUB_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          sub_filters: state.filters.sub_filters?.filter(
            (filter) => filter.id !== action.filterId
          ),
        },
      };
    case 'REMOVE_PRIMARY_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          primary_filter: undefined,
        },
      };
    case 'UPDATE_PAGE':
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.page,
        },
      };
    case 'UPDATE_SORTING':
      return {
        ...state,
        sorting: action.sorting,
      };
    default:
      return state;
  }
};
