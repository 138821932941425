// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Flex } from '@chakra-ui/layout';
import React, { useRef, useEffect, useState } from 'react';
import SlotText from './SlotText';
import { SelectionCategories } from '@revelio/filtering';
import { stateData } from './utils/stateMapping';

function Reel({
  isPressed,
  isOpen,
  onClose,
  data,
  handleWinner,
  playSelection,
  isSpinning,
  setIsSpinning,
  play2,
  autoPlay,
  autoPlayRef,
  autoplayTimeout,
  reelSound,
  setWinnerCompleted,
  playSpinButton,
  buttonSound,
  stopSelection,
  stopWinner,
}) {
  const DEBUG_MODE = false;

  const reelRef = useRef(null);
  const reelRef2 = useRef(null);
  const reelRef3 = useRef(null);

  const [seniority, setSeniority] = useState([
    { class: 'red-white-black uppercase', shortName: 'Manager' },
    { class: 'blue uppercase', shortName: 'Junior' },
    { class: 'gold-purple uppercase', shortName: 'Associate' },
  ]);
  const [rolek150, setRolek150] = useState([
    { class: 'gold-red', shortName: 'Economist' },
    { class: 'sunset-retro', shortName: 'Graphic Designer' },
    { class: 'white-green', shortName: 'Writer' },
  ]);
  const [msa, setMsa] = useState([
    { class: 'green', shortName: 'Atlanta, GA' },
    { class: 'pure-gold montecarlo-font', shortName: 'New York, NY' },
    { class: 'silver', shortName: 'Memphis, TN' },
  ]);

  const getNewText = () => {
    const textStyles = [
      'gold',
      'blue',
      'gold-red',
      'green',
      'pink',
      'fire',
      'purple-light',
      'purple',
      'pink-purple',
      'red-white-black',
      'silver',
      'blue-retro',
      'gold-purple',
      'white-red',
      'white-green',
      'white-red-gloss',
      'white-blue-gloss',
      'rainbow',
      'sunset-retro',
      'sky-blue-retro',
      'pure-90s',
    ];

    const fontAndStyles = {
      seniority: [
        {
          name: 'fredoka-font',
          styles: textStyles,
        },
        {
          name: 'rye-font',
          styles: textStyles,
        },
        {
          name: 'russo-one-font',
          styles: textStyles,
        },
        {
          name: 'dancing-script-font',
          styles: textStyles,
        },
        {
          name: 'shrikhand-font',
          styles: textStyles,
        },
        // {
        //   name: 'zen-dots-font',
        //   styles: textStyles,
        // },
        {
          name: 'bevan-font',
          styles: textStyles,
        },
        // {
        //   name: 'bowlby-one-font',
        //   styles: textStyles,
        // },
        {
          name: 'montecarlo-font',
          styles: ['pure-gold'],
        },
        {
          name: 'JQKAs-wild-font',
          styles: textStyles,
        },
      ],
      role: [
        {
          name: 'fredoka-font',
          styles: textStyles,
        },
        {
          name: 'rye-font',
          styles: textStyles,
        },
        {
          name: 'russo-one-font',
          styles: textStyles,
        },
        {
          name: 'dancing-script-font',
          styles: textStyles,
        },
        {
          name: 'shrikhand-font',
          styles: textStyles,
        },
        // {
        //   name: 'zen-dots-font',
        //   styles: textStyles,
        // },
        {
          name: 'bevan-font',
          styles: textStyles,
        },
        // {
        //   name: 'bowlby-one-font',
        //   styles: textStyles,
        // },
        {
          name: 'montecarlo-font',
          styles: ['pure-gold', 'pure-silver'],
        },
        {
          name: 'JQKAs-wild-font',
          styles: textStyles,
        },
      ],
      msa: [
        {
          name: 'fredoka-font',
          styles: textStyles,
        },
        {
          name: 'rye-font',
          styles: textStyles,
        },
        {
          name: 'russo-one-font',
          styles: textStyles,
        },
        {
          name: 'dancing-script-font',
          styles: textStyles,
        },
        {
          name: 'shrikhand-font',
          styles: textStyles,
        },
        // {
        //   name: 'zen-dots-font',
        //   styles: textStyles,
        // },
        {
          name: 'bevan-font',
          styles: textStyles,
        },
        // {
        //   name: 'bowlby-one-font',
        //   styles: textStyles,
        // },
        {
          name: 'montecarlo-font',
          styles: ['pure-gold'],
        },
        {
          name: 'JQKAs-wild-font',
          styles: textStyles,
        },
      ],
    };

    // Based from Talent Discovery count data
    // const top50msa = [
    //   2, 0, 11, 15, 14, 26, 30, 17, 24, 21, 62, 28, 31, 51, 47, 38, 72, 59, 55,
    //   103, 48, 199, 95, 92, 165, 67, 98, 106, 99, 63, 126, 125, 132, 119, 136,
    //   162, 111, 105, 109, 139, 115, 118, 133, 135, 158, 149, 306, 177, 159, 194,
    // ];

    // Based from Bruce's position count data
    const top50msa = [
      2, 15, 11, 26, 14, 30, 24, 17, 21, 28, 31, 47, 51, 38, 59, 55, 95, 92, 67,
      98, 63, 106, 126, 105, 132, 119, 109, 111, 139, 115, 158, 135, 118, 133,
      194, 177, 149, 159, 202, 242, 185, 195, 232, 217, 224, 263, 415, 157, 264,
      326,
    ];

    const top100roles = [
      122, 41, 136, 64, 132, 149, 138, 17, 90, 89, 49, 63, 127, 52, 57, 36, 143,
      83, 133, 111, 98, 119, 8, 148, 146, 113, 60, 129, 88, 34, 18, 87, 12, 144,
      130, 19, 147, 86, 109, 33, 69, 58, 16, 106, 55, 84, 99, 54, 32, 117, 72,
      59, 145, 43, 68, 142, 85, 108, 48, 9, 3, 38, 24, 10, 75, 110, 103, 11,
      131, 26, 29, 74, 80, 140, 96, 141, 7, 5, 20, 23, 51, 104, 118, 1, 2, 123,
      94, 42, 13, 47, 6, 97, 21, 115,
    ];

    interface DataInterface {
      id: string;
      shortName: string;
      longName: string;
      label: string;
    }

    function getRandomItem(array) {
      return array[Math.floor(Math.random() * array.length)];
    }

    const seniority_ = data
      .find((f) => f.id === SelectionCategories.SENIORITY)
      ?.value?.filter((f) => f.id != 5 && f.id != 7)
      .map((obj) => {
        const randomStyleObj = getRandomItem(fontAndStyles['seniority']);
        const randomStyle = getRandomItem(randomStyleObj.styles);
        return {
          ...obj,
          class: `${randomStyleObj.name} ${randomStyle}`,
          shortName: obj.id == 6 ? 'Executive' : obj.shortName,
        };
      });

    const rolek150_ = data
      .find((f) => f.id === SelectionCategories.ROLE_K150)
      ?.value?.filter((f) => f.id != 0 && top100roles.includes(+f.id))
      .map((obj) => {
        const randomStyleObj = getRandomItem(fontAndStyles['role']);
        const randomStyle = getRandomItem(randomStyleObj.styles);
        return {
          ...obj,
          class: `${randomStyleObj.name} ${randomStyle}`,
        };
      });

    const msa_ = data
      .find((f) => f.id === SelectionCategories.METRO_AREA)
      ?.value?.filter(
        (i: DataInterface) =>
          i.parentId == 1 &&
          !i.shortName.includes('Non-Metro') &&
          top50msa.includes(+i.id)
      )
      .map((obj) => {
        const randomStyleObj = getRandomItem(fontAndStyles['role']);
        const randomStyle = getRandomItem(randomStyleObj.styles);
        return {
          ...obj,
          class: `${randomStyleObj.name} ${randomStyle}`,
          shortName: `${obj.shortName
            .replace(' (United States)', '')
            .replace(' (California)', '')}, ${stateData[obj.id]}`,
        };
      });

    const getRandom = (obj: DataInterface[]) => {
      const randomIndex = Math.floor(Math.random() * obj?.length);
      return obj?.[randomIndex];
    };

    const isTargetValue = (item) => item.shortName === 'Executive';

    const getRandomWithWeight = (arr, weight: number) => {
      const targetOccurrences = arr.filter(isTargetValue).length;
      const PNatural = targetOccurrences / arr.length;
      const PWeighted = PNatural * weight;
      if (Math.random() < PWeighted) {
        const targetItems = arr.filter(isTargetValue);
        return targetItems[Math.floor(Math.random() * targetItems.length)];
      }
      let nonTargetItem;
      do {
        nonTargetItem = arr[Math.floor(Math.random() * arr.length)];
      } while (isTargetValue(nonTargetItem));
      return nonTargetItem;
    };

    const seniority = Array.from({ length: 16 }, () =>
      getRandomWithWeight(seniority_, 0.5)
    );
    const rolek150 = Array.from({ length: 24 }, () => getRandom(rolek150_));
    const msa = Array.from({ length: 40 }, () => getRandom(msa_));

    return { seniority, rolek150, msa };
  };

  const animateReel = (
    ref,
    transformValue,
    duration,
    easing,
    iterations = 1
  ) => {
    return new Promise((resolve, reject) => {
      if (!ref.current) {
        reject(new Error('Reference is not available'));
        return;
      }

      const animation = ref.current.animate(
        [
          { transform: 'none', filter: 'blur(2px)' },
          { filter: 'blur(2px)' },
          {
            transform: `translateY(-${transformValue}%)`,
            filter: 'blur(0)',
          },
        ],
        {
          duration: DEBUG_MODE ? 0 : duration,
          easing,
          iterations,
          fill: 'forwards',
        }
      );

      animation.onfinish = resolve;
      animation.onerror = reject;
    });
  };

  const spinAll = (props) => {
    if (!reelRef.current || !reelRef2.current || !reelRef3.current) {
      return;
    }
    if (props) {
      reelRef.current.animation?.cancel();
      reelRef2.current.animation?.cancel();
      reelRef3.current.animation?.cancel();
      stopSelection[0]();
      stopSelection[1]();
      stopSelection[2]();
      stopWinner();
    }
    const { seniority, rolek150, msa } = getNewText();

    setSeniority(seniority);
    setRolek150(rolek150);
    setMsa(msa);

    const getTransformValue = (itemsLength) => {
      const finalPosition = (itemsLength - 3) * itemsLength;
      const totalDistance =
        itemsLength * 100 + finalPosition * (100 / itemsLength);
      return totalDistance;
    };

    const reelAnimation1 = animateReel(
      reelRef,
      getTransformValue(seniority.length),
      seniority.length * 150,
      'cubic-bezier(0.5, 1, 0.89, 1)',
      1
    );

    const reelAnimation2 = animateReel(
      reelRef2,
      getTransformValue(rolek150.length),
      rolek150.length * 150,
      'cubic-bezier(0.5, 1, 0.89, 1)',
      1
    );

    const reelAnimation3 = animateReel(
      reelRef3,
      getTransformValue(msa.length),
      msa.length * 150,
      'cubic-bezier(0.5, 1, 0.89, 1)',
      1
    );

    reelAnimation1.then(
      () => !autoPlayRef.current && !props && playSelection[0]()
    );
    reelAnimation2.then(
      () => !autoPlayRef.current && !props && playSelection[1]()
    );
    reelAnimation3.then(
      () => !autoPlayRef.current && !props && playSelection[2]()
    );

    Promise.allSettled([reelAnimation1, reelAnimation2, reelAnimation3])
      .then(() => {
        if (autoPlay && autoPlayRef.current) {
          setIsSpinning(false);
          setWinnerCompleted(true);
          clearTimeout(autoplayTimeout.current);
          autoplayTimeout.current = setTimeout(() => {
            // setIsSpinning(true);
            spinAll(true);
            setWinnerCompleted(false);
          }, 5000);
        } else {
          handleWinner(
            [
              seniority.slice(-2, -1)[0],
              rolek150.slice(-2, -1)[0],
              msa.slice(-2, -1)[0],
            ],
            props
          );
        }
      })
      .catch((error) => {
        console.error('An error occurred during the animations', error);
      });
  };

  useEffect(() => {
    if (reelRef.current && isPressed && !isSpinning) {
      if (isOpen) {
        onClose();
        playSpinButton();
        buttonSound.fade(0.75, 0, 350);
      } else {
        playSpinButton();
        buttonSound.fade(0.75, 0, 350);
        play2();
        reelSound.fade(0.5, 0.2, 6000);
        spinAll();
        setIsSpinning(true);
        setWinnerCompleted(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPressed, autoPlay, autoPlayRef.current]);

  useEffect(() => {
    if (autoPlay && autoPlayRef.current) {
      setIsSpinning(false);
      spinAll(true);
      setWinnerCompleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlay]);

  return (
    <div className="reel-container">
      <Flex w="full" h="full" gap="5vh">
        <div className="reel" ref={reelRef}>
          {seniority.map((d, index) => (
            <div key={index} className="image-container">
              <SlotText textStyleClass={d?.class} text={d?.shortName} />
            </div>
          ))}
          {seniority.map((d, index) => (
            <div key={`duplicate-${index}`} className="image-container">
              <SlotText textStyleClass={d?.class} text={d?.shortName} />
            </div>
          ))}
        </div>
        <div className="reel" ref={reelRef2}>
          {rolek150.map((d, index) => (
            <div key={index} className="image-container">
              <SlotText textStyleClass={d?.class} text={d?.shortName} />
            </div>
          ))}
          {rolek150.map((d, index) => (
            <div key={`duplicate-${index}`} className="image-container">
              <SlotText textStyleClass={d?.class} text={d?.shortName} />
            </div>
          ))}
        </div>
        <div className="reel" ref={reelRef3}>
          {msa.map((d, index) => (
            <div key={index} className="image-container">
              <SlotText textStyleClass={d?.class} text={d?.shortName} />
            </div>
          ))}
          {msa.map((d, index) => (
            <div key={`duplicate-${index}`} className="image-container">
              <SlotText textStyleClass={d?.class} text={d?.shortName} />
            </div>
          ))}
        </div>
      </Flex>
    </div>
  );
}

export default Reel;
