import { Meter } from '@revelio/d3';
import { getHumanReadableNumbers } from '../utils/getHumanReadableNumbers';
import { CompositionDataQuery } from '@revelio/data-access';
import { calculateMeterValue } from './meter-scaling/calculateMeterValue';
import { useEffect, useState } from 'react';

interface WagePressureMeterProps {
  compositionData: CompositionDataQuery | undefined;
}
export const WagePressureMeter = ({
  compositionData,
}: WagePressureMeterProps) => {
  const salaryTimeseries =
    compositionData?.composition?.[0]?.metrics?.salary?.timeseries;

  const yearStartWage = salaryTimeseries?.[0]?.value || 0;
  const yearEndWage =
    salaryTimeseries?.[salaryTimeseries?.length - 1]?.value || 0;

  const wageDifference = yearEndWage - yearStartWage;
  const wageDifferencePercentage = (wageDifference / yearStartWage) * 100 || 0;

  const wageDifferenceString = `${
    wageDifference > 0 ? '+' : ''
  }${wageDifferencePercentage.toFixed(0)}%`;

  const [meterValue, setMeterValue] = useState<number | null>(null);

  useEffect(() => {
    const meterValue = calculateMeterValue({
      value: wageDifference / yearStartWage,
      metric: 'wageGrowth',
    });

    setMeterValue(meterValue);
  }, [wageDifference, yearStartWage]);

  return (
    <Meter
      name="wage-pressure-meter"
      data={{
        title: `${getHumanReadableNumbers(yearStartWage)} → 
        ${getHumanReadableNumbers(yearEndWage)}`,
        value: meterValue,
        unit: 'yoy',
      }}
      format="d"
      customFormatter={(_value: string) => wageDifferenceString}
      customMargins={{ top: 15, right: 0, bottom: 15, left: 0 }}
    />
  );
};
