import {
  Dimension1,
  GetTalentDiscoveryTooltipPayload,
  TALENT_DISCOVERY_FIELDS,
  TalentDiscoveryField,
} from '@revelio/data-access';
import { scoreMap } from './td-score-data';
import { Store, getStore } from '@ngneat/elf';
import { getActiveEntities } from '@ngneat/elf-entities';

const mapScoreToNearestPtile = (scoreVal: number): number => {
  let nearestPtile = scoreMap[0].ptile;
  let smallestDiff = Math.abs(scoreMap[0].score - scoreVal);
  for (let i = 1; i < scoreMap.length; i++) {
    const difference = Math.abs(scoreMap[i].score - scoreVal);
    if (difference < smallestDiff) {
      smallestDiff = difference;
      nearestPtile = scoreMap[i].ptile;
    }
  }
  return nearestPtile;
};

export const calcMarketTightness = (
  postings: number,
  countRaw: number,
  ttf: number
): number => {
  const ratio = (postings + 1.1) / (countRaw + 10);
  const ratioExp = Math.pow(ratio, 0.2);
  const ttfNorm = (ttf - 37.39) / 24.16;
  const ratioExpNorm = (ratioExp - 0.568) / 0.181;
  const score = ratioExpNorm * 0.5 + ttfNorm * 0.5;
  return mapScoreToNearestPtile(score);
};

export interface GetTalentDiscoveryInputProps {
  id: number;
  type: number;
}

export const getTalentDiscoveryInput = ({
  id,
  type,
}: GetTalentDiscoveryInputProps): GetTalentDiscoveryTooltipPayload => {
  /** TODO/jbellizzi: not importing filterStore from @revelio/filtering because of circular dependency.
   * Need to refactor */
  const filterStore = getStore('filters') as Store;

  const filters = filterStore.query(getActiveEntities());

  const companyFilter = filters.find((obj) => obj.id === 'company');

  const hasCompanyFilter = !!companyFilter?.value?.length;

  const dim1: GetTalentDiscoveryTooltipPayload['dim1'] = hasCompanyFilter
    ? Dimension1.Company
    : Dimension1.Geography;

  const lastMonth = filterStore.query((store) => store.defaultLastMonth);
  return filters.reduce<GetTalentDiscoveryTooltipPayload>(
    (acc, filter) => {
      const { id, value } = filter;

      if (
        id === 'dim1' ||
        id === 'country' ||
        !TALENT_DISCOVERY_FIELDS.includes(id as TalentDiscoveryField) ||
        !Array.isArray(value)
      ) {
        return acc;
      }

      return {
        ...acc,
        [id]: value.map((v) => {
          if (typeof v === 'number') return v;
          else if (v instanceof Date) return Number(v);
          else return Number(v.id);
        }),
      };
    },
    {
      [type === 1 ? 'country' : 'msa']: [id],
      dim1,
      start_date: lastMonth,
      end_date: lastMonth,
    }
  );
};
