import { CompositionDataQuery } from '@revelio/data-access';
import { SingleValue } from 'chakra-react-select';

export type SummaryEntities = NonNullable<CompositionDataQuery['composition']>;
export enum CompanySummaryMetric {
  Headcount = 'headcount',
  AttritionRate = 'attrition_rate',
  GrowthRate = 'growth_rate',
  HiringRate = 'hiring_rate',
}
export enum GeographySummaryMetric {
  Skills = 'skills',
  Roles = 'job_categories',
  Industries = 'industries',
  // Companies = 'companies',
}

export type HorizontalPlotDataFormat =
  | ({
      id: number | null | undefined;
      value: number | null | undefined;
      metadata: {
        longName: string | null | undefined;
        shortName: string | null | undefined;
        type: string | null | undefined;
        isPrimary?: boolean;
      };
    } | null)[]
  | null;

export interface CompetitorBarChartsData {
  headcount: HorizontalPlotDataFormat;
  attrition_rate: HorizontalPlotDataFormat;
  growth_rate: HorizontalPlotDataFormat;
  hiring_rate: HorizontalPlotDataFormat;
}

export interface GeographyBarChartsData {
  // companies: HorizontalPlotDataFormat;
  industries: HorizontalPlotDataFormat;
  skills: HorizontalPlotDataFormat;
  job_categories: HorizontalPlotDataFormat;
}

export type GeographySummaryEntities = Pick<
  NonNullable<NonNullable<NonNullable<SummaryEntities>[number]>['metrics']>,
  'industries' | 'skills' | 'job_categories'
>;

export type SingleOption = SingleValue<{
  value: string;
  label: string;
}>;
