import { FeatureFlag, PageTitles, PrimaryFilters } from '@revelio/core';
import {
  FilterMenuLimits,
  FilterSets,
  LocalSelectionCategories,
  PrimaryFilterLimits,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';
import Transitions from '../../../transitions/transitions';
import { useFlag } from '@unleash/proxy-client-react';

/* eslint-disable-next-line */
export interface RoleTransitionsProps {}

export function RoleTransitions(props: RoleTransitionsProps) {
  const title = [PageTitles.ROLE, PageTitles.TRANSITIONS];

  const primaryViewFilter = [
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
    },
  ];

  const keywordFiltersFeatureFlag = useFlag(FeatureFlag.RoleTransitionsKeyword);

  const selectableFilters = [
    SelectionCategories.HIGHEST_DEGREE,
    SelectionCategories.ETHNICITY,
    SelectionCategories.GENDER,
    {
      filters: [
        SelectionCategories.REGION,
        SelectionCategories.COUNTRY,
        SelectionCategories.METRO_AREA,
      ],
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY],
      isNested: true,
      limit: 10,
    },
    SelectionCategories.DATE_RANGE,
    ...(keywordFiltersFeatureFlag
      ? [SelectionCategories.KEYWORD, SelectionCategories.RAW_TITLE]
      : []),
  ];

  const additionalNonActiveFilters = [
    LocalSelectionCategories.INFLOW_OR_OUTFLOW,
    SelectionCategories.DATE_RANGE,
  ];

  const otherFilters: [] = [];

  return (
    <Transitions
      title={title}
      viewType={ViewTypes.ROLE}
      primaryFilter={PrimaryFilters.ROLE}
      primaryViewFilters={primaryViewFilter}
      selectableFilters={selectableFilters}
      additionalNonActiveFilters={additionalNonActiveFilters}
      otherFilters={otherFilters}
      sharedFilterSetId={FilterSets.ROLE}
      filterSet={FilterSets.ROLE_TRANSITIONS}
      primaryFilterLimit={PrimaryFilterLimits.TRANSITIONS}
      filterMenuLimit={FilterMenuLimits.TRANSITIONS}
    />
  );
}

export default RoleTransitions;
