import { PrimaryView } from '@revelio/core';
import { Filters, PostingsDataQueryVariables } from '@revelio/data-access';
import {
  Filter,
  FilterName,
  FilterParameterKeys,
  GqlFilterKeyMapper,
  SelectionCategories,
  formatAndBreakoutFilters,
  mergeFiltersTogether,
} from '@revelio/filtering';
import { mapKeys } from 'lodash';
import { getDim1FromView } from './get-dim1-from-view';

type TransformFiltersToPostingVariablesProps = {
  view: PrimaryView;
  filters: Filter[];
};
export const transformFiltersToPostingVariables = ({
  view,
  filters,
}: TransformFiltersToPostingVariablesProps): PostingsDataQueryVariables => {
  const { formattedFilter } = formatAndBreakoutFilters(filters, [
    SelectionCategories.PRIMARY_FILTER,
  ]);

  const mergedFilters = mergeFiltersTogether(formattedFilter, [
    [SelectionCategories.KEYWORD, SelectionCategories.KEYWORDS_CATEGORY],
  ]);

  const mappedFilters: Filters = mapKeys(
    mergedFilters,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof mergedFilters;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  return {
    dim1: getDim1FromView(view),
    filters: mappedFilters,
  };
};
