// eslint-disable-next-line @nx/enforce-module-boundaries
import '../../../../../../libs/layout/src/lib/components/table/table.css';
import { DefaultIcon, itemTypeToComponent } from '@revelio/layout';
import {
  GET_SCREENER_DATA,
  ScreenerSortCol,
  ScreenerSorting,
  useScreenerFilter,
} from '@revelio/filtering';
import { Loading, FeatureFlag } from '@revelio/core';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import {
  Box,
  Flex,
  Image,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createUltimatePagination } from 'react-ultimate-pagination';
import { useQuery } from 'urql';
import { deserialiseScreenerData } from './utils/transformEntityData';
import { getScreenerColumns } from './utils/getScreenerColumns';
import { GetScreenerDataQuery, ScreenerDimension } from '@revelio/data-access';
import styles from './screener-table.module.scss';
import { serialiseScreenerFilters } from './utils';
import { TypeDataGridProps } from '@inovua/reactdatagrid-community/types';
import { isEqual } from 'lodash';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { CustomiseColumnsButton } from '../../../shared/components/customise-columns-button';
import { useUnleashFlag } from '../../../hooks/unleash/useUnleashFlag';
import { ColumnSelectModal } from './column-select-modal/column-select-modal';

interface ScreenerTableProps {
  view: ScreenerDimension;
}

export const LIMIT = 35;

type GetScreenerDataQueryWithSorting = GetScreenerDataQuery & {
  screener: {
    __typename?: 'ScreenerResp';
    pagination: {
      __typename?: 'ScreenerRespPagination';
      total_results: number;
    };
    entities: Array<{
      __typename?: 'ScreenerRespEntity';
      short_name: string;
      long_name: string;
      entity_id: number;
      headcount: Array<number>;
      inflow: Array<number>;
      outflow: Array<number>;
      hiring: Array<number>;
      attrition: Array<number>;
      growth: Array<number>;
      salary: Array<number>;
      tenure: Array<number>;
    }>;
  };
  sorting?: ScreenerSorting;
};

export const ScreenerTable = ({ view }: ScreenerTableProps) => {
  const { state, dispatch } = useScreenerFilter();

  const filters = useMemo(
    () => serialiseScreenerFilters(state.filters, view),
    [state.filters, view]
  );

  const [page, setPage] = useState<number>(1);

  const [prevFilters, setPrevFilters] = useState(filters);
  useEffect(() => {
    if (!isEqual(prevFilters, filters)) {
      setPage(1);
      setPrevFilters(filters);
    }
  }, [filters, prevFilters]);

  const filterCount = useMemo(
    () =>
      (Object.keys(filters?.segments)?.length || 0) +
      (Object.keys(filters?.primary_selector || {}).length || 0),
    [filters]
  );

  const sorting = useMemo(() => state.sorting, [state.sorting]);
  const setSorting = useMemo(
    () => (sortingToUpdate: ScreenerSorting | undefined) => {
      dispatch({ type: 'UPDATE_SORTING', sorting: sortingToUpdate });
    },
    [dispatch]
  );

  useEffect(() => {
    setSorting(undefined);
  }, [filters, setSorting]);

  const handleSort = useCallback(
    (column: { key: string; columnNumber: number }) => {
      const newSortBy =
        ScreenerSortCol[
          `SORT_BY_${column.key.toUpperCase()}` as keyof typeof ScreenerSortCol
        ];

      const ascending = (() => {
        if (
          !sorting ||
          sorting.sort_by !== newSortBy ||
          sorting.num_col !== column.columnNumber
        ) {
          return false; // Start with descending if no sorting or sorting by a different column
        }

        if (sorting.ascending === false) {
          return true; // Move to ascending
        }

        return undefined; // Move to neutral
      })();

      if (ascending === undefined) {
        setSorting(undefined);
        return;
      }

      setSorting({
        sort_by: newSortBy,
        num_col: column.columnNumber,
        ascending,
      });
    },
    [sorting, setSorting]
  );

  const sortingFeatureFlag = useUnleashFlag(FeatureFlag.ScreenerSorting);
  const [{ data: screenerData, fetching: loading, error }] =
    useQuery<GetScreenerDataQueryWithSorting>({
      query: GET_SCREENER_DATA,
      variables: {
        filters: {
          ...filters,
          pagination: { offset: (page - 1) * LIMIT, limit: LIMIT },
          sorting: sortingFeatureFlag ? sorting : undefined,
        },
      },
      pause: !filterCount,
    });

  const data = screenerData?.screener;
  const parsedData = useMemo(
    () => deserialiseScreenerData(data?.entities || [], filters, state),
    [data, filters, state]
  );

  const columns = useMemo(
    () =>
      getScreenerColumns(parsedData, view).map((column) => {
        const sortChevron = (() => {
          if (
            !(
              sorting?.sort_by ===
                ScreenerSortCol[
                  `SORT_BY_${column.key.toUpperCase()}` as keyof typeof ScreenerSortCol
                ] && sorting?.num_col === column.columnNumber
            )
          ) {
            return null;
          }

          return sorting.ascending ? <ChevronUpIcon /> : <ChevronDownIcon />;
        })();

        return {
          ...column,
          sortable: false,
          columnNumber: column.columnNumber,
          headerText: column.header,
          header: (
            <Text
              title={column.header}
              width="100%"
              display="flex"
              alignItems="center"
              onClick={() => handleSort(column)}
              cursor="pointer"
            >
              <Box
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                display="block"
              >
                {column.header}
              </Box>
              {sortChevron && <Box ml={2}>{sortChevron}</Box>}
            </Text>
          ),
        };
      }),
    [parsedData, view, sorting, handleSort]
  );

  const Wrapper = useCallback((props: { children: JSX.Element }) => {
    return <Box>{props.children}</Box>;
  }, []);

  const UltimatePagination = useMemo(
    () =>
      createUltimatePagination({
        itemTypeToComponent: itemTypeToComponent(),
        WrapperComponent: Wrapper,
      }),
    [Wrapper]
  );

  const renderPaginationToolbar = useCallback(() => {
    const totalPages =
      Math.floor((data?.pagination?.total_results || 0) / LIMIT) || 0;

    const changePage = (e: number) => {
      setPage(e);
    };

    return (
      <Flex alignItems="center" h="50px" w="100%" justifyContent="center">
        <Box p={2}>
          {totalPages > 0 && (
            <UltimatePagination
              currentPage={page}
              totalPages={totalPages}
              boundaryPagesRange={1}
              siblingPagesRange={1}
              hidePreviousAndNextPageLinks={false}
              hideFirstAndLastPageLinks={true}
              hideEllipsis={false}
              onChange={changePage}
            />
          )}
        </Box>
      </Flex>
    );
  }, [data, page, UltimatePagination]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [filteredColumns, setFilteredColumns] = useState(columns);
  const [savedCheckedColumns, setSavedCheckedColumns] = useState<string[]>([]);

  useEffect(() => {
    const filteredCols = columns.filter(
      (column) =>
        column?.headerText && savedCheckedColumns.includes(column?.headerText)
    );

    setFilteredColumns(filteredCols);
  }, [savedCheckedColumns, columns]);

  const shouldShowCustomiseColumnsButton = !!parsedData?.length && !loading;

  return (
    <Box h="100%">
      {shouldShowCustomiseColumnsButton && (
        <CustomiseColumnsButton onClick={onOpen} right={4} />
      )}
      <ColumnSelectModal
        isOpen={isOpen}
        onClose={onClose}
        columns={columns.map((column) => ({
          value: column.headerText,
          label: column.headerText,
        }))}
        setSavedCheckedColumns={setSavedCheckedColumns}
        savedCheckedColumns={savedCheckedColumns}
      />
      {!filterCount ? (
        <Flex
          h="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <VStack>
            <Image as={DefaultIcon} alt="default placeholder icon" />
            <Text>Add a filter to view data.</Text>
          </VStack>
        </Flex>
      ) : (
        !error &&
        parsedData &&
        columns && (
          <ReactDataGrid
            className={styles['table']}
            loading={loading}
            showCellBorders="vertical"
            columns={filteredColumns}
            dataSource={parsedData}
            style={{ minHeight: '100%' }}
            rowHeight={30}
            renderLoadMask={LoadMask}
            renderPaginationToolbar={renderPaginationToolbar}
            pagination
            emptyText="No Results"
            theme="green-light"
            showColumnMenuTool={false}
            nativeScroll
          />
        )
      )}
    </Box>
  );
};

const LoadMask: TypeDataGridProps['renderLoadMask'] = ({ visible }) => {
  return visible ? (
    <Flex
      borderRadius="10px"
      height="full"
      position="relative"
      backgroundColor="white"
      data-testid="page-data-loading"
    >
      <Loading></Loading>
    </Flex>
  ) : null;
};
