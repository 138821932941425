import {
  getAllEntities,
  hasEntity,
  getEntity,
  setEntities,
  upsertEntities,
  deleteAllEntities,
  selectAllEntities,
  deleteEntities,
  updateEntitiesByPredicate,
  updateEntities,
} from '@ngneat/elf-entities';
import { useLifecycles } from 'react-use';
import {
  AnyFilter,
  FilterList,
  FilterName,
  FilterTypes,
  FilterValue,
  LocalSelectionCategories,
  SelectFilter,
  SelectableCategories,
  SelectionCategories,
  UserStoredFilterSet,
  ValidValueTypes,
  ViewFilterDefaultIds,
  ViewsWithFilters,
} from './filters.model';
import {
  filterStore,
  storedFilterSetEntitiesRef,
  getActiveSet,
  setActiveSet,
  GlobalFilterSetId,
  setCustomActiveSet,
  selectCustomActiveSet,
  resetCustomActiveSet,
  viewFilterDefaultEntitiesRef,
  getActiveTab,
  setActiveTab,
} from './filters.core';
import {
  deleteStoredFilterSetFromRemote,
  PersistMethods,
  saveStoredFilterSetToRemote,
} from './filters.persist';
import { JsonValue, SetOptional } from 'type-fest';
import { lastValueFrom, Observable } from 'rxjs';
import { catchError, distinct, map, shareReplay, tap } from 'rxjs/operators';
import { distinctUntilArrayItemChanged, emitOnce } from '@ngneat/elf';
import {
  DefaultKeyLookup,
  FilterFormatOverrides,
  FilterSets,
} from '../data-api/data-api.model';
import { useEffect$, useUntilDestroyed } from '@ngneat/react-rxjs';
import { useState } from 'react';
import { find, get, has, isUndefined, reduce, set } from 'lodash';
import { clearViewViewTypeDefault } from './filters.defaults.core';
import { UserTrackingEvents } from '@revelio/core';
import { updateRequestsCache } from '@ngneat/elf-requests';
import mixpanel from 'mixpanel-browser';
import { deleteFilter, upsertFilter } from './filters.repository';
import _ from 'lodash';

export function resetStoredFilterState() {
  filterStore.update(resetCustomActiveSet());
}

export function setStoredFilterState(setId: string) {
  filterStore.update(setCustomActiveSet(setId));
}

export function getStoredFilterSet(setId: string) {
  return filterStore.query(
    getEntity(setId, { ref: storedFilterSetEntitiesRef })
  );
}

export function recallStoredFilterSet(
  setId: string,
  setActive = false,
  setCustomActive = false,
  primaryEntitiesSync = false,
  limit = 6,
  uniqueSetId = '',
  defaultLimit?: number,
  filterNames = [] as (
    | SelectableCategories
    | LocalSelectionCategories
    | FilterName
  )[]
) {
  let isCustomSet = false;

  const isPrimaryFiltersExcluded = pagesExcludedFromDND.includes(
    uniqueSetId as FilterSets
  );

  const hasRequestedStoredFilterState = filterStore.query(
    hasEntity(setId as ViewsWithFilters, { ref: storedFilterSetEntitiesRef })
  );

  const hasRequestedUniqueStoredFilterState = filterStore.query(
    hasEntity(uniqueSetId as ViewsWithFilters, {
      ref: storedFilterSetEntitiesRef,
    })
  );

  const storedFilterSetState = filterStore.query(
    getEntity(setId as ViewsWithFilters, { ref: storedFilterSetEntitiesRef })
  );

  if (isPrimaryFiltersExcluded && !hasRequestedUniqueStoredFilterState) {
    return hasRequestedStoredFilterState;
  }

  if (hasRequestedStoredFilterState) {
    isCustomSet = has(storedFilterSetState, 'creator');

    let uniquePrimaryEntities = [] as any;

    const uniqueStoredSet = filterStore.query(
      getEntity(uniqueSetId, { ref: storedFilterSetEntitiesRef })
    );

    if (isPrimaryFiltersExcluded) {
      uniquePrimaryEntities =
        uniqueStoredSet?.entities.filter((ent) => {
          return [
            ...filterNames,
            LocalSelectionCategories.PRIMARY_ENTITIES,
            SelectionCategories.COMPANY,
          ].includes(ent.id as SelectableCategories);
        }) || [];
    }

    const entitiesToSet = storedFilterSetState?.entities?.reduce(
      (acc: any, cur: any) => {
        let entitiesToExclude = filterNames;

        if (isPrimaryFiltersExcluded) {
          entitiesToExclude = [
            ...entitiesToExclude,

            // TODO: can handle this better, but this is a quick way to
            // deal with the python companies
            SelectionCategories.COMPANY,
            LocalSelectionCategories.PRIMARY_ENTITIES,
          ];
        }
        if (entitiesToExclude.includes(cur.id)) {
          return acc;
        }

        return [...acc, cur];
      },
      []
    );

    if (entitiesToSet && uniquePrimaryEntities) {
      const combinedEntities = [...entitiesToSet, ...uniquePrimaryEntities];
      filterStore.update(setEntities(combinedEntities));
    }

    if (!isPrimaryFiltersExcluded) {
      if (primaryEntitiesSync) {
        const relevantSet = isPrimaryFiltersExcluded
          ? uniqueStoredSet
          : storedFilterSetState;

        const primaryEntities = find(
          relevantSet?.entities,
          (ent) => ent.id == LocalSelectionCategories.PRIMARY_ENTITIES
        );

        const primaryEntityValues: any | undefined =
          primaryEntities?.value || [];

        if (!Array.isArray(primaryEntityValues)) return;

        type PrimaryEntitiesLookup = {
          [key in FilterName]?: FilterValue[];
        };

        const initialPrimaryEntitiesLookup: PrimaryEntitiesLookup =
          filterNames.reduce((acc, cur) => {
            return {
              ...acc,
              [cur]: [],
            };
          }, {} as PrimaryEntitiesLookup);

        const prevEntityValues = uniqueStoredSet?.entities?.find(
          (ent) => ent.id == LocalSelectionCategories.PRIMARY_ENTITIES
        );

        const previousActiveCount = get(uniqueStoredSet, 'count');

        const entitiesLimit = previousActiveCount || defaultLimit || limit;

        let limitRemaining = entitiesLimit;

        const updatedPrimaryEntities: FilterValue[] = [];

        const pageHasActiveEntities = _.intersectionBy(
          (prevEntityValues?.value as FilterList<ValidValueTypes>) || [],
          primaryEntityValues,
          (x) => `${x.id}-${x.selectionListId}`
        ).some((x) => x.isActive);

        const primaryEntitiesToUpsert = reduce(
          primaryEntityValues,
          (acc, cur, index) => {
            let isActive = get(cur, 'isActive', true);

            if (limitRemaining == 0) {
              updatedPrimaryEntities.push({ ...cur, isActive: false });
              return acc;
            }

            if (!isUndefined(prevEntityValues)) {
              const entityValues = get(prevEntityValues, 'value');
              if (entityValues && Array.isArray(entityValues)) {
                const prevEntity = find(entityValues, (ent: any) => {
                  return (
                    ent.id == cur.id &&
                    ent.selectionListId == cur.selectionListId
                  );
                });

                if (prevEntity) {
                  isActive = get(prevEntity, 'isActive', true);
                }
              }
            }

            // if no active entities, we want to set the first primary entity to true
            if (!pageHasActiveEntities && index == 0) {
              isActive = true;
            }

            if (isActive) {
              const selectionListId: FilterName = get(cur, 'selectionListId');

              acc[selectionListId]?.push(cur);

              limitRemaining -= 1;
            }

            updatedPrimaryEntities.push({ ...cur, isActive });

            return acc;
          },
          initialPrimaryEntitiesLookup
        );

        Object.entries(primaryEntitiesToUpsert).forEach((entry) => {
          const [key, value] = entry;

          if (value.length == 0) {
            deleteFilter(key as FilterName);
            return;
          }

          upsertFilter(key as FilterName, {
            id: key,
            label: key,
            type: 'SELECT',
            isMulti: true,
            selectionListId: key,
            value,
          });
        });

        upsertFilter(LocalSelectionCategories.PRIMARY_ENTITIES, {
          value: updatedPrimaryEntities,
        });
      } else {
        // TODO: jbellizzi - fix to align with filterStore
        filterStore.update(
          setEntities(
            storedFilterSetState?.entities as SelectFilter<ValidValueTypes>[]
          )
        );
      }
    }
  }

  if (setActive) {
    filterStore.update(setActiveSet(setId));
  }

  if (setCustomActive) {
    if (hasRequestedStoredFilterState && isCustomSet) {
      setStoredFilterState(setId);
    } else {
      resetStoredFilterState();
    }
  }
  return hasRequestedStoredFilterState;
}

export function getFiltersState() {
  return filterStore.query(getAllEntities());
}

interface UpsertStoredFilterSetProps {
  def: SetOptional<UserStoredFilterSet, 'id' | 'entities'>;
  options?: {
    updateFiltersState?: boolean;
    updateView?: boolean;
    defaultOptions?: {
      isDefault?: boolean;
      viewIdForDefault?: string;
      existingSet?: any;
    };
  };
  mode?: PersistMethods.CREATE_PRESET | PersistMethods.UPDATE_PRESET;
}

export function upsertStoredFilterSet({
  def,
  options = {
    updateFiltersState: true,
    updateView: true,
  },
  mode = PersistMethods.CREATE_PRESET,
}: UpsertStoredFilterSetProps) {
  const {
    updateFiltersState,
    updateView,
    defaultOptions = { isDefault: false },
  } = options;

  const { isDefault = false, existingSet } = defaultOptions;

  const { entities: currentEntities = [], ...rest } = def;

  // TODO: update this to get just the "current page" filters used.
  // This probably means creating another arrayPropsFactory to track this
  const filtersState = updateFiltersState ? getFiltersState() : currentEntities;

  const entry = { ...rest, entities: filtersState, isDefault: isDefault };

  const entryForServer = {
    ...entry,
    entities: entry.entities.map((f: AnyFilter) => {
      if ([FilterTypes.SELECT].includes(f.type)) {
        f = { ...f, formatOverride: FilterFormatOverrides.PRESETS };
      }
      return f;
    }),
  };

  return saveStoredFilterSetToRemote({
    setEntry: entryForServer,
    updateView,
    isDefault,
    mode,
  }).then(({ preset_id }: JsonValue & { preset_id?: ViewsWithFilters }) => {
    return new Promise((resolve, reject) => {
      try {
        if (preset_id) {
          entry.id = preset_id;
        }

        const predicate = (entity: any) => {
          const isNotSetToUpsert = entity.id !== entry.id;
          const isOldDefault = entity.isDefault;
          const isEntityForCurrentView = entity.view === getActiveSetId();

          return isNotSetToUpsert && isOldDefault && isEntityForCurrentView;
        };

        const { view: currentView } = entryForServer;

        const viewsToRefetchDefaults = [currentView];

        if (!isUndefined(existingSet)) {
          // For existing sets, we want to refetch defaults on the old view
          // if the set was a default on the old view and is being updated
          // to the current view.

          const { view: previousView, isDefault: previousIsDefault } =
            existingSet;

          const viewChange = previousView !== currentView;

          if (viewChange && previousIsDefault) {
            viewsToRefetchDefaults.push(previousView);
          }
        }

        emitOnce(() => {
          filterStore.update(
            isDefault
              ? updateEntitiesByPredicate(
                  predicate,
                  (entity) => {
                    return { ...entity, isDefault: false };
                  },
                  { ref: storedFilterSetEntitiesRef }
                )
              : (x) => x,
            upsertEntities(entry, { ref: storedFilterSetEntitiesRef })
          );

          if (viewsToRefetchDefaults.length > 0) {
            const defaultDataKeys: ViewFilterDefaultIds[] = [];
            const requestCachesToUpdate = {};

            viewsToRefetchDefaults.forEach((view) => {
              const defaultKey = DefaultKeyLookup[view] as ViewFilterDefaultIds;

              defaultDataKeys.push(defaultKey);
              set(requestCachesToUpdate, defaultKey, { value: 'none' });
            });

            emitOnce(() => {
              filterStore.update(
                updateEntities(
                  defaultDataKeys,
                  { value: {}, full: false },
                  { ref: viewFilterDefaultEntitiesRef }
                ),
                updateRequestsCache(requestCachesToUpdate)
              );
            });
          }
        });

        resolve({ isDefault, entry });
      } catch (err) {
        reject({ error: 'Something went wrong!' });
      }
    });
  });
}

export async function deleteExistingStoredFilterSetById(
  setId: string,
  isDefault = false,
  viewIdForDefault: ViewFilterDefaultIds | undefined = undefined
) {
  const response = await lastValueFrom(deleteStoredFilterSetFromRemote(setId));

  if (response.status === 204) {
    // success
    const options = { ref: storedFilterSetEntitiesRef };

    emitOnce(() => {
      filterStore.update(deleteEntities(setId as ViewsWithFilters, options));

      if (isDefault) {
        clearViewViewTypeDefault(viewIdForDefault);
      }
    });

    return Promise.resolve(response.status);
  } else {
    /// delete not successful
    return Promise.reject(response.status);
  }
}

// TODO: possibly add arg to pass in a filter/select entity fn, right now just do it with this fn's results
export function getExistingStoredFilterSets(
  asObservable = false
): Observable<UserStoredFilterSet[]> | UserStoredFilterSet[] {
  const options = { ref: storedFilterSetEntitiesRef };
  // TODO: jbellizzi - fix to align with filterStore
  return asObservable
    ? (filterStore.pipe(selectAllEntities(options)) as Observable<
        UserStoredFilterSet[]
      >)
    : (filterStore.query(getAllEntities(options)) as UserStoredFilterSet[]);
}

export function getExistingStoredFilterSetsObservable(): Observable<
  UserStoredFilterSet[]
> {
  const options = { ref: storedFilterSetEntitiesRef };
  return filterStore.pipe(selectAllEntities(options)) as Observable<
    UserStoredFilterSet[]
  >;
}

export function getActiveSetId() {
  return filterStore.query(getActiveSet);
}

export function useCustomActiveSetState() {
  const [customSet, setCustomSet] = useState<string | undefined>(undefined);
  const [error, setError] = useState(undefined);

  const { untilDestroyed } = useUntilDestroyed();

  useEffect$(() =>
    filterStore.pipe(selectCustomActiveSet()).pipe(
      untilDestroyed(),
      distinct(),
      tap((set) => {
        setCustomSet(set);
      }),
      catchError((e) => {
        setError(e);
        return e;
      })
    )
  );

  return [customSet, error];
}

export function getCustomActiveSetState() {
  return filterStore.pipe(selectCustomActiveSet()).pipe(
    map((set) => (set ? [set] : [])),
    distinctUntilArrayItemChanged(),
    shareReplay<any[]>({ refCount: true })
  );
}

export const pagesExcludedFromDND = [
  FilterSets.COMPANY_SKILL,
  FilterSets.GEOGRAPHY_SKILLS,
  FilterSets.ROLE_SKILLS,
  FilterSets.TALENT_DISCOVERY,
];

interface StoredFilterSetProps {
  sharedSetId: string;
  tab?: string;
  primaryEntitiesSync?: boolean;
  limit?: number;
  filterNames?: (
    | SelectableCategories
    | LocalSelectionCategories
    | FilterName
  )[];
  uniqueSetId?: string;
  defaultLimit?: number;
}

export function useStoredFilterSet({
  sharedSetId,
  tab = '',
  primaryEntitiesSync = false,
  limit = 6,
  filterNames = [] as (
    | SelectableCategories
    | LocalSelectionCategories
    | FilterName
  )[],
  uniqueSetId = '',
  defaultLimit = 0,
}: StoredFilterSetProps) {
  useLifecycles(
    () => {
      const activeSetId = filterStore.query(getActiveSet);
      const existingFiltersState = filterStore.query(getAllEntities());

      const previousTab = filterStore.query(getActiveTab);

      const hasChangedTabs = previousTab != tab;

      if (hasChangedTabs) {
        filterStore.update(setActiveTab(tab));
      }

      // if it's a global state then get it and save/update the global set before unloading it
      if (
        [GlobalFilterSetId, ''].includes(activeSetId) &&
        existingFiltersState.length
      ) {
        filterStore.update(
          upsertEntities(
            {
              id: GlobalFilterSetId as ViewsWithFilters,
              entities: existingFiltersState,
            },
            { ref: storedFilterSetEntitiesRef }
          )
        );
      }

      const hasRequestedStoredFilterState = recallStoredFilterSet(
        sharedSetId,
        true,
        true,
        primaryEntitiesSync,
        limit,
        uniqueSetId,
        defaultLimit,
        filterNames
      );

      if (!hasRequestedStoredFilterState) {
        filterStore.update(deleteAllEntities());
      }

      mixpanel.track(UserTrackingEvents.PAGE_VISIT, { page: getActiveSetId() });
      mixpanel.time_event(UserTrackingEvents.PAGE_LEAVE);
    },
    () => {
      mixpanel.track(UserTrackingEvents.PAGE_LEAVE, { page: getActiveSetId() });
      // save/update this set
      const filtersStateOnExit = filterStore.query(getAllEntities());

      const sharedFilterSet = filterStore.query(
        getEntity(sharedSetId as ViewsWithFilters, {
          ref: storedFilterSetEntitiesRef,
        })
      );

      const sharedEntities: any[] = [];

      const isExcluded = pagesExcludedFromDND.includes(
        uniqueSetId as FilterSets
      );

      const activeEntitiesCount = filtersStateOnExit.reduce((acc, cur) => {
        if (
          filterNames.includes(cur.id) ||
          cur.id == LocalSelectionCategories.PRIMARY_ENTITIES
        ) {
          if (
            !isExcluded &&
            cur.id == LocalSelectionCategories.PRIMARY_ENTITIES
          ) {
            sharedEntities.push(cur);
          }

          if (!filterNames.includes(cur.id)) return acc;

          if (Array.isArray(cur.value)) {
            return acc + cur.value.length;
          }
        }

        return acc;
      }, 0);

      const retrievedSharedEntities = get(
        sharedFilterSet,
        'sharedEntities'
      )?.find((ent: any) => {
        return ent.id == LocalSelectionCategories.PRIMARY_ENTITIES;
      });

      const updatedSharedSet = isExcluded
        ? {
            id: sharedSetId,
            entities: [
              ...filtersStateOnExit.filter((ent) => {
                return ![
                  ...filterNames,
                  LocalSelectionCategories.PRIMARY_ENTITIES,
                ].includes(ent.id);
              }),
            ],
          }
        : {
            id: sharedSetId,
            sharedEntities,
            previousPage: { id: uniqueSetId, limit },
            entities: filtersStateOnExit,
          };

      if (isExcluded && retrievedSharedEntities) {
        updatedSharedSet.entities.push(retrievedSharedEntities);
      }

      const entitiesToUpsert = isExcluded
        ? [
            updatedSharedSet,
            {
              id: uniqueSetId,
              entities: filtersStateOnExit,
              count: activeEntitiesCount,
            },
          ]
        : [
            updatedSharedSet,
            {
              id: uniqueSetId,
              entities: filtersStateOnExit.filter((ent) =>
                [
                  ...filterNames,
                  LocalSelectionCategories.PRIMARY_ENTITIES,
                ].includes(ent.id)
              ),

              count: activeEntitiesCount,
            },
          ];

      filterStore.update(
        upsertEntities(entitiesToUpsert, { ref: storedFilterSetEntitiesRef })
      );
    }
  );
}
