import {
  InputMaybe,
  ScreenerDimension,
  ScreenerReq,
  ScreenerReqFilters,
  ScreenerReqSegments,
} from '@revelio/data-access';
import { ScreenerFilterState, serialiseSubFilter } from '@revelio/filtering';

const convertToInt = (value: string | number): number => {
  return typeof value === 'string' ? parseInt(value, 10) : value;
};

const convertFilterValuesToInt = (
  filter: InputMaybe<ScreenerReqFilters> | undefined
): Record<string, number[]> => {
  return Object.entries(filter || {}).reduce(
    (acc, [key, values]) => {
      if (!values) {
        return acc;
      }

      return {
        ...acc,
        [key]: values.map((value) => convertToInt(value)),
      };
    },
    {} as Record<string, number[]>
  );
};

export const serialiseScreenerFilters = (
  filters: ScreenerFilterState['filters'],
  view: ScreenerDimension
): Omit<ScreenerReq, 'pagination'> => {
  const primaryFilters = Object.values(filters?.primary_filter || {});

  // Group filters by their type selectionListId and convert to int
  const primarySelectors = primaryFilters.reduce(
    (acc, filter) => {
      const key = filter?.selectionListId || '';
      const filterId = filter?.item?.id;

      if (key && filterId) {
        const convertedId = convertToInt(filterId);
        return {
          ...acc,
          [key]: [...(acc[key] || []), convertedId],
        };
      }

      return acc;
    },
    {} as Record<string, number[]>
  );

  const subFilters: ScreenerReqSegments = (
    filters.sub_filters || []
  ).reduce<ScreenerReqSegments>((acc, filter) => {
    const segment = serialiseSubFilter(filter);

    return Object.keys(segment).reduce((innerAcc, key) => {
      const segmentKey = key as keyof ScreenerReqSegments;
      const convertedSegment = segment[segmentKey]?.map((item) => ({
        ...item,
        filter: convertFilterValuesToInt(item.filter),
      }));

      return {
        ...innerAcc,
        [segmentKey]: [
          ...(innerAcc[segmentKey] || []),
          ...(convertedSegment || []),
        ],
      };
    }, acc);
  }, {});

  return {
    dim: view,
    primary_selector: primarySelectors,
    segments: subFilters,
  };
};
