import { graphql } from '@revelio/data-access';

export const GET_SCREENER_DATA = graphql(`
  query GetScreenerData($filters: ScreenerReq!) {
    screener(filters: $filters) {
      pagination {
        total_results
      }
      entities {
        short_name
        long_name
        entity_id
        headcount
        inflow
        outflow
        hiring
        attrition
        growth
        growth_yoy
        salary
        tenure
      }
    }
  }
`);
