import { useState, useEffect, useRef } from 'react';

interface SpacebarPressState {
  isPressed: boolean;
  isHeld: boolean;
}

function useSpacebarPress(): SpacebarPressState {
  const [isPressed, setIsPressed] = useState<boolean>(false);
  const [isHeld, setIsHeld] = useState<boolean>(false);

  const isPressedRef = useRef(isPressed);
  const isHeldRef = useRef(isHeld);

  useEffect(() => {
    isPressedRef.current = isPressed;
    isHeldRef.current = isHeld;
  }, [isPressed, isHeld]);

  const holdTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Space' && !isPressedRef.current) {
        setIsPressed(true);
        holdTimerRef.current = setTimeout(() => {
          setIsHeld(true);
        }, 4000);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        setIsPressed(false);
        if (holdTimerRef.current) {
          clearTimeout(holdTimerRef.current);
          holdTimerRef.current = null;
        }
        setIsHeld(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      if (holdTimerRef.current) {
        clearTimeout(holdTimerRef.current);
      }
    };
  }, []);

  return { isPressed, isHeld };
}

export default useSpacebarPress;
